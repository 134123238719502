const elementsById: Partial<Record<string, HTMLDivElement>> = {};

const createPortalContainer = (id: string) => {
	if (typeof document === 'undefined') {
		return null;
	}

	const element = document.createElement('div');
	element.id = id;
	document.body.appendChild(element);
	elementsById[id] = element;

	return element;
};

// NOTE: This used to be a hook, but it isn't really anymore. Creating a container in a `useEffect`
// isn't viable due to React portals getting confused. That's an implementation detail, though,
// meaning that we can keep the `usePortalContainer` name as if it were a hook.
export const usePortalContainer = (id: string) => elementsById[id] ?? createPortalContainer(id);
