import { actionTypes as ReduxFormActionTypes, FormAction } from 'redux-form';
import { path, curry, dissocPath } from 'ramda';
import { createAction, createReducer } from '@reduxjs/toolkit';

export const saveSubmittedValues = createAction<{ formId: string; values: Record<string, any> }>(
	'@ci/redux-form/saveSubmittedValues'
);

export const getSubmittedValues = curry((formId: string, state: any) =>
	path<Record<string, any>>(['@ci/redux-form', 'submittedValues', formId], state)
);

const initialState = { submittedValues: {} };

export const submittedValuesReducer = createReducer(initialState, builder => {
	builder
		.addCase(ReduxFormActionTypes.DESTROY, (state, { meta: { form } }: FormAction) =>
			dissocPath(['submittedValues', form], state)
		)
		.addCase(saveSubmittedValues, (state, { payload: { values, formId } }) => ({
			...state,
			submittedValues: {
				...state.submittedValues,
				[formId]: values,
			},
		}));
});
