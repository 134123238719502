import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

const STEP_NUMBER_SIZE = '30px';

interface StepNumberStyleProps {
	isActive: boolean;
	isDone: boolean;
}

const stepNumberStyle = prepareStyle<StepNumberStyleProps>((utils, { isActive, isDone }) => ({
	alignItems: 'center',
	backgroundColor: 'transparent',
	border: `1px solid ${utils.colors.gray400}`,
	borderRadius: STEP_NUMBER_SIZE,
	color: utils.colors.gray400,
	display: 'flex',
	fontSize: utils.fontSizes.base,
	fontWeight: utils.fontWeights.normal,
	height: STEP_NUMBER_SIZE,
	justifyContent: 'center',
	lineHeight: 1,
	marginInlineEnd: utils.spacings.md,
	width: STEP_NUMBER_SIZE,
	extend: [
		{
			condition: isActive || isDone,
			style: {
				color: utils.colors.white,
				fontWeight: utils.fontWeights.extraBold,
			},
		},
		{
			condition: isDone,
			style: {
				backgroundColor: utils.colors.black,
				borderColor: utils.colors.black,
			},
		},
		{
			condition: isActive,
			style: {
				backgroundColor: utils.colors.primary,
				borderColor: utils.colors.primary,
			},
		},
		{
			condition: isDone,
			style: {
				backgroundColor: utils.colors.black,
				borderColor: utils.colors.black,
			},
		},
	],
}));

export interface StepperNumberProps {
	activeIndex: number;
	index: number;
	isActive: boolean;
}

export const StepperNumber = ({ activeIndex, index, isActive }: StepperNumberProps) => {
	const { applyStyle } = useStyles();
	const isDone = index < activeIndex;

	return <span className={applyStyle(stepNumberStyle, { isActive, isDone })}>{index + 1}</span>;
};
