import { processNativeStyles, processStyles } from './processStyles';
import { NativeStyle, NativeStyles, Style, Styles } from './types';

export const mergeStyles =
	<TProps extends object>(styles: Styles<TProps>): Style<TProps> =>
	(utils, props) =>
		processStyles(styles, style => style(utils, props));

export const mergeNativeStyles =
	<TProps extends object>(styles: NativeStyles<TProps>): NativeStyle<TProps> =>
	(utils, props) =>
		processNativeStyles(styles, style => style(utils, props));
