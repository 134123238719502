import { ComponentType } from 'react';

export const getDisplayName = (component: ComponentType<any> | string): string => {
	if (typeof component === 'string') {
		return component || 'Unknown';
	}

	if ('displayName' in component && component.displayName) {
		return component.displayName;
	}

	if (component.name) {
		return component.name;
	}

	return 'Unknown';
};
