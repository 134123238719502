import { useSelector } from 'react-redux';
import {
	all,
	any,
	compose,
	curry,
	filter,
	head,
	includes,
	isEmpty,
	isNil,
	keys,
	map,
	o,
	propEq,
	propOr,
} from 'ramda';
import invariant from 'invariant';
import { equalsToTrue, isFunction } from 'ramda-extension';

import { getPermissions } from './features/account';

const getPermission = code =>
	compose(propOr('', 'functionAccess'), head, filter(propEq('functionSetCode', code)));

// use `includes` to match "Read" in "ReadWrite"
const hasPermission = (access, code) => o(includes(access), getPermission(code));
const createHasPermissions = permissions => (predicateFactory, permissionsToCheck) =>
	predicateFactory(
		equalsToTrue,
		o(
			map(code => hasPermission(permissionsToCheck[code], code)(permissions)),
			keys
		)(permissionsToCheck)
	);

export const usePermission = (access, code) => {
	const permissions = useSelector(getPermissions);
	if (isNil(permissions)) {
		return false;
	}

	return hasPermission(access, code)(permissions);
};

export const usePermissions = curry((predicateFactory, permissionsToCheck = {}) => {
	const permissions = useSelector(getPermissions);
	if (isEmpty(permissionsToCheck)) {
		return true;
	}

	invariant(isFunction(predicateFactory), 'predicateFactory has to be function');

	if (isNil(permissions)) {
		return false;
	}

	const hasPermissions = createHasPermissions(permissions);

	return hasPermissions(predicateFactory, permissionsToCheck);
});
export const useAnyPermissions = () => usePermissions(any);
export const useAllPermissions = () => usePermissions(all);
