import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { cx } from 'ramda-extension';
import { stringOrNumberPropType } from '@ci/react-utils';
import './Skeletor.scss';

import Box from '../Box';

const Skeletor = forwardRef(
	(
		{
			className,
			component: Component = Box,
			disableAnimation,
			height = 50,
			style,
			variant = 'rectangle',
			width = 'auto',
			...rest
		},
		ref
	) => (
		<Component
			ref={ref}
			className={cx(
				'skeletor',
				`skeletor--variant-${variant}`,
				{ 'skeletor--animate': !disableAnimation },
				className
			)}
			{...rest}
			style={{ width, height, ...style }}
		/>
	)
);

Skeletor.displayName = 'Skeletor';

Skeletor.propTypes = {
	/** A custom class name. */
	className: PropTypes.string,
	/** The component used for the root node. A string to use a DOM element or a component. */
	component: PropTypes.elementType,
	/** If `true` the animation effect is disabled. */
	disableAnimation: PropTypes.bool,
	/** Height of the skeleton. */
	height: stringOrNumberPropType,
	/** Object with custom styles to apply. */
	style: PropTypes.object,
	/** The type of content that will be rendered. */
	variant: PropTypes.oneOf(['text', 'rectangle', 'circle']),
	/** Width of the skeleton. */
	width: stringOrNumberPropType,
};

export default Skeletor;
