import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';

const ContentInner = ({ withoutShadow, children, className, ...rest }) => (
	<Box
		className={cx('content-inner', withoutShadow && 'content-inner--without-shadow', className)}
		{...rest}
	>
		{children}
	</Box>
);

ContentInner.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** Should have shadow */
	withoutShadow: PropTypes.bool,
};

export default ContentInner;
