import Tippy from '@tippyjs/react';
import type * as CSS from 'csstype';
import { isNil as getIsNil } from 'ramda';
import { ReactElement, ReactNode, createContext, forwardRef } from 'react';

import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';

export const TooltipContext = createContext(false);

// NOTE: This means the tooltip will be placed 20px away from the trigger element (to reserve enough space for the arrow element).
const TOOLTIP_OFFSET = 20;
const TOOLTIP_MAX_WIDTH = '200px';
const TOOLTIP_MIN_WIDTH = '75px';

export interface TooltipStyleProps {
	shouldPreserveCase: boolean;
}

const tooltipStyle = prepareStyle<TooltipStyleProps>((utils, { shouldPreserveCase }) => ({
	backgroundColor: utils.colors.gray800,
	border: 'none',
	borderRadius: utils.borders.radii.basic,
	boxShadow: utils.boxShadows.basic,
	color: utils.colors.white,
	display: 'flex',
	flexDirection: 'column',
	fontSize: utils.fontSizes.caption,
	justifyContent: 'center',
	minWidth: TOOLTIP_MIN_WIDTH,
	padding: `${utils.spacings.xs} ${utils.spacings.sm}`,
	position: 'relative',
	textAlign: 'center',
	whiteSpace: 'pre-wrap',
	zIndex: utils.zIndices.tooltip,
	selectors: {
		'& .tippy-content': {
			position: 'relative',
			zIndex: 1,
		},
		'& .tippy-arrow::before': {
			borderColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: utils.spacings.sm,
			content: "''",
			left: `-${utils.spacings.sm}`,
			position: 'absolute',
		},
		'&[data-placement="top"] > .tippy-arrow': { bottom: 0 },
		'&[data-placement="bottom"] > .tippy-arrow': { top: 0 },
		'&[data-placement="top"] > .tippy-arrow::before': {
			borderBottomWidth: 0,
			borderTopColor: utils.colors.gray800,
			bottom: `-${utils.spacings.sm}`,
			transformOrigin: 'center top',
		},
		'&[data-placement="bottom"] > .tippy-arrow::before': {
			borderBottomColor: utils.colors.gray800,
			borderTopWidth: 0,
			top: `-${utils.spacings.sm}`,
			transformOrigin: 'center bottom',
		},
	},
	extend: {
		condition: !shouldPreserveCase,
		style: {
			textTransform: 'uppercase',
		},
	},
}));

export interface TooltipProps {
	children: ReactElement;
	customStyle?: Style<TooltipStyleProps>;
	maxWidth?: CSS.Property.MaxWidth;
	offset?: number;
	shouldPreserveCase?: boolean;
	tooltip: ReactNode;
}

export const Tooltip = forwardRef<Element, TooltipProps>(
	(
		{
			children,
			customStyle,
			maxWidth = TOOLTIP_MAX_WIDTH,
			offset = TOOLTIP_OFFSET,
			shouldPreserveCase = false,
			tooltip,
		},
		ref
	) => {
		const { applyStyle, utils } = useStyles();

		if (getIsNil(tooltip)) {
			return children;
		}

		return (
			// eslint-disable-next-line react/jsx-boolean-value
			<TooltipContext.Provider value={true}>
				<Tippy
					animation={false}
					placement="bottom"
					content={tooltip}
					maxWidth={maxWidth}
					offset={[0, offset]}
					zIndex={utils.zIndices.tooltip}
					className={applyStyle([tooltipStyle, customStyle], { shouldPreserveCase })}
					ref={ref}
				>
					{children}
				</Tippy>
			</TooltipContext.Provider>
		);
	}
);
