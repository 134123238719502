import { always } from 'ramda';
import { noop } from 'ramda-extension';

import { isInBrowser } from './isInBrowser';

const passive = { current: false };

if (isInBrowser() && typeof window.addEventListener === 'function') {
	const options = {
		// eslint-disable-next-line getter-return
		get passive() {
			passive.current = true;
		},
	};

	window.addEventListener('testPassiveEventSupport', noop, options);
	window.removeEventListener('testPassiveEventSupport', noop, options);
}

export const arePassiveEventsSupported = always(passive.current);
