import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Text from '../Text';
import './Heading.scss';

const Heading = ({ children, className, ...otherProps }) => (
	<Text {...otherProps} className={cx(className)}>
		{children}
	</Text>
);

Heading.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
};

Heading.defaultProps = {
	as: 'h2',
};

export default Heading;
