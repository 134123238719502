import { tap, compose, includes, keys, split, head, filter, find } from 'ramda';

const getInputName = compose(
	head,
	// NOTE: Input names are suffixed by arbitrary IDs. Hyphens are not used in field names.
	split(/-/),
	input => input?.name
);

const makeFindFirstInvalidInput =
	(errors: Record<string, any>) => (allInputs: HTMLInputElement[]) => {
		const invalidFieldNames = keys(errors);

		const possibleInputs = filter(input => {
			const inputName = getInputName(input);

			return Boolean(inputName && includes(inputName, invalidFieldNames)) && !input.disabled;
		}, allInputs);

		const firstInput = head(possibleInputs);

		if (!firstInput) {
			return;
		}

		const firstInputName = getInputName(firstInput);

		// NOTE: If a field comprises of multiple inputs, we assume that they all have the same name.
		// We try to find the one with `data-focuspriority` set and use it as the focus. This is useful
		// for example in `DateTimePicker` if the date value is correct, but the hours are missing.
		return (
			find(
				input => getInputName(input) === firstInputName && Boolean(input.dataset.focuspriority),
				possibleInputs
			) ?? firstInput
		);
	};

const getFormElementChildren = (formElement: HTMLFormElement): HTMLElement[] =>
	Array.from(formElement) as any[];

const makeFocusFirstInvalidFormInput = (errors: Record<string, any>) =>
	compose(
		tap(input => input?.focus()),
		makeFindFirstInvalidInput(errors),
		elements =>
			elements.filter(
				(element): element is HTMLInputElement => element instanceof HTMLInputElement
			),
		getFormElementChildren
	);

const findFormElement = (parentElement: HTMLElement): HTMLFormElement | null =>
	parentElement.getElementsByTagName('FORM').item(0) as any;

export const focusFirstInvalidField = (
	errors: Record<string, any>,
	parentElement: HTMLElement
): HTMLInputElement | undefined => {
	const formElement = findFormElement(parentElement);

	if (formElement) {
		return makeFocusFirstInvalidFormInput(errors)(formElement);
	}
};
