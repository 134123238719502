import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import React, { forwardRef } from 'react';

import Box from '../Box';

/**
 * Horizontal divider. Extends <Box>, so all Box specific utility props can be used.
 */
const ContentDivider = forwardRef(({ full, className, ...rest }, ref) => (
	<Box
		ref={ref}
		className={cx('content-divider', full && 'content-divider--full', className)}
		as="hr"
		{...rest}
	/>
));

ContentDivider.displayName = 'forwardRef(ContentDivider)';

ContentDivider.propTypes = {
	/** Class to be added after the divider default classes */
	className: PropTypes.string,
	/** Should the divider be full width */
	full: PropTypes.bool,
};

export default ContentDivider;
