import { head } from 'ramda';
import { isString } from 'ramda-extension';

const getFrontOfficeError = error => {
	const { target, messageLocalized, code } = error;

	return {
		severity: code,
		fieldName: target,
		message: messageLocalized,
	};
};

/**
 * MyCI API does not send error messages in standardized way. Should be discussed with BE devs, then data could be removed.
 * @param response
 * @returns {*}
 */
export const getErrorMessageFromResponse = response => {
	const { error, errors, error_description: errorDescription, data } = response;

	const errorMessage =
		(error && error.messageLocalized) ||
		errorDescription ||
		(errors ? head(errors)?.message : undefined) ||
		(isString(data) ? data : undefined);

	return errorMessage;
};

// TODO: type action as ErrorEventAction when converting file to TS
export const getInnerErrorFromAction = action => action.meta?.error?.innerError;

export const makeProcessApiErrors = applicationType => parsedResponse => {
	const { errors, error } = parsedResponse;
	if (errors || error) {
		switch (applicationType) {
			case 'FO': {
				if (error?.details) {
					return error.details.map(errorDetail => getFrontOfficeError(errorDetail));
				}
				return [getFrontOfficeError(error)];
			}
			case 'BO': {
				return errors;
			}
			default:
				return {};
		}
	}
};
