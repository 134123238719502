import moment from 'moment';

export const LOCAL_ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type DateModifierPrefix = 'startOf' | 'endOf';
type DateModifierPeriod = 'Day' | 'Week' | 'Month' | 'Year';

export type DateModifier = `${DateModifierPrefix}${DateModifierPeriod}`;

export const formatDate = (
	date: moment.Moment,
	modifier?: DateModifier,
	format: string = LOCAL_ISO_8601_FORMAT
): string => {
	if (modifier?.startsWith('startOf')) {
		const period = modifier.substring('startOf'.length).toLowerCase() as moment.unitOfTime.StartOf;

		return date.startOf(period).format(format);
	}

	if (modifier?.startsWith('endOf')) {
		const period = modifier.substring('endOf'.length).toLowerCase() as moment.unitOfTime.StartOf;

		return date.endOf(period).format(format);
	}

	return date.format(format);
};
