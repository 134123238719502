import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Text from '../Text';

const CurrencyCodeSuffix = ({ className, children = 'USD', ...otherProps }) => (
	<Text as="span" className={cx('align-middle text-suffix', className)} {...otherProps}>
		{children}
	</Text>
);

CurrencyCodeSuffix.propTypes = {
	/** Class name for root. */
	children: PropTypes.node,
	className: PropTypes.string,
};

export default CurrencyCodeSuffix;
