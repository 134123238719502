import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { TabButton } from './TabButton';
import { StepperNumber } from './StepperNumber';
import { TabButtonLabel } from './TabButtonLabel';

export interface StepperTabButtonProps {
	activeIndex: number;
	index: number;
	isActive: boolean;
	isInvalid: boolean;
	label: ReactNode;
	onClick: () => void;
}

export const StepperTabButton = forwardRef(
	(
		{ activeIndex, index, isActive, isInvalid, label, onClick }: StepperTabButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const isDisabled = index > activeIndex;

		return (
			<TabButton
				ref={ref}
				isActive={isActive}
				isInvalid={isInvalid}
				isDisabled={isDisabled}
				onClick={onClick}
			>
				<StepperNumber activeIndex={activeIndex} index={index} isActive={isActive} />
				<TabButtonLabel>{label}</TabButtonLabel>
			</TabButton>
		);
	}
);

StepperTabButton.displayName = 'StepperTabButton';
