import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import Heading from '../Heading';
import Grid from '../Grid';
import Icon from '../Icon';
import ClientLink from '../ClientLink';
import './Panel.scss';

/**
 * Panel displays content inside a block with optional title, link and icon.
 */
const Panel = ({
	children,
	className,
	defaultAlignItems,
	link,
	title,
	titleIcon,
	titleAside,
	titleProps,
	transparent,
	afterTitleContent,
	contentCols = { xs: 12, lg: 10 },
	...otherProps
}) => (
	<Box
		as={link && ClientLink}
		to={link}
		className={cx(
			'panel',
			{ 'panel--no-title': !title },
			{ 'panel--transparent': transparent },
			className
		)}
		{...otherProps}
	>
		{link && <Icon type="next-page" color="icon" size="16" className="panel__link-icon" />}
		<Grid row alignItems={titleAside && !defaultAlignItems ? 'baseline' : null}>
			{title && (
				<Grid col={titleAside ? { xs: 12, lg: 2 } : { xs: 12 }} flex>
					<Heading
						as="h4"
						className={cx('panel__title', { 'panel__title--aside': titleAside })}
						{...titleProps}
					>
						{titleIcon && (
							<Icon
								type={titleIcon}
								size="20"
								color="icon"
								className="panel__title-icon"
								mr={[2, 3]}
							/>
						)}
						{title}
					</Heading>
					{afterTitleContent && <Box ml="auto">{afterTitleContent}</Box>}
				</Grid>
			)}
			<Grid col={titleAside ? contentCols : { xs: 12 }}>{children}</Grid>
		</Grid>
	</Box>
);

Panel.propTypes = {
	/** Content rendered right in header element */
	afterTitleContent: PropTypes.node,
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** Object with grid cols system for content in panel. */
	contentCols: PropTypes.object,
	/** Set align items prop to defaul value. */
	defaultAlignItems: PropTypes.bool,
	/** Anchor link to another route/page. */
	link: PropTypes.string,
	/** Title aside position */
	title: PropTypes.node,
	/** Icon used in panel title . */
	titleAside: PropTypes.bool,
	/** Panel title props */
	titleIcon: PropTypes.string,
	/** Props for Panel Title */
	titleProps: PropTypes.object,
	/** If `true`, dashboard panel's background is transparent. */
	transparent: PropTypes.bool,
};

export default Panel;
