import {
	NativeStyle,
	NativeStyleObject,
	NativeStyleUtils,
	Style,
	StyleObject,
	StyleUtils,
} from './types';

export const prepareStyle = <TProps extends object>(style: Style<TProps>) => style;

export const prepareNativeStyle = <TProps extends object>(style: NativeStyle<TProps>) => style;

export const prepareStyleFactory =
	<TOurProps extends object, TTheirProps extends object = object>(
		style: (utils: StyleUtils, ourProps: TOurProps, theirProps: TTheirProps) => StyleObject
	) =>
	(ourProps: TOurProps): Style<TTheirProps> =>
	(utils, theirProps) =>
		style(utils, ourProps, theirProps);

export const prepareNativeStyleFactory =
	<TOurProps extends object, TTheirProps extends object = object>(
		style: (
			utils: NativeStyleUtils,
			ourProps: TOurProps,
			theirProps: TTheirProps
		) => NativeStyleObject
	) =>
	(ourProps: TOurProps): NativeStyle<TTheirProps> =>
	(utils, theirProps) =>
		style(utils, ourProps, theirProps);
