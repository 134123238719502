import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { always, ifElse } from 'ramda';
import { includes } from 'ramda-extension';

import Box from '../Box';

import {
	EventTypes,
	baseFunctionReducer,
	combineReducers,
	getDefaultOpenIndicies,
	openAllReducer,
} from './utils';
import './Accordion.scss';

const getType = ifElse(includes, always(EventTypes.CLOSING), always(EventTypes.OPENING));

class Accordion extends Component {
	static propTypes = {
		/** Children which is rendering content. */
		children: PropTypes.func.isRequired,
		/** Class name for root element. */
		className: PropTypes.string,
		/** Index of panel that is opened by default. */
		defaultOpenIndex: PropTypes.number,
		/** Length of indices **/
		indicesLength: PropTypes.number,
		/** State reducers list that is applied to accordion state handler. */
		reducers: PropTypes.arrayOf(PropTypes.func),
	};

	static defaultProps = {
		reducers: [],
	};

	state = {
		openIndices: getDefaultOpenIndicies(this.props.defaultOpenIndex),
	};

	openAll() {
		const { indicesLength } = this.props;

		this.reduceState({
			state: this.state,
			action: { type: EventTypes.OPEN_ALL, indicesLength },
		});
	}

	reduceState = data => {
		const { reducers } = this.props;
		const combinedReducers = combineReducers([...reducers, baseFunctionReducer, openAllReducer]);
		this.setState(combinedReducers(data));
	};

	onClickHandler = index => () => {
		this.reduceState({
			state: this.state,
			action: { type: getType(this.state.openIndices, index), index },
		});
	};

	render() {
		const { children, ...rest } = this.props;
		const { openIndices } = this.state;

		return (
			<Box className="accordion">
				{children({
					onClickHandler: this.onClickHandler,
					openIndices,
					...rest,
				})}
			</Box>
		);
	}
}

export default Accordion;
