import { cx } from 'ramda-extension';
import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

const AccordionBody = ({ className, children }) => (
	<Box className={cx('accordion__item-body', className)}>{children}</Box>
);

AccordionBody.propTypes = {
	/** Body content */
	children: PropTypes.node,
	/** ClassName */
	className: PropTypes.string,
	/** Identifier for opened/closed states */
	isOpen: PropTypes.bool,
};

export default AccordionBody;
