import { ReactNode, useContext } from 'react';

import { ExposedMessage } from './ExposedMessage';
import { TranslationModeContext } from '../contexts';
import { MessageId } from '../types';

export interface TranslationModeAwareMessageProps {
	children: ReactNode;
	id: MessageId;
}

export const TranslationModeAwareMessage = ({ id, children }: TranslationModeAwareMessageProps) => {
	const isTranslationModeEnabled = useContext(TranslationModeContext);

	return isTranslationModeEnabled ? (
		<ExposedMessage title={String(id)}>{children}</ExposedMessage>
	) : (
		<span data-testid={id}>{children}</span>
	);
};
