import { Text } from '@creditinfo-ui/atoms';
import { prepareStyleFactory } from '@creditinfo-ui/styles';
import { ReactNode } from 'react';

interface TitleStyleProps {
	isCentered: boolean;
}

const titleStyle = prepareStyleFactory<TitleStyleProps>((utils, { isCentered }) => ({
	marginBottom: utils.spacings.md,

	paddingLeft: utils.spacings.md,
	paddingRight: utils.spacings.md,

	selectors: {
		[utils.breakpoints.lg]: {
			paddingLeft: utils.spacings.lg,
			paddingRight: utils.spacings.lg,
		},
	},

	extend: {
		condition: isCentered,
		style: {
			// HACK: Must be important due to `Heading.scss` having `textAlign` with a high specificity.
			// @ts-expect-error
			textAlign: 'center !important',
		},
	},
}));

export interface StaticModalHeaderProps {
	isCentered?: boolean;
	title: ReactNode;
}

export const StaticModalHeader = ({ isCentered = false, title }: StaticModalHeaderProps) => (
	<Text customStyle={titleStyle({ isCentered })} variant="extraTitle">
		{title}
	</Text>
);
