import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Text from '../Text';
import Box from '../Box';

export const StatusBarTitle = props => (
	<Text className="collapsible__title" as="div" mr={3} weight="bold" {...props} />
);

export const StatusBarInner = props => <Box className="collapsible__status-inner" {...props} />;

export const StatusBar = ({ className, ...otherProps }) => (
	<Box className={cx('collapsible__status', className)} {...otherProps} />
);

StatusBar.propTypes = {
	className: PropTypes.string,
};
