import { isFetching, request } from '@ci/api';
import { run } from '@ci/control-flow';
import { intl } from '@myci/intl';
import { navigate } from '@myci/navigation';
import { addToast } from '@ci/toasts';
import { startSubmitForm, stopSubmitForm } from '@myci/ui-components-redux';
import {
	composeMiddleware,
	getCurrentUrlWithoutQueryParams,
	isInBrowser,
	makeMiddleware,
	setBrowserLocationUsingPost,
	typeEq,
} from '@myci/utils';
import {
	makeActionTypes,
	makeConstantActionCreator,
	makeReducer,
	makeSimpleActionCreator,
} from 'redux-syringe';
import { complement, mergeDeepLeft, o, path, propOr } from 'ramda';
import { reset } from 'redux-form';

import m from '../messages';

const ActionTypes = makeActionTypes('@wallet', [
	'FETCH_WALLET_BALANCE',
	'SET_WALLET_BALANCE',
	'SET_INITIALIZED',
	'TOP_UP',
]);

export const fetchWalletBalance = makeConstantActionCreator(ActionTypes.FETCH_WALLET_BALANCE);
export const setWalletBalance = makeSimpleActionCreator(ActionTypes.SET_WALLET_BALANCE);
export const topUp = makeSimpleActionCreator(ActionTypes.TOP_UP);

const initialState = {
	initialized: false,
	wallet: {},
};

const getSlice = path(['userWallet']);

export const isUserWalletInitialized = o(path(['initialized']), getSlice);
export const isNotUserWalletInitialized = complement(isUserWalletInitialized);
export const isFetchingTopUp = isFetching(ActionTypes.TOP_UP);

export const getWalletBalanceAmount = o(path(['wallet', 'balance']), getSlice);
export const getWalletBalanceCurrency = o(path(['wallet', 'currency']), getSlice);

const fetchWalletBalanceMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_WALLET_BALANCE),
	({ dispatch }) =>
		action => {
			dispatch(
				run(
					() =>
						request(
							{
								url: 'wallet/balance',
							},
							{
								origin: action,
							}
						),
					({ payload }, { isError }) => {
						if (isError) {
							return dispatch(
								addToast({
									type: 'warning',
									content: m.walletFetchFailed,
								})
							);
						}

						dispatch(setWalletBalance(payload));
					}
				)
			);
		}
);

const topUpBalanceMiddleware = makeMiddleware(
	typeEq(ActionTypes.TOP_UP),
	({ dispatch }) =>
		action => {
			const { amount, method, form } = action.payload;
			const callbackUrl = isInBrowser() ? getCurrentUrlWithoutQueryParams() : '';
			dispatch(startSubmitForm(form));
			dispatch(
				run(
					() =>
						request(
							{
								url: 'wallet/funds',
								method: 'POST',
								body: {
									method,
									amount,
									uiCallback: callbackUrl,
								},
							},
							{
								origin: action,
								form,
							}
						),
					(action, { isError }) => {
						const { payload } = action;
						dispatch(stopSubmitForm(action));

						if (isError) {
							return dispatch(
								addToast({
									type: 'warning',
									content: m.topUpBalanceFailed,
								})
							);
						}

						const redirectUrl = path(['result', 'redirectUrl'], payload);
						const paymentData = path(['result', 'data', 'formData'], payload);

						if (isInBrowser()) {
							setBrowserLocationUsingPost(redirectUrl, paymentData);
						} else {
							// NOTE: successParams & failParams are not extracted outside middleware because it has to be constructed after intl initialization
							navigate('webView', {
								// TODO: add test url to PR description uri: 'http://10.0.2.2:8000/payment-success',
								uri: redirectUrl,
								successParams: {
									image: 'payment',
									heading: intl.formatMessage(m.paymentSuccesful),
									description: intl.formatMessage(m.addedToBalance),
									button: {
										label: intl.formatMessage(m.ok),
										onPress: () => navigate('app'),
									},
								},
								failParams: {
									image: 'failed',
									heading: intl.formatMessage(m.paymentFailed),
									description: intl.formatMessage(m.paymentFailedText),
									button: {
										label: intl.formatMessage(m.ok),
										onPress: () => navigate('app'),
									},
								},
							});
						}
						dispatch(reset(form));
					}
				)
			);
		}
);

export const userWalletMiddleware = composeMiddleware(
	fetchWalletBalanceMiddleware,
	topUpBalanceMiddleware
);

const reducer = makeReducer(
	[
		[
			ActionTypes.SET_WALLET_BALANCE,
			(state, { payload }) =>
				mergeDeepLeft(
					{
						initialized: true,
						wallet: propOr({}, 'result', payload),
					},
					state
				),
		],
	],
	initialState
);

export default reducer;
