import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNilOrEmptyString } from 'ramda-extension';

import {
	fetchVersions,
	selectApiVersion,
	selectMinAndroidVersion,
	selectMinIosVersion,
} from './duck';

export const useVersions = () => {
	const dispatch = useDispatch();
	const apiVersion = useSelector(selectApiVersion);
	const minAndroidVersion = useSelector(selectMinAndroidVersion);
	const minIosVersion = useSelector(selectMinIosVersion);

	useEffect(() => {
		if (isNilOrEmptyString(apiVersion)) {
			dispatch(fetchVersions());
		}
	}, [apiVersion]);

	return { apiVersion, minAndroidVersion, minIosVersion };
};
