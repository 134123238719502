import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import { useTheme } from '../ThemeProvider';

import './Label.scss';

const Label = ({ children, className, isHidden, isDisabled, isReadOnly, ...rest }) => {
	const theme = useTheme();

	return (
		<label
			{...rest}
			className={cx(
				'control-label',
				{
					'control-label--dir-rtl': theme.isRTL,
					'sr-only': isHidden,
					'control-label--readonly': isReadOnly,
					'control-label--disabled': isDisabled,
				},
				className
			)}
		>
			{children}
		</label>
	);
};

Label.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	/** Whether the label should be hidden on regular screens. */
	isHidden: PropTypes.bool,
	isReadOnly: PropTypes.bool,
};

Label.defaultProps = {
	isHidden: false,
};

export default Label;
