import { compose, prop, path, assocPath, o, complement } from 'ramda';
import genericDecodeToken from 'jwt-decode';

// NOTE: `exp` should be in seconds, but `Date.now()` is in milliseconds
const getIsHistoricalTimestamp = (exp: number) => exp * 1000 < Date.now();

const decodeToken = (token: string) => genericDecodeToken<any>(token);

export const getIsTokenExpired = compose(getIsHistoricalTimestamp, prop('exp'), decodeToken);

export const getIsBuffered = o(Boolean, path(['meta', 'isBuffered']));
export const getIsNotBuffered = complement(getIsBuffered);
export const setIsBuffered = assocPath(['meta', 'isBuffered'], true);
