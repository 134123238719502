import {
	Dispatch,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { head, last } from 'ramda';

import { FontFaceLoader } from '@creditinfo-ui/fonts';
import { VerticalSpacing } from '@creditinfo-ui/atoms';
import { FontWeight, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { getCurrentQueryParams, serializeQueryParams } from '@creditinfo-ui/utils';

import { Tab } from './Tab';
import { TabContent } from './TabContent';
import { TabDropdown } from './TabDropdown';
import { HorizontalInset, TabItem, TabKey, TabsVariant } from '../types';
import { resolveHorizontalInset } from '../utils';

import {
	useFindNextTabKey,
	useFindPreviousTabKey,
	useFindTabIndex,
	useTabDropdown,
} from '../hooks';

export const TabsDefaultHorizontalInsetContext = createContext<HorizontalInset>('none');

export interface TabBarStyleProps {
	areWidthStatesInitialized: boolean;
	horizontalInset: HorizontalInset;
	isTabBarCentered: boolean;
}

const tabBarStyle = prepareStyle<TabBarStyleProps>(
	(utils, { areWidthStatesInitialized, horizontalInset, isTabBarCentered }) => ({
		...resolveHorizontalInset(horizontalInset, utils),
		display: 'flex',
		extend: [
			{
				condition: isTabBarCentered,
				style: {
					justifyContent: 'center',
				},
			},
			{
				condition: !areWidthStatesInitialized,
				style: {
					visibility: 'hidden',
				},
			},
		],
	})
);

export interface TabsProps {
	activeKey?: TabKey;
	contentVerticalPadding?: VerticalSpacing;
	defaultActiveKey?: TabKey;
	hasInsetShadow?: boolean;
	horizontalInset?: HorizontalInset;
	isTabBarCentered?: boolean;
	onTabClick?: (key: TabKey) => void;
	queryParam?: string;
	setActiveKey?: Dispatch<SetStateAction<TabKey>>;
	tabs: TabItem[];
	variant?: TabsVariant;
}

const PureTabs = ({
	activeKey: activeKeyProp,
	contentVerticalPadding = 'none',
	defaultActiveKey,
	hasInsetShadow = false,
	horizontalInset: horizontalInsetProp,
	isTabBarCentered = false,
	onTabClick,
	setActiveKey: setActiveKeyProp,
	queryParam,
	tabs,
	variant = 'default',
}: TabsProps) => {
	const { applyStyle } = useStyles();
	const [localActiveKey, setLocalActiveKey] = useState(() => {
		const queryParamActiveKey = queryParam ? getCurrentQueryParams()[queryParam] : null;

		return queryParamActiveKey ?? defaultActiveKey ?? tabs[0].key;
	});

	const activeKey = activeKeyProp === undefined ? localActiveKey : activeKeyProp;
	const activeKeyRef = useRef(activeKey);

	const defaultHorizontalInset = useContext(TabsDefaultHorizontalInsetContext);
	const horizontalInset = horizontalInsetProp ?? defaultHorizontalInset;

	useEffect(() => {
		activeKeyRef.current = activeKey;
	}, [activeKey]);

	const setActiveKey = setActiveKeyProp ?? setLocalActiveKey;

	const findTabIndex = useFindTabIndex(tabs);
	const activeIndex = useMemo(() => findTabIndex(activeKey), [activeKey, findTabIndex]);

	const handleTabClick = useCallback(
		(key: TabKey) => {
			onTabClick?.(key);
			setActiveKey(key);

			if (queryParam) {
				window.history.replaceState(
					window.history.state,
					'',
					`${window.location.pathname}?${serializeQueryParams({
						...getCurrentQueryParams(),
						[queryParam]: key,
					})}`
				);
			}
		},
		[onTabClick, queryParam, setActiveKey]
	);

	const {
		areWidthStatesInitialized,
		dropdownItems,
		dropdownRef,
		isDropdownActive,
		makeTabRefCallback,
		shouldRenderDropdown,
		shownTabsCount,
		tabBarRef,
	} = useTabDropdown(tabs, activeKey);

	const findNextTabKey = useFindNextTabKey(tabs);
	const findPreviousTabKey = useFindPreviousTabKey(tabs);

	const next = useCallback(() => {
		setActiveKey(findNextTabKey);
	}, [setActiveKey, findNextTabKey]);

	const previous = useCallback(() => {
		setActiveKey(findPreviousTabKey);
	}, [setActiveKey, findPreviousTabKey]);

	return (
		<>
			<div
				role="tablist"
				className={applyStyle(tabBarStyle, {
					areWidthStatesInitialized,
					horizontalInset,
					isTabBarCentered,
				})}
				ref={tabBarRef}
			>
				{tabs.map(
					(tab, index) =>
						index < shownTabsCount && (
							<Tab
								ref={makeTabRefCallback(tab.key)}
								tab={tab}
								key={tab.key}
								index={index}
								variant={variant}
								onTabClick={handleTabClick}
								activeKey={activeKey}
								activeIndex={activeIndex}
							/>
						)
				)}
				{shouldRenderDropdown && (
					<TabDropdown
						ref={dropdownRef}
						activeIndex={activeIndex}
						activeKey={activeKey}
						isActive={isDropdownActive}
						items={dropdownItems}
						onTabClick={handleTabClick}
						shownTabsCount={shownTabsCount}
						variant={variant}
					/>
				)}
			</div>
			<div>
				{tabs.map(tab => {
					const { content, key, isAlwaysMounted, verticalPadding } = tab;
					const isVisible = activeKey === key;

					return (
						(isVisible || isAlwaysMounted) && (
							<TabContent
								hasInsetShadow={hasInsetShadow}
								horizontalInset={horizontalInset}
								isFirst={tab === head(tabs)}
								isLast={tab === last(tabs)}
								isVisible={isVisible}
								key={key}
								next={next}
								previous={previous}
								setActiveKey={setActiveKey}
								verticalPadding={verticalPadding ?? contentVerticalPadding}
							>
								{content}
							</TabContent>
						)
					);
				})}
			</div>
		</>
	);
};

// NOTE: Necessary for calculating the widths properly.
// `normal` is inactive tab, `extraBold` is active tab.
const tabsFontWeights: FontWeight[] = ['normal', 'extraBold'];

export const Tabs = (props: TabsProps) => (
	<FontFaceLoader fontWeights={tabsFontWeights}>
		<PureTabs {...props} />
	</FontFaceLoader>
);
