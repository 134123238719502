import moment from 'moment';
import { startsWithPrefix } from 'ramda-extension';

const FORMAT_ISO8601_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';
const FORMAT_ISO8601_WITHOUT_TIME_AND_TZ = 'YYYY-MM-DD';

/**
 * Returns an ISO 8601 without time zone date string from a date
 * @param date Any date format acceptable by moment
 * @param modifier Modifier in the form (startOf|endOf)(year|month|...|second)
 * @see https://momentjs.com/docs/#/manipulating/start-of/
 * @see https://momentjs.com/docs/#/manipulating/end-of/
 */
export const getDateIsoString = (date, modifier) => {
	if (!date) {
		return '';
	}

	const momentDate = moment(date);

	if (!modifier) {
		return momentDate.format(FORMAT_ISO8601_WITHOUT_TZ);
	}

	if (startsWithPrefix('startOf', modifier)) {
		return momentDate.startOf(modifier.substr('startOf'.length)).format(FORMAT_ISO8601_WITHOUT_TZ);
	}

	if (startsWithPrefix('endOf', modifier)) {
		return momentDate.endOf(modifier.substr('endOf'.length)).format(FORMAT_ISO8601_WITHOUT_TZ);
	}

	console.warn(`Invalid modifier ${modifier}`);

	return '';
};

export const getDateIsoWithoutTimeString = date => {
	if (!date) {
		return '';
	}
	const momentDate = moment(date);

	return momentDate.format(FORMAT_ISO8601_WITHOUT_TIME_AND_TZ);
};
