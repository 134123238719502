import { Breakpoint } from '@creditinfo-ui/fela';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { ShowcaseItem } from './ShowcaseItem';
import { ShowcaseItemDescriptor } from './types';

export interface ShowcaseStyleProps {
	itemCount: number;
	mobileLayoutBreakpoint: Breakpoint;
}

const showcaseStyle = prepareStyle<ShowcaseStyleProps>(
	(utils, { itemCount, mobileLayoutBreakpoint }) => ({
		display: 'grid',
		gridAutoColumns: 'minmax(min-content, 1fr)',
		gridAutoFlow: 'column',
		gridTemplateRows: `repeat(${Math.ceil(itemCount / 3) * 2}, min-content)`,
		marginBottom: utils.multiply(-1, utils.spacings.sm),
		marginTop: utils.multiply(-1, utils.spacings.sm),
		width: '100%',

		selectors: {
			[utils.breakpoints[mobileLayoutBreakpoint]]: {
				gridTemplateRows: 'repeat(2, min-content)',
			},
		},
	})
);

export interface ShowcaseProps {
	customStyle?: Style<ShowcaseStyleProps>;
	items: ShowcaseItemDescriptor[];
	mobileLayoutBreakpoint?: Breakpoint;
}

export const Showcase = ({ items, mobileLayoutBreakpoint = 'sm' }: ShowcaseProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={applyStyle(showcaseStyle, { itemCount: items.length, mobileLayoutBreakpoint })}>
			{items.map((item, index) => (
				<ShowcaseItem key={index} mobileLayoutBreakpoint={mobileLayoutBreakpoint} {...item} />
			))}
		</div>
	);
};
