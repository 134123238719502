import { typeEq, generateUuid } from '@ci/utils';
import { tail, isNil, head } from 'ramda';
import { isNotEmpty } from 'ramda-extension';
import invariant from 'invariant';

import { MethodActionTypes } from '../actions';
import {
	isTaskEvent,
	assocTaskId,
	getTaskId,
	isTaskError,
	makeCallback,
	getControlFlowId,
} from '../utils';

const middleware = ({ dispatch }) => {
	const tasksByControlFlowId = {};
	const callbacksByControlFlowId = {};
	const controlFlowIdsByTaskId = {};
	const cancelledControlFlowIds = new Set();

	return next => action => {
		next(action);

		if (typeEq(MethodActionTypes.WATERFALL, action)) {
			const { tasks, callback: userCallback } = action.payload;
			const controlFlowId = getControlFlowId(action);
			const delegatedTaskId = getTaskId(action);

			invariant(isNotEmpty(tasks), 'You must pass at least one task to waterfall.');

			const nextTask = head(tasks);
			const nextAction = nextTask();
			tasksByControlFlowId[controlFlowId] = tail(tasks);

			callbacksByControlFlowId[controlFlowId] = makeCallback(
				userCallback,
				dispatch,
				delegatedTaskId
			);

			const taskId = generateUuid();
			controlFlowIdsByTaskId[taskId] = controlFlowId;
			dispatch(assocTaskId(taskId, nextAction));
		}

		if (typeEq(MethodActionTypes.WATERFALL_CANCEL, action)) {
			const controlFlowId = action.payload;
			cancelledControlFlowIds.add(controlFlowId);

			if (tasksByControlFlowId[controlFlowId]) {
				tasksByControlFlowId[controlFlowId] = [];
			}
		}

		if (isTaskEvent(action)) {
			const { taskId } = action.payload;
			const controlFlowId = controlFlowIdsByTaskId[taskId];

			if (isNil(controlFlowId)) {
				return;
			}

			const callback = callbacksByControlFlowId[controlFlowId];

			if (isTaskError(action)) {
				return callback(action);
			}

			const tasks = tasksByControlFlowId[controlFlowId];
			const nextTask = head(tasks);

			if (!nextTask) {
				return callback(action, { isCancelled: cancelledControlFlowIds.has(controlFlowId) });
			}

			const nextAction = nextTask(action.payload.event);
			tasksByControlFlowId[controlFlowId] = tail(tasks);
			const nextTaskId = generateUuid();
			controlFlowIdsByTaskId[nextTaskId] = controlFlowId;
			dispatch(assocTaskId(nextTaskId, nextAction));
		}
	};
};

export default middleware;
