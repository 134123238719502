import { ComponentType } from 'react';
import { getDisplayName } from '@creditinfo-ui/utils';

import { Modal, ModalProps } from './components/Modal';

export const withModal =
	<TPayload extends object, TPresenterProps extends object>(
		modalProps: Omit<ModalProps<TPayload, TPresenterProps>, 'children'>
	) =>
	<TProps extends object>(Component: ComponentType<TProps>) => {
		const WithModal = (props: TProps) => (
			<Modal {...modalProps}>
				<Component {...props} />
			</Modal>
		);

		WithModal.displayName = `WithModal(${getDisplayName(Component)})`;

		return WithModal;
	};
