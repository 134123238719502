import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSubmitting, isValid } from 'redux-form';
import { defaultNamespace, useNamespace, useNamespacedSelector } from 'redux-syringe';
import { o } from 'ramda';
import { safeIncludes } from '@myci/utils';
import { getAllFormErrors } from '../forms';

import { FEATURE } from './constants';
import {
	getCurrentStep,
	getDirtySteps,
	getHasJourneyStepErrors,
	getJourneyFirstInvalidStep,
	getNumberOfSteps,
	getShouldFocusFirstInvalidField,
	getVisitedSteps,
	isInitialized,
} from './selectors';
import {
	goToNextStep,
	goToPreviousStep,
	goToStep,
	reset,
	setNumberOfSteps,
	submit,
} from './actions';
import { FormProps } from './contexts';

const useNamespacedDispatch = (forcedNamespace, feature, ...args) => {
	const dispatch = useDispatch(...args);
	const namespace = useNamespace(feature);

	return o(dispatch, defaultNamespace(forcedNamespace || namespace));
};

const useJourneySelector = (selector, forcedNamespace) =>
	useNamespacedSelector(selector, undefined, { feature: FEATURE, namespace: forcedNamespace });

export const useIsJourneyInitialized = forcedNamespace =>
	useJourneySelector(isInitialized, forcedNamespace);

export const useJourneyCurrentStep = forcedNamespace =>
	useJourneySelector(getCurrentStep, forcedNamespace);

export const useJourneyNumberOfSteps = forcedNamespace =>
	useJourneySelector(getNumberOfSteps, forcedNamespace);

export const useIsJourneyStepVisited = forcedNamespace => {
	const visitedSteps = useJourneySelector()(forcedNamespace)(getVisitedSteps);

	const isJourneyStepVisited = step => safeIncludes(step, visitedSteps);

	return isJourneyStepVisited;
};

export const useIsJourneyStepDirty = forcedNamespace => {
	const dirtySteps = useFormSelector(forcedNamespace)(getDirtySteps);
	const isJourneyStepDirty = step => safeIncludes(step, dirtySteps);

	return isJourneyStepDirty;
};

// FIXME:
const useFormSelector = forcedNamespace => selector => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const namespace = useNamespace(FEATURE);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelector(selector(forcedNamespace || namespace));
};

export const useIsJourneyFormSubmitting = forcedNamespace =>
	useFormSelector(forcedNamespace)(isSubmitting);

export const useJourneyErrors = forcedNamespace =>
	useFormSelector(forcedNamespace)(getAllFormErrors);

export const useJourneyFirstInvalidStep = forcedNamespace =>
	useFormSelector(forcedNamespace)(getJourneyFirstInvalidStep);

export const useHasJourneyStepErrors = forcedNamespace =>
	useFormSelector(forcedNamespace)(getHasJourneyStepErrors);

export const useIsJourneyFormValid = forcedNamespace => useFormSelector(forcedNamespace)(isValid);

export const useJourneyControls = journey => {
	const dispatch = useNamespacedDispatch(journey, FEATURE);

	// One must love Dan:
	// https://github.com/reduxjs/react-redux/issues/1252#issuecomment-488160930
	// bindActionCreators are not good for our codebase!
	return {
		goToStep: useCallback((...args) => dispatch(goToStep(...args)), [dispatch]),
		goToNextStep: useCallback((...args) => dispatch(goToNextStep(...args)), [dispatch]),
		goToPreviousStep: useCallback((...args) => dispatch(goToPreviousStep(...args)), [dispatch]),
		submit: useCallback((...args) => dispatch(submit(...args)), [dispatch]),
		setNumberOfSteps: useCallback((...args) => dispatch(setNumberOfSteps(...args)), [dispatch]),
		reset: useCallback((...args) => dispatch(reset(...args)), [dispatch]),
	};
};

export const useJourneyFormProps = () => useContext(FormProps);

export const useShouldFocusFirstInvalidField = namespace =>
	useJourneySelector(getShouldFocusFirstInvalidField, namespace);
