import React, { forwardRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { ascend, compose, filter, identity, includes, isNil, reject, sortWith } from 'ramda';
import { includesIgnoreCase, toggle } from '@ci/utils';
import { stringOrNumberPropType } from '@ci/react-utils';
import { cx, isNilOrEmpty, isNotNil, mapIndexed, noop } from 'ramda-extension';
import { Message } from '@myci/intl';

import {
	SelectStaticBehaviors,
	getItemKey,
	getOriginalStaticKey,
	getSelectSortCharacter,
	isSelectStatic,
	useStaticAwareFormatter,
} from '../../constants';
import m from '../../messages';
import Menu from '../Menu';
import TextInput from '../TextInput';
import MenuItem from '../MenuItem';
import Checkbox from '../Checkbox';
import Box from '../Box';
import Button from '../Button';
import Grid from '../Grid';

import './Multiselect.scss';

import {
	changeCheckbox,
	clear,
	confirm,
	initialState,
	reducer,
	setControlledArray,
	setDefaultStatic,
	setIsOpen,
	setSearchValue,
	setShowAllItems,
} from './reducer';
import useRenderedValues from '../../utils/useRenderedValues';

const getStaticBehavior = key => SelectStaticBehaviors[getOriginalStaticKey(key)];

const Multiselect = forwardRef((props, ref) => {
	const {
		className,
		disabled,
		formatter,
		onChange,
		items: allItems,
		value,
		statics = [],
		id,
		name,
		emptyValueSelectsAll = false,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const { searchValue, controlledArray, isOpen, showAllItems, defaultStatic } = state;
	const valueKey = getItemKey(value);
	const Formatter = useStaticAwareFormatter(formatter);

	const renderedValues = useRenderedValues(Formatter, allItems, statics);

	const handleInputValueChange = e => {
		// NOTE: Sometimes, an `onBlur` action emits `null`, so we want to ignore it.
		if (isNotNil(e.target.value)) {
			dispatch(setSearchValue(e.target.value));
		}

		dispatch(setShowAllItems(false));
	};

	useEffect(() => statics.forEach(s => s.default && dispatch(setDefaultStatic(s))), [statics]);

	useEffect(() => {
		if (emptyValueSelectsAll && isNilOrEmpty(value)) {
			dispatch(setControlledArray([...allItems]));
			onChange(allItems);
		} else {
			dispatch(setControlledArray(isNilOrEmpty(value) ? [] : [...value]));
		}
	}, [allItems]);

	// NOTE: Necessary for setting initial input value. Acts as a delayed `componentDidMount`.
	useEffect(() => {
		if (renderedValues[valueKey]) {
			dispatch(setSearchValue(renderedValues[valueKey]));
		}
	}, [renderedValues, value, valueKey]);

	return (
		<Downshift
			// NOTE: This is just to suppress some downshift warnings (we use formatters!)
			itemToString={identity}
			inputValue={searchValue}
			onOuterClick={() => {
				onChange(controlledArray);
				dispatch(confirm());
			}}
			isOpen={isOpen}
		>
			{({ getMenuProps, highlightedIndex, getItemProps, inputValue, getToggleButtonProps }) => {
				const renderCheckboxes = compose(
					mapIndexed((item, index) => {
						const key = getItemKey(item);

						const staticBehavior = getStaticBehavior(key);

						return (
							<MenuItem
								className={cx({
									active: highlightedIndex === index,
									selected: includes(item, controlledArray),
								})}
								{...getItemProps({ item, key, index })}
							>
								<Checkbox
									checked={
										staticBehavior
											? staticBehavior.isChecked(controlledArray, allItems)
											: includes(item, controlledArray)
									}
									label={<Formatter>{item}</Formatter>}
									name={`checkbox_${key}`}
									onChange={() => {
										if (isSelectStatic(item) && staticBehavior) {
											dispatch(
												setControlledArray(staticBehavior.onSelect(controlledArray, allItems))
											);
										} else {
											dispatch(changeCheckbox(toggle(item, controlledArray)));
										}
									}}
								/>
							</MenuItem>
						);
					}),
					sortWith([ascend(getSelectSortCharacter), ascend(Formatter.formatAsPrimitive)]),
					filter(item => {
						if (item.hidden) {
							return false;
						}

						if (!inputValue || showAllItems) {
							return true;
						}

						const key = getItemKey(item);
						const renderedValue = renderedValues[key];

						return includesIgnoreCase(inputValue, renderedValue);
					}),
					reject(isNil)
				);

				const renderLabel = controlledArray => {
					if (
						defaultStatic &&
						SelectStaticBehaviors[defaultStatic.key].isChecked(controlledArray, allItems)
					) {
						return <Formatter>{defaultStatic}</Formatter>;
					}

					return (
						<Message
							{...m.countSelected}
							values={{
								count: controlledArray.length,
							}}
						/>
					);
				};

				// TODO: For a11y, we should pass `getLabelProps()` to the label.
				// TODO: For a11y, we should pass `getRootProps()` to the root element.
				return (
					<div ref={ref} data-test-id={name}>
						<Button
							className={cx({ 'form-control--active': isOpen }, className)}
							disabled={disabled}
							tabIndex="0"
							{...getToggleButtonProps({
								onClick: () => {
									dispatch(setShowAllItems(true));
									dispatch(setIsOpen(!isOpen));
								},
								onBlur: noop,
								id,
							})}
							data-test-id={`${name}_label`}
						>
							{renderLabel(controlledArray)}
						</Button>
						{!disabled && isOpen && (
							<Box className="multiselect" data-test-id={`${name}_multiselect`}>
								<Menu {...getMenuProps()}>
									<Box as="li" className="multiselect__search">
										<TextInput
											placeholder="Search"
											name="search"
											onChange={handleInputValueChange}
											hasFloatingLabel={false}
											value={inputValue}
										/>
									</Box>
									{renderCheckboxes([...statics, ...allItems])}
								</Menu>
								<Grid className="multiselect__confirm" justifyContent="center" py={3} px={3}>
									<Button size="sm" kind="secondary" outline onClick={() => dispatch(clear())}>
										<Message {...m.clear} />
									</Button>
									<Button
										size="sm"
										kind="secondary"
										ml={2}
										onClick={() => {
											onChange(controlledArray);
											dispatch(confirm());
										}}
									>
										<Message {...m.confirm} />
									</Button>
								</Grid>
							</Box>
						)}
					</div>
				);
			}}
		</Downshift>
	);
});

Multiselect.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,

	/** If true, all values will be automatically selected when the user deselects all values. Useful for filters */
	emptyValueSelectsAll: PropTypes.bool,
	formatter: PropTypes.elementType,
	id: PropTypes.string,
	items: PropTypes.arrayOf(stringOrNumberPropType).isRequired,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	statics: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.any,
};

Multiselect.displayName = 'Multiselect';

export default Multiselect;
