import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';

class Portal extends Component {
	constructor(...args) {
		super(...args);
		this.findNode();
	}
	componentDidUpdate() {
		this.findNode();
	}

	componentWillUnmount() {
		this.mountNode = null;
	}

	findNode() {
		const { container } = this.props;
		// eslint-disable-next-line react/no-find-dom-node
		this.mountNode = ReactDOM.findDOMNode(container);
	}

	render() {
		const { children } = this.props;

		return this.mountNode ? ReactDOM.createPortal(children, this.mountNode) : null;
	}
}
Portal.propTypes = {
	/** Content to render. */
	children: PropTypes.node,
	/** Container where to render */
	container: PropTypes.object,
};

export default Portal;
