import { downloadFile } from './downloadFile';

export const downloadTextFile = (content, filename, mimetype) => {
	let contentBlob = {};

	try {
		const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
		contentBlob = new Blob([BOM, content], { type: mimetype });
	} catch (error) {
		if (error.name === 'InvalidStateError') {
			// IE workaround: change of global variable is necessary for IE
			window.BlobBuilder =
				window.BlobBuilder ||
				window.WebKitBlobBuilder ||
				window.MozBlobBuilder ||
				window.MSBlobBuilder;

			if (window.BlobBuilder) {
				const blobBuilder = new window.BlobBuilder();

				blobBuilder.append(content);
				contentBlob = blobBuilder.getBlob(mimetype);
			}
		}
	}

	downloadFile(contentBlob, filename);
};
