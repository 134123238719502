import { cx } from 'ramda-extension';

import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { ButtonsDefaultMobileLayoutContext } from '@creditinfo-ui/layout';

import { SLIDE_MODAL_PADDING_X, SLIDE_MODAL_PADDING_Y } from './SlideModal';

const SLIDE_MODAL_FOOTER_HEIGHT = '102px';
const SLIDE_MODAL_MOBILE_FOOTER_HEIGHT = '72px';

const slideModalFooterStyle = prepareStyle(utils => ({
	alignItems: 'center',
	backgroundColor: '#fff',
	boxShadow: utils.boxShadows.footer,
	display: 'flex',
	justifyContent: 'center',
	minHeight: SLIDE_MODAL_MOBILE_FOOTER_HEIGHT,

	selectors: {
		[utils.breakpoints.md]: {
			minHeight: SLIDE_MODAL_FOOTER_HEIGHT,
			padding: `${SLIDE_MODAL_PADDING_Y} ${SLIDE_MODAL_PADDING_X}`,
		},
	},
}));

export interface SlideModalFooterProps {
	children: ReactNode;
	className?: string;
}

export const SlideModalFooter = forwardRef(
	({ className, ...otherProps }: SlideModalFooterProps, ref: ForwardedRef<HTMLDivElement>) => {
		const { applyStyle } = useStyles();

		return (
			<ButtonsDefaultMobileLayoutContext.Provider value="row">
				<footer
					className={cx(applyStyle(slideModalFooterStyle), className)}
					ref={ref}
					{...otherProps}
				/>
			</ButtonsDefaultMobileLayoutContext.Provider>
		);
	}
);

SlideModalFooter.displayName = 'SlideModalFooter';
