import { compose, filter, last, length, o, path, prop } from 'ramda';
import { makeActionTypes, makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';
import { composeMiddleware, downloadBase64File, makeMiddleware, typeEq } from '@myci/utils';
import { isFetching, request } from '@ci/api';
import { run } from '@ci/control-flow';
import { addToast } from '@ci/toasts';
import { storeEntities, viewEntities } from '@ci/entities';
import { schema } from 'normalizr';

import m from '../messages';

const transactionsSchema = new schema.Entity(
	'transactions',
	{},
	{
		idAttribute: prop('id'),
	}
);

const Schemas = {
	TRANSACTIONS_ARRAY: [transactionsSchema],
};

export const TRANSACTIONS_RESULT_KEY = 'TRANSACTIONS_RESULT_KEY';
export const RECEIPTS_RESULT_KEY = 'RECEIPTS_RESULT_KEY';

const ActionTypes = makeActionTypes('@transactions', [
	'FETCH_WALLET_TRANSACTIONS',
	'FETCH_RECEIPT_FILE',
]);

export const fetchWalletTransactions = makeConstantActionCreator(
	ActionTypes.FETCH_WALLET_TRANSACTIONS
);
export const fetchReceiptFile = makeSimpleActionCreator(ActionTypes.FETCH_RECEIPT_FILE);

export const isFetchingWalletTransactions = isFetching(ActionTypes.FETCH_WALLET_TRANSACTIONS);
export const getWalletTransactions = viewEntities(
	Schemas.TRANSACTIONS_ARRAY,
	TRANSACTIONS_RESULT_KEY
);
export const getReceipts = viewEntities(Schemas.TRANSACTIONS_ARRAY, RECEIPTS_RESULT_KEY);
export const getReceiptsCount = o(length, getReceipts);
export const getLastReceiptDate = compose(prop('date'), last, getReceipts);

const fetchWalletTransactionsMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_WALLET_TRANSACTIONS),
	({ dispatch }) =>
		action => {
			dispatch(
				run(
					() =>
						request(
							{
								url: 'wallet/transactions',
							},
							{
								origin: action,
							}
						),
					({ payload }, { isError }) => {
						if (isError) {
							return dispatch(
								addToast({
									type: 'warning',
									content: m.transactionFetchFailed,
								})
							);
						}

						dispatch(
							storeEntities(
								Schemas.TRANSACTIONS_ARRAY,
								TRANSACTIONS_RESULT_KEY,
								path(['result', 'transactions'], payload)
							)
						);

						dispatch(
							storeEntities(
								Schemas.TRANSACTIONS_ARRAY,
								RECEIPTS_RESULT_KEY,
								compose(
									filter(({ receiptNumber }) => Boolean(receiptNumber)),
									path(['result', 'transactions'])
								)(payload)
							)
						);
					}
				)
			);
		}
);

const fetchReceiptFileMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_RECEIPT_FILE),
	({ dispatch }) =>
		action => {
			const { receiptNumber, receiptPrefix } = action.payload;
			dispatch(
				run(
					() =>
						request(
							{
								url: `/wallet/receipt/${receiptNumber}`,
								method: 'GET',
							},
							{
								origin: action,
							}
						),
					(action, { isError }) => {
						if (isError) {
							dispatch(
								addToast({
									content: m.downloadArchiveError,
									type: 'danger',
								})
							);
						} else {
							const pdfFile = action?.payload?.result?.receipt?.pdfFile;
							if (pdfFile) {
								downloadBase64File(pdfFile, `${receiptPrefix}_${receiptNumber}.pdf`);
							}
						}
					}
				)
			);
		}
);

export const walletTransactionsMiddleware = composeMiddleware(
	fetchWalletTransactionsMiddleware,
	fetchReceiptFileMiddleware
);
