import { mergeNativeStyleObjects, mergeStyleObjects } from './mergeStyleObjects';
import { NativeStyle, NativeStyles, Style, Styles } from './types';

type InvokeStyle<TProps extends object> = (style: Style<TProps>) => ReturnType<Style<TProps>>;

type InvokeNativeStyle<TProps extends object> = (
	style: NativeStyle<TProps>
) => ReturnType<NativeStyle<TProps>>;

export const processStyles = <TProps extends object>(
	styles: Styles<TProps>,
	invokeStyle: InvokeStyle<TProps>
) =>
	mergeStyleObjects(
		styles.filter((style): style is Style<TProps> => style != null).map(invokeStyle)
	);

export const processNativeStyles = <TProps extends object>(
	styles: NativeStyles<TProps>,
	invokeStyle: InvokeNativeStyle<TProps>
) =>
	mergeNativeStyleObjects(
		styles.filter((style): style is NativeStyle<TProps> => style != null).map(invokeStyle)
	);
