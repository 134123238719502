import { addToast } from '@ci/toasts';
import { sleep } from '@ci/utils';
import { isInBrowser as getIsInBrowser } from '@myci/utils';
import { navigate } from '@myci/navigation';
import { createThunk, request, selectIsThunkPending } from '@ci/api';
import { reset } from 'redux-form';
import { requestBiometricAuthenticationUpdate } from '@myci/native-biometrics/setup';
import { signIn } from '@myci/authentication';

import m from '../messages';

import { getUsername, setAccountState } from './account';

export const changePassword = createThunk(
	{
		originType: '@changePassword/changePassword',
	},
	async ({ dispatch, getState }, payload) => {
		try {
			const successAction = await dispatch(
				request({
					url: '/password/change',
					method: 'POST',
					body: payload,
				})
			);

			const isInBrowser = getIsInBrowser();

			if (!isInBrowser) {
				dispatch(
					requestBiometricAuthenticationUpdate({
						password: successAction.payload.changedPassword,
					})
				);
			}

			const username = getUsername(getState());
			dispatch(addToast({ content: m.changePasswordSuccess }));

			await dispatch(
				signIn({
					password: payload.changedPassword,
					username,
					form: payload.form,
				})
			);

			dispatch(reset(payload.form));

			if (isInBrowser) {
				// NOTE: delay redirect so that the user can see the success message
				await sleep(3000);
			}

			dispatch(setAccountState({ needsToChangePassword: false }));

			if (isInBrowser) {
				window.location.replace('../settings');
			} else {
				navigate('../settings');
			}
		} catch (error) {
			let errorText = '';
			const errorDetails = error?.meta?.error?.innerError || [];
			errorDetails.forEach(error => {
				if (error.messageLocalized) {
					errorText += `${error.messageLocalized}. `;
				}
			});

			dispatch(
				addToast({
					type: 'warning',
					content: errorText ?? m.changePasswordFail,
				})
			);
		}
	}
);

export const selectIsChangingPassword = selectIsThunkPending(changePassword);
