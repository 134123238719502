import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import Swiper from 'react-id-swiper';
import { mergeDeepLeft } from 'ramda';

import './Carousel.scss';

const Carousel = ({ children, className, options = {} }) => {
	const mergedOptions = mergeDeepLeft(
		{
			slidesPerView: 'auto',
			spaceBetween: 20,
			slidesOffsetBefore: 20,
			slidesOffsetAfter: 20,
			breakpoints: {
				768: {
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
					spaceBetween: 40,
					centeredSlides: true,
				},
			},
		},
		options
	);

	return (
		<Swiper className={cx(className)} {...mergedOptions}>
			{children}
		</Swiper>
	);
};

Carousel.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** Carouse options. */
	options: PropTypes.object,
};

export default Carousel;
