import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import ClientLink from '../ClientLink';

const Breadcrumb = ({ children, className, to, ...otherProps }) => (
	<li className={cx(className, 'breadcrumb-item')} {...otherProps}>
		<ClientLink to={to}>{children}</ClientLink>
	</li>
);

Breadcrumb.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	to: PropTypes.string.isRequired,
};

export default Breadcrumb;
