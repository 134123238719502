import { aperture, head, last, o, reduce, reduced } from 'ramda';

export const getBreakpointByWidth = orderedBreakpoints => {
	const intervals = aperture(2)(orderedBreakpoints);
	const maxBreakpoint = o(head, last)(orderedBreakpoints);

	return width =>
		reduce(
			(resolvedBreakpoint, [left, right]) =>
				width < right[1] ? reduced(left[0]) : resolvedBreakpoint,
			maxBreakpoint,
			intervals
		);
};
