import { useIntl } from 'react-intl';
import { cx } from 'ramda-extension';
import {
	HTMLAttributes,
	ReactNode,
	createContext,
	forwardRef,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	Style,
	mergeStyles,
	prepareStyle,
	prepareStyleFactory,
	useStyles,
} from '@creditinfo-ui/styles';

import { Tooltip } from './Tooltip';
import { m } from '../messages';
import { visuallyHiddenStyle } from '../styles';
import { getElementTotalWidth } from '../utils';
import { Icon, IconProps, IconType, iconSizes } from './Icon';
import { AddonPlacement, AddonWidths } from '../types';

export interface InputWrapperProps extends HTMLAttributes<HTMLDivElement> {
	addon?: ReactNode;
	addonPlacement?: AddonPlacement;
	children: ReactNode;
	customStyle?: Style;
	error?: ReactNode;
	icon?: IconType;
	iconProps?: Partial<IconProps> & { tooltip?: ReactNode };
	isDisabled?: boolean;
	isRequired?: boolean;
	label?: ReactNode;
	labelFor?: string;
	labelId?: string;
	legend?: ReactNode;
}

interface LabelStyleProps {
	isDisabled: boolean;
}

const rootStyle = prepareStyle(() => ({ position: 'relative' }));
const innerContainerStyle = prepareStyle(() => ({ position: 'relative' }));

const labelStyle = prepareStyle<LabelStyleProps>((utils, { isDisabled }) => ({
	alignItems: 'flex-start',
	display: 'flex',
	fontSize: utils.fontSizes.base,
	fontWeight: utils.fontWeights.semiBold,
	padding: `0 ${utils.spacings.md}`,
	extend: {
		condition: isDisabled,
		style: { color: utils.colors.gray400 },
	},
}));

const legendStyle = prepareStyle(utils => ({
	color: utils.colors.gray600,
	flexGrow: 1,
	padding: `0 ${utils.spacings.md}`,
}));

const errorStyle = prepareStyle(utils => ({
	color: utils.colors.danger,
	padding: `0 ${utils.spacings.md}`,
}));

interface AddonStyleProps {
	hasPointerEvents: boolean;
	placement: AddonPlacement;
}

const addonStyle = prepareStyleFactory<AddonStyleProps>(
	(utils, { hasPointerEvents, placement }) => ({
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		extend: [
			{
				condition: !hasPointerEvents,
				style: {
					pointerEvents: 'none',
				},
			},
			{
				condition: placement === 'start',
				style: {
					insetInlineStart: utils.spacings.md,
				},
			},
			{
				condition: placement === 'end',
				style: {
					insetInlineEnd: utils.spacings.sm,
				},
			},
		],
	})
);

const errorLegendWrapperStyle = prepareStyle(utils => ({
	display: 'flex',
	fontSize: utils.fontSizes.caption,
	fontWeight: utils.fontWeights.semiBold,
	marginTop: '0.4rem',
}));

export interface InputWrapperContextValue {
	addonWidths?: AddonWidths;
}

export const InputWrapperContext = createContext<InputWrapperContextValue>({});

export const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>(
	(
		{
			addon,
			addonPlacement = 'end',
			label,
			labelFor,
			labelId,
			legend,
			children,
			className,
			customStyle,
			icon,
			iconProps,
			isRequired = false,
			isDisabled = false,
			error,
			...otherProps
		}: InputWrapperProps,
		ref
	) => {
		const intl = useIntl();
		const { applyStyle } = useStyles();
		const addonRef = useRef<HTMLDivElement>(null);
		const [addonWidths, setAddonWidths] = useState<AddonWidths>({});

		const mergedIconStyle = mergeStyles([
			addonStyle({
				hasPointerEvents: Boolean(iconProps?.tooltip),
				placement: 'end',
			}),
			iconProps?.customStyle,
		]);

		const iconNode = icon && (
			<Icon
				size="md"
				isLabeled
				color={isDisabled ? 'gray400' : 'gray800'}
				type={icon}
				{...iconProps}
				customStyle={mergedIconStyle}
			/>
		);

		useLayoutEffect(() => {
			const nextAddonWidths: AddonWidths = {};

			if (addon && addonRef.current) {
				nextAddonWidths[addonPlacement] = `${getElementTotalWidth(addonRef.current) / 10}rem`;
			}

			if (icon) {
				nextAddonWidths.end = iconSizes.md;
			}

			setAddonWidths(nextAddonWidths);
		}, [addon, addonPlacement, icon]);

		const inputWrapperContextValue = useMemo(() => ({ addonWidths }), [addonWidths]);

		return (
			<div
				{...otherProps}
				className={cx(applyStyle([rootStyle, customStyle]), className, 'input-wrapper')}
				ref={ref}
			>
				{label && (
					<label className={applyStyle(labelStyle, { isDisabled })} id={labelId} htmlFor={labelFor}>
						{label}
						{isRequired && (
							<>
								<Icon color="danger" isLabeled type="requiredAsterisk" />
								<span className={applyStyle(visuallyHiddenStyle)}>
									{` ${intl.formatMessage(m.required)}`}
								</span>
							</>
						)}
					</label>
				)}
				<div className={applyStyle(innerContainerStyle)}>
					<InputWrapperContext.Provider value={inputWrapperContextValue}>
						{children}
					</InputWrapperContext.Provider>
					{iconNode && iconProps?.tooltip ? (
						<Tooltip tooltip={iconProps.tooltip}>{iconNode}</Tooltip>
					) : (
						iconNode
					)}
					{addon && (
						<div
							ref={addonRef}
							className={applyStyle(
								addonStyle({
									hasPointerEvents: true,
									placement: addonPlacement,
								})
							)}
						>
							{addon}
						</div>
					)}
				</div>
				{(error || legend) && (
					<div className={applyStyle(errorLegendWrapperStyle)}>
						{error && <div className={applyStyle(errorStyle)}>{error}</div>}
						{legend && <div className={applyStyle(legendStyle)}>{legend}</div>}
					</div>
				)}
			</div>
		);
	}
);

InputWrapper.displayName = 'InputWrapper';
