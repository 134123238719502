import { chain, type, map, toPairs, fromPairs } from 'ramda';

// NOTE: Shamelessly stolen from https://github.com/ramda/ramda/wiki/Cookbook#flatten-a-nested-object-into-dot-separated-key--value-pairs
export const flattenObject = obj => {
	const go = obj_ =>
		// eslint-disable-next-line id-length
		chain(([k, v]) => {
			if (type(v) === 'Object' || type(v) === 'Array') {
				return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
			}

			return [[k, v]];
		}, toPairs(obj_));

	return fromPairs(go(obj));
};
