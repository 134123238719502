import { MutableRefObject, RefCallback } from 'react';

export const mergeRefs =
	<T>(refs: Array<MutableRefObject<T | null> | RefCallback<T>>): RefCallback<T> =>
	value =>
		refs.forEach(ref => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (typeof ref !== 'string') {
				ref.current = value;
			}
		});
