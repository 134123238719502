import { Action, AnyAction, Middleware, MiddlewareAPI } from 'redux';

interface MiddlewareTypePredicate<TAction extends Action> {
	(action: Action<unknown>): action is TAction;
}

interface MiddlewareBooleanPredicate {
	(action: Action<unknown>): boolean;
}

interface SimpleMiddleware<TAction extends Action> {
	(api: MiddlewareAPI): (action: TAction) => any;
}

/**
 * @deprecated Use thunks instead.
 */
export const makeMiddleware =
	<TAction extends Action = AnyAction>(
		predicate: MiddlewareTypePredicate<TAction> | MiddlewareBooleanPredicate,
		simpleMiddleware: SimpleMiddleware<TAction>
	): Middleware =>
	(middlewareAPI: MiddlewareAPI) => {
		const middlewareBody = simpleMiddleware(middlewareAPI);

		return next => action => {
			const returnValue = next(action);

			if (predicate(action)) {
				(async () => {
					try {
						await middlewareBody(action);
					} catch (error) {
						console.error(error);
					}
				})();
			}

			return returnValue;
		};
	};
