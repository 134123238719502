import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';
import { ReactNode } from 'react';

import { HorizontalInset, TabsDefaultHorizontalInsetContext } from '@creditinfo-ui/tabs';
import { SLIDE_MODAL_MOBILE_PADDING_X, SLIDE_MODAL_PADDING_X } from './SlideModal';

const slideModalContentStyle = prepareStyle<{ isBeforeFooter: boolean }>(
	(utils, { isBeforeFooter }) => ({
		flexGrow: 1,
		overflowY: 'auto',
		paddingLeft: SLIDE_MODAL_MOBILE_PADDING_X,
		paddingRight: SLIDE_MODAL_MOBILE_PADDING_X,

		selectors: {
			[utils.breakpoints.md]: {
				paddingLeft: SLIDE_MODAL_PADDING_X,
				paddingRight: SLIDE_MODAL_PADDING_X,
			},
			'& .content-inner': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'& hr': {
				marginLeft: utils.multiply(-1, SLIDE_MODAL_MOBILE_PADDING_X),
				marginRight: utils.multiply(-1, SLIDE_MODAL_MOBILE_PADDING_X),
				selectors: {
					[utils.breakpoints.md]: {
						marginLeft: utils.multiply(-1, SLIDE_MODAL_PADDING_X),
						marginRight: utils.multiply(-1, SLIDE_MODAL_PADDING_X),
					},
				},
			},
		},
		extend: {
			condition: !isBeforeFooter,
			style: {
				paddingBottom: utils.spacings.lg,
			},
		},
	})
);

const defaultHorizontalInset: HorizontalInset = { xs: 'md', md: 'lg' };

export interface SlideModalContentProps {
	children: ReactNode;
	className?: string;
	isBeforeFooter?: boolean;
}

export const SlideModalContent = ({
	className,
	children,
	isBeforeFooter = false,
}: SlideModalContentProps) => {
	const { applyStyle } = useStyles();

	return (
		<TabsDefaultHorizontalInsetContext.Provider value={defaultHorizontalInset}>
			<div className={cx(applyStyle(slideModalContentStyle, { isBeforeFooter }), className)}>
				{children}
			</div>
		</TabsDefaultHorizontalInsetContext.Provider>
	);
};
