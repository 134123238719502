import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { ReactNode } from 'react';

const footerStyle = prepareStyle(utils => ({
	flexBasis: 'auto',
	flexGrow: 1,
	flexShrink: 0,
	marginBottom: utils.spacings.lg,
	marginTop: utils.spacings.lg,
	paddingLeft: utils.spacings.md,
	paddingRight: utils.spacings.md,
	width: '100%',

	selectors: {
		[utils.breakpoints.lg]: {
			paddingLeft: utils.spacings.lg,
			paddingRight: utils.spacings.lg,
		},
	},
}));

export interface StaticModalFooterProps {
	children: ReactNode;
}

export const StaticModalFooter = ({ children }: StaticModalFooterProps) => {
	const { applyStyle } = useStyles();

	return <div className={applyStyle(footerStyle)}>{children}</div>;
};
