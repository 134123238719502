import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ className, children, ...otherProps }) => (
	<nav className={cx(className)} {...otherProps} aria-label="breadcrumb">
		<ol className="breadcrumb">{children}</ol>
	</nav>
);

Breadcrumbs.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
};

export default Breadcrumbs;
