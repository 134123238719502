import {
	__,
	andThen,
	compose,
	concat,
	defaultTo,
	difference,
	isEmpty,
	lensPath,
	mergeAll,
	mergeRight,
	o,
	over,
	prop,
	values,
} from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';
import { makeActionTypes, makeReducer, makeSimpleActionCreator } from 'redux-syringe';
import {
	composeMiddleware,
	getEnvironmentVariable,
	isInBrowser,
	makeBinaryActionCreator,
	makeMiddleware,
	typeEq,
} from '@myci/utils';

import supportedLocaleData from '../data/supportedLocales.json';

import { importIntlOptions } from './importIntlOptions';
import { polyfillLocale } from './polyfillLocale';
import { polyfillIntl } from './polyfillIntl';
import { importTranslations } from './importTranslations';
import { getLoadedNamespacesByLocale, getLoadingNamespacesByLocale_ } from './selectors';

export const SCOPE = '@intl';

export const ActionTypes = makeActionTypes(SCOPE, [
	'INIT',
	'MERGE_TRANSLATIONS',
	'ENSURE_TRANSLATIONS',
	'ENSURE_TRANSLATIONS_REQUEST',
	'LOAD_LOCALE',
	'LOAD_LOCALE_REQUEST',
	'LOAD_LOCALE_SUCCESS',
	'LOAD_INTL_OPTIONS',
	'LOAD_INTL_OPTIONS_REQUEST',
	'LOAD_INTL_OPTIONS_SUCCESS',
]);

export const init = makeSimpleActionCreator(ActionTypes.INIT);
export const ensureTranslations = makeSimpleActionCreator(ActionTypes.ENSURE_TRANSLATIONS);
export const ensureTranslationsRequest = makeSimpleActionCreator(
	ActionTypes.ENSURE_TRANSLATIONS_REQUEST
);
export const mergeTranslations = makeSimpleActionCreator(ActionTypes.MERGE_TRANSLATIONS);
export const loadLocale = makeBinaryActionCreator(ActionTypes.LOAD_LOCALE);
export const loadLocaleRequest = makeSimpleActionCreator(ActionTypes.LOAD_LOCALE_REQUEST);
export const loadLocaleSuccess = makeSimpleActionCreator(ActionTypes.LOAD_LOCALE_SUCCESS);
export const loadIntlOptions = makeSimpleActionCreator(ActionTypes.LOAD_INTL_OPTIONS);
export const loadIntlOptionsRequest = makeSimpleActionCreator(
	ActionTypes.LOAD_INTL_OPTIONS_REQUEST
);
export const loadIntlOptionsSuccess = makeSimpleActionCreator(
	ActionTypes.LOAD_INTL_OPTIONS_SUCCESS
);

const LOCALE_KEY = '@myci/locale';

export const getLocaleFromStorage = () => {
	if (!isInBrowser()) {
		return;
	}

	const locale = window.localStorage.getItem(LOCALE_KEY) || '';
	if (locale.match(/^[a-z]{2}-[A-Z]{2}$/)) {
		return locale;
	}
};

export const isCurrentLocaleUserRequested = () => Boolean(getLocaleFromStorage());

const preferedLocale = getLocaleFromStorage() || getEnvironmentVariable('GATSBY_DEFAULT_LOCALE');

export const initialState = {
	locale: preferedLocale,
	preferedLocale,
	isLoadingLocale: true,
	translations: {},
	intlOptions: null,
	supportedLocales: supportedLocaleData.supportedLocales,
};

const mergeAllValues = o(mergeAll, values);

const mergeLoaded = (locale, namespaces) => messages =>
	mergeTranslations({
		locale,
		namespaces,
		messages: mergeAllValues(messages),
	});

const loadIntlOptionsMiddleware = makeMiddleware(
	typeEq(ActionTypes.LOAD_INTL_OPTIONS),
	({ dispatch }) =>
		action => {
			const locale = action.payload;
			dispatch(loadIntlOptionsRequest(locale));

			importIntlOptions(locale)
				.then(intlOptions => dispatch(loadIntlOptionsSuccess(intlOptions)))
				.catch(error => {
					console.error(error);

					return dispatch(loadLocaleRequest(error));
				});
		}
);

const ensureTranslationsMiddleware = makeMiddleware(
	typeEq(ActionTypes.ENSURE_TRANSLATIONS),
	({ dispatch, getState }) =>
		action => {
			const { namespaces, locale } = action.payload;

			if (!locale || isNilOrEmpty(namespaces)) {
				return;
			}

			const loadedNamespaces = getLoadedNamespacesByLocale(locale)(getState());
			const loadingNamespaces = getLoadingNamespacesByLocale_(locale)(getState());

			const missingNamespaces = difference(namespaces, [...loadedNamespaces, ...loadingNamespaces]);

			if (isEmpty(missingNamespaces)) {
				return;
			}

			dispatch(ensureTranslationsRequest({ locale, namespaces: missingNamespaces }));

			const mergeMissingMessages = mergeLoaded(locale, missingNamespaces);

			compose(
				andThen(o(dispatch, mergeMissingMessages)),
				importTranslations(locale)
			)(missingNamespaces);
		}
);

const loadLocaleMiddleware = makeMiddleware(
	typeEq(ActionTypes.LOAD_LOCALE),
	({ dispatch }) =>
		action => {
			const locale = action.payload;
			const userRequested = prop('userRequested', action.meta);

			dispatch(loadLocaleRequest(locale));
			dispatch(loadIntlOptions(locale));

			polyfillIntl()
				.then(() => polyfillLocale(locale))
				.then(() => {
					dispatch(loadLocaleSuccess(locale));
					if (isInBrowser() && userRequested) {
						window.localStorage.setItem(LOCALE_KEY, locale);
					}
				})
				.catch(error => dispatch(loadLocaleRequest(error)));
		}
);

export const middleware = composeMiddleware(
	ensureTranslationsMiddleware,
	loadIntlOptionsMiddleware,
	loadLocaleMiddleware
);

const reducer = makeReducer(
	[
		[
			ActionTypes.INIT,
			(_, { payload: { intlOptions, messages, locale, namespaces } }) => ({
				...initialState,
				locale,
				intlOptions,
				translations: {
					[locale]: {
						messages,
						loadedNamespaces: namespaces,
					},
				},
			}),
		],
		[
			ActionTypes.ENSURE_TRANSLATIONS_REQUEST,
			(state, { payload: { locale, namespaces } }) =>
				over(
					lensPath(['translations', locale, 'loadingNamespaces']),
					o(concat(namespaces), defaultTo([]))
				)({ ...state, isLoadingLocale: false }),
		],
		[
			ActionTypes.MERGE_TRANSLATIONS,
			(state, { payload: { locale, messages, namespaces } }) =>
				isEmpty(messages)
					? state
					: compose(
							over(
								lensPath(['translations', locale, 'messages']),
								o(mergeRight(messages), defaultTo({}))
							),
							over(
								lensPath(['translations', locale, 'loadedNamespaces']),
								o(concat(namespaces), defaultTo([]))
							),
							over(
								lensPath(['translations', locale, 'loadingNamespaces']),
								o(difference(__, namespaces), defaultTo([]))
							)
					  )(state),
		],
		[
			ActionTypes.LOAD_LOCALE_REQUEST,
			state => ({ ...state, error: null, isLoadingLocale: true }),
			(state, { payload }) => ({ ...state, error: payload, isLoadingLocale: false }),
		],
		[
			ActionTypes.LOAD_LOCALE_SUCCESS,
			(state, { payload: locale }) => ({ ...state, locale, error: null, isLoadingLocale: false }),
		],
		[
			ActionTypes.LOAD_INTL_OPTIONS_SUCCESS,
			(state, { payload: intlOptions }) => ({
				...state,
				intlOptions,
				error: null,
				isLoadingIntlOptions: false,
			}),
		],
	],
	initialState
);
export default reducer;
