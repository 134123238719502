import { Fragment, cloneElement, createElement } from 'react';

/**
 * @deprecated Do not clone elements. Come up with a better interface instead.
 */
export const cloneElementOrFragment =
	wrapperInsteadOfFragment =>
	(element, ...rest) =>
		!element || element === Fragment
			? createElement(wrapperInsteadOfFragment, ...rest)
			: cloneElement(element, ...rest);

export default cloneElementOrFragment;
