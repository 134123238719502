import { createElement } from 'react';
import { getDisplayName } from '@ci/utils';
import { curry } from 'ramda';
import { isFunction } from 'ramda-extension';

/**
 * HOC similar to recompose's `withProps`. Pass props to add to the wrapped component.
 * New props have priority. You can also pass a function, which will receive the previous props.
 *
 * @param {Object|Function} propOrGetProps additionalProps props
 */
const withProps = curry((propsOrGetProps, Component) => {
	const WithProps = props =>
		createElement(
			Component,
			isFunction(propsOrGetProps)
				? { ...props, ...propsOrGetProps(props) }
				: { ...props, ...propsOrGetProps }
		);

	WithProps.displayName = `WithProps(${getDisplayName(Component)})`;

	return WithProps;
});

export default withProps;
