import { addToast } from '@ci/toasts';
import { navigate } from '@myci/navigation';
import { createThunk, getIsAnyErrorResponseAction, request } from '@ci/api';
import { reset as resetForm } from 'redux-form';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { isInBrowser as getIsInBrowser } from '@myci/utils';
import { requestBiometricAuthenticationUpdate } from '@myci/native-biometrics/setup';
import { getInnerErrorFromAction } from '@myci/api';

import m from '../messages';
import { fetchUserAccount } from './account';

export const setIsEmailCodeSent = createAction<boolean>('@changeEmail/setIsEmailCodeSent');
export const resetEmail = createAction('@changeEmail/resetEmail');

export interface RequestEmailVerificationCodePayload {
	email: string;
	form: string;
}

export interface VerifyEmailCodePayload {
	code: string;
	email: string;
	form: string;
	onSuccess?: () => void;
}

interface EmailChangeState {
	isEmailCodeSent: boolean;
}

const initialState: EmailChangeState = {
	isEmailCodeSent: false,
};

export const requestEmailVerificationCode = createThunk(
	{
		originType: '@changeEmail/requestEmailVerificationCode',
	},
	async ({ dispatch }, payload: RequestEmailVerificationCodePayload) => {
		const { form, email } = payload;

		dispatch(setIsEmailCodeSent(false));

		try {
			await dispatch(
				request({ url: '/account/email/request', method: 'POST', body: { email } }, { form })
			);

			dispatch(setIsEmailCodeSent(true));

			dispatch(
				addToast({
					type: 'success',
					content: m.sendEmailCodeSuccess,
					values: { email: payload.email },
				})
			);
		} catch (error) {
			if (getIsAnyErrorResponseAction(error)) {
				const innerError = getInnerErrorFromAction(error);
				dispatch(
					addToast({
						type: 'warning',
						content: m[`verificationError_${innerError.code}`] ?? m.verifyEmailFail,
					})
				);
			}
		}
	}
);
export const selectIsEmailCodeSent = state => state.changeEmail?.isEmailCodeSent;

export const verifyEmailCode = createThunk(
	{
		originType: '@changeEmail/verifyEmailCode',
	},
	async ({ dispatch }, payload: VerifyEmailCodePayload) => {
		const { form, code, onSuccess, email } = payload;
		const isInBrowser = getIsInBrowser();

		try {
			await dispatch(
				request({ url: '/account/email/verify', method: 'POST', body: { code, email } }, { form })
			);

			if (!isInBrowser) {
				dispatch(
					requestBiometricAuthenticationUpdate({
						username: email,
					})
				);
			}
			dispatch(addToast({ type: 'success', content: m.changeEmailSuccess }));
			dispatch(resetForm(form));
			dispatch(fetchUserAccount());

			if (onSuccess) {
				onSuccess();
			}

			navigate('../settings');
		} catch (error) {
			if (getIsAnyErrorResponseAction(error)) {
				dispatch(
					addToast({
						type: 'warning',
						content: { ...m.verifyEmailFail, defaultMessage: error.payload },
					})
				);
			}
		}
	}
);

export const changeEmailReducer = createReducer(initialState, builder => {
	builder.addCase(setIsEmailCodeSent, (state, action) => ({
		...state,
		isEmailCodeSent: action.payload,
	}));
});
