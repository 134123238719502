import { LiHTMLAttributes, forwardRef } from 'react';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';

type MenuItemSize = 'sm' | 'lg';

export interface MenuItemProps extends LiHTMLAttributes<HTMLLIElement> {
	customStyle?: Style<MenuItemStyleProps>;
	isDisabled?: boolean;
	isHighlighted?: boolean;
	size?: MenuItemSize;
}

interface MenuItemStyleProps {
	isDisabled: boolean;
	isHighlighted: boolean;
	size: MenuItemSize;
}

const menuItemStyle = prepareStyle<MenuItemStyleProps>(
	(utils, { isDisabled, isHighlighted, size }) => {
		const yPadding = size === 'sm' ? utils.spacings.xxs : utils.spacings.sm;
		const xPadding = size === 'sm' ? utils.spacings.xs : utils.spacings.md;

		const highlightedStyle = {
			backgroundColor: utils.colors.gray100,
		};

		return {
			cursor: 'pointer',
			fontSize: utils.fontSizes.base,
			overflow: 'hidden',
			padding: `${yPadding} ${xPadding}`,
			width: '100%',
			extend: [
				{
					condition: !isDisabled,
					style: {
						selectors: {
							':hover, :focus': highlightedStyle,
						},
					},
				},
				{
					condition: isDisabled,
					style: {
						cursor: 'not-allowed',
						opacity: 0.3,
					},
				},
				{
					condition: isHighlighted,
					style: highlightedStyle,
				},
			],
		};
	}
);

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
	(
		{
			className,
			customStyle,
			isDisabled = false,
			isHighlighted = false,
			size = 'lg',
			...otherProps
		}: MenuItemProps,
		ref
	) => {
		const { applyStyle } = useStyles();

		return (
			<li
				{...otherProps}
				className={cx(
					applyStyle([menuItemStyle, customStyle], { isDisabled, isHighlighted, size }),
					className
				)}
				ref={ref}
			/>
		);
	}
);

MenuItem.displayName = 'MenuItem';
