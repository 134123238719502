import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import { DateTimeFormatter } from '@ci/formatters';
import { Message } from '@myci/intl';
import { prepareStyle } from '@ci/styles';
import { Icon, Tooltip } from '@ci/atoms';
import Box from '../Box';
import Button from '../Button';
import Text from '../Text';
import Grid from '../Grid';

import m from '../../messages';
import './TimelineMessage.scss';

const iconStyle = prepareStyle(utils => ({
	marginLeft: utils.spacings.sm,
}));

const TimelineMessage = ({
	children,
	displayUnreadIcon,
	type,
	system,
	sender,
	attachments,
	recipient,
	confirmed,
	timestamp,
	readTimestamp,
	...otherProps
}) => {
	const messageTextRef = useRef();

	const [more, setMore] = useState(false);
	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		if (messageTextRef) {
			const elHeight = messageTextRef.current.offsetHeight;
			const maxElLineHeight = parseInt(getComputedStyle(messageTextRef.current).lineHeight) * 3;

			setShowMore(elHeight > maxElLineHeight);
		}
	}, []);

	const iconColor = type === 'internal' ? 'success' : 'teal';
	return (
		<Box
			className={cx(
				'timeline-message',
				type && `timeline-message--${type}`,
				system && 'timeline-message--system',
				confirmed && 'timeline-message--confirmed'
			)}
			{...otherProps}
		>
			<Box className="timeline-message__inner">
				<time className="timeline-message__timestamp" dateTime={timestamp}>
					<DateTimeFormatter>{timestamp}</DateTimeFormatter>
				</time>
				{system && (
					<Box className="timeline-message__title">
						<Text as="strong">
							<Message {...m.systemMessage} />
						</Text>
						<Icon type="statusProcessing" color="gray500" size="md" customStyle={iconStyle} />
					</Box>
				)}
				{sender && recipient && !system && (
					<Box className="timeline-message__title">
						<Text as="strong">{`${sender} `}</Text>
						<Fragment>
							<br className="d-lg-none" />
							<Message {...m.wroteTo} values={{ recipient }} />
						</Fragment>
						<Icon type={type} color={iconColor} size="md" customStyle={iconStyle} />
					</Box>
				)}
				<Text
					ref={messageTextRef}
					className={cx('timeline-message__text', more && 'timeline-message__text--more')}
					mb={0}
				>
					{children}
				</Text>
				{readTimestamp && (
					<Box className="timeline-message__read">
						<Tooltip offset={5} tooltip={<DateTimeFormatter>{readTimestamp}</DateTimeFormatter>}>
							<Icon type="messageRead" color={iconColor} size="md" />
						</Tooltip>
					</Box>
				)}
				{displayUnreadIcon && (
					<Box className="timeline-message__read">
						<Tooltip offset={5} tooltip={<Message {...m.unread} />}>
							<Icon type="contact" color={iconColor} size="md" />
						</Tooltip>
					</Box>
				)}

				{attachments && (
					<Grid flexDirection="column" mt={3}>
						{attachments}
					</Grid>
				)}
				{showMore && (
					<Button onClick={() => setMore(!more)} kind="blank" size="xs">
						{more ? <Message {...m.hide} /> : <Message {...m.more} />} <Icon type="caret-down" />
					</Button>
				)}
			</Box>
			{confirmed && (
				<Icon className="timeline-message__icon" type="statusSuccess" color="success" size="sm" />
			)}
		</Box>
	);
};
TimelineMessage.propTypes = {
	/** Attachments in message body. */
	attachments: PropTypes.node,
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** If `true`, message is confirmed with icon. */
	confirmed: PropTypes.bool,
	displayUnreadIcon: PropTypes.bool,
	readTimestamp: PropTypes.string,
	/** User recieving the message. */
	recipient: PropTypes.string,
	/** User sending the message. */
	sender: PropTypes.string,
	/** If `true`, message is sent from system. */
	system: PropTypes.bool,
	/** Message timestamp */
	timestamp: PropTypes.string,
	/** Visual type of message, can be internal or external. */
	type: PropTypes.oneOf(['internal', 'external']),
};

export default TimelineMessage;
