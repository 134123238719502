import { createAction, createReducer } from '@reduxjs/toolkit';
import { addToast } from '@ci/toasts';
import { createThunk, isFetching, request, selectIsThunkPending } from '@ci/api';
import { logOut } from '@myci/authentication';

import m from './messages';

type PlanFunctionality =
	| 'alerts'
	| 'bouncedCheques'
	| 'contractDetails'
	| 'disputes'
	| 'extraReports'
	| 'inquiries'
	| 'paymentIncidents'
	| 'scoring'
	| 'telecom'
	| 'thirdPartyReport'
	| 'wallet';

interface ReportCounterConfig {
	canDownloadReport: boolean;
	extraReports: number;
	freeReportsLeft: number;
	freeReportsTotal: number;
	hasUnlimitedReports: boolean;
	reportsAvailable: number;
}

interface PlanAccessConfig {
	alerts: boolean;
	bouncedCheques: boolean;
	contractDetails: boolean;
	disputes: boolean;
	extraReports: boolean;
	inquiries: boolean;
	paymentIncidents: boolean;
	scoring: boolean;
	telecom: boolean;
	thirdPartyReport: boolean;
	wallet: boolean;
}

interface PlanManagementState {
	planAccessConfig: PlanAccessConfig | null;
	reportCounterConfig: ReportCounterConfig | null;
}

interface GlobalState {
	planManagement: PlanManagementState;
}

const initialState: PlanManagementState = {
	reportCounterConfig: null,
	planAccessConfig: null,
};

const storeReportCounterConfig = createAction<ReportCounterConfig>(
	'@planManagement/storeReportCounterConfig'
);
export const storePlanAccessConfig = createAction<PlanAccessConfig>(
	'@planManagement/storePlanAccessConfig'
);

export const fetchPlanAccessConfig = createThunk(
	'@planManagement/fetchPlanAccessConfig',
	async ({ dispatch }) => {
		try {
			const successAction = await dispatch(
				request({
					url: 'subscriptions/uisettings',
					method: 'GET',
				})
			);
			dispatch(storePlanAccessConfig(successAction.payload.result.uiSettings));
		} catch {
			dispatch(
				addToast({
					type: 'warning',
					content: m.errorPricingPlansConfig,
				})
			);
		}
	}
);

export const fetchReportCounter = createThunk(
	'@planManagement/fetchReportCounter',
	async ({ dispatch }) => {
		try {
			const successAction = await dispatch(
				request({
					url: 'report/counter',
					method: 'GET',
				})
			);
			dispatch(
				storeReportCounterConfig({
					...successAction.payload.result,
					hasUnlimitedReports: successAction.payload.result.unlimitedReports,
				})
			);
		} catch {
			dispatch(
				addToast({
					type: 'warning',
					content: m.errorReportCounter,
				})
			);
		}
	}
);

export const selectIsFetchingReportCounter = isFetching(fetchReportCounter.originType);

export const selectCanDownloadReport = (state: GlobalState) =>
	state.planManagement.reportCounterConfig?.canDownloadReport;

export const selectFreeReportsLeft = (state: GlobalState) =>
	state.planManagement.reportCounterConfig?.freeReportsLeft;

export const selectReportCounterConfig = (state: GlobalState) =>
	state.planManagement.reportCounterConfig;

export const selectPlanAccessConfig = (state: GlobalState) => state.planManagement.planAccessConfig;
export const selectIsFetchingPlanAccessConfig = selectIsThunkPending(fetchPlanAccessConfig);

export const selectIsFunctionalityAccessibleWithCurrentPlan =
	(functionality: PlanFunctionality) =>
	(state: GlobalState): boolean =>
		state.planManagement.planAccessConfig?.[functionality] ?? false;

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(storeReportCounterConfig, (state, { payload }) => ({
			...state,
			reportCounterConfig: payload,
		}))
		.addCase(storePlanAccessConfig, (state, { payload }) => ({
			...state,
			planAccessConfig: payload,
		}))
		.addCase(logOut.originType, () => initialState)
);
