import { createThunk } from '@ci/api';
import { createAction, createReducer } from '@reduxjs/toolkit';
import memoize from 'fast-memoize';
import { LocalLookupKey, LookupData, PreparedLookupData, ResolveLookupEntries } from './types';
import { prepareLookupData, prepareLookupEntries } from './utils';

export interface State {
	preparedLookupData: PreparedLookupData;
}

export interface RootState {
	lookups: State;
}

export const initialState: State = {
	preparedLookupData: {},
};

export const selectPreparedLookupData = (state: RootState): PreparedLookupData =>
	state.lookups.preparedLookupData;

export const selectLookupEntries = (localLookupKey: LocalLookupKey) => (state: RootState) =>
	state.lookups.preparedLookupData[localLookupKey]?.entries;

const defaultLookupNormalizations = { byKey: {}, byValue: {} };

export const selectLookupNormalizations = (localLookupKey: LocalLookupKey) => (state: RootState) =>
	state.lookups.preparedLookupData[localLookupKey]?.normalizations ?? defaultLookupNormalizations;

export const selectLookupKeysByValue = (localLookupKey: LocalLookupKey) => (state: RootState) =>
	selectLookupNormalizations(localLookupKey)(state).byValue;

export const selectLookupValuesByKey = (localLookupKey: LocalLookupKey) => (state: RootState) =>
	selectLookupNormalizations(localLookupKey)(state).byKey;

export const storeLookupData = createAction<LookupData>('@ci/lookups/storeLookupData');

export const storePreparedLookupData = createAction<PreparedLookupData>(
	'@ci/lookups/storePreparedLookupData'
);

export interface FetchLookupDataPayload {
	localLookupKey: LocalLookupKey;
	resolveLookupEntries: ResolveLookupEntries;
	shouldOverwrite?: boolean;
}

export const fetchLookupData = createThunk(
	'@ci/lookups/fetchLookupData',
	async ({ dispatch, getState }, payload: FetchLookupDataPayload) => {
		const { localLookupKey, resolveLookupEntries, shouldOverwrite } = payload;
		const lookupEntries = await resolveLookupEntries(localLookupKey);

		if (shouldOverwrite || !selectLookupEntries(localLookupKey)(getState())) {
			dispatch(storePreparedLookupData({ [localLookupKey]: prepareLookupEntries(lookupEntries) }));
		}
	}
);

// NOTE: When `store.replaceReducer` is called by Redux Syringe, Redux DevTools enhancer replays
// all previously dispatched actions. Applications which dispatch `storeLookupData()` instead of
// `storePreparedLookupData()` can get extremely slow in development mode (since every injection
// of reducers causes `prepareLookupData` to be called again, possibly with all lookup data).
const memoizedPrepareLookupData =
	process.env.NODE_ENV === 'development' ? memoize(prepareLookupData) : prepareLookupData;

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(storeLookupData, (state, action) => {
			state.preparedLookupData = {
				...state.preparedLookupData,
				...memoizedPrepareLookupData(action.payload),
			};
		})
		.addCase(storePreparedLookupData, (state, action) => {
			state.preparedLookupData = {
				...state.preparedLookupData,
				...action.payload,
			};
		})
);
