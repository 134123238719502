import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { all, includes, isEmpty, uniq } from 'ramda';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { isNilOrEmpty } from 'ramda-extension';
import { TranslationFetchingContext } from '@ci/message';

import { ensureTranslations, init, loadIntlOptions } from '../../duck';
import {
	getLoadedNamespacesByLocale,
	getLocale,
	getMessagesByLocale,
	getPreferedLocale,
} from '../../selectors';
import { getMessageIdNamespace } from '../../utils';

const isAllEmpty = all(isNilOrEmpty);
/**
 * @myci/IntlProvider is a wrapper over react-intl's IntlProvider.
 *
 * It loades necessary translations based on `namespaces` property.
 *
 * Initial values can be passed through `initial{Messages|Namespaces|Locale}` properties.
 * Those values are used in the first render (useful for SSR) if the there is no difference between `initialNamespaces` and `namespaces`.
 */
const IntlProvider = ({
	initialLocale,
	initialMessages,
	initialIntlOptions,
	initialNamespaces = [],
	init,
	locale,
	preferedLocale,
	ensureTranslations,
	messages,
	loadedNamespaces,
	children,
}) => {
	const [localNamespaces, setLocalNamespaces] = useState([]);

	// Redux store initiation
	useEffect(() => {
		if (isAllEmpty([initialNamespaces, initialMessages, initialIntlOptions, initialLocale])) {
			return;
		}

		init({
			namespaces: initialNamespaces,
			messages: initialMessages,
			intlOptions: initialIntlOptions,
			locale: initialLocale || preferedLocale,
		});
	}, [initialNamespaces, initialMessages, initialLocale, preferedLocale]);

	// Ensure translations for current locale changes
	useEffect(() => {
		if (locale && !isAllEmpty([localNamespaces, initialNamespaces])) {
			ensureTranslations({ namespaces: uniq([...localNamespaces, ...initialNamespaces]), locale });
		}
	}, [locale, ensureTranslations, localNamespaces]);

	const dispatch = useDispatch();
	useEffect(() => {
		if (isNilOrEmpty(initialIntlOptions)) {
			dispatch(loadIntlOptions(locale));
		}
	}, [initialIntlOptions, locale]);

	const resolvedLocale = locale || initialLocale;
	const resolvedMessages = isEmpty(messages) ? initialMessages : messages;

	const translationFetchingContextValue = useMemo(
		() => ({
			getIsTranslationFetched: id =>
				includes(getMessageIdNamespace(String(id)), loadedNamespaces ?? []),

			registerMessage: id => {
				const namespace = getMessageIdNamespace(String(id));

				setLocalNamespaces(namespaces =>
					namespaces.includes(namespace) ? namespaces : [...namespaces, namespace]
				);
			},
		}),
		[loadedNamespaces]
	);

	if (!resolvedLocale) {
		return null;
	}

	return (
		// NOTE: We always want to use latin numerals, even when arabic numerals should be used (according to the locale).
		// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat for more info.
		<ReactIntlProvider
			locale={`${resolvedLocale}-u-nu-latn`}
			messages={resolvedMessages}
			textComponent={Fragment}
		>
			<TranslationFetchingContext.Provider value={translationFetchingContextValue}>
				{children}
			</TranslationFetchingContext.Provider>
		</ReactIntlProvider>
	);
};

IntlProvider.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	ensureTranslations: PropTypes.func,
	init: PropTypes.func,
	initialIntlOptions: PropTypes.object,
	/** Purpose of intial values is to pass localization data to the client from SSR-loaded messages. */
	initialLocale: PropTypes.string,
	/** Purpose of intial values is to pass localization data to the client from SSR-loaded messages. */
	initialMessages: PropTypes.object,
	/** Purpose of intial values is to pass localization data to the client from SSR-loaded messages. */
	initialNamespaces: PropTypes.arrayOf(PropTypes.string),
	loadedNamespaces: PropTypes.arrayOf(PropTypes.string),
	locale: PropTypes.string,
	messages: PropTypes.object,
	/** Used as default locale if the locale is not set through URL. */
	preferedLocale: PropTypes.string,
};

const IntlProviderConnected = connect(
	(state, ownProps) => {
		const { initialIntlOptions, initialNamespaces, initialLocale, initialMessages } = ownProps;

		const locale = getLocale(state);

		return {
			messages: getMessagesByLocale(locale)(state),
			loadedNamespaces: getLoadedNamespacesByLocale(locale)(state),
			locale,
			preferedLocale: getPreferedLocale(state),
			initialLocale,
			initialMessages,
			initialIntlOptions,
			initialNamespaces,
		};
	},
	{ ensureTranslations, init }
)(IntlProvider);

export default IntlProviderConnected;
