import PropTypes from 'prop-types';
import { map, mergeDeepRight, o, reduce, split, splitEvery } from 'ramda';
import { isString } from 'ramda-extension';
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Replaces non-string parts of the translated message with placeholders
 * and joints the parts back into a message.
 * I.e. ['foo', <b>bar</b>, 'baz'] => 'foo[[PART_1]]baz'
 * @param parts Parts of the translated message
 * @return {string}
 */
export const prepareTemplateWithPlaceholders = parts => {
	const segments = parts.map((part, index) => (isString(part) ? part : `[[PART_${index}]]`));

	return segments.join('');
};

/**
 * Splits the message template into parts with associated list depth
 * i.e.
 * '$ A $$ Ax $$ Ay $ B'
 * =>
 * [{level: 1, part: 'A'}, {level: 2, part: 'Ax}, {level: 2, part: 'Ay'}, {level: 1, part: 'B'}]
 * @param template Message template with $ depth markers
 * @param listDepthMarker Separator used to determine the item depth
 * @return {{level: number, part: number}[]}
 */
export const getLevelsAndParts = (template, listDepthMarker) => {
	const levelsAndPartsLinear = reduce(
		([items, counter], val) => (val ? [[...items, counter, val], 1] : [[...items], counter + 1]),
		[[], 0],
		split(listDepthMarker, template)
	)[0];

	return o(
		map(([level, part]) => ({ level, part })),
		splitEvery(2)
	)(levelsAndPartsLinear);
};

/**
 * Builds a hierarchy from levels and parts.
 * Hierarchy is a tree of nodes with parent - child relations
 * @param levelAndPartPairs Pairs generated by getLevelsAndParts()
 * @return {{parent: null, children: Array, level: number}} Top level node of the hierarchy
 */
export const buildHierarchy = levelAndPartPairs => {
	const root = {
		parent: null,
		children: [],
		level: 0,
	};

	let activeNode = root;
	levelAndPartPairs.forEach(({ level, part }) => {
		for (let i = activeNode.level; i < level; i++) {
			// Create new nodes when diving deeper into the hierarchy
			const newNode = {
				parent: activeNode,
				children: [],
				level: i + 1,
			};

			activeNode.children.push(newNode);
			activeNode = newNode;
		}

		for (let i = activeNode.level; i > level; i--) {
			// Returning from a deeper level up
			activeNode = activeNode.parent;
		}

		activeNode.children.push(part);
	});

	return root;
};

/**
 * Transforms a hierarchy into a tree of UI components
 * @param hierarchyNode Node to convert
 * @param placeholders Components to replace placeholders
 * @param renderTopLevelItem Component used to render the top level part of the message
 * @param renderList Component used to render list elements
 * @param renderListItem Component used to render list elements (defaults to <li>)
 * @param parentKey String used for keying individual items
 * @return UI components for the given node
 */
export const buildTree = (
	hierarchyNode,
	placeholders,
	renderTopLevelItem,
	renderList,
	renderListItem,
	parentKey
) => {
	const [List, ListItem, TopLevelItem] = [renderList, renderListItem, renderTopLevelItem];

	return hierarchyNode.children.map((child, index) => {
		const key = parentKey !== undefined ? `${parentKey}_${index}` : String(index);

		if (isString(child)) {
			// Replace placeholders with original values
			const formattedChild = child.split(/(\[\[PART_[0-9]+]])/).map(frag => {
				const match = frag.match(/\[\[PART_([0-9])+]]/);

				return match ? placeholders[Number(match[1])] : frag;
			});

			return hierarchyNode.level ? (
				<ListItem key={key} level={hierarchyNode.level}>
					{formattedChild}
				</ListItem>
			) : (
				<TopLevelItem key={key}>{formattedChild}</TopLevelItem>
			);
		}
		return (
			<List key={key} level={hierarchyNode.level}>
				{buildTree(child, placeholders, renderTopLevelItem, renderList, renderListItem, key)}
			</List>
		);
	});
};

/* eslint-disable react/display-name */
// Replacers for XML tags inside the message
const tagReplacers = {
	b: msg => <strong>{msg}</strong>,
};
/* eslint-enable react/display-name */

const MessageList = ({
	values,
	listDepthMarker,
	renderTopLevelItem,
	renderList,
	renderListItem,
	...otherProps
}) => {
	const valuesWithReplacers = mergeDeepRight(tagReplacers, values || {});
	const partsToList = parts => {
		const template = prepareTemplateWithPlaceholders(parts);
		const levelsAndParts = getLevelsAndParts(template, listDepthMarker);
		const root = buildHierarchy(levelsAndParts);

		return buildTree(root, parts, renderTopLevelItem, renderList, renderListItem);
	};

	return (
		<FormattedMessage values={valuesWithReplacers} {...otherProps}>
			{partsToList}
		</FormattedMessage>
	);
};

MessageList.propTypes = {
	children: PropTypes.func,
	id: PropTypes.string.isRequired,
	/** Marker used to specify list depth in message (defaults to $) */
	listDepthMarker: PropTypes.string,
	/** Component used to render list elements (defaults to <ul>) */
	renderList: PropTypes.elementType,
	/** Component used to render list elements (defaults to <li>) */
	renderListItem: PropTypes.elementType,
	/** Component used to render the top level part of the message (defaults to <p>) */
	renderTopLevelItem: PropTypes.elementType,
	/** Additional values and formatters for the message */
	values: PropTypes.object,
};

MessageList.defaultProps = {
	listDepthMarker: '$',
	renderTopLevelItem: 'p',
	renderList: 'ul',
	renderListItem: 'li',
};

export default MessageList;
