import React from 'react';
import { cx } from 'ramda-extension';
import { getBase64DataUrl } from '@myci/utils';
import { Box } from '@myci/ui-components';

import { Dropzone } from '../Dropzone';
import { Attachment } from '../types';
import { getBase64OfFile } from './utils';

export interface FileInputMetadataControlProps {
	acceptedExtensions: string[];
	className?: string;
	disabled?: boolean;
	onChange: (value: Attachment) => void;
	value: Attachment;
}

export const FileInputMetadataControl = ({
	acceptedExtensions,
	className,
	value,
	onChange,
	disabled: isDisabled,
}: FileInputMetadataControlProps) => {
	const handleDropAccepted = files => {
		const file = files[0];
		const { name, size, type } = file;

		getBase64OfFile(file, async base64 => {
			onChange({ name, size, type, base64: getBase64DataUrl(base64, type) });
		});
	};

	return (
		<Box className={cx('file-input', className)}>
			<Dropzone
				acceptedExtensions={acceptedExtensions}
				fileName={value?.name}
				onDropAccepted={handleDropAccepted}
				value={value ? getBase64DataUrl(value.base64, value.type) : ''}
				isDisabled={isDisabled}
			/>
		</Box>
	);
};
