import {
	useConfirmNavigation,
	useHasCurrentBoundaryUnsavedChanges,
} from '@creditinfo-ui/unsaved-changes';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	hideModal,
	selectIsModalInStack,
	selectIsModalOnTop,
	selectModalPayload,
	selectModalStackIndex,
	showModal,
} from './duck';
import { ModalInstance } from './types';

export const useModal = <TPayload extends object>(modalInstance: ModalInstance<TPayload>) => {
	const dispatch = useDispatch();
	const hasCurrentBoundaryUnsavedChanges = useHasCurrentBoundaryUnsavedChanges();
	const confirmNavigation = useConfirmNavigation();

	const payload = useSelector(selectModalPayload(modalInstance));
	const payloadRef = useRef(payload);

	const show: (
		...params: {} extends TPayload ? [payload?: TPayload] : [payload: TPayload]
	) => void = useCallback(
		(nextPayload?: TPayload) => {
			dispatch(showModal(modalInstance, nextPayload as TPayload));
		},
		[dispatch, modalInstance]
	);

	const hide = useCallback(() => {
		if (hasCurrentBoundaryUnsavedChanges) {
			const shouldHideModal = confirmNavigation();

			if (!shouldHideModal) {
				return;
			}
		}

		dispatch(hideModal(modalInstance));
	}, [hasCurrentBoundaryUnsavedChanges, dispatch, modalInstance, confirmNavigation]);

	const isOnTop = useSelector(selectIsModalOnTop(modalInstance));
	const stackIndex = useSelector(selectModalStackIndex(modalInstance));
	const isInStack = useSelector(selectIsModalInStack(modalInstance));

	if (isInStack) {
		payloadRef.current = payload;
	}

	const modal = useMemo(
		() => ({
			show,
			hide,
			isInStack,
			isOnTop,
			stackIndex,
			payload: payloadRef.current,
		}),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[show, hide, isInStack, isOnTop, payload, stackIndex]
	);

	return modal;
};
