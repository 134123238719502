import { andThen, compose, fromPairs, map, mergeAll, o, values } from 'ramda';

import supportedLocaleData from '../data/supportedLocales.json';

import { importTranslations } from './importTranslations';

const mergeAllValues = o(mergeAll, values);

const loadTranslationsForLocale = namespaces => locale =>
	importTranslations(locale)(namespaces).then(xs => [locale, mergeAllValues(xs)]);

export const loadAllTranslations = namespaces =>
	compose(
		andThen(fromPairs),
		xs => Promise.all(xs),
		map(loadTranslationsForLocale(namespaces))
	)(supportedLocaleData.supportedLocales);
