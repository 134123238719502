import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';

import './InfoTab.scss';

const InfoTab = ({ children, className, ...otherProps }) => (
	<Box className={cx(className, 'info-tab')} tabIndex="0" {...otherProps}>
		{children}
	</Box>
);

InfoTab.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
};

export default InfoTab;
