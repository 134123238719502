import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';

import './TabBar.scss';

const TabBar = ({ className, ...rest }) => {
	const [isContentOverflowing, setIsContentOverflowing] = useState(false);

	const tabBarRef = useRef();
	useLayoutEffect(() => {
		const tabBarContainer = tabBarRef?.current;
		const handleResize = () => {
			setIsContentOverflowing(
				tabBarContainer && tabBarContainer.scrollWidth > tabBarContainer.offsetWidth
			);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Box
			ref={tabBarRef}
			className={cx('tab-bar', { 'tab-bar--with-more-tabs': isContentOverflowing }, className)}
			{...rest}
		/>
	);
};

TabBar.propTypes = { className: PropTypes.string };

export default TabBar;
