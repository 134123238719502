import React from 'react';
import PropTypes from 'prop-types';
import { stringOrNumberPropType } from '@ci/react-utils';
import { IdentityFormatter } from '@ci/formatters';
import { cx } from 'ramda-extension';

import Label from '../Label';
import Grid from '../Grid';
import Box from '../Box';
import Text from '../Text';
import FormText from '../FormText';
import FormGroup from '../FormGroup';

const RadioGroup = ({
	error,
	formatter: Formatter = IdentityFormatter,
	radioControl: RadioControl,
	items,
	label,
	labelComponent: LabelComponent = Label,
	required,
	disabled,
	groupProps,
	selectedValue,
	...otherProps
}) => {
	const hasError = Boolean(error);

	return (
		<FormGroup
			className={cx({
				'is-group-required': required,
				'is-disabled': disabled,
			})}
			hasError={hasError}
			kind="radio-group"
			hasLabel
			{...groupProps}
		>
			{label && (
				<LabelComponent className="group-label" isDisabled={disabled}>
					{label}
					{required && (
						<Text className="required-sign" as="span">
							{' '}
							*
						</Text>
					)}
				</LabelComponent>
			)}
			<Grid flex="true" pt={1}>
				{items.map(item => (
					<Box className="form-group__radio-box">
						<RadioControl
							key={item}
							disabled={disabled}
							required={false}
							label={<Formatter>{item}</Formatter>}
							value={item}
							checked={item === selectedValue}
							{...otherProps}
						/>
					</Box>
				))}
			</Grid>
			{error && <FormText hasError={hasError}>{error}</FormText>}
		</FormGroup>
	);
};

RadioGroup.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	disabled: PropTypes.bool,
	/** Error component to be rendered under radios */
	error: PropTypes.node,
	/** Formatter used to format radio's label */
	formatter: PropTypes.node,
	/** Additional props to pass to the FormGroup */
	groupProps: PropTypes.object,
	/** Array of strings or numbers to be rendered */
	items: PropTypes.arrayOf(stringOrNumberPropType).isRequired,
	/** Label of radio group component */
	label: PropTypes.node,
	/** Container for label */
	labelComponent: PropTypes.node,
	/* RadioControl component */
	radioControl: PropTypes.func,
	/** Prop to show * symbol after label */
	required: PropTypes.bool,
	/** Actual selected value of radio group component */
	selectedValue: stringOrNumberPropType,
};

export default RadioGroup;
