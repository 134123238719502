import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { always, applySpec, compose } from 'ramda';
import { isFunction } from 'ramda-extension';
import { useDeepEqualityMemoWarning } from '@ci/react-utils';
import {
	namespacedConnect,
	withMiddleware,
	withNamespaceProvider,
	withReducers,
} from 'redux-syringe';
import { destroy as destroyReduxForm } from 'redux-form';

import { FEATURE } from '../constants';
import { FormProps } from '../contexts';
import { destroy, initialize, registerForm, unregisterForm } from '../actions';
import { areAnyFormsRegisteredForCurrentStep, getCurrentStep, isInitialized } from '../selectors';
import { middleware, reducer } from '../duck';
import JourneyForm from './JourneyForm';

const Div = always(<div />);

const Journey = ({
	children,
	isJourneyInitialized,
	initializeJourney,
	initialStep,
	currentStep,
	onSubmit,
	formProps,
	initialValues,
	namespace,
	validate,
	areAnyFormsRegisteredForCurrentStep,
	registerForm,
	unregisterForm,
	destroyJourney,
	passAllSubmitArgs,
	...otherProps
}) => {
	const journeyProps = useRef();
	// NOTE: This will prevent an unnecessary update in components deeper in the tree.
	// We don't want to pass `otherProps` as a dependency to `const handleSubmit = useCallback()`,
	// yet we want the inner function to always receive the latest `otherProps`.
	journeyProps.current = otherProps;

	useEffect(() => {
		if (!isJourneyInitialized) {
			initializeJourney({ initialStep });
		}

		return () => {
			destroyJourney();
		};
	}, []);

	const handleSubmit = useCallback(
		(values, dispatch, currentStepProps) => {
			const resolvedOnSubmit = onSubmit || formProps?.onSubmit;

			if (isFunction(resolvedOnSubmit)) {
				if (passAllSubmitArgs) {
					resolvedOnSubmit(values, dispatch, journeyProps.current, currentStepProps);
				}

				return resolvedOnSubmit(values);
			}
		},
		[onSubmit, formProps?.onSubmit, passAllSubmitArgs]
	);

	const innerFormProps = useMemo(
		() => ({
			// See: https://redux-form.com/8.2.2/examples/wizard/
			destroyOnUnmount: false,
			forceUnregisterOnUnmount: true,
			submitAsSideEffect: true,
			validate,
			initialValues,
			form: namespace,
			registerForm,
			unregisterForm,
			onSubmit: handleSubmit,
			passAllSubmitArgs,
			...formProps,
		}),
		[initialValues, handleSubmit, validate, formProps]
	);

	useDeepEqualityMemoWarning(initialValues, '<Journey />', 'initialValues');
	useDeepEqualityMemoWarning(formProps, '<Journey />', 'formProps');

	// NOTE: We are rendering dummy form if there is none shown on the current step to make the `submit` workflow of redux-form work.
	const emptyJourneyForm = useMemo(
		() => <JourneyForm ignoreRegistering renderForm={Div} visibleOnStep={currentStep} />,
		[currentStep]
	);

	const footer =
		areAnyFormsRegisteredForCurrentStep || !isJourneyInitialized ? null : emptyJourneyForm;

	return (
		<FormProps.Provider value={innerFormProps}>
			<Fragment>
				{children}
				{footer}
			</Fragment>
		</FormProps.Provider>
	);
};

Journey.propTypes = {
	areAnyFormsRegisteredForCurrentStep: PropTypes.bool,
	children: PropTypes.node,
	currentStep: PropTypes.number,
	destroyJourney: PropTypes.func,
	fallback: PropTypes.node,
	formProps: PropTypes.object,
	initialStep: PropTypes.number,
	initialValues: PropTypes.object,
	initializeJourney: PropTypes.func,
	isJourneyInitialized: PropTypes.bool,
	namespace: PropTypes.string.isRequired,
	onSubmit: PropTypes.func,
	passAllSubmitArgs: PropTypes.bool,
	registerForm: PropTypes.func,
	unregisterForm: PropTypes.func,
	validate: PropTypes.func,
};

export default compose(
	withNamespaceProvider({ feature: FEATURE }),
	withMiddleware({ journeyMiddleware: middleware }, { feature: FEATURE }),
	withReducers(reducer, { feature: FEATURE }),
	namespacedConnect(
		applySpec({
			areAnyFormsRegisteredForCurrentStep,
			currentStep: getCurrentStep,
			isJourneyInitialized: isInitialized,
		}),
		(dispatch, ownProps) => ({
			initializeJourney: (...args) => dispatch(initialize(...args)),
			destroyJourney: (...args) => {
				// NOTE: Can't be handled through the middleware, because the component will be
				// already unmounted and the journey middlewared ejected.
				dispatch(destroyReduxForm(ownProps.namespace));

				dispatch(destroy(...args));
			},
			registerForm: (...args) => dispatch(registerForm(...args)),
			unregisterForm: (...args) => dispatch(unregisterForm(...args)),
		}),
		undefined,
		{ feature: FEATURE }
	)
)(Journey);
