import { noop } from 'ramda-extension';
import { usePortalContainer } from '@creditinfo-ui/utils';
import { useStyles } from '@creditinfo-ui/styles';
import { ReactNode, useCallback, useRef } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { withMiddleware, withReducers } from 'redux-syringe';
import { withUnsavedChangesBoundary } from '@creditinfo-ui/unsaved-changes';
import { pipe } from 'ramda';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { middleware, reducer } from '../duck';
import { useModal } from '../hooks';
import { ModalInstance } from '../types';
import { ModalInstanceContext } from '../contexts';

export interface PredefinedModalPresenterProps {
	children: ReactNode;
	isAlwaysMounted: boolean;
	isModalInStack: boolean;
	onBackdropClick: () => void;
	onCloseButtonClick: () => void;
	onTransitionExited: () => void;
	zIndex: number;
}

export interface ModalHideHandler<TPayload extends object> {
	(params: { dispatch: Dispatch; payload: TPayload }): void;
}

export interface ModalProps<TPayload extends object, TPresenterProps extends object> {
	children: React.ReactNode;
	instance: ModalInstance<TPayload>;
	isAlwaysMounted?: boolean;
	onHide?: ModalHideHandler<TPayload>;
	presenterProps?: Omit<TPresenterProps, keyof PredefinedModalPresenterProps>;
	renderPresenter: React.ComponentType<TPresenterProps>;
	zIndex?: number;
}

const PureModal = <TPayload extends object, TPresenterProps extends object>({
	children,
	instance,
	isAlwaysMounted = false,
	onHide,
	presenterProps,
	renderPresenter: Presenter,
	zIndex,
}: ModalProps<TPayload, TPresenterProps>) => {
	const { hide, isInStack, payload, stackIndex } = useModal(instance);
	const { id } = instance;
	const portalContainer = usePortalContainer(id);
	const { utils } = useStyles();
	const dispatch = useDispatch();

	const stackIndexRef = useRef(stackIndex);

	// NOTE: If there are multiple modals shown at the same time, we need to make sure that
	// their `zIndex` is set according to their stack index. If we hide a modal, its index
	// will immediately be set to `-1`, meaning that its hide animation will not be shown
	// (it will be immediately covered by the other modal). The implementation below will
	// remember the last stack index from when the modal was still shown.
	if (stackIndex !== -1) {
		stackIndexRef.current = stackIndex;
	}

	const handleTransitionExited = useCallback(() => {
		if (onHide) {
			onHide({ dispatch, payload: payload! });
		}
	}, [onHide, dispatch, payload]);

	if (!portalContainer) {
		return null;
	}

	const predefinedPresenterProps: PredefinedModalPresenterProps = {
		children,
		isAlwaysMounted,
		isModalInStack: isInStack,
		onBackdropClick: noop,
		onCloseButtonClick: hide,
		onTransitionExited: handleTransitionExited,
		zIndex: zIndex ?? utils.zIndices.modal + stackIndexRef.current,
	};

	const allPresenterProps: TPresenterProps = {
		...predefinedPresenterProps,
		...presenterProps,
	} as any;

	return createPortal(
		<ModalInstanceContext.Provider value={instance}>
			<Presenter {...allPresenterProps} />
		</ModalInstanceContext.Provider>,
		portalContainer
	);
};

// NOTE: `as any` with `typeof PureModal` is necessary because decorators would eat our generics.
export const Modal: typeof PureModal = pipe(
	withUnsavedChangesBoundary,
	withMiddleware(middleware),
	withReducers({ modalStack: reducer })
)(PureModal) as any;
