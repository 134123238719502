import { makeActionTypes, makeSimpleActionCreator } from 'redux-syringe';
import { generateUuid } from '@ci/utils';

export const METHOD_PREFIX = '@control-flow/method';
export const TASK_PREFIX = '@control-flow/task';
export const ASYNC_HELPER_PREFIX = '@control-flow/async-helper';

// TODO: Implement more method action creators from https://caolan.github.io/async/docs.html#controlflow
export const MethodActionTypes = makeActionTypes(METHOD_PREFIX, [
	// Waterfall method actions.
	'WATERFALL',
	'WATERFALL_CANCEL',
	// Enqueue method actions.
	'ENQUEUE',
	'ENQUEUE_REMOVE',
	'ENQUEUE_CLEAR',
	// Parallel method actions.
	'PARALLEL',
	// Poll method actions.
	'POLL',
	'POLL_CANCEL',
]);

export const TaskActionTypes = makeActionTypes(TASK_PREFIX, ['SUCCESS', 'ERROR']);
export const AsyncHelperActionTypes = makeActionTypes(ASYNC_HELPER_PREFIX, ['SKIP', 'FAIL']);

/**
 * @deprecated Use `await` with thunks instead.
 */
export const waterfall = (tasks, callback) => ({
	type: MethodActionTypes.WATERFALL,
	payload: { tasks, callback },
	meta: { controlFlowId: generateUuid() },
});

export const enqueue = (key, task, callback) => ({
	type: MethodActionTypes.ENQUEUE,
	payload: { key, task, callback },
	meta: { controlFlowId: generateUuid() },
});

/**
 * @deprecated Use `await Promise.all()` with thunks instead.
 */
export const parallel = (tasks, callback) => ({
	type: MethodActionTypes.PARALLEL,
	payload: { tasks, callback },
	meta: { controlFlowId: generateUuid() },
});

/**
 * @deprecated Use `useThunkPolling` or for-loops with `await` instead.
 */
// eslint-disable-next-line max-params
export const poll = (key, task, callback = undefined, interval = 5000) => ({
	type: MethodActionTypes.POLL,
	payload: { key, task, callback, interval },
	meta: { controlFlowId: generateUuid() },
});

export const cancelPoll = key => ({
	type: MethodActionTypes.POLL_CANCEL,
	payload: { key },
});

/**
 * @deprecated Use `await` with thunks instead.
 */
export const run = (task, callback) => waterfall([task], callback);

export const taskSuccessEvent = (taskId, event) => ({
	type: TaskActionTypes.SUCCESS,
	payload: { taskId, event },
});

export const taskErrorEvent = (taskId, event) => ({
	type: TaskActionTypes.ERROR,
	payload: { taskId, event },
});

export const skip = reason => ({ type: AsyncHelperActionTypes.SKIP, payload: reason });
export const fail = reason => ({ type: AsyncHelperActionTypes.FAIL, payload: reason });

waterfall.cancel = makeSimpleActionCreator(MethodActionTypes.WATERFALL_CANCEL);

enqueue.remove = makeSimpleActionCreator(MethodActionTypes.ENQUEUE_REMOVE);
enqueue.clear = makeSimpleActionCreator(MethodActionTypes.ENQUEUE_CLEAR);
