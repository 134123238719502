import React, { forwardRef } from 'react';
import { FormField } from '@myci/ui-components';

import { FileInputControl, FileInputMetadataControl } from '../FileInputControl';
import './FileInput.scss';

export interface FileInputProps {
	hasFileMetadata: boolean;
}

export const FileInput = forwardRef((props: FileInputProps, ref) => {
	const { hasFileMetadata, ...otherProps } = props;

	return (
		<FormField
			ref={ref}
			control={hasFileMetadata ? FileInputMetadataControl : FileInputControl}
			kind="text-input"
			autoComplete="off"
			{...otherProps}
		/>
	);
});

FileInput.displayName = 'forwardRef(FileInput)';
