import { typeEq } from '@ci/utils';
import { isNil } from 'ramda';
import { MethodActionTypes } from '../actions';
import {
	assocTaskId,
	FIRST_POLL_RUN,
	getControlFlowId,
	getTaskId,
	isTaskEvent,
	makeCallback,
} from '../utils';

const middleware = ({ dispatch }) => {
	const keysByTaskId = {};
	const taskByKey = {};
	const configsByKey = {};
	const taskIdsByTask = new WeakMap();

	return next => action => {
		next(action);

		if (typeEq(MethodActionTypes.POLL, action)) {
			const { key, task, callback: userCallback, interval } = action.payload;

			const taskId = getControlFlowId(action);
			const delegatedTaskId = getTaskId(action);

			taskIdsByTask.set(task, taskId);
			keysByTaskId[taskId] = key;
			configsByKey[key] = {
				callback: makeCallback(userCallback, dispatch, delegatedTaskId),
				interval,
			};

			taskByKey[key] = task;
			const nextAction = task(FIRST_POLL_RUN);
			dispatch(assocTaskId(taskId, nextAction));
		}

		if (typeEq(MethodActionTypes.POLL_CANCEL, action)) {
			const { key } = action.payload;

			delete taskByKey[key];
		}

		if (isTaskEvent(action)) {
			const taskId = action.payload.taskId;
			const key = keysByTaskId[taskId];
			const task = taskByKey[key];

			// Missing task means that this task was not ours (but of another control flow method)
			// or it is already cancelled.
			if (isNil(task)) {
				return;
			}

			const { callback, interval } = configsByKey[key];
			const nextAction = task(action.payload.event.payload);

			if (!nextAction) {
				callback(action);
			} else {
				setTimeout(() => {
					dispatch(assocTaskId(taskId, nextAction));
				}, interval);
			}
		}
	};
};

export default middleware;
