import { isNil } from 'ramda';
import { useIntl } from 'react-intl';
import { Formatter } from 'afformative';
import { throttle } from '@creditinfo-ui/utils';
import { Message } from '@creditinfo-ui/messages';
import { isNilOrEmptyString } from 'ramda-extension';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import {
	ReactElement,
	RefObject,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { m } from './messages';
import { NilMessage, OpenDirection } from './types';
import {
	getHasElementOverflowHidden,
	getHasElementPositionFixed,
	queryAncestor,
	queryBottomOverlay,
	queryFixedContainerFooter,
} from './utils';

export const useDownshiftFormatter = <TInput, TOutput, TPrimitiveOutput>(
	formatter: Formatter<TInput, TOutput, TPrimitiveOutput>,
	nilMessage: NilMessage
) => {
	const intl = useIntl();

	type TNextInput = TInput | null | undefined;
	type TNextOutput = TOutput | ReactElement;
	type TNextPrimitiveOutput = TPrimitiveOutput | string;

	return useMemo(
		() =>
			formatter.wrap<TNextInput, TNextOutput, TNextPrimitiveOutput>(
				(delegate, value, suggestions) => {
					if (isNil(value)) {
						if (typeof nilMessage === 'string') {
							return nilMessage;
						}

						if (nilMessage !== false) {
							if (suggestions.includes('primitive')) {
								return intl.formatMessage(nilMessage);
							}

							return <Message {...nilMessage} />;
						}
					}

					// NOTE: At this point, if `value` is nil, `nilMessage` must be `false`, which implies
					// `delegate` can handle nil values. Proper type safety would be difficult to achieve,
					// so we just pretend `value` is not nil to make TypeScript happy.
					const formattedValue = delegate(value!);

					if (isNilOrEmptyString(formattedValue)) {
						if (suggestions.includes('primitive')) {
							return intl.formatMessage(m.emptyValue);
						}

						return <Message {...m.emptyValue} />;
					}

					if (suggestions.includes('primitive')) {
						return String(formattedValue);
					}

					return formattedValue;
				}
			),
		[formatter, intl, nilMessage]
	);
};

export interface UseViewportOffsetBottomParams {
	isDisabled?: boolean;
	originRef: RefObject<HTMLElement>;
}

// NOTE: This hook is very opinionated and assumes a certain application layout.
// There are two concepts we're working with: bottom overlays and fixed container footers.
//
// Bottom overlay: Has a fixed position and covers the bottom of the document. This bottom overlay
// generally isn't fixed on mobile devices, in which case its height is not considered. However,
// we still need to "capture it" and consider its height in case the window width increases.
//
// Fixed container footer: The best example is `SlideModalFooter`. The footer itself is not fixed,
// but is always at the bottom of a fixed container, behaving very similarly to a bottom overlay.
export const useViewportOffsetBottom = ({
	isDisabled = false,
	originRef,
}: UseViewportOffsetBottomParams) => {
	const footerRef = useRef<HTMLElement | null | undefined>(undefined);
	const [hasQueriedFooter, setHasQueriedFooter] = useState(false);
	const [hasFixedAncestor, setHasFixedAncestor] = useState(false);
	const [offsetBottom, setOffsetBottom] = useState(0);

	if (!hasQueriedFooter && originRef.current) {
		const fixedAncestor = queryAncestor(originRef.current, getHasElementPositionFixed);

		footerRef.current = fixedAncestor
			? queryFixedContainerFooter(fixedAncestor)
			: queryBottomOverlay();

		setHasFixedAncestor(Boolean(fixedAncestor));
		setHasQueriedFooter(true);
	}

	const handleFooterResize = useCallback(() => {
		if (isDisabled || !hasQueriedFooter || !footerRef.current) {
			return;
		}

		if (hasFixedAncestor || getHasElementPositionFixed(footerRef.current)) {
			setOffsetBottom(footerRef.current.clientHeight);
		} else {
			setOffsetBottom(0);
		}
	}, [hasFixedAncestor, hasQueriedFooter, isDisabled]);

	useResizeDetector({
		handleWidth: false,
		onResize: handleFooterResize,
		targetRef: footerRef,
		skipOnMount: true,
	});

	useEffect(() => {
		handleFooterResize();
	}, [handleFooterResize]);

	// NOTE: `handleWindowResize` and the corresponding `useEffect` handle situations where the bottom
	// overlay is not fixed to begin with, but then becomes fixed as the viewport width increases.
	const handleWindowResize = useCallback(() => {
		if (hasQueriedFooter && !hasFixedAncestor && !footerRef.current) {
			footerRef.current = queryBottomOverlay();

			if (footerRef.current) {
				handleFooterResize();
			}
		}
	}, [handleFooterResize, hasFixedAncestor, hasQueriedFooter]);

	useEffect(() => {
		const debounceResizeTimeout = 250;
		const handleResize = throttle(handleWindowResize, debounceResizeTimeout, {
			leading: false,
			trailing: true,
		});

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	if (isDisabled || !hasQueriedFooter || !footerRef.current) {
		return 0;
	}

	return offsetBottom;
};

export interface UseOpenDirectionParams {
	getRequiredHeight: () => number;
	shouldUpdate?: boolean;
	triggerRef: RefObject<HTMLElement>;
}

export const useOpenDirection = ({
	getRequiredHeight,
	shouldUpdate,
	triggerRef,
}: UseOpenDirectionParams) => {
	const [openDirection, setOpenDirection] = useState<OpenDirection>('down');
	const viewportOffsetBottom = useViewportOffsetBottom({ originRef: triggerRef });

	const updateOpenDirection = useCallback(() => {
		if (!triggerRef.current) {
			return;
		}

		const triggerRect = triggerRef.current.getBoundingClientRect();
		const viewportHeight = window.innerHeight;
		const requiredHeight = getRequiredHeight();

		const overflowContainer = queryAncestor(triggerRef.current, getHasElementOverflowHidden);
		const overflowContainerTop = overflowContainer?.getBoundingClientRect().top ?? 0;

		const canOpenUpAtAll = triggerRect.top - overflowContainerTop - requiredHeight > 0;

		if (!canOpenUpAtAll) {
			setOpenDirection('down');

			return;
		}

		const canOpenDownWithoutScrolling =
			triggerRect.bottom + requiredHeight < viewportHeight - viewportOffsetBottom;

		if (!canOpenDownWithoutScrolling) {
			setOpenDirection('up');

			return;
		}

		setOpenDirection('down');
	}, [viewportOffsetBottom, triggerRef, getRequiredHeight]);

	useLayoutEffect(() => {
		if (shouldUpdate) {
			updateOpenDirection();
		}
	}, [shouldUpdate, updateOpenDirection]);

	useEffect(() => {
		// NOTE: Usually, only one element will be open at a time, meaning that throttling isn't
		// really necessary. Instead, we prefer better responsiveness. Throttling also brings in
		// complexity with handling unmounting while scrolling (zombie `updateOpenDirection` calls).
		if (shouldUpdate) {
			window.addEventListener('scroll', updateOpenDirection);

			return () => window.removeEventListener('scroll', updateOpenDirection);
		}

		return undefined;
	}, [shouldUpdate, updateOpenDirection]);

	return {
		openDirection,
		updateOpenDirection,
	};
};
