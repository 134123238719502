import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import Heading from '../Heading';
import './CodeBlock.scss';

const CodeBlock = ({ code, title, children, className, ...otherProps }) => (
	<Box className={cx('code-block', className)} {...otherProps}>
		<Box className="code-block__header">
			<Heading as="h2" className="h3 mb-0">
				{title}
			</Heading>
		</Box>
		<Box className="code-block__body">
			<code>{code}</code>
		</Box>
		{children}
	</Box>
);

CodeBlock.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	code: PropTypes.node,
	title: PropTypes.node,
};

export default CodeBlock;
