import { useState } from 'react';
import { useOnClickOutside } from '@ci/react-utils';

import { ENTER_KEY_CODE, ESC_KEY_CODE } from '../../constants';

const useEditableText = ({ onChange, value, rootRef }) => {
	const [oldFormValue, setOldFormValue] = useState(value);
	const [isEdit, setIsEdit] = useState(false);

	const onClick = () => {
		setOldFormValue(value);
		setIsEdit(true);
	};

	const onConfirm = () => {
		setIsEdit(false);
	};

	const onCancel = () => {
		setIsEdit(false);
		onChange(oldFormValue);
	};

	const onKeyDown = event => {
		if (event.keyCode === ENTER_KEY_CODE) {
			onConfirm();
		} else if (event.keyCode === ESC_KEY_CODE) {
			onCancel();
		}
	};

	const handleOnChange = event => onChange(event.target.value);

	useOnClickOutside([rootRef], onConfirm);

	return {
		isEdit,
		inputProps: {
			onChange: handleOnChange,
			value,
			onKeyDown,
		},
		onCancel,
		onConfirm,
		onClick,
	};
};

export default useEditableText;
