import { repeat, splitEvery } from 'ramda';
import { cx, isNotNil } from 'ramda-extension';
import { ReactNode, isValidElement, useMemo } from 'react';
import { MaxWidths, Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';

import { getColumnCount } from '../utils';

type FieldGridLayout = 'exact' | 'compact' | 'grow';

const ROOT_CLASS_NAME = 'FieldGrid';

export interface FieldGridProps {
	customStyle?: Style;
	layout?: FieldGridLayout;
	maxFieldWidth?: keyof MaxWidths;
	maxWidth?: keyof MaxWidths;
	nodeAbove?: ReactNode;
	nodeBelow?: ReactNode;
	rows: ReactNode[][];
}

interface RootStyleProps {
	maxWidth?: keyof MaxWidths;
}

const rootStyle = prepareStyle<RootStyleProps>((utils, { maxWidth }) => ({
	marginBottom: utils.multiply(-1, utils.spacings.sm),
	marginTop: utils.multiply(-1, utils.spacings.sm),

	// NOTE: Fixes vertical margins when `FieldGrid` elements are rendered as siblings.
	// This is useful when we want to use the `compact` layout as well as having some field
	// always rendered on a separate row (can be easily achieved with two `FieldGrid` elements).
	selectors: {
		[`& + .${ROOT_CLASS_NAME}`]: {
			marginTop: utils.spacings.sm,
		},
	},

	extend: {
		condition: Boolean(maxWidth),
		style: {
			maxWidth: utils.maxWidths[maxWidth!],
		},
	},
}));

const rowStyle = prepareStyle(utils => ({
	// NOTE: `alignItems: 'flex-end'` should not be used here due to misalignment of invalid fields
	// with valid ones.
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginInlineEnd: utils.multiply(-1, utils.spacings.md),
	marginInlineStart: utils.multiply(-1, utils.spacings.md),
}));

interface FieldStyleProps {
	columnCount: number;
	maxWidth?: keyof MaxWidths;
}

const fieldStyle = prepareStyle<FieldStyleProps>((utils, { columnCount, maxWidth }) => ({
	flexBasis: 0,
	flexGrow: 1,
	flexShrink: 0,
	paddingInlineEnd: utils.spacings.md,
	paddingInlineStart: utils.spacings.md,

	selectors: {
		':not(:empty)': {
			paddingBottom: utils.spacings.sm,
			paddingTop: utils.spacings.sm,
		},
	},

	extend: [
		{
			condition: Boolean(maxWidth),
			style: {
				maxWidth: utils.maxWidths[maxWidth!],
			},
		},
		// TODO: Improve this mechanism. There are some special requirements to consider here:
		//
		// 1. For example, a 3-column grid shouldn't form patterns like ☵ in smaller viewports.
		// Instead, it should collapse immediately into a 1-column grid. Similarly, a 4-column grid
		// should first collapse into a 2-column grid, then into a 1-column grid. The grid should
		// always look even/clean, all fields should always have equal width.
		//
		// 2. Since the user of `FieldGrid` is responsible for specifying the rows, it is expected
		// that they will not "merge". The first element of a row definition must thus always be
		// rendered on a new line (instead of continuing a previous row).
		//
		// 3. We need to support IE 11, meaning CSS Grid Layout is not allowed due to IE 11 using
		// some older standard, potentially causing inconsistencies between browsers.
		//
		// Because we're only dealing with very few variants, the easiest solution is to just handle
		// them all manually. No special styles are required for a field grid with 1 column.
		{
			condition: columnCount === 2,
			style: {
				minWidth: { xs: '100%', md: 'auto' },
			},
		},
		{
			condition: columnCount === 3,
			style: {
				minWidth: { xs: '100%', lg: 'auto' },
			},
		},
		{
			condition: columnCount === 4,
			style: {
				minWidth: { xs: '100%', md: '50%', xl: 'auto' },
			},
		},
		// NOTE: More than 4 columns are not supported.
	],
}));

const nodeAboveStyle = prepareStyle(utils => ({
	marginBottom: utils.spacings.md,
}));

const nodeBelowStyle = prepareStyle(utils => ({
	marginTop: utils.spacings.md,
}));

const getCompactRows = (columnCount: number, rows: ReactNode[][]): ReactNode[][] =>
	splitEvery(columnCount, rows.flat().filter(isNotNil));

export const FieldGrid = ({
	customStyle,
	layout = 'exact',
	maxFieldWidth,
	maxWidth,
	nodeAbove,
	nodeBelow,
	rows: rowsProp,
}: FieldGridProps) => {
	const columnCount = useMemo(() => getColumnCount(rowsProp), [rowsProp]);
	const { applyStyle } = useStyles();

	const rows = useMemo(
		() => (layout === 'compact' ? getCompactRows(columnCount, rowsProp) : rowsProp),
		[layout, columnCount, rowsProp]
	);

	return (
		<>
			{nodeAbove && <div className={applyStyle(nodeAboveStyle)}>{nodeAbove}</div>}
			<div className={cx(applyStyle([rootStyle, customStyle], { maxWidth }), ROOT_CLASS_NAME)}>
				{rows.map(
					(fields, rowIndex) =>
						fields?.some(isNotNil) && (
							<div className={applyStyle(rowStyle)} key={rowIndex}>
								{[
									...fields,
									...(layout === 'grow' ? [] : repeat(null, columnCount - fields.length)),
								].map((field, fieldIndex) => {
									const fieldName: string | null =
										isValidElement(field) && typeof field.props.name === 'string'
											? field.props.name
											: null;

									return (
										<div
											className={applyStyle(fieldStyle, {
												columnCount,
												maxWidth: maxFieldWidth,
											})}
											key={fieldName ?? fieldIndex}
										>
											{field}
										</div>
									);
								})}
							</div>
						)
				)}
			</div>
			{nodeBelow && <div className={applyStyle(nodeBelowStyle)}>{nodeBelow}</div>}
		</>
	);
};
