import { equals } from 'ramda';
import { useRef } from 'react';

/**
 * Use this hook to inform developers that a value/prop they are passing to a reusable component
 * should be memoized via `useMemo`. This is to avoid unnecessary reinitializations which happen
 * when an object reference is changed, but the inner values stay the same (e.g. initial values
 * of a form).
 */
const useDeepEqualityMemoWarning = (value, ...debugArgs) => {
	if (process.env.NODE_ENV !== 'development') {
		return;
	}

	const valueRef = useRef(value);

	if (valueRef.current !== value && equals(valueRef.current, value)) {
		console.debug('Potentially missing `useMemo` usage.', ...debugArgs, {
			from: valueRef.current,
			to: value,
		});
	}

	valueRef.current = value;
};

export default useDeepEqualityMemoWarning;
