import { Button } from '@creditinfo-ui/atoms';
import { Message, MessageValues } from '@creditinfo-ui/messages';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { useCallback } from 'react';

import { m } from './messages';
import { ToastContent, ToastType } from './types';

const richContentStyle = prepareStyle(utils => ({
	maxHeight: '15rem',
	overflow: 'hidden',
	position: 'relative',

	selectors: {
		'::after': {
			background: 'linear-gradient(transparent 10rem, white 100%)',
			content: "''",
			height: '100%',
			left: 0,
			pointerEvents: 'none',
			position: 'absolute',
			top: 0,
			width: '100%',
		},
		'& h1, & h2, & h3': {
			fontSize: utils.fontSizes.base,
		},
		'& pre': {
			whiteSpace: 'pre-wrap',
		},
		'& > :first-child': {
			marginTop: 0,
		},
		'& > :last-child': {
			marginBottom: 0,
		},
	},
}));

const rootStyle = prepareStyle(utils => ({
	flexGrow: 1,
	// HACK: Line height is set so the text is inline with the toast icon. Also, the same line
	// height is used by the announcement modal.
	lineHeight: 2,
	paddingInlineStart: utils.spacings.sm,
}));

const readMoreStyle = prepareStyle(utils => ({
	marginTop: utils.spacings.sm,
	textAlign: 'end',
}));

export interface ToastBodyProps {
	content: ToastContent;
	expansionId?: string;
	onExpand?: (expansionId: string) => void;
	type: ToastType;
	values?: MessageValues;
}

export const ToastBody = ({ type, content, values, onExpand, expansionId }: ToastBodyProps) => {
	const { applyStyle } = useStyles();

	const handleReadMoreClick = useCallback(() => {
		if (expansionId && onExpand) {
			onExpand(expansionId);
		}
	}, [expansionId, onExpand]);

	return (
		<div className={applyStyle(rootStyle)}>
			{typeof content === 'string' ? (
				<div
					className={type === 'announcement' ? applyStyle(richContentStyle) : undefined}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			) : (
				<Message {...content} values={values} />
			)}
			{expansionId && (
				<div className={applyStyle(readMoreStyle)}>
					<Button onClick={handleReadMoreClick} size="small" variant="subtle">
						<Message {...m.readMore} />
					</Button>
				</div>
			)}
		</div>
	);
};
