import { createAction } from '@reduxjs/toolkit';
import { createModalInstance } from '@ci/modals';
import type { BiometricsConfiguration } from './settings';

export type BiometricsSetupModalPayload = BiometricsConfiguration;

export const biometricsSetupModalInstance =
	createModalInstance<BiometricsSetupModalPayload>('@nativeBiometrics/setup');

export const requestBiometricAuthenticationSetup = createAction<any>(
	'@nativeBiometrics/requestBiometricAuthenticationSetup'
);

export const configureBiometricAuthentication = createAction<any>(
	'@nativeBiometrics/configureBiometricAuthentication'
);

export const requestBiometricAuthenticationUpdate = createAction<Partial<BiometricsConfiguration>>(
	'@nativeBiometrics/requestBiometricAuthenticationUpdate'
);
