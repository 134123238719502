import { makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';

const ActionTypes = {
	CLEAR: 'CLEAR',
	CONFIRM: 'CONFIRM',
	SET_IS_OPEN: 'SET_IS_OPEN',
	SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
	CHANGE_CHECKBOX: 'CHANGE_CHECKBOX',
	SET_CONTROLLED_ARRAY: 'SET_CONTROLLED_ARRAY',
	SET_SHOW_ALL_ITEMS: 'SET_SHOW_ALL_ITEMS',
	SET_DEFAULT_STATIC: 'SET_DEFAULT_STATIC',
};

export const clear = makeConstantActionCreator(ActionTypes.CLEAR);
export const confirm = makeConstantActionCreator(ActionTypes.CONFIRM);
export const setIsOpen = makeSimpleActionCreator(ActionTypes.SET_IS_OPEN);
export const setSearchValue = makeSimpleActionCreator(ActionTypes.SET_SEARCH_VALUE);
export const changeCheckbox = makeSimpleActionCreator(ActionTypes.CHANGE_CHECKBOX);
export const setControlledArray = makeSimpleActionCreator(ActionTypes.SET_CONTROLLED_ARRAY);
export const setShowAllItems = makeSimpleActionCreator(ActionTypes.SET_SHOW_ALL_ITEMS);
export const setDefaultStatic = makeSimpleActionCreator(ActionTypes.SET_DEFAULT_STATIC);

export const initialState = {
	searchValue: '',
	controlledArray: [],
	isOpen: false,
	showAllItems: false,
	defaultStatic: null,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case ActionTypes.CLEAR: {
			return {
				...state,
				searchValue: '',
				controlledArray: [],
			};
		}
		case ActionTypes.CONFIRM: {
			return { ...state, isOpen: false, searchValue: '' };
		}
		case ActionTypes.SET_IS_OPEN: {
			return { ...state, isOpen: action.payload };
		}
		case ActionTypes.SET_SEARCH_VALUE: {
			return { ...state, searchValue: action.payload };
		}
		case ActionTypes.CHANGE_CHECKBOX: {
			return { ...state, controlledArray: action.payload };
		}
		case ActionTypes.SET_CONTROLLED_ARRAY: {
			return { ...state, controlledArray: action.payload };
		}
		case ActionTypes.SET_SHOW_ALL_ITEMS: {
			return { ...state, showAllItems: action.payload };
		}
		case ActionTypes.SET_DEFAULT_STATIC: {
			return { ...state, defaultStatic: action.payload };
		}
	}
};
