import { path, assocPath } from 'ramda';

import { ensureArray } from './ensureArray';

export const scopeReducer = (scope, reducer) => {
	const scopeArray = ensureArray(scope);
	const getScopedState = path(scopeArray);

	return (state, action) => {
		const scopedState = getScopedState(state);
		const nextScopedState = reducer(getScopedState(state), action);

		if (scopedState === nextScopedState) {
			return state;
		}

		return assocPath(scopeArray, nextScopedState, state);
	};
};
