import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Button from '../Button';

const IconButton = forwardRef(({ children, className, ...rest }, ref) => (
	<Button className={cx('btn--icon', className)} ref={ref} {...rest}>
		{children}
	</Button>
));

IconButton.displayName = 'forwardRef(IconButton)';

IconButton.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
};

export default IconButton;
