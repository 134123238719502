import { run } from '@ci/control-flow';
import { compose, filter, head, includes, isEmpty, map, prop, toPairs } from 'ramda';
import { isNilOrEmpty, isNilOrEmptyString } from 'ramda-extension';

const makeAsyncValidate =
	(validationTask, getError, asyncBlurFields = []) =>
	(values, dispatch, props) => {
		const getShouldFieldBeValidated = ([fieldName, fieldValue]) =>
			!isNilOrEmpty(fieldValue) && includes(fieldName, asyncBlurFields);
		const fieldNames = compose(map(head), filter(getShouldFieldBeValidated), toPairs)(values);
		const validationPromises = fieldNames.map(fieldName => ({
			fieldName,
			request: new Promise((resolve, reject) => {
				dispatch(
					run(
						() => validationTask(values, props, fieldName),
						(action, { isError }) => {
							if (isError) {
								reject(action);
							} else {
								resolve();
							}
						}
					)
				);
			}),
		}));

		const errorPromises = validationPromises.map(promise => promise.request.catch(getError));

		return Promise.all(errorPromises).then(errorList => {
			const errors = {};
			errorList.forEach((resolvedPromiseError, validationPromiseIndex) => {
				if (
					!isNilOrEmptyString(resolvedPromiseError) &&
					prop('errors', resolvedPromiseError) &&
					!isEmpty(resolvedPromiseError.errors)
				) {
					const errorsLocalized = resolvedPromiseError.errors.map(
						error => error.messageLocalized ?? error
					);
					errors[validationPromises[validationPromiseIndex].fieldName] = errorsLocalized;
				}
			});

			if (!isEmpty(errors)) {
				throw errors;
			}
		});
	};

export default makeAsyncValidate;
