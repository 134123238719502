import {
	makeBinaryActionCreator,
	makeMiddleware,
	typeEq,
	composeMiddleware,
	downloadBase64File,
	generateUuid,
	scopeReducer,
} from '@ci/utils';
import { makeActionTypes, makeReducer, makeSimpleActionCreator } from 'redux-syringe';
import { isNumber, isString, rejectNil, isNotNil, defaultToEmptyArray } from 'ramda-extension';
import { curry, path, compose, includes, times, over, lensProp, append, o } from 'ramda';
import invariant from 'invariant';
import { waterfall, delegateTask } from '@ci/control-flow';

import { request } from '../actions';
import { getIsSuccessResponseAction, replaceChunk } from '../utils';

export const ActionTypes = makeActionTypes('@api/downloading', [
	'DOWNLOAD',
	'SUCCESS',
	'ERROR',
	'STORE_CHUNK',
]);

export const download = makeBinaryActionCreator(ActionTypes.DOWNLOAD);
export const downloadingSuccessEvent = makeBinaryActionCreator(ActionTypes.SUCCESS);
export const downloadingErrorEvent = makeBinaryActionCreator(ActionTypes.ERROR);
export const storeChunk = makeSimpleActionCreator(ActionTypes.STORE_CHUNK);

export const getDownloadId = path(['meta', 'downloadId']);
export const hasDownloadId = o(isNotNil, getDownloadId);

export const initialState = {};

const getChunksByDownloadId = curry((id, state) => path(['api', 'downloadedChunks', id], state));

export const getContent = compose(rejectNil, getChunksByDownloadId);

const downloadMiddleware = makeMiddleware(
	typeEq(ActionTypes.DOWNLOAD),
	({ dispatch, getState }) =>
		action => {
			const downloadId = generateUuid();
			const { filename, chunkCount, url, ...requestPayload } = action.payload;

			invariant(
				includes(':chunk', url),
				"`download` action creator's URL must contain the `:chunk` substring parameter."
			);

			invariant(
				isNumber(chunkCount),
				'`download` action creator must receive a `chunkCount` number in the payload.'
			);

			invariant(
				isString(filename),
				'`download` action creator must receive a `filename` string in the payload.'
			);

			const requestChunk = chunkNumber => () =>
				request(
					{
						...requestPayload,
						// NOTE: Chunks are indexed from 1.
						url: replaceChunk(chunkNumber + 1, url),
					},
					{
						// NOTE: `origin: action` is first so it can be overriden by manually passed actions.
						origin: action,
						...action.meta,
						// NOTE: Passed here for tracking API success actions.
						downloadId,
					}
				);

			dispatch(
				delegateTask(
					action,
					waterfall(times(requestChunk, chunkCount), (event, { isError }) => {
						const payload = { filename, downloadId, url, event };

						if (isError) {
							dispatch(downloadingErrorEvent(payload, action.meta));
						} else {
							dispatch(downloadingSuccessEvent(payload, action.meta));
							downloadBase64File(getContent(downloadId, getState()), filename);
						}
					})
				)
			);
		}
);

const makeFetchChunkSuccessMiddleware = getDownloadedChunkResponseData =>
	makeMiddleware(getIsSuccessResponseAction(hasDownloadId), ({ dispatch }) => action => {
		dispatch(
			storeChunk({
				downloadId: getDownloadId(action),
				data: getDownloadedChunkResponseData(action.payload),
			})
		);
	});

export const makeDownloadingMiddleware = ({ getDownloadedChunkResponseData }) =>
	composeMiddleware(
		downloadMiddleware,
		makeFetchChunkSuccessMiddleware(getDownloadedChunkResponseData)
	);

export const downloadingReducer = scopeReducer(
	'downloadedChunks',
	makeReducer(
		[
			[
				ActionTypes.STORE_CHUNK,
				(state, action) =>
					over(
						lensProp(action.payload.downloadId),
						o(append(action.payload.data), defaultToEmptyArray),
						state
					),
			],
		],
		initialState
	)
);
