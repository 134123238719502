import { Schema } from 'normalizr';
import { composeReducers } from 'redux-syringe';

import { viewEntities as untypedViewEntities, normalizrReducer } from './duck.normalizr';
import { dataManagerReducer } from './duck';

export * from './duck';

export {
	ActionTypes,
	SCOPE,
	getAllEntities,
	getEntities,
	getEntity,
	getResult,
	getSimpleEntities,
	getSubresult,
	initialState,
	isResultNotNil,
	middleware,
	selectIsResultStored,
	storeEntities,
	storeEntity,
	storeResult,
	storeSubentities,
	storeSubresult,
	viewSubentities,
} from './duck.normalizr';

type KeyOrGetIds = string | Function;
type State = any;

type ViewEntities = <TEntity>(
	schema: Schema<TEntity>
) => (keyOrGetIds: KeyOrGetIds) => (state: State) => TEntity[];

/**
 * @deprecated Use `createDataManager` instead.
 */
export const viewEntities: ViewEntities = untypedViewEntities as any;

export const reducer = composeReducers(dataManagerReducer, normalizrReducer);
