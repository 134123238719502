import invariant from 'invariant';

type PreparedMessages<TNamespace extends string, TIdUnion extends string> = {
	[KId in TIdUnion]: { defaultMessage: string; description: string; id: `${TNamespace}.${KId}` };
};

interface PrepareMessagesOptions {
	areDotsAllowed?: boolean;
}

export const prepareMessages = <TNamespace extends string, TIdUnion extends string>(
	namespace: TNamespace,
	ids: Readonly<
		Array<
			| TIdUnion
			| [id: TIdUnion, defaultMessage?: string, description?: string]
			| { defaultMessage?: string; description?: string; id: TIdUnion }
		>
	>,
	options: PrepareMessagesOptions = {}
): PreparedMessages<TNamespace, TIdUnion> =>
	ids.reduce((messages, messageEntry) => {
		const key =
			typeof messageEntry === 'object'
				? Array.isArray(messageEntry)
					? messageEntry[0]
					: messageEntry.id
				: messageEntry;

		// NOTE: Placed inside the loop for simpler unit tests. When building a bundle, this should become
		// `"production" === "production"` and should be optimized away by the interpreter/compiler.
		const isNotProduction = process.env.NODE_ENV !== 'production';

		if (isNotProduction) {
			if (!options.areDotsAllowed) {
				invariant(
					!/\./.test(key),
					`Message key '${key}' with namespace '${namespace}' cannot contain a dot. Pass \`{ areDotsAllowed: true }\` as the third argument to disable this behavior.`
				);
			}

			invariant(!messages[key], `Message key '${key}' with namespace '${namespace}' is duplicate.`);
		}

		const id = `${namespace}.${key}`;

		const defaultMessage =
			(typeof messageEntry === 'object'
				? Array.isArray(messageEntry)
					? messageEntry[1]
					: messageEntry.defaultMessage
				: key) ?? key;

		const description =
			(typeof messageEntry === 'object'
				? Array.isArray(messageEntry)
					? messageEntry[2]
					: messageEntry.description
				: key) ?? key;

		return {
			...messages,
			[key]: { defaultMessage, description, id },
		};
	}, {} as PreparedMessages<TNamespace, TIdUnion>);
