import { converge, mergeRight, identity } from 'ramda';

/**
 *
 * Merges input with result of mapping function. If the key of the `input` with the same name will exist in the the result of calling `mapping` function, it will be used.
 *
 * @param {function} mapping
 * @param {Object} input
 * @return {Object}
 * @sig (a -> b) -> a -> b
 * @example
 *
 *	addPropsLeft((x) => ({ mama: x.female, tata: x.male, male: 'M' }))({ male: 'T', female: 'B' })
 *  // { mama: 'B', tata: 'T', female: 'B', male: 'M' }
 *
 */
export const addPropsRight = mapper => converge(mergeRight, [identity, mapper]);
