import { map, ifElse, toPairs, o, mapObjIndexed } from 'ramda';
import { isArray } from 'ramda-extension';
import { NOT_SPECIFIED_VALUE } from './filters';
import {
	LocalLookupKey,
	LookupData,
	LookupEntry,
	LookupEntryKey,
	LookupEntryValue,
	LookupStaticsDefinition,
	PreparedLookupData,
	PreparedLookupEntries,
} from './types';

export const prepareLookupEntries = (entries: LookupEntry[]): PreparedLookupEntries => ({
	entries,
	normalizations: {
		byKey: entries.reduce((values, { key, value }) => ({ ...values, [key]: value }), {}),
		byValue: entries.reduce((values, { key, value }) => ({ ...values, [value]: key }), {}),
	},
});

export const prepareLookupData = (data: LookupData): PreparedLookupData =>
	mapObjIndexed(prepareLookupEntries, data);

const getLookupEntriesFromArray = (lookupEntryValues: LookupEntryValue[]): LookupEntry[] => {
	// NOTE: When the values don't start with `NotSpecified`, we don't want any other value to take
	// up the `0` key, otherwise it could be treated as such. If we still need some lookup entry to
	// have `key: 0`, an object definition should be used (rather than an array with implicit keys).
	const shouldSkipZeroKey = lookupEntryValues[0] !== NOT_SPECIFIED_VALUE;

	return lookupEntryValues.map((value, key) => ({
		key: shouldSkipZeroKey ? key + 1 : key,
		value,
	}));
};

// NOTE: Object statics can be used to specify keys manually, rather than relying
// on indexing from zero.
const getLookupEntriesFromObject = o(
	map<[LookupEntryKey, LookupEntryValue], LookupEntry>(([key, value]) => ({ value, key })),
	(valuesByKey: Record<LookupEntryKey, LookupEntryValue>) => toPairs(valuesByKey)
);

export interface MapStaticsToLookupEntries {
	(statics: LookupStaticsDefinition): Record<LocalLookupKey, LookupEntry[]>;
}

export const mapStaticsToLookupEntries: MapStaticsToLookupEntries = map(
	ifElse(isArray, getLookupEntriesFromArray, getLookupEntriesFromObject)
) as any;
