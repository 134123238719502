import { composeMiddleware, makeMiddleware, typeEq, serializeFunction } from '@ci/utils';
import { map, cond, evolve, identity, T, when } from 'ramda';
import { isObject, isFunction } from 'ramda-extension';

import { isControlFlowMethod, getTaskId } from '../utils';
import waterfallMiddleware from './waterfall';
import enqueueMiddleware from './enqueue';
import parallelMiddleware from './parallel';
import pollMiddleware from './poll';
import { AsyncHelperActionTypes, taskSuccessEvent, taskErrorEvent } from '../actions';

const controlFlowSerializerMiddleware = () => next => action =>
	next(
		when(
			isControlFlowMethod,
			evolve({
				payload: map(
					cond([
						[isFunction, serializeFunction],
						[isObject, map(when(isFunction, serializeFunction))],
						[T, identity],
					])
				),
			})
		)(action)
	);

const skipMiddleware = makeMiddleware(
	typeEq(AsyncHelperActionTypes.SKIP),
	({ dispatch }) =>
		action =>
			dispatch(taskSuccessEvent(getTaskId(action), action.payload))
);

const failMiddleware = makeMiddleware(
	typeEq(AsyncHelperActionTypes.FAIL),
	({ dispatch }) =>
		action =>
			dispatch(taskErrorEvent(getTaskId(action), action.payload))
);

export default composeMiddleware(
	waterfallMiddleware,
	enqueueMiddleware,
	parallelMiddleware,
	pollMiddleware,
	skipMiddleware,
	failMiddleware,
	// NOTE: This middleware MUST be the last.
	controlFlowSerializerMiddleware
);
