import { of, T, __, nth, cond, useWith, identity } from 'ramda';
import {
	isNilOrEmptyString,
	alwaysEmptyArray,
	mapIndexed,
	isArray,
	isNilOrEmpty,
	isPlainObject,
} from 'ramda-extension';

const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];
const getBreakpointByIndex = nth(__, BREAKPOINTS);

const prepareBreakpointArrayFromObject = obj =>
	BREAKPOINTS.map(breakpoint => (isNilOrEmptyString(obj[breakpoint]) ? null : obj[breakpoint]));

const prepareBreakpointArray = cond([
	[isNilOrEmpty, alwaysEmptyArray],
	[isPlainObject, prepareBreakpointArrayFromObject],
	[isArray, identity],
	[T, of],
]);
const prepareClassNameFactory = useWith(__, [identity, getBreakpointByIndex]);

/**
 * Creates array of classNames for grid framework based on "xs, sm, md, lg, xl" breakpoints.
 *
 * @sig (String -> String -> String) -> [String] -> [String]
 *
 * @example
 *
 *    classNamesByBreakpoint(
 *      (col, breakpoint) => `col-${breakpoint}-${col}`,
 *      [1, 2]
 *    )
 *    // ["col-xs-1", "col-sm-2"]
 *
 *    or
 *
 *    classNamesByBreakpoint(
 *      (col, breakpoint) => `col-${breakpoint}-${col}`,
 *      {xs: 1, sm: 2}
 *    )
 *    // ["col-xs-1", "col-sm-2"]
 *
 */
export const classNamesByBreakpoint = useWith(mapIndexed, [
	prepareClassNameFactory,
	prepareBreakpointArray,
]);
