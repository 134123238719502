import { generateUuid } from '@ci/utils';
import { assocPath, path } from 'ramda';
import { Action, Middleware } from 'redux';
import { RequestAction, SuccessEventAction } from '../actions';
import {
	getIsAnyRequestAction,
	getIsAnyResponseAction,
	getIsAnySuccessResponseAction,
} from '../utils';

type PromiseLikeId = string;
type OnFulfilled = (action: Action) => void;
type OnRejected = (action: Action) => void;

interface PromiseLikeAction extends Action {
	__settlePromise: (onFulfilled: OnFulfilled, onRejected: OnRejected) => void;
	then: (onFulfilled: OnFulfilled, onRejected: OnRejected) => void;
}

interface PromiseLikeEntry {
	onFulfilled: OnFulfilled;
	onRejected: OnRejected;
	promiseLikeAction: PromiseLikeAction;
}

const getPromiseLikeId = path<PromiseLikeId>(['meta', 'promiseLikeId']);
const addPromiseLikeId = assocPath<PromiseLikeId, Action>(['meta', 'promiseLikeId']);

export const IGNORE_RESPONSE_ACTION = 'IGNORE_RESPONSE_ACTION';

const handleResponseAction = (entry: PromiseLikeEntry, responseAction: Action) => {
	const promiseLikeAction = entry.promiseLikeAction;

	// NOTE: Here we associate the result (success or error action) with the
	// request action. It's not great, but there aren't many solutions without
	// memory leaks available to us.
	promiseLikeAction.__settlePromise = (onFulfilled, onRejected) => {
		if (getIsAnySuccessResponseAction(responseAction)) {
			onFulfilled(responseAction);
		} else {
			onRejected(responseAction);
		}
	};

	promiseLikeAction.__settlePromise(entry.onFulfilled, entry.onRejected);
};

export const promiseLikeRequestMiddleware: Middleware = () => {
	const promiseLikeStorage: Record<PromiseLikeId, Set<PromiseLikeEntry>> = {};

	const addToStorage = (promiseLikeId: PromiseLikeId, entry: PromiseLikeEntry) => {
		if (!promiseLikeStorage[promiseLikeId]) {
			promiseLikeStorage[promiseLikeId] = new Set();
		}

		promiseLikeStorage[promiseLikeId].add(entry);
	};

	const createPromiseLikeAction = action => {
		const promiseLikeId = generateUuid();
		const promiseLikeAction = addPromiseLikeId(promiseLikeId, action) as PromiseLikeAction;

		promiseLikeAction.then = (onFulfilled, onRejected) => {
			// NOTE: `__settlePromise` is necessary so we can await the promise-like even after
			// the response has been processed. We either register our onFulfilled/onRejected functions
			// to be resolved later, or we call `__settlePromise` which has the result (success
			// or error action) in a closure.
			if (promiseLikeAction.__settlePromise) {
				promiseLikeAction.__settlePromise(onFulfilled, onRejected);
			} else {
				addToStorage(promiseLikeId, { onRejected, onFulfilled, promiseLikeAction });
			}
		};

		return promiseLikeAction;
	};

	return next => action => {
		if (getIsAnyRequestAction(action)) {
			// NOTE: If a request action has been "redispatched" by the authentication middleware,
			// we do not generate a new promise-like ID, but instead reuse the existing one.
			// Otherwise, the outer request promise-like (which we await in our application code) would
			// never get resolved, as the associated promise-like ID would get thrown away.
			const existingPromiseLikeId = getPromiseLikeId(action);
			const promiseLikeAction = existingPromiseLikeId ? action : createPromiseLikeAction(action);

			next(promiseLikeAction);

			// NOTE: Return value of the outermost middleware is what we receive as the return value
			// of calling `dispatch(action)`.
			return promiseLikeAction;
		}

		const innerMiddlewareReturnValue = next(action);

		if (getIsAnyResponseAction(action) && innerMiddlewareReturnValue !== IGNORE_RESPONSE_ACTION) {
			const promiseLikeId = getPromiseLikeId(action);

			if (promiseLikeId) {
				const entries = promiseLikeStorage[promiseLikeId] ?? [];
				delete promiseLikeStorage[promiseLikeId];

				entries?.forEach(entry => handleResponseAction(entry, action));
			}
		}

		return innerMiddlewareReturnValue;
	};
};

declare module 'redux' {
	export interface Dispatch {
		<TResponseBody, TRequestBody, TOrigin extends Action>(
			requestAction: RequestAction<TResponseBody, TRequestBody, TOrigin>
		): PromiseLike<SuccessEventAction<TResponseBody, TOrigin>>;
	}
}
