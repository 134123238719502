import React from 'react';
import { useSelector } from 'react-redux';
import { Message, getSupportedLocales } from '@myci/intl';
import { SelectField as UntypedSelectField } from '../forms/components/fields';

import m from '../../messages';

const SelectField = UntypedSelectField as any;

interface CultureCodeSelectFieldProps {
	disabled?: boolean;
	required?: boolean;
}

export const CultureCodeSelectField = ({
	required = false,
	disabled = false,
}: CultureCodeSelectFieldProps) => {
	const supportedLocales = useSelector(getSupportedLocales);

	return (
		<SelectField
			label={<Message {...m.fieldCultureCode} />}
			name="culture"
			items={supportedLocales}
			required={required}
			disabled={disabled}
		/>
	);
};
