import PropTypes from 'prop-types';
import { assoc, endsWith, mergeRight, prop, startsWith, values } from 'ramda';
import { cx, isArray, isNotEmpty, isPlainObject } from 'ramda-extension';
import React, { Fragment, isValidElement } from 'react';

import Box from '../Box';
import Grid from '../Grid';
import LabeledIcon from '../LabeledIcon';
import Icon from '../Icon';

import './DataList.scss';

const normalizeRowDefinition = (rowDefinition, props) =>
	isArray(rowDefinition)
		? {
				items: rowDefinition,
				props,
		  }
		: {
				items: prop('items', rowDefinition),
				props: mergeRight(props, prop('props', rowDefinition)),
		  };

const normalizeItemDefinition = (itemDefinition, props, descFirst) =>
	isArray(itemDefinition)
		? {
				term: itemDefinition[descFirst ? 1 : 0],
				desc: itemDefinition[descFirst ? 0 : 1],
				iconProps: itemDefinition[2],
				props,
		  }
		: {
				term: prop('term', itemDefinition),
				desc: prop('desc', itemDefinition),
				iconProps: prop('iconProps', itemDefinition),
				props: mergeRight(props, prop('props', itemDefinition)),
		  };

const normalizeValueDefinition = (valueDefinition, props) =>
	isPlainObject(valueDefinition) && !isValidElement(valueDefinition)
		? {
				value: prop('value', valueDefinition),
				props: mergeRight(props, prop('props', valueDefinition)),
		  }
		: {
				value: valueDefinition,
				props,
		  };

export const DataListIconPositions = {
	BEFORE_TERM: 'BEFORE_TERM',
	AFTER_TERM: 'AFTER_TERM',
	BEFORE_DESC: 'BEFORE_DESC',
	AFTER_DESC: 'AFTER_DESC',
};

const shouldRenderIcon = {
	after: startsWith('AFTER'),
	before: startsWith('BEFORE'),
	nearTerm: endsWith('TERM'),
	nearDesc: endsWith('DESC'),
};

/**
 * Component for drawing data lists (key-value pairs organized in rows and columns).
 *
 * Items definition structure:
 * Value  = node | {value: node, props?: object};
 * Term  = Value
 * Desc  = Value
 * Item  = [First, Second, ?IconProps] | {term: Term, desc: Desc, iconProps?: object, props?: object)
 * Row   = Item[] | {items: Item[], props?: object}
 * Rows  = Row[]
 */
const DataList = ({
	alignToSides = false,
	collapseRows = false,
	rows,
	rowProps,
	itemProps,
	termProps,
	descProps,
	className,
	iconPosition = DataListIconPositions.AFTER_TERM,
	descFirst = false,
	locked,
	...rest
}) => {
	const rowComponents = rows.map((rowDef, rowIndex) => {
		const { items: itemsDef, props: currentRowProps } = normalizeRowDefinition(rowDef, rowProps);

		const rowItems = itemsDef.map((itemDef, itemIndex) => {
			const {
				term: termDef,
				desc: descDef,
				props: currentItemProps,
				iconProps = {},
			} = normalizeItemDefinition(itemDef, itemProps, descFirst);

			const { value: term, props: baseTermProps } = normalizeValueDefinition(termDef, termProps);
			const { value: desc, props: baseDescProps } = normalizeValueDefinition(descDef, descProps);

			const termClassName = cx('data-list__term', prop('className', baseTermProps), {
				'text-right': alignToSides && descFirst,
			});

			const descClassName = cx('data-list__desc', prop('className', baseDescProps), {
				'text-right': alignToSides && !descFirst,
			});

			const currentTermProps = assoc('className', termClassName, currentTermProps);
			const currentDescProps = assoc('className', descClassName, currentDescProps);

			const hasIcon = isNotEmpty(iconProps);
			const { type, color, ...otherIconProps } = iconProps;

			const wrapWithIcon = node => (
				<LabeledIcon
					iconType={type}
					iconColor={color}
					iconProps={otherIconProps}
					{...{ iconBeforeText: shouldRenderIcon.before(iconPosition) }}
				>
					{node}
				</LabeledIcon>
			);

			const termNode = (
				<dt {...currentTermProps}>
					{hasIcon && shouldRenderIcon.nearTerm(iconPosition) ? wrapWithIcon(term) : term}
				</dt>
			);

			const descNode = (
				<dd {...currentDescProps}>
					{hasIcon && shouldRenderIcon.nearDesc(iconPosition) ? wrapWithIcon(desc) : desc}
				</dd>
			);

			return (
				<Grid
					key={`${rowIndex}-${itemIndex}`}
					{...(alignToSides && { justifyContent: 'between' })}
					{...currentItemProps}
				>
					{descFirst && descNode}
					{locked ? <Icon type="collaterals" size={16} /> : termNode}
					{!descFirst && descNode}
				</Grid>
			);
		});

		return collapseRows ? (
			<Fragment key={rowIndex}>{rowItems}</Fragment>
		) : (
			<Grid key={rowIndex} row {...currentRowProps}>
				{rowItems}
			</Grid>
		);
	});

	return (
		<Box as="dl" className={cx('data-list', className)} {...rest}>
			{rowComponents}
		</Box>
	);
};

const ValuePropType = PropTypes.oneOfType([
	PropTypes.node.isRequired,
	PropTypes.shape({
		value: PropTypes.node.isRequired,
		props: PropTypes.object,
	}).isRequired,
]).isRequired;

export const ItemPropType = PropTypes.oneOfType([
	PropTypes.arrayOf(ValuePropType).isRequired,
	PropTypes.shape({
		term: ValuePropType,
		desc: ValuePropType,
		props: PropTypes.object,
	}).isRequired,
]).isRequired;

const RowPropType = PropTypes.oneOfType([
	PropTypes.arrayOf(ItemPropType).isRequired,
	PropTypes.shape({
		items: PropTypes.arrayOf(ItemPropType).isRequired,
		props: PropTypes.object,
	}).isRequired,
]).isRequired;

DataList.propTypes = {
	/** Whether the terms and values should be aligned to the container sides */
	alignToSides: PropTypes.bool,

	/** Class to add to the default 'data-list' class name */
	className: PropTypes.string,

	/** If true, rows will not be generated and items will be direct children of the DataList component */
	collapseRows: PropTypes.bool,

	/** Show desc first and term after */
	descFirst: PropTypes.bool,

	/** Default properties for item desc component */
	descProps: PropTypes.object,

	/** The position of the icon **/
	iconPosition: PropTypes.oneOf(values(DataListIconPositions)),

	/** Default properties for item component */
	itemProps: PropTypes.object,

	/** Show lock icon instead of values */
	locked: PropTypes.bool,

	/** Default properties for row component */
	rowProps: PropTypes.object,

	/** Definition of data list items */
	rows: PropTypes.arrayOf(RowPropType).isRequired,

	/** Default properties for item term component */
	termProps: PropTypes.object,
};

DataList.defaultProps = {
	itemProps: {},
	rowProps: {},
	termProps: {},
	descProps: {},
};

export default DataList;
