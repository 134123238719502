import { assocPath, path } from 'ramda';
import { generateUuid } from '@ci/utils';
import { getIsAnyRequestAction } from '../utils';

export const localRequestIdMiddleware = () => next => action => {
	if (getIsAnyRequestAction(action)) {
		// NOTE: We use the `localRequestId` to avoid conflicts with `requestId` from BE.
		return next(assocPath(['meta', 'localRequestId'], generateUuid(), action));
	}

	return next(action);
};

export const getLocalRequestId = path(['meta', 'localRequestId']);
