import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import Grid from '../Grid';

const HeadingButtons = ({ children }) => {
	const count = Children.count(children);

	return (
		<Grid alignItems={{ md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
			{Children.map(children, (child, i) => (
				<Box
					mb={{ xs: 2, md: 0 }}
					className={cx(child.props.className, {
						'pl-md-2': i !== 0,
						'pr-md-2': i !== count - 1,
					})}
				>
					{cloneElement(child, {
						className: 'w-100',
					})}
				</Box>
			))}
		</Grid>
	);
};

HeadingButtons.propTypes = {
	children: PropTypes.node,
};

export default HeadingButtons;
