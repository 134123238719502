import { Breakpoint } from '@creditinfo-ui/fela';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { T, always, cond, gte } from 'ramda';

export const GAUGE_WIDTH = 120;
export const GAUGE_HEIGHT = 66;
export const MOBILE_GAUGE_WIDTH = 74;
export const MOBILE_GAUGE_HEIGHT = 41;

const GAUGE_LINE_LENGTH = 115;
const MAX_VALUE = 15;

export type GaugeValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

// NOTE: 1-3 danger, 4-6 semidanger, 7-9 warning, 10-12 ok, 13-15 excellent
type GaugeColor = 'danger' | 'semidanger' | 'warning' | 'ok' | 'excellent';

const getGaugeColor = cond([
	[gte(3), always('danger' as const)],
	[gte(6), always('semidanger' as const)],
	[gte(9), always('warning' as const)],
	[gte(12), always('ok' as const)],
	[gte(15), always('excellent' as const)],
	[T, always('danger' as const)],
]);

const colors: Record<GaugeColor, [string, string]> = {
	danger: ['#af0000', '#ff9900'],
	semidanger: ['#ff9900', '#ffc700'],
	warning: ['#ffc700', '#7ece62'],
	ok: ['#7ece62', '#59d004'],
	excellent: ['#59d004', '#00b12d'],
};

export interface GaugeStyleProps {
	mobileLayoutBreakpoint: Breakpoint;
}

const gaugeStyle = prepareStyle<GaugeStyleProps>((utils, { mobileLayoutBreakpoint }) => ({
	height: `${MOBILE_GAUGE_HEIGHT}px`,
	width: `${MOBILE_GAUGE_WIDTH}px`,

	selectors: {
		[utils.breakpoints[mobileLayoutBreakpoint]]: {
			height: `${GAUGE_HEIGHT}px`,
			width: `${GAUGE_WIDTH}px`,
		},
	},

	extend: {
		condition: utils.isRtl,
		style: {
			transform: 'scaleX(-100%)',
		},
	},
}));

interface GaugeGradientStyleProps {
	color: GaugeColor;
	type: 'start' | 'end';
}

const gaugeGradientStyle = prepareStyle<GaugeGradientStyleProps>((utils, { type, color }) => {
	const [start, end] = colors[color];

	return {
		stopColor: type === 'start' ? start : end,
	};
});

interface ValueGaugeBarStyleProps {
	value: GaugeValue;
}

const valueGaugeBarStyle = prepareStyle<ValueGaugeBarStyleProps>((utils, { value }) => ({
	strokeDasharray: GAUGE_LINE_LENGTH,
	strokeDashoffset: -GAUGE_LINE_LENGTH + value * (GAUGE_LINE_LENGTH / MAX_VALUE),
	transition: `stroke-dashoffset ${utils.transitions.speeds.default} ${utils.transitions.easing}`,
}));

export interface GaugeProps {
	customStyle?: Style<GaugeStyleProps>;
	mobileLayoutBreakpoint?: Breakpoint;
	value: GaugeValue;
}

export const Gauge = ({ customStyle, mobileLayoutBreakpoint = 'sm', value }: GaugeProps) => {
	const { applyStyle, utils } = useStyles();
	const color = getGaugeColor(value);

	return (
		<svg
			className={applyStyle([gaugeStyle, customStyle], { mobileLayoutBreakpoint })}
			viewBox="0 0 74 41"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<linearGradient x1="0%" y1="0%" x2="75%" y2="0%" id="gauge-gradient">
					<stop className={applyStyle(gaugeGradientStyle, { color, type: 'start' })} offset="0%" />
					<stop className={applyStyle(gaugeGradientStyle, { color, type: 'end' })} offset="100%" />
				</linearGradient>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-222.000000, -419.000000)">
					<g transform="translate(224.000000, 421.000000)">
						<g strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.4992">
							<path
								d="M69.9197241,36.685034 C69.9467412,36.1210591 69.9604043,35.5535834
								69.9604043,34.9829162 C69.9604043,15.6623851 54.2992344,0 34.9802022,0
								C15.66117,0 0,15.6623851 0,34.9829162 C0,35.5499146 0.0134880648,36.1137624
								0.0401608129,36.6741562"
								stroke={utils.colors.gray200}
							/>
							<path
								d="M69.9197241,36.685034 C69.9467412,36.1210591 69.9604043,35.5535834
								69.9604043,34.9829162 C69.9604043,15.6623851 54.2992344,0 34.9802022,0
								C15.66117,0 0,15.6623851 0,34.9829162 C0,35.5499146 0.0134880648,36.1137624
								0.0401608129,36.6741562"
								className={applyStyle(valueGaugeBarStyle, { value })}
								stroke="url(#gauge-gradient)"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
