import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	downloadAttachment,
	makeSelectAttachmentScanResult,
	scanAttachment,
	selectIsFetchingScanAttachment,
} from './duck';

const getIsDataUrl = (value: string) => value.startsWith('data:');

export const useAntimalwareScan = (value: string) => {
	const dispatch = useDispatch();
	const isDataUrl = getIsDataUrl(value);
	const isFetchingScanAttachment = useSelector(selectIsFetchingScanAttachment);
	const attachmentScanResult = useSelector(makeSelectAttachmentScanResult(value));
	const { antimalwareScanDetail } = attachmentScanResult ?? {};
	const handleDownloadAttachment = useCallback(() => {
		if (antimalwareScanDetail) {
			dispatch(downloadAttachment(attachmentScanResult));
		}
	}, [attachmentScanResult]);

	useEffect(() => {
		if (value && !isDataUrl) {
			dispatch(scanAttachment(value));
		}
	}, [value]);

	return [
		isDataUrl,
		attachmentScanResult,
		isFetchingScanAttachment,
		handleDownloadAttachment,
	] as const;
};
