import { ReactElement, ReactNode, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { intersperse, join, map, o, when } from 'ramda';
import { isString } from 'ramda-extension';

import { TranslationModeAwareMessage } from '../TranslationModeAwareMessage';
import { TranslationFetchingContext } from '../../contexts';
import { MessageProps } from './types';

const hideReactElement = (element: ReactElement) => (
	<span style={{ visibility: 'hidden' }}>{element}</span>
);

export const defaultMessageValues = {
	br: <br />,

	b: (value: ReactNode) => <b>{value}</b>,
	i: (value: ReactNode) => <i>{value}</i>,
	p: (value: ReactNode) => <p>{value}</p>,
	code: (value: ReactNode) => <code>{value}</code>,
	strong: (value: ReactNode) => <strong>{value}</strong>,
};

const obfuscateStrings = map(when(isString, o(join(''), intersperse('\u200b'))));

export const Message = ({
	children,
	icons,
	id,
	shouldObfuscate,
	values: valuesProp,
	...otherProps
}: MessageProps) => {
	const { registerMessage, getIsTranslationFetched } = useContext(TranslationFetchingContext);

	useEffect(() => {
		if (id) {
			registerMessage(id);
		}
	}, [id, registerMessage]);

	const values = useMemo(
		() => ({
			...defaultMessageValues,
			...valuesProp,
			...icons,
		}),
		[valuesProp, icons]
	);

	const messageProps = { id, values, ...otherProps };

	const formatChunks = useMemo(
		() => (shouldObfuscate ? o(children ?? (chunks => <>{chunks}</>), obfuscateStrings) : children),
		[shouldObfuscate, children]
	);

	if (!id) {
		console.error('Rendering `Message` without an `id` prop.', messageProps);

		return null;
	}

	const formattedMessage = <FormattedMessage {...messageProps}>{formatChunks}</FormattedMessage>;

	return (
		<TranslationModeAwareMessage id={id}>
			{getIsTranslationFetched(id) ? formattedMessage : hideReactElement(formattedMessage)}
		</TranslationModeAwareMessage>
	);
};
