import { isNotNilNorEmpty } from '@ci/utils';
import { assocPath, compose, dissocPath, filter } from 'ramda';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { OriginType, ThunkApiMeta, ThunkFactory, ThunkId } from './types';

interface AddPendingThunkPayload {
	meta: ThunkApiMeta;
	originType: OriginType;
	payload: any;
	thunkId: ThunkId;
}

interface RemovePendingThunkPayload {
	originType: OriginType;
	thunkId: ThunkId;
}

export const addPendingThunk = createAction<AddPendingThunkPayload>(
	'@api/thunks/ADD_PENDING_THUNK'
);

export const removePendingThunk = createAction<RemovePendingThunkPayload>(
	'@api/thunks/REMOVE_PENDING_THUNK'
);

export interface PendingThunk<TPayload> {
	meta: ThunkApiMeta;
	payload: TPayload;
}

type PendingThunks = Record<OriginType, Record<ThunkId, PendingThunk<any>>>;

export interface State {
	pendingThunks: PendingThunks;
}

const initialState: State = {
	pendingThunks: {},
};

export interface RootState {
	api: State;
}

const selectPendingThunksByOriginType = (originType: OriginType) => (state: RootState) =>
	state.api?.pendingThunks?.[originType] ?? {};

export const selectIsThunkPending =
	({ originType }: ThunkFactory<any, any>) =>
	(state: RootState) =>
		compose(isNotNilNorEmpty, selectPendingThunksByOriginType(originType))(state);

export const selectIsThunkPendingBy =
	<TPayload>(
		{ originType }: ThunkFactory<TPayload, any>,
		predicate: (pendingThunk: PendingThunk<TPayload>) => boolean
	) =>
	(state: RootState) =>
		compose(
			isNotNilNorEmpty,
			filter(predicate),
			selectPendingThunksByOriginType(originType)
		)(state);

export const pendingThunksReducer = createReducer(initialState, builder =>
	builder
		.addCase(addPendingThunk, (state, { payload: { originType, thunkId, payload, meta } }) =>
			assocPath(['pendingThunks', originType, thunkId], { payload, meta }, state)
		)
		.addCase(removePendingThunk, (state, { payload: { originType, thunkId } }) =>
			dissocPath(['pendingThunks', originType, thunkId], state)
		)
);
