import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { T, always } from 'ramda';
import { alwaysNull, isNotEmpty, isNotNil } from 'ramda-extension';
import { useDispatch } from 'react-redux';
import { attachNamespace, useNamespace } from 'redux-syringe';
import { focusFirstInvalidField, reduxForm } from '@ci/redux-form';

import {
	useJourneyControls,
	useJourneyCurrentStep,
	useJourneyErrors,
	useJourneyFirstInvalidStep,
	useJourneyFormProps,
	useShouldFocusFirstInvalidField,
} from '../hooks';
import { FEATURE } from '../constants';
import { setShouldFocusFirstInvalidField } from '../actions';

const noValidation = always(undefined);
const NullComponent = alwaysNull;

const DummyField = () => (
	<Field name="dummyFieldLevelValidate" validate={noValidation} component={NullComponent} />
);

const JourneyForm = ({
	asyncBlurFields,
	asyncValidate,
	renderForm: FormComponent,
	onSubmit: propsOnSubmit,
	onSubmitFail,
	visibleOnStep,
	stepProps,
	namespace,
	ignoreRegistering,
	forceValidation,
	passAllSubmitArgs: passAllSubmitArgsProp,
}) => {
	const { goToStep } = useJourneyControls(namespace);
	const currentStep = useJourneyCurrentStep(namespace);
	const formProps = useJourneyFormProps(namespace);
	const firstInvalidStep = useJourneyFirstInvalidStep(namespace);
	const shouldFocusFirstInvalidField = useShouldFocusFirstInvalidField(namespace);
	const errors = useJourneyErrors(namespace);

	const dispatch = useDispatch();
	const journeyNamespace = useNamespace(FEATURE);

	const formRef = useRef(null);

	const {
		registerForm,
		unregisterForm,
		onSubmit: journeyOnSubmit,
		passAllSubmitArgs: passAllSubmitArgsContext,
		...otherReduxFormProps
	} = formProps;

	useEffect(() => {
		if (ignoreRegistering) {
			return;
		}

		registerForm(visibleOnStep);

		return () => unregisterForm(visibleOnStep);
	}, []);

	useEffect(() => {
		if (
			currentStep === visibleOnStep &&
			formRef.current &&
			isNotEmpty(errors) &&
			shouldFocusFirstInvalidField
		) {
			const field = focusFirstInvalidField(errors, formRef.current);

			if (field) {
				dispatch(attachNamespace(journeyNamespace, setShouldFocusFirstInvalidField(false)));
			}
		}
	}, [currentStep, shouldFocusFirstInvalidField]);

	const firstInvalidStepRef = useRef();
	firstInvalidStepRef.current = firstInvalidStep;

	const submitHandler = useRef();

	// NOTE: Perf. optimization: actual submit handlers is only needed, when the reduxForm will fire the submit event.
	// so no need to recreate `ReduxForm` if the submit handler changed.
	submitHandler.current = (...args) => {
		const firstInvalidStep = firstInvalidStepRef.current;

		if (isNotNil(firstInvalidStep) && firstInvalidStep <= currentStep) {
			dispatch(attachNamespace(journeyNamespace, setShouldFocusFirstInvalidField(true)));
			goToStep(firstInvalidStep);
		} else {
			const resolvedCallback = propsOnSubmit || journeyOnSubmit;

			return resolvedCallback(...args);
		}
	};

	const passAllSubmitArgs = passAllSubmitArgsProp ?? passAllSubmitArgsContext;

	// This fake field fixes non-refreshing validation in redux-form: https://github.com/redux-form/redux-form/issues/4582
	const FormWithDummyField = useMemo(
		// eslint-disable-next-line react/display-name
		() => props =>
			(
				<>
					<FormComponent {...props} />
					{forceValidation && <DummyField />}
				</>
			),
		[FormComponent, forceValidation]
	);

	const ReduxForm = useMemo(
		() =>
			reduxForm({
				...otherReduxFormProps,
				...(forceValidation ? { shouldError: T } : {}),
				onSubmit: submitHandler.current,
				onSubmitFail,
				passAllSubmitArgs,
				asyncBlurFields,
				asyncValidate,
			})(FormWithDummyField),
		[FormWithDummyField, forceValidation, formProps, passAllSubmitArgs]
	);

	return (
		<div ref={formRef}>
			{currentStep === visibleOnStep && <ReduxForm visibleOnStep={visibleOnStep} {...stepProps} />}
		</div>
	);
};

JourneyForm.propTypes = {
	/** Which fields should fire the asynchronous validation when they are blurred */
	asyncBlurFields: PropTypes.array,
	/** To provide asynchronous validation. Takes an object of form values, and the Redux dispatch function, and returns a promise that either rejects with an object of errors or resolves. */
	asyncValidate: PropTypes.func,
	forceValidation: PropTypes.bool,
	ignoreRegistering: PropTypes.bool,
	/** For connecting outside the render tree of `<Journey>` */
	namespace: PropTypes.string,
	onSubmit: PropTypes.func,
	onSubmitFail: PropTypes.func,
	passAllSubmitArgs: PropTypes.bool,
	/** Render prop for inner form. Recommended to use <Form> from `@ci/forms` within. */
	renderForm: PropTypes.elementType,
	/** Props are passed to the `renderForm` when rendering. Recommended to use `memo` for the property. */
	stepProps: PropTypes.object,
	/** On which step is JourneyForm visible. */
	visibleOnStep: PropTypes.number,
};

export default JourneyForm;
