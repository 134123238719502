import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { isInBrowser } from '@myci/utils';

const SafeSuspense = ({ children, fallback, forceSuspense, ...rest }) => {
	if (forceSuspense) {
		return fallback;
	}

	return isInBrowser() ? (
		<Suspense fallback={fallback} {...rest}>
			{children}
		</Suspense>
	) : (
		fallback
	);
};

SafeSuspense.propTypes = {
	/** Children of bodom. @see https://www.cobhc.com/ */
	children: PropTypes.node,
	/** Fallback if in the subtree is loading suspensed */
	fallback: PropTypes.node,
	/** if `true` fallback will be rendered instead of children */
	forceSuspense: PropTypes.bool,
};

export default SafeSuspense;
