import { makeBinaryActionCreator } from '@myci/utils';
import { makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';

import { ActionTypes } from './constants';

export const initialize = makeSimpleActionCreator(ActionTypes.INITIALIZE);
export const destroy = makeConstantActionCreator(ActionTypes.DESTROY);
export const submit = makeConstantActionCreator(ActionTypes.SUBMIT);
export const goToStep = makeSimpleActionCreator(ActionTypes.GO_TO_STEP);
export const goToNextStep = makeConstantActionCreator(ActionTypes.GO_TO_NEXT_STEP);
export const goToPreviousStep = makeConstantActionCreator(ActionTypes.GO_TO_PREVIOUS_STEP);
export const setStep = makeBinaryActionCreator(ActionTypes.SET_STEP);
export const setNumberOfSteps = makeSimpleActionCreator(ActionTypes.SET_NUMBER_OF_STEPS);
export const registerField = makeBinaryActionCreator(ActionTypes.REGISTER_FIELD);
export const registerForm = makeSimpleActionCreator(ActionTypes.REGISTER_FORM);
export const unregisterForm = makeSimpleActionCreator(ActionTypes.UNREGISTER_FORM);
export const reset = makeSimpleActionCreator(ActionTypes.RESET);

export const setShouldFocusFirstInvalidField = makeSimpleActionCreator(
	ActionTypes.SET_SHOULD_FOCUS_FIRST_INVALID_FIELD
);
