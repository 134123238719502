import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { Match, Redirect } from '@gatsbyjs/reach-router';
import { InstanceTypes, currentInstanceType, getEnvironmentVariable } from '@myci/utils';

const configuredChangePasswordUrl = getEnvironmentVariable('GATSBY_MYCI_CHANGE_PASSWORD_URL');

const changePasswordPath =
	currentInstanceType === InstanceTypes.INSTANCE_FO
		? 'my-profile/settings/password'
		: 'dashboard/change-password';

const changePasswordUrl =
	currentInstanceType === InstanceTypes.INSTANCE_FO && configuredChangePasswordUrl
		? `/${configuredChangePasswordUrl}`
		: `/app/${changePasswordPath}`;

const CheckUserPassword = ({ isPasswordChangeNecessary, children }) => {
	// NOTE: until we have account data response, we don't want to cause the falsy value to render children
	if (isNil(isPasswordChangeNecessary)) {
		return null;
	}
	if (!isPasswordChangeNecessary) {
		return children;
	}

	return (
		<Match path={changePasswordUrl}>
			{({ match }) => (!match ? <Redirect noThrow to={changePasswordUrl} /> : children)}
		</Match>
	);
};
CheckUserPassword.propTypes = {
	children: PropTypes.node,
	isPasswordChangeNecessary: PropTypes.bool,
};
export default CheckUserPassword;
