import * as R from 'ramda';
import { rejectNil, mapIndexed } from 'ramda-extension';

const mapOverTail = fn => mapIndexed((element, index) => (index === 0 ? element : fn(element)));

type UrlPart = string | number | undefined | null;

export const createUrl: (parts: UrlPart[]) => string = R.compose(
	R.reduce<string, string>(R.concat, ''),
	mapOverTail(R.unless(R.startsWith('/'), R.concat('/'))),
	R.map(R.o(R.when(R.endsWith('/'), R.init), String)),
	rejectNil
);
