import React from 'react';
import { Icon, Showcase, ShowcaseProps } from '@ci/atoms';
import { map } from 'ramda';

interface PremiumShowcaseProps extends ShowcaseProps {
	isLocked?: boolean;
}

export const PremiumShowcase = ({ isLocked, items, ...otherProps }: PremiumShowcaseProps) => (
	<Showcase
		{...otherProps}
		items={map(item => ({ ...item, value: isLocked ? <Icon type="locked" /> : item.value }), items)}
	/>
);
