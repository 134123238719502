import { startsWith, o, prop, path, assocPath, dissocPath, isNil, equals, pathEq } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { typeEq, makeMiddleware, composeMiddleware } from '@ci/utils';

import {
	METHOD_PREFIX,
	TASK_PREFIX,
	TaskActionTypes,
	taskSuccessEvent,
	taskErrorEvent,
} from './actions';

const getType = prop('type');
const taskIdPath = ['meta', 'taskId'];
const getErrors = path(['payload', 'event', 'meta', 'errors']);

export const FIRST_POLL_RUN = '@control-flow/firstPollRun';

export const getTaskId = path(taskIdPath);
export const hasTaskId = o(isNotNil, getTaskId);
export const assocTaskId = assocPath(taskIdPath);
export const dissocTaskId = dissocPath(taskIdPath);

export const getControlFlowId = path(['meta', 'controlFlowId']);

export const isControlFlowMethod = o(startsWith(METHOD_PREFIX), getType);
export const isTaskEvent = o(startsWith(TASK_PREFIX), getType);
export const isTaskSuccess = typeEq(TaskActionTypes.SUCCESS);
export const isTaskError = typeEq(TaskActionTypes.ERROR);

const AUTHENTICATION_ERROR_STATUS = 'AuthenticationError';

export const isAuthenticationError = pathEq(['meta', 'status'], AUTHENTICATION_ERROR_STATUS);

const AUTHORIZATION_ERROR_STATUS = 'AuthorizationError';

export const isAuthorizationError = pathEq(['meta', 'status'], AUTHORIZATION_ERROR_STATUS);

export const delegateTask = (source, target) => assocTaskId(getTaskId(source), target);

const makeGenericIntegration = (predicate, createTaskEvent) =>
	makeMiddleware(predicate, ({ dispatch }) => action => {
		const taskId = getTaskId(action);

		if (taskId) {
			dispatch(createTaskEvent(taskId, action));
		}
	});

export const makeIntegration = (isSuccess, isError) =>
	composeMiddleware(
		makeGenericIntegration(isSuccess, taskSuccessEvent),
		makeGenericIntegration(isError, taskErrorEvent)
	);

export const makeCallback =
	(userCallback, dispatch, delegatedTaskId) => (taskEvent, otherParams) => {
		const { event } = taskEvent.payload;

		if (userCallback) {
			userCallback(event, {
				isError: isTaskError(taskEvent),
				isAuthenticationError: isAuthenticationError(event),
				isAuthorizationError: isAuthorizationError(event),
				error: isTaskError(taskEvent) && getErrors(taskEvent) && getErrors(taskEvent)[0],
				...otherParams,
			});
		}

		if (isNil(delegatedTaskId)) {
			return;
		}

		if (isTaskSuccess(taskEvent)) {
			dispatch(taskSuccessEvent(delegatedTaskId, event));
		} else {
			dispatch(taskErrorEvent(delegatedTaskId, event));
		}
	};

export const isFirstPollRun = equals(FIRST_POLL_RUN);
