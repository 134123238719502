import { addToast } from '@ci/toasts';
import { navigate } from '@myci/navigation';
import {
	RequestAction,
	ResponseEventAction,
	createThunk,
	getIsAnyErrorResponseAction,
	request,
} from '@ci/api';
import { getInnerErrorFromAction } from '@myci/api';
import { reset as resetForm } from 'redux-form';
import { createAction, createReducer } from '@reduxjs/toolkit';
import m from '../messages';
import { fetchUserAccount } from './account';

export const setIsPhoneCodeSent = createAction<boolean>('@changePhone/setIsPhoneCodeSent');
export const resetPhone = createAction('@changePhone/resetPhone');

export interface RequestPhoneVerificationCodePayload {
	form: string;
	phone: string;
}

interface PhoneChangeState {
	isPhoneCodeSent: boolean;
}

const initialState: PhoneChangeState = {
	isPhoneCodeSent: false,
};

export const requestPhoneVerificationCode = createThunk(
	{
		originType: '@changePhone/requestPhoneVerificationCode',
	},
	async ({ dispatch }, payload: RequestPhoneVerificationCodePayload) => {
		const { form, phone } = payload;

		dispatch(setIsPhoneCodeSent(false));

		try {
			await dispatch(
				request({ url: '/account/phone/request', method: 'POST', body: { phone } }, { form })
			);

			dispatch(setIsPhoneCodeSent(true));

			dispatch(
				addToast({
					type: 'success',
					content: m.sendPhoneCodeSuccess,
					values: { phone: payload.phone },
				})
			);
		} catch (error) {
			if (getIsAnyErrorResponseAction(error)) {
				dispatch(
					addToast({
						type: 'warning',
						content: { ...m.verifyPhoneFail, defaultMessage: error.payload },
					})
				);
			}
		}
	}
);
export const selectIsPhoneCodeSent = state => state.changePhone?.isPhoneCodeSent;

export interface VerifyPhoneCodePayload {
	code: string;
	form: string;
	onSuccess?: () => void;
	phone: string;
}

export const verifyPhoneCode = createThunk(
	{
		originType: '@changePhone/verifyPhoneCode',
	},
	async ({ dispatch }, payload: VerifyPhoneCodePayload) => {
		const { form, code, onSuccess, phone } = payload;

		try {
			await dispatch(
				request({ url: '/account/phone/verify', method: 'POST', body: { code, phone } }, { form })
			);

			dispatch(addToast({ type: 'success', content: m.changePhoneSuccess }));
			dispatch(resetForm(form));
			dispatch(fetchUserAccount());

			if (onSuccess) {
				onSuccess();
			}

			navigate('../settings');
		} catch (error) {
			if (getIsAnyErrorResponseAction(error)) {
				dispatch(
					addToast({
						type: 'warning',
						content: { ...m.verifyPhoneFail, defaultMessage: error.payload },
					})
				);
			}
		}
	}
);

export const getPhoneCheckUniquenessErrors = (response: ResponseEventAction) => {
	const errors = getInnerErrorFromAction(response);

	return {
		errors: errors.map(error => ({
			message: m[`verificationError_${error.code}`] ?? m.changePhoneFail,
		})),
	};
};

interface CreateCheckPhoneFieldUniquenessRequestValues {
	phone: string;
	userType: string;
}

export const createCheckPhoneFieldUniquenessRequest = (
	values: CreateCheckPhoneFieldUniquenessRequestValues,
	props: any,
	currentFieldName: string
): RequestAction => {
	const { userType } = values;

	const userTypePath = userType ?? 'Individual';

	return request(
		{
			url: `/register/check/${userTypePath}/phoneNumber`,
			method: 'POST',
			body: { value: values[currentFieldName] },
		},
		{
			origin: {
				type: '@changePhone/checkPhoneFieldUniquenessRequest',
			},
		}
	);
};

export const changePhoneReducer = createReducer(initialState, builder => {
	builder.addCase(setIsPhoneCodeSent, (state, action) => ({
		...state,
		isPhoneCodeSent: action.payload,
	}));
});
