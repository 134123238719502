import { createAction, createReducer } from '@reduxjs/toolkit';
import { propEq, reject, slice } from 'ramda';

import { ToastDefinition, ToastId, ToastsState } from './types';

const MAX_OPEN_TOASTS_LIMIT = 3;

export type AddToastPayload = Omit<ToastDefinition, 'id'>;

export const addToast = createAction<AddToastPayload>('@creditinfo-ui/toasts/addToast');
export const removeToast = createAction<ToastId>('@creditinfo-ui/toasts/removeToast');
export const removeAllToasts = createAction('@creditinfo-ui/toasts/removeAllToasts');

interface RootState {
	toasts?: ToastsState;
}

export const selectToasts = (state: RootState) => state.toasts;

export const initialState: ToastsState = { stack: [], counter: 0 };

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(addToast, (state, { payload }) => {
			state.stack = [
				{ id: `toast-${state.counter}`, ...payload },
				...slice(0, MAX_OPEN_TOASTS_LIMIT - 1, state.stack),
			];

			state.counter += 1;
		})
		.addCase(removeToast, (state, { payload }) => {
			state.stack = reject(propEq('id', payload), state.stack);
		})
		.addCase(removeAllToasts, () => initialState);
});
