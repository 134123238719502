import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { Transition, useBounceSlideTransitionStyles } from '@creditinfo-ui/transitions';
import { map } from 'ramda';
import { cx } from 'ramda-extension';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { TransitionGroup } from 'react-transition-group';

import { ToastDefinition } from './types';

const toastStackStyle = prepareStyle(utils => ({
	bottom: 'auto',
	insetInlineEnd: utils.spacings.xs,
	insetInlineStart: utils.spacings.xs,
	paddingInlineStart: utils.borders.widths.xxl,
	position: 'fixed',
	top: utils.spacings.md,
	zIndex: utils.zIndices.toast,
	selectors: {
		[utils.breakpoints.sm]: {
			insetInlineEnd: utils.spacings.md,
			insetInlineStart: 'auto',
			maxWidth: utils.maxWidths.xs,
		},
	},
}));

export interface ToastStackProps {
	renderToast: (toast: ToastDefinition) => React.ReactNode;
	toasts: ToastDefinition[];
}

export const ToastStack = ({ toasts, renderToast }: ToastStackProps) => {
	const { applyStyle } = useStyles();
	const transitionSpeed = 'slower';
	const { classNames, baseClassName } = useBounceSlideTransitionStyles(transitionSpeed);

	const container = typeof document === 'undefined' ? null : document.body;

	if (!container) {
		return null;
	}

	return createPortal(
		<TransitionGroup className={applyStyle(toastStackStyle)}>
			{map(
				toast => (
					<Transition key={`transition-${toast.id}`} speed={transitionSpeed}>
						{state => (
							<div className={cx(classNames[state], baseClassName)}>{renderToast(toast)}</div>
						)}
					</Transition>
				),
				toasts
			)}
		</TransitionGroup>,
		container
	);
};
