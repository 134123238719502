import { useMemo } from 'react';
import { reduce } from 'ramda';
import { IdentityFormatter } from '@ci/formatters';
import { getItemKey } from '../constants';

// TODO: This name is used so the original select code can be largely left as-is.
const useRenderedValues = (formatter = IdentityFormatter, items = [], statics = []) =>
	useMemo(
		() =>
			reduce(
				(previousValues, item) => ({
					...previousValues,
					[getItemKey(item)]: formatter.formatAsPrimitive(item),
				}),
				{},
				[...statics, ...items]
			),
		[formatter, items, statics]
	);

export default useRenderedValues;
