import { assignStyle as untypedAssignStyle } from 'css-in-js-utils';
import { pipe, reduce } from 'ramda';
import felaPluginExtend from 'fela-plugin-extend';
import felaPluginMultipleSelectors from 'fela-plugin-multiple-selectors';

import { NativeStyleObject, StyleObject } from './types';

const applyExtendPlugin = felaPluginExtend() as <T>(styleObject: T) => T;
const applyMultipleSelectorsPlugin = felaPluginMultipleSelectors() as <T>(styleObject: T) => T;

const applyPlugins = pipe(applyExtendPlugin, applyMultipleSelectorsPlugin);
const applyNativePlugins = applyExtendPlugin;

const assignStyle: (...styleObjects: StyleObject[]) => StyleObject = untypedAssignStyle as any;

export const mergeStyleObjects = (styleObjects: StyleObject[]): StyleObject =>
	styleObjects.length === 1
		? applyPlugins(styleObjects[0])
		: assignStyle({}, ...styleObjects.map(applyPlugins));

export const mergeNativeStyleObjects = (styleObjects: NativeStyleObject[]): NativeStyleObject =>
	styleObjects.length === 1
		? applyNativePlugins(styleObjects[0])
		: reduce(Object.assign, {}, styleObjects.map(applyNativePlugins));
