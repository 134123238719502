export const downloadFile = (content, filename) => {
	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(content, filename);
	} else {
		const data = window.URL.createObjectURL(content);
		const downloadLink = document.createElement('a');
		downloadLink.setAttribute('href', data);
		downloadLink.setAttribute('download', filename);
		downloadLink.style.display = 'none';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}
};
