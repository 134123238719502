import { Text } from '@creditinfo-ui/atoms';
import { prepareStyle } from '@creditinfo-ui/styles';

const customTextStyle = prepareStyle(utils => ({
	display: 'block',
	paddingBottom: utils.spacings.md,
	paddingTop: utils.spacings.md,
}));

export const tabsFixture = [
	{
		key: `CURRENT_SUBJECT_DATA`,
		label: `Current Subject Data`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				Current Subject Data
			</Text>
		),
	},
	{
		key: `ADDITIONAL_INFORMATION`,
		label: `Additional Information`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				Additional Information
			</Text>
		),
	},
	{
		key: `CONTRACT_SUMMARY`,
		label: `Contract Summary`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				Contract Summary
			</Text>
		),
	},
	{
		key: `DEBTOR'S_EXISTING_CONTRACTS`,
		label: `Debtor's existing contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Debtor's existing contracts"}
			</Text>
		),
	},
	{
		key: `GUARANTOR'S_EXISTING_CONTRACTS`,
		label: `Guarantor's existing contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Guarantor's existing contracts"}
			</Text>
		),
	},
	{
		key: `DEBTOR'S_TERMINATED_CONTRACTS`,
		label: `Debtor's terminated contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Debtor's terminated contracts"}
			</Text>
		),
	},
	{
		key: `GUARANTOR'S_TERMINATED_CONTRACTS`,
		label: `Guarantor's terminated contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Guarantor's terminated contracts"}
			</Text>
		),
	},
	{
		key: `DEBTOR'S_DEPERSONALIZED_EXISTING_CONTRACTS`,
		label: `Debtor's depersonalized existing contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Debtor's depersonalized existing contracts"}
			</Text>
		),
	},
	{
		key: `GUARANTOR'S_DEPERSONALIZED_EXISTING_CONTRACTS`,
		label: `Guarantor's depersonalized existing contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Guarantor's depersonalized existing contracts"}
			</Text>
		),
	},
	{
		key: `DEBTOR'S_DEPERSONALIZED_TERMINATED_CONTRACTS`,
		label: `Debtor's depersonalized terminated contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Debtor's depersonalized terminated contracts"}
			</Text>
		),
	},
	{
		key: `GUARANTOR'S_DEPERSONALIZED_TERMINATED_CONTRACTS`,
		label: `Guarantor's depersonalized terminated contracts`,
		content: (
			<Text variant="tagline" customStyle={customTextStyle}>
				{"Guarantor's depersonalized terminated contracts"}
			</Text>
		),
	},
];
