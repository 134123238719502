import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Message } from '@myci/intl';

import FormField from '../FormField';
import SelectControl from '../SelectControl';

import './Select.scss';

const Select = ({ hasFloatingLabel, label, ...otherProps }) => {
	const nextLabel =
		label?.type === Message ? cloneElement(label, { shouldObfuscate: true }) : label;

	return (
		<FormField
			control={SelectControl}
			hasFloatingLabel={hasFloatingLabel}
			kind="select"
			label={nextLabel}
			{...otherProps}
		/>
	);
};

Select.propTypes = {
	hasFloatingLabel: PropTypes.bool,
	label: PropTypes.node,
};

export default Select;
