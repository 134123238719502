export const USER_TYPE = { Company: 2, Individual: 1 } as const;

export const USERS_DICT = {
	1: 'Individual',
	2: 'Company',
};

export const subscriptionStatus = {
	finished: 'Finished',
	ongoing: 'Ongoing',
	upcoming: 'Upcoming',
} as const;
