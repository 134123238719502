import { andThen, compose, fromPairs, map, toLower } from 'ramda';

const promiseAll = xs => Promise.all(xs);

export const importTranslations = locale =>
	compose(
		andThen(fromPairs),
		promiseAll,
		map(namespace =>
			import(
				/* webpackMode: "lazy", webpackChunkName: "translations-[request]-[index]" */
				`../data/translations/${toLower(namespace)}/${locale}.json`
			)
				.then(mod => [namespace, mod.default || mod])
				.catch(error => {
					console.warn('Missing translations', { locale, namespace, error });

					// NOTE: If there are no translations on the BE for a namespace, we should provide
					// an empty object to ensure that a default message will still be displayed.
					return [namespace, { [namespace]: {} }];
				})
		)
	);
