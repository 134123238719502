import { composeMiddleware, makeMiddleware, safeReject, typeEq } from '@myci/utils';
import { assocPath, equals, filter, forEachObjIndexed, isNil } from 'ramda';
import { isString, uniqAppend } from 'ramda-extension';
import {
	actionTypes as ActionTypesReduxForm,
	reset as resetReduxForm,
	submit as submitReduxForm,
} from 'redux-form';
import { attachNamespace, getNamespaceByAction, makeReducer } from 'redux-syringe';
import { addToast } from '@ci/toasts';
import { getAllFormErrors } from '../forms';

import { goToStep, registerField, setShouldFocusFirstInvalidField, setStep } from './actions';
import { ActionTypes } from './constants';
import {
	clampStepByAction,
	getCurrentStep,
	getCurrentStepByAction,
	getJourneyFirstInvalidStep,
	getNextStepByAction,
	getPreviousStepByAction,
} from './selectors';

const initialState = {
	currentStep: 1,
	numberOfSteps: 0,
	visitedSteps: [],
	registeredForms: [],
	registeredFields: {},
	shouldFocusFirstInvalidField: false,
};

const journeyGoToStepMiddleware = makeMiddleware(
	typeEq(ActionTypes.GO_TO_STEP),
	({ dispatch, getState }) =>
		action => {
			const newStep = clampStepByAction(action, getState());
			const currentStep = getCurrentStepByAction(action, getState());

			if (newStep === currentStep) {
				return;
			}
			dispatch(setStep(newStep));
		}
);

const journeyGoToPreviousStepMiddleware = makeMiddleware(
	typeEq(ActionTypes.GO_TO_PREVIOUS_STEP),
	({ dispatch, getState }) =>
		action => {
			const previousStep = getPreviousStepByAction(action, getState());

			dispatch(setStep(previousStep));
		}
);

const journeyGoToNextStepMiddleware = makeMiddleware(
	typeEq(ActionTypes.GO_TO_NEXT_STEP),
	({ dispatch, getState }) =>
		action => {
			const nextStep = getNextStepByAction(action, getState());

			dispatch(setStep(nextStep, action.meta));
		}
);
const journeySubmitMiddleware = makeMiddleware(
	typeEq(ActionTypes.SUBMIT),
	({ dispatch }) =>
		action => {
			const namespace = getNamespaceByAction(action);

			dispatch(submitReduxForm(namespace));
		}
);
const journeyResetMiddleware = makeMiddleware(
	typeEq(ActionTypes.RESET),
	({ dispatch }) =>
		action => {
			const namespace = getNamespaceByAction(action);

			dispatch(resetReduxForm(namespace));
		}
);

export const reduxFormRegisterFieldMiddleware = makeMiddleware(
	typeEq(ActionTypesReduxForm.REGISTER_FIELD),
	({ dispatch }) =>
		({ payload, meta: { form } }) => {
			dispatch(registerField(payload, { namespace: form }));
		}
);

const reduxFormSetSubmitFailedMiddleware = makeMiddleware(
	typeEq(ActionTypesReduxForm.SET_SUBMIT_FAILED),
	({ dispatch, getState, getNamespacedState, namespace }) =>
		({ meta: { form } }) => {
			if (namespace !== form) {
				return;
			}

			const firstInvalidStep = getJourneyFirstInvalidStep(form)(getState());
			const currentStep = getCurrentStep(getNamespacedState());

			// NOTE: In UX terms, this is not the best solution. If an error happens on a different tab
			// that we haven't yet visited, we cannot reliably go to the appropriate step, because we
			// don't know what step the field is mounted at (we haven't seen the field yet!). Instead,
			// we just dispatch the errors as toast notifications, so the user knows something went wrong.
			// This is only applicable to editing, because in creation journeys we always visit all steps.
			// However, we still need to dispatch `setShouldFocusFirstInvalidField(true)` even if we do not
			// go to the appropriate step automatically. This is because if the field hasn't been touched
			// yet, no error would be shown to the user upon switching tabs (meanwhile, the user is trying
			// very hard to find the step where the error occurred, unsuccessfully).
			dispatch(attachNamespace(form, setShouldFocusFirstInvalidField(true)));

			if (isNil(firstInvalidStep)) {
				const errors = getAllFormErrors(form)(getState());
				// NOTE: String errors only come from the back end. Locally, we use React Intl messages.
				const stringErrors = filter(isString, errors);

				forEachObjIndexed(
					(stringError, key) =>
						dispatch(
							addToast({
								content: { id: `Validation.${key}`, defaultMessage: stringError },
								type: 'danger',
							})
						),
					stringErrors
				);
			} else if (firstInvalidStep !== currentStep) {
				dispatch(attachNamespace(form, goToStep(firstInvalidStep)));
			}
		}
);

export const middleware = composeMiddleware(
	reduxFormRegisterFieldMiddleware,
	reduxFormSetSubmitFailedMiddleware,
	journeyGoToPreviousStepMiddleware,
	journeyGoToNextStepMiddleware,
	journeyGoToStepMiddleware,
	journeySubmitMiddleware,
	journeyResetMiddleware
);

export const reducer = makeReducer(
	[
		[
			ActionTypes.SET_STEP,
			(state, { payload }) => ({
				...state,
				visitedSteps: uniqAppend(payload, state.visitedSteps),
				currentStep: payload,
			}),
		],
		[
			ActionTypes.REGISTER_FIELD,
			(state, { payload: { name, type } }) =>
				assocPath(['registeredFields', name], { name, type, step: getCurrentStep(state) }, state),
		],
		[
			ActionTypes.REGISTER_FORM,
			(state, { payload: step }) => ({
				...state,
				registeredForms: uniqAppend(step, state.registeredForms),
			}),
		],
		[
			ActionTypes.UNREGISTER_FORM,
			(state, { payload: step }) => ({
				...state,
				registeredForms: safeReject(equals(step), state.registeredForms),
			}),
		],
		[
			ActionTypes.INITIALIZE,
			(state, { payload }) => {
				const currentStep = isNil(payload.initialStep)
					? initialState.currentStep
					: payload.initialStep;

				return {
					...state,
					isInitialized: true,
					currentStep,
					visitedSteps: uniqAppend(currentStep, state.visitedSteps),
				};
			},
		],
		[ActionTypes.DESTROY, () => initialState],
		[
			ActionTypes.SET_NUMBER_OF_STEPS,
			(state, { payload: numberOfSteps }) => ({
				...state,
				numberOfSteps,
			}),
		],
		[
			ActionTypes.SET_SHOULD_FOCUS_FIRST_INVALID_FIELD,
			(state, { payload }) => ({
				...state,
				shouldFocusFirstInvalidField: payload,
			}),
		],
	],
	initialState
);

export default reducer;
