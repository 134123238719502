import { ReactNode, useRef } from 'react';
import { prepareStyle, prepareStyleFactory, useStyles } from '@creditinfo-ui/styles';
import { isArray, isNotNil } from 'ramda-extension';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { Breakpoint } from '@creditinfo-ui/fela';
import { Buttons } from './Buttons';

export interface ViewHeaderProps {
	actions?: ReactNode[] | ReactNode;
	desktopLayoutBreakpoint?: Breakpoint;
	status?: ReactNode;
	title: ReactNode;
}

const viewHeaderStyle = prepareStyle<{ desktopLayoutBreakpoint: Breakpoint }>(
	(utils, { desktopLayoutBreakpoint }) => ({
		display: 'flex',
		flexDirection: 'column',
		marginBottom: utils.spacings.md,

		selectors: {
			[utils.breakpoints[desktopLayoutBreakpoint]]: {
				alignItems: 'center',
				flexDirection: 'row',
			},
		},
	})
);

const titleStatusWrapperStyle = prepareStyle<{
	desktopLayoutBreakpoint: Breakpoint;
	hasActions: boolean;
}>((utils, { desktopLayoutBreakpoint, hasActions }) => ({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',

	extend: {
		condition: hasActions,
		style: {
			marginBottom: utils.spacings.sm,
			selectors: {
				[utils.breakpoints[desktopLayoutBreakpoint]]: {
					marginBottom: 0,
				},
			},
		},
	},
}));

const titleStyle = prepareStyle<{ hasStatus: boolean }>((utils, { hasStatus }) => ({
	extend: {
		condition: hasStatus,
		style: {
			marginInlineEnd: utils.spacings.md,
		},
	},
}));

const statusStyle = prepareStyle(utils => ({
	bottom: utils.spacings.xs,
	marginTop: utils.spacings.sm,
	position: 'relative',
}));

const actionsStyle = prepareStyleFactory<{ desktopLayoutBreakpoint: Breakpoint }>(
	(utils, { desktopLayoutBreakpoint }) => ({
		selectors: {
			[utils.breakpoints[desktopLayoutBreakpoint]]: {
				marginInlineStart: 'auto',
			},
		},
	})
);

export const ViewHeader = ({
	actions,
	desktopLayoutBreakpoint = 'xl',
	status,
	title,
}: ViewHeaderProps) => {
	const { applyStyle } = useStyles();
	const actionsRef = useRef<HTMLDivElement>(null);
	const { height: actionsHeight } = useResizeDetector({ targetRef: actionsRef });
	const hasStatus = isNotNil(status);

	return (
		<div className={applyStyle(viewHeaderStyle, { desktopLayoutBreakpoint })}>
			<div
				className={applyStyle(titleStatusWrapperStyle, {
					desktopLayoutBreakpoint,
					hasActions: actionsHeight !== 0,
				})}
			>
				<div className={applyStyle(titleStyle, { hasStatus })}>{title}</div>
				{status && <div className={applyStyle(statusStyle)}>{status}</div>}
			</div>
			{isArray(actions) ? (
				<Buttons
					customStyle={actionsStyle({ desktopLayoutBreakpoint })}
					buttons={actions}
					ref={actionsRef}
				/>
			) : (
				<div className={applyStyle(actionsStyle({ desktopLayoutBreakpoint }))} ref={actionsRef}>
					{actions}
				</div>
			)}
		</div>
	);
};
