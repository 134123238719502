import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@myci/ui-components';

import { useIsJourneyInitialized, useIsJourneyStepVisited, useJourneyCurrentStep } from '../hooks';

/**
 * In default component will mount children after first visit the step determined by `visibleOnStep`.
 * Hidding the component then is done just by CSS preventing the children to unmount.
 */
const JourneyLazyContent = ({
	visibleOnStep,
	children,
	alwaysUnmount,
	disableLazyMount,
	namespace,
}) => {
	const isInitialized = useIsJourneyInitialized(namespace);
	const currentStep = useJourneyCurrentStep(namespace);
	const isStepVisited = useIsJourneyStepVisited(namespace);
	const isVisible = currentStep === visibleOnStep;
	const shouldMount = disableLazyMount || isStepVisited(visibleOnStep);

	if (!isInitialized) {
		return null;
	}

	if (shouldMount && !alwaysUnmount) {
		return <Hidden isVisible={isVisible}>{children}</Hidden>;
	}

	return isVisible ? children : null;
};
JourneyLazyContent.propTypes = {
	/** If `true`, `children` will be unmount if not visible instead of hiding it just visually. */
	alwaysUnmount: PropTypes.bool,
	children: PropTypes.node,
	/** If `true`, `children` will be mounted even if not visible. If `false`, `children` will be mounted after first visit. */
	disableLazyMount: PropTypes.bool,
	/** For connecting the component outside the context of `<Journey>`. */
	namespace: PropTypes.any,
	visibleOnStep: PropTypes.number,
};

export default JourneyLazyContent;
