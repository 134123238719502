import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { cx, noop } from 'ramda-extension';

import Icon from '../Icon';
import Grid from '../Grid';

// TODO: markup
const AccordionHeader = ({
	className,
	closedIconName = 'caret-down',
	disabled,
	isOpen,
	onClickHandler,
	openIconName = 'caret-up',
	secondaryTitle,
	secondaryTitleIcon,
	title,
}) => (
	<div
		onClick={disabled ? noop : onClickHandler}
		className={cx('accordion__item-header', { disabled }, className)}
		data-toggle="collapse"
	>
		<Grid row>
			<Grid col={10}>
				<h4 className="accordion__item-title">{title}</h4>
			</Grid>
			<Grid col={2} flex justifyContent="end" alignItems="center">
				{secondaryTitle && <span className="text-right">{secondaryTitle}</span>}
				{secondaryTitleIcon &&
					cloneElement(secondaryTitleIcon, {
						className: cx('ml-2 mr-3', secondaryTitleIcon.props.className),
					})}

				<Icon type={isOpen ? openIconName : closedIconName} />
			</Grid>
		</Grid>
	</div>
);
// status-success"
AccordionHeader.propTypes = {
	/** ClassName */
	className: PropTypes.string,
	/** Icon for closed panel */
	closedIconName: PropTypes.string,
	/** Is header disabled */
	disabled: PropTypes.bool,
	/** Data index */
	index: PropTypes.number,
	/** Is panel open indicator */
	isOpen: PropTypes.bool,
	/** On click handler */
	onClickHandler: PropTypes.func,
	/** Icon for opened panel */
	openIconName: PropTypes.string,
	secondaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/** Secondary title. */
	secondaryTitleIcon: PropTypes.node,
	/** Title */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default AccordionHeader;
