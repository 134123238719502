import { head } from 'ramda';
import { FieldError } from 'react-hook-form';
import { EXTENDED_FIELD_ERROR_TYPE } from '../constants';
import { ExtendedFieldError, ValidationError } from '../types';

const getIsExtendedFieldError = (fieldError: FieldError): fieldError is ExtendedFieldError =>
	fieldError.type === EXTENDED_FIELD_ERROR_TYPE && 'validationErrors' in fieldError;

export const getValidationErrors = (fieldError: FieldError | undefined): ValidationError[] => {
	if (!fieldError) {
		return [];
	}

	if (!getIsExtendedFieldError(fieldError)) {
		return fieldError.message ? [{ message: fieldError.message }] : [];
	}

	return fieldError.validationErrors;
};

export const getFirstValidationError = (
	fieldError: FieldError | undefined
): ValidationError | undefined => head(getValidationErrors(fieldError));
