import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Grid from '../Grid';
import Icon from '../Icon';
import Heading from '../Heading';
import './PanelTitle.scss';

const PanelTitle = ({
	className,
	icon,
	title,
	iconProps = { color: 'icon', size: 18, mr: 2 },
	headingProps = { as: 'h3', color: 'info' },
	...rest
}) => (
	<Grid className={cx('panel-title', className)} flex alignItems="baseline" {...rest}>
		<Icon type={icon} {...iconProps} />
		<Heading className="panel-title__heading" {...headingProps}>
			{title}
		</Heading>
	</Grid>
);

PanelTitle.propTypes = {
	/** Class name for root. */
	className: PropTypes.string,
	/** Other props for heading element */
	headingProps: PropTypes.object,
	/** Type of the icon used in heading. */
	icon: PropTypes.string.isRequired,
	/** Other props for icon element */
	iconProps: PropTypes.object,
	/** Type of the icon used in heading. */
	title: PropTypes.node.isRequired,
};

export default PanelTitle;
