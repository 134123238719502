import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Grid from '../Grid';

const TemplatePageContent = ({
	children,
	heading,
	actions,
	headingActions,
	className,
	...otherProps
}) => (
	<Fragment>
		<Grid row flexDirection="column" mb={3} {...otherProps} className={cx(className)}>
			<Grid col="auto">
				<Grid
					row
					flexDirection={{ xs: 'column', md: 'row' }}
					alignItems={{ md: 'center' }}
					justifyContent={{ md: 'between' }}
				>
					{heading && (
						<Grid col="auto" mb={{ xs: 3, md: 0 }}>
							{heading}
						</Grid>
					)}

					{headingActions && <Grid col="auto">{headingActions}</Grid>}
				</Grid>
			</Grid>

			{actions && (
				<Grid col="auto" mt={3}>
					{actions}
				</Grid>
			)}
		</Grid>

		{children}
	</Fragment>
);

TemplatePageContent.propTypes = {
	actions: PropTypes.node,
	children: PropTypes.node,
	className: PropTypes.string,
	heading: PropTypes.node,
	headingActions: PropTypes.node,
};

export default TemplatePageContent;
