import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import Heading from '../Heading';
import RadioControl from '../RadioControl';
import './CardRadio.scss';

const CardRadio = ({ children, className, title, icon, icon2x, iconAlt, ...rest }) => (
	<label className="card-radio">
		<RadioControl className="card-radio__input" {...rest} />
		<Box className={cx('card-radio__inner', className)}>
			<Heading as="h3" className="card-radio__title">
				{title}
			</Heading>
			{children}
			{icon && icon2x && (
				<img
					className="card-radio__icon"
					srcSet={`${icon} 1x, ${icon2x} 2x`}
					src={icon}
					alt={iconAlt || title}
				/>
			)}
		</Box>
	</label>
);

CardRadio.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** Icon URL fragment. */
	icon: PropTypes.string,
	/** Icon 2x URL fragment. */
	icon2x: PropTypes.string,
	/** Alt HTML attribute for the icon. */
	iconAlt: PropTypes.string,
	/** Title for the card. */
	title: PropTypes.node.isRequired,
};

export default CardRadio;
