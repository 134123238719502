import PropTypes from 'prop-types';
import React from 'react';

const Video = ({ src, mediaType, ...rest }) => (
	<video src={src} {...rest}>
		<source src={src} type={mediaType} />
		Your browser does not support the video tag.
	</video>
);

Video.propTypes = {
	/** Video media type */
	mediaType: PropTypes.string.isRequired,
	/** Path to resource */
	src: PropTypes.string.isRequired,
};

export default Video;
