import { useRef } from 'react';

let counter = 0;

export const generateId = () => {
	counter += 1;

	// NOTE: `c` is an arbitrary prefix to avoid collision with more naïve ID generation approaches.
	return `c${counter}`;
};

export const useGeneratedId = (): string => {
	const idRef = useRef<string>('');

	if (idRef.current === '') {
		idRef.current = generateId();
	}

	return idRef.current;
};
