import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

// FIXME: until https://github.com/chenqingspring/react-lottie/issues/100 is fixed
// const Lottie = React.lazy(() => import('react-lottie'));
const Lottie = React.lazy(() =>
	import('@crello/react-lottie').then(module => ({ default: module.Lottie }))
);

const defaultOptions = {
	loop: true,
	autoplay: true,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const Animation = ({ className, animationData, options = defaultOptions, ...otherProps }) => (
	// NOTE: lottieEventListeners must be filled otherwise it Lottie will crash on TypeError
	<Lottie
		className={cx(className)}
		config={animationData ? { ...options, animationData } : options}
		lottieEventListeners={[]}
		{...otherProps}
	/>
);

Animation.propTypes = {
	/** JSON default animation data. */
	animationData: PropTypes.object,
	/** Class name for root. */
	className: PropTypes.string,
	/** Lottie animation config. */
	options: PropTypes.object,
};

export default Animation;
