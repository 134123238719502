import { defineMessages } from 'react-intl';

export default defineMessages({
	apiError: {
		id: 'notifications.apiError',
		defaultMessage: 'Unexpected error has occurred. Request ID: {requestId}.',
		description: 'Various error messages returned from API.',
	},
	apiWarning: {
		id: 'notifications.apiWarning',
		defaultMessage: '{message}',
		description: 'Various error messages returned from API.',
	},
});
