import { mapObjIndexed } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { Breakpoint } from '@creditinfo-ui/fela';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { Icon, getIsIconType, iconSizes } from '../Icon';
import {
	GAUGE_HEIGHT,
	GAUGE_WIDTH,
	Gauge,
	MOBILE_GAUGE_HEIGHT,
	MOBILE_GAUGE_WIDTH,
} from '../Gauge';
import { ShowcaseItemDescriptor } from './types';

const getShowcaseValueFontSize = (mobileLayoutBreakpoint: Breakpoint) => ({
	xs: '1.8rem' as const,
	[mobileLayoutBreakpoint]: '3rem' as const,
});

const showcaseValueStyle = prepareStyle<{ hasGauge: boolean; mobileLayoutBreakpoint: Breakpoint }>(
	(utils, { hasGauge, mobileLayoutBreakpoint }) => {
		const showcaseValueFontSize = getShowcaseValueFontSize(mobileLayoutBreakpoint);

		return {
			alignItems: 'center',
			color: utils.colors.gray800,
			display: 'flex',
			flexDirection: 'column',
			fontSize: showcaseValueFontSize,
			fontWeight: utils.fontWeights.bold,
			justifyContent: 'flex-end',
			marginTop: utils.spacings.sm,
			overflowWrap: 'break-word',
			paddingLeft: utils.spacings.sm,
			paddingRight: utils.spacings.sm,
			position: 'relative',
			textAlign: 'center',
			selectors: {
				[utils.breakpoints[mobileLayoutBreakpoint]]: {
					selectors: {
						':not(:nth-last-child(2))': {
							selectors: {
								':after': {
									backgroundColor: utils.colors.gray300,
									content: '""',
									height: mapObjIndexed(
										fontSize => utils.multiply(utils.lineHeights.base, fontSize),
										showcaseValueFontSize
									),
									insetInlineEnd: 0,
									margin: '0 auto',
									position: 'absolute',
									width: utils.borders.widths.sm,
								},
							},
						},
					},
				},
			},
			extend: {
				condition: hasGauge,

				style: {
					minHeight: `${MOBILE_GAUGE_HEIGHT}px`,
					minWidth: `calc(${MOBILE_GAUGE_WIDTH}px + ${utils.spacings.md})`,
					selectors: {
						[utils.breakpoints.sm]: {
							minHeight: `${GAUGE_HEIGHT}px`,
							minWidth: `calc(${GAUGE_WIDTH}px + ${utils.spacings.md})`,
						},
					},
				},
			},
		};
	}
);

const showcaseIconStyle = prepareStyle<{ hasValue: boolean; mobileLayoutBreakpoint: Breakpoint }>(
	(utils, { hasValue, mobileLayoutBreakpoint }) => ({
		marginInlineEnd: utils.spacings.xs,
		extend: {
			condition: hasValue,
			style: {
				fontSize: iconSizes.sm,
				// HACK: Since we don't use `display: 'flex'` to vertically align the icon with the value
				// due to ugly multiline visuals, we have to rely on `verticalAlign` instead. However,
				// because the icon and the value have a different `fontSize`, we cannot just use
				// `verticalAlign: '-0.125em' like in the `Icon` atom itself. Hence these hacky values.
				verticalAlign: '1px',
				selectors: {
					[utils.breakpoints[mobileLayoutBreakpoint]]: {
						fontSize: iconSizes.md,
						verticalAlign: '3px',
					},
				},
			},
		},
	})
);

const showcaseGaugeStyle = prepareStyle(() => ({
	bottom: 0,
	left: '50%',
	position: 'absolute',
	transform: 'translateX(-50%)',
}));

const showcaseLabelStyle = prepareStyle(utils => ({
	color: utils.colors.gray600,
	marginBottom: utils.spacings.sm,
	overflowWrap: 'break-word',
	paddingLeft: utils.spacings.sm,
	paddingRight: utils.spacings.sm,
	textAlign: 'center',
}));

export interface ShowcaseItemProps extends ShowcaseItemDescriptor {
	mobileLayoutBreakpoint: Breakpoint;
}

export const ShowcaseItem = ({
	gaugeValue,
	icon,
	iconProps,
	label,
	mobileLayoutBreakpoint,
	value,
}: ShowcaseItemProps) => {
	const { applyStyle } = useStyles();
	const hasGauge = isNotNil(gaugeValue);

	return (
		<>
			<div
				className={applyStyle(showcaseValueStyle, {
					hasGauge,
					mobileLayoutBreakpoint,
				})}
			>
				{hasGauge && (
					<Gauge
						customStyle={showcaseGaugeStyle}
						mobileLayoutBreakpoint={mobileLayoutBreakpoint}
						value={gaugeValue}
					/>
				)}
				<div>
					{icon && (
						<span
							className={applyStyle(showcaseIconStyle, {
								hasValue: isNotNil(value),
								mobileLayoutBreakpoint,
							})}
						>
							{getIsIconType(icon) ? <Icon type={icon} {...iconProps} /> : icon}
						</span>
					)}
					{value}
				</div>
			</div>

			<div className={applyStyle(showcaseLabelStyle)}>{label}</div>
		</>
	);
};
