import { defineMessages } from 'react-intl';

export default defineMessages({
	apiError: {
		id: 'uiComponentsRedux.apiError',
		defaultMessage: 'Unexpected error has occurred. Request ID: {requestId}.',
		description: 'Various error messages returned from API.',
	},
	apiWarning: {
		id: 'uiComponentsRedux.apiWarning',
		defaultMessage: '{message}',
		description: 'Various error messages returned from API.',
	},
	apiValidationError: {
		id: 'uiComponentsRedux.apiValidationError',
		defaultMessage: '{message}',
		description: 'Various error messages returned from API.',
	},
	cancel: {
		id: 'uiComponentsRedux.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel action',
	},
	submit: {
		id: 'uiComponentsRedux.submit',
		defaultMessage: 'Submit',
		description: 'Submit action',
	},
	nextStep: {
		id: 'uiComponentsRedux.nextStep',
		defaultMessage: 'Next Step',
		description: 'next step action',
	},
	previousStep: {
		id: 'uiComponentsRedux.previousStep',
		defaultMessage: 'Previous Step',
		description: 'previous step action',
	},
	fieldCultureCode: {
		id: 'uiComponentsRedux.fieldCultureCode',
		defaultMessage: 'Culture Code',
		description: 'Culture Code field label',
	},
});
