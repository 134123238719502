import {
	ReactElement,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { noop } from 'ramda-extension';

type DynamicFooterContextValue = readonly [ReactNode, (dynamicFooter: ReactNode) => void];

export const DynamicFooterContext = createContext<DynamicFooterContextValue>([null, noop]);

export interface DynamicFooterProviderProps {
	children: ReactNode;
}

export const DynamicFooterProvider = ({ children }: DynamicFooterProviderProps) => {
	const [dynamicFooter, setDynamicFooter] = useState<ReactNode>(null);

	const value = useMemo(
		() => [dynamicFooter, setDynamicFooter] as const,
		[dynamicFooter, setDynamicFooter]
	);

	return <DynamicFooterContext.Provider value={value}>{children}</DynamicFooterContext.Provider>;
};

export const useDynamicFooter = (dynamicFooter: ReactNode) => {
	const [, setDynamicFooter] = useContext(DynamicFooterContext);

	useEffect(() => {
		setDynamicFooter(dynamicFooter);

		return () => setDynamicFooter(null);
	}, [setDynamicFooter, dynamicFooter]);
};

export const DynamicFooter = (): ReactElement | null => {
	const [dynamicFooter] = useContext(DynamicFooterContext);

	if (!dynamicFooter) {
		return null;
	}

	return <>{dynamicFooter}</>;
};
