import {
	mergeWith,
	mergeRight,
	curry,
	prop,
	compose,
	evolve,
	pathOr,
	isNil,
	o,
	concat,
	path,
	hasPath,
} from 'ramda';
import { makeActionTypes, makeReducer, makeSimpleActionCreator } from 'redux-syringe';
import { normalize, denormalize } from 'normalizr';
import { isArray, isFunction, defaultToEmptyObject, isNotNil } from 'ramda-extension';
import { serializeFunction, typeEq, EMPTY_ARRAY } from '@ci/utils';
import invariant from 'invariant';
import { createSelector } from 'reselect';

export const SCOPE = '@core';

export const ActionTypes = makeActionTypes(SCOPE, [
	'STORE_ENTITIES',
	'STORE_RESULT',
	'STORE_SUBRESULT',
]);

// NOTE: This constant is used so we can use `isNotNil` invariant in `storeSubentities`.
const TOP_LEVEL_ENTITY = 'TOP_LEVEL_ENTITY';

const addSubresultPrefix = o(concat('[By ID] '), String);

export const storeResult = makeSimpleActionCreator(ActionTypes.STORE_RESULT);
export const storeSubresult = makeSimpleActionCreator(ActionTypes.STORE_SUBRESULT);

/**
 * @deprecated Use `createDataManager` instead.
 */
// eslint-disable-next-line max-params
export const storeSubentities = (schema, resultKeyOrActionCreator, parentId, entities) => {
	invariant(schema, 'You must specify a schema for entity normalization.');

	invariant(
		isNotNil(resultKeyOrActionCreator),
		'You must specify a result key for accessing appropriate entity subsets.'
	);

	invariant(isNotNil(parentId), 'You must specify a parent ID when storing subentities.');

	const key = isFunction(resultKeyOrActionCreator) ? 'storeResult' : 'resultKey';

	return {
		type: ActionTypes.STORE_ENTITIES,
		payload: {
			...normalize(entities, schema),
			[key]: resultKeyOrActionCreator,
			parentId: parentId === TOP_LEVEL_ENTITY ? null : parentId,
		},
	};
};

/**
 * @deprecated Use `createDataManager` instead.
 */
export const storeEntities = (schema, resultKeyOrActionCreator, entities) => {
	invariant(isArray(entities), 'Entities have to be array.');

	return storeSubentities(schema, resultKeyOrActionCreator, TOP_LEVEL_ENTITY, entities);
};

/**
 * @deprecated Use `createDataManager` instead.
 */
export const storeEntity = (schema, entity) => {
	invariant(
		!isArray(entity),
		'Youre trying to store array as entity, maybe you wanna use storeEntities?'
	);

	return {
		type: ActionTypes.STORE_ENTITIES,
		payload: normalize(entity, schema),
	};
};

const serializeStoreEntitiesAction = evolve({
	payload: {
		storeResult: serializeFunction,
	},
});

export const middleware =
	({ dispatch }) =>
	next =>
	action => {
		if (typeEq(ActionTypes.STORE_ENTITIES, action)) {
			next(serializeStoreEntitiesAction(action));

			const { result, resultKey, storeResult: customStoreResult, parentId } = action.payload;

			if (resultKey) {
				if (isNil(parentId)) {
					dispatch(storeResult({ key: resultKey, result }));
				} else {
					dispatch(storeSubresult({ key: addSubresultPrefix(resultKey), result, parentId }));
				}
			}

			if (customStoreResult) {
				if (isNil(parentId)) {
					dispatch(customStoreResult(result));
				} else {
					dispatch(customStoreResult(parentId, result));
				}
			}
		} else {
			next(action);
		}
	};

export const getAllEntities = pathOr({}, ['entities', 'data']);

/**
 * @deprecated Use `createDataManager` instead.
 */
export const getEntities = curry((name, state) =>
	compose(defaultToEmptyObject, prop(name), getAllEntities)(state)
);

/**
 * @deprecated Use `createDataManager` instead.
 */
export const getSimpleEntities = curry((definition, state) => getEntities(definition.key, state));

/**
 * @deprecated Use `createDataManager` instead.
 */
export const getEntity = curry((name, id, state) => prop(id, getEntities(name, state)));

/**
 * @deprecated Use `createDataManager` instead.
 */
export const getResult = curry((key, state) =>
	pathOr(EMPTY_ARRAY, ['entities', 'results', key], state)
);

/**
 * @deprecated Use `createDataManager` instead.
 */
export const isResultNotNil = curry((key, state) =>
	o(isNotNil, path(['entities', 'results', key]))(state)
);

/**
 * @deprecated Use `createDataManager` instead.
 */
export const getSubresult = curry((key, parentId, state) =>
	o(prop(parentId), getResult(addSubresultPrefix(key)))(state)
);

/**
 * Allows you to retrieve a denormalized subset of entities based on IDs stored in Redux.
 *
 * @param {string} schema entity schema from normalizr
 * @param {string|Function} keyOrGetIds either a key used in `storeEntities` or a result selector
 * @param {Object} state Redux state
 * @returns {Array} an array of entities matching the name and resolved IDs
 */
export const viewEntities = (...args) => {
	if (args.length === 1) {
		const [schema] = args;

		return (...innerArgs) => {
			if (innerArgs.length === 1) {
				const [keyOrGetIds] = innerArgs;

				return viewEntities(schema, keyOrGetIds);
			}

			const [keyOrGetIds, state] = innerArgs;

			return viewEntities(schema, keyOrGetIds, state);
		};
	}

	if (args.length === 2) {
		const [schema, keyOrGetIds] = args;

		return createSelector(
			[isFunction(keyOrGetIds) ? keyOrGetIds : getResult(keyOrGetIds), getAllEntities],
			(results, allEntities) => denormalize(results, schema, allEntities)
		);
	}

	if (args.length === 3) {
		const [schema, keyOrGetIds, state] = args;

		return viewEntities(schema)(keyOrGetIds)(state);
	}
};

/**
 * @deprecated Use `createDataManager` instead.
 */
// eslint-disable-next-line max-params
export const viewSubentities = curry((schema, keyOrGetIds, parentId, state) =>
	denormalize(
		isFunction(keyOrGetIds)
			? keyOrGetIds(parentId, state)
			: getSubresult(keyOrGetIds, parentId, state),
		schema,
		getAllEntities(state)
	)
);

/**
 * @deprecated Use `createDataManager` instead.
 */
export const selectIsResultStored = curry((resultKey, state) =>
	hasPath(['entities', 'results', resultKey], state)
);

export const initialState = {
	data: {},
	results: {},
};

/**
 * The first `mergeWith` merges entity types, the second `mergeWith` merges individual entities.
 * This is necessary because sometimes entities have different properties (detail/collection response).
 * We don't use a standard deep merge, because if an entity contains some collection, we DO NOT want to merge it.
 *
 * In this example, we fetch a collection of users, then we fetch a detail, but a comment is removed.
 * The `comments` array is empty as intended, but both `firstName` and `name` properties are preserved.
 *
 * @example
 * 		state   = { users: { A: { name: 'Adolf', comments: ['COMMENT'] } } }
 * 		payload = { users: { A: { firstName: 'Adolf', comments: [] } } }
 * 		result  = { users: { A: { name: 'Adolf', firstName: 'Adolf', comments: [] } } }
 *
 */
const mergeEntities = mergeWith(mergeWith(mergeRight));

export const normalizrReducer = makeReducer(
	[
		[
			ActionTypes.STORE_ENTITIES,
			(state, { payload: { entities } }) => ({
				...state,
				data: mergeEntities(state.data, entities),
			}),
		],
		[
			ActionTypes.STORE_RESULT,
			(state, { payload: { key, result } }) => ({
				...state,
				results: {
					...state.results,
					[key]: result,
				},
			}),
		],
		[
			ActionTypes.STORE_SUBRESULT,
			(state, { payload: { key, result, parentId } }) => ({
				...state,
				results: {
					...state.results,
					[key]: {
						...state.results[key],
						[parentId]: result,
					},
				},
			}),
		],
	],
	initialState
);
