import { makeActionTypes, makeConstantActionCreator } from 'redux-syringe';

export const ActionTypes = makeActionTypes('@myci/push-notifications', [
	'REGISTER_PUSH_NOTIFICATIONS',
	'UNREGISTER_PUSH_NOTIFICATIONS',
]);

export const registerPushNotifications = makeConstantActionCreator(
	ActionTypes.REGISTER_PUSH_NOTIFICATIONS
);

export const unregisterPushNotifications = makeConstantActionCreator(
	ActionTypes.UNREGISTER_PUSH_NOTIFICATIONS
);
