import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form as ReduxForm } from 'redux-form';
import { withMiddleware } from 'redux-syringe';
import { useSelector } from 'react-redux';
import { isRtl as getIsRtl } from '@myci/intl';

import { formMiddleware } from '../../duck';

const InvisibleSubmitButton = memo(() => {
	const isRtl = useSelector(getIsRtl);
	const leftPosition = isRtl ? '9999px' : '-9999px';

	return (
		<input
			type="submit"
			style={{ position: 'absolute', left: leftPosition, width: '1px', height: '1px' }}
			tabIndex="-1"
		/>
	);
});

InvisibleSubmitButton.displayName = 'InvisibleSubmitButton';

const Form = ({
	children,
	disableSubmitOnEnter,
	// eslint-disable-next-line  react/prop-types, @typescript-eslint/no-unused-vars
	namespace,
	// eslint-disable-next-line  react/prop-types, @typescript-eslint/no-unused-vars
	feature,
	...rest
}) => (
	<ReduxForm {...rest}>
		{children}
		{!disableSubmitOnEnter && <InvisibleSubmitButton />}
	</ReduxForm>
);

Form.propTypes = {
	children: PropTypes.node,
	/** If true there will be no invisible submit button added */
	disableSubmitOnEnter: PropTypes.bool,
};

export default withMiddleware({ formMiddleware }, { isGlobal: true })(Form);
