import { forEachObjIndexed } from 'ramda';

export const serializeQueryParams = params => {
	const queryParams = new URLSearchParams();
	forEachObjIndexed((value, key) => {
		if (value) {
			queryParams.append(key, value);
		}
	}, params);

	return queryParams.toString();
};
