import React, { ReactNode } from 'react';
import { Style, prepareStyle, useStyles } from '@ci/styles';
import { Text } from '@ci/atoms';

type ListItemContent = ReactNode;
type BorderVariant = 'top' | 'bottom' | 'both' | 'none';
interface ListItemStyleProps {
	borderVariant: BorderVariant;
}
export interface ListItemProps {
	borderVariant?: BorderVariant;
	customStyle?: Style;
	endContent?: ListItemContent;
	startContent?: ListItemContent;
}

const listItemStyle = prepareStyle<ListItemStyleProps>((utils, { borderVariant }) => ({
	display: 'flex',
	flexBasis: '100%',
	justifyContent: 'space-between',
	paddingBottom: utils.spacings.sm,
	paddingTop: utils.spacings.sm,
	extend: [
		{
			condition: borderVariant === 'bottom' || borderVariant === 'both',
			style: {
				borderBottomColor: utils.colors.gray400,
				borderBottomStyle: 'solid',
				borderBottomWidth: utils.borders.widths.sm,
			},
		},
		{
			condition: borderVariant === 'top' || borderVariant === 'both',
			style: {
				borderTopColor: utils.colors.gray400,
				borderTopStyle: 'solid',
				borderTopWidth: utils.borders.widths.sm,
			},
		},
	],
}));

export const ListItem = ({
	startContent,
	endContent,
	borderVariant = 'none',
	customStyle,
}: ListItemProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={applyStyle([listItemStyle, customStyle], { borderVariant })}>
			<Text weight="bold">{startContent}</Text>
			<Text>{endContent}</Text>
		</div>
	);
};
