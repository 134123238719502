import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import {
	ascend,
	compose,
	equals,
	filter,
	find,
	head,
	identity,
	includes,
	isNil,
	reject,
	sortWith,
	toLower,
	toPairs,
} from 'ramda';
import { stringOrNumberPropType, useGeneratedId } from '@ci/react-utils';
import {
	cx,
	defaultToEmptyArray,
	defaultToEmptyString,
	isNilOrEmpty,
	isNilOrEmptyString,
	mapIndexed,
	noop,
} from 'ramda-extension';
import { IdentityFormatter } from '@ci/formatters';

import Menu from '../Menu';
import TextInput from '../TextInput';
import MenuItem from '../MenuItem';
import {
	SelectStaticBehaviors,
	SelectStatics,
	getItemKey,
	getOriginalStaticKey,
	getSelectSortCharacter,
	isSelectStatic,
	isStaticKey,
	useStaticAwareFormatter,
} from '../../constants';
import useRenderedValues from '../../utils/useRenderedValues';

const defaultStatics = [];

const SelectControlAutocomplete = forwardRef((props, ref) => {
	const {
		className,
		disabled,
		// TODO: This lexicographic order logic should be made consistent between
		// `SelectControlAutocomplete` and `SelectControlStatic`.
		disableLexicographicOrder = false,
		formatter = IdentityFormatter,
		onChange,
		items,
		value,
		statics = defaultStatics,
		...otherProps
	} = props;

	const [showAllItems, setShowAllItems] = useState(false);
	const [defaultStatic, setDefaultStatic] = useState(null);

	const [inputValue, setRawInputValue] = useState('');
	const setInputValue = compose(setRawInputValue, String, defaultToEmptyString);

	const Formatter = useStaticAwareFormatter(formatter);
	const valueKey = getItemKey(value);
	const renderedValues = useRenderedValues(Formatter, items, statics);
	const generatedId = useGeneratedId();

	const handleTextInputFocus = event => {
		event.target.select();
		setShowAllItems(true);
	};

	// NOTE: Can't use `static` because it's a keyword.
	useEffect(() => statics.forEach(s => s.default && setDefaultStatic(s)), [statics]);

	useEffect(() => {
		if (isNilOrEmpty(value)) {
			onChange(defaultStatic);
		}
	}, [value, defaultStatic]);

	// NOTE: Necessary for setting initial input value. Acts as a delayed `componentDidMount`.
	useEffect(() => {
		if (isNilOrEmptyString(valueKey)) {
			setInputValue(renderedValues[getItemKey(defaultStatic)]);
		} else if (renderedValues[valueKey]) {
			setInputValue(renderedValues[valueKey]);
		}
	}, [renderedValues, value]);

	const renderedKey = compose(
		head,
		defaultToEmptyArray,
		find(([, value]) => equals(value, inputValue)),
		toPairs
	)(renderedValues);

	const handleBlurOrOuterClick = () => {
		if (isNilOrEmptyString(inputValue)) {
			// NOTE: Use default static if value is cleared.
			onChange(defaultStatic);
			setInputValue(renderedValues[getItemKey(defaultStatic)]);
		} else if (isNil(renderedKey)) {
			// NOTE: Use currently selected value if input value is not recognized.
			setInputValue(renderedValues[getItemKey(value)]);
		} else if (isStaticKey(renderedKey)) {
			// NOTE: If the user has typed in a valid static, use that.
			const originalKey = getOriginalStaticKey(renderedKey);
			const currentStatic = SelectStatics[originalKey];

			onChange(currentStatic);
			setInputValue(renderedValues[renderedKey]);
		} else {
			// NOTE: User has typed in a valid non-static item, use that.
			onChange(renderedKey);
			setInputValue(renderedValues[renderedKey]);
		}
	};

	const handleItemChange = item => {
		const key = getItemKey(item);
		const renderedValue = renderedValues[key];

		setInputValue(renderedValue);
	};

	const handleStateChange = ({ type, inputValue }) => {
		if (type === Downshift.stateChangeTypes.changeInput) {
			setInputValue(inputValue);
			setShowAllItems(false);
		}

		if (type === Downshift.stateChangeTypes.keyDownEnter) {
			setInputValue(renderedValues[inputValue]);
			onChange(inputValue);
		}

		if (type === Downshift.stateChangeTypes.blurInput) {
			handleBlurOrOuterClick();
		}
	};

	return (
		<Fragment>
			<Downshift
				// NOTE: This is just to suppress some downshift warnings (we use formatters!)
				// TODO: Investigate using `Formatter.formatAsPrimitive` here directly.
				itemToString={identity}
				inputValue={inputValue}
				onOuterClick={handleBlurOrOuterClick}
				onChange={handleItemChange}
				onStateChange={handleStateChange}
			>
				{({
					getInputProps,
					getMenuProps,
					highlightedIndex,
					getItemProps,
					inputValue,
					isOpen,
					toggleMenu,
				}) => {
					const handleTextInputClick = () => {
						toggleMenu();
						setShowAllItems(true);
					};

					const renderItems = compose(
						mapIndexed((item, index) => {
							const key = getItemKey(item);
							const renderedValue = renderedValues[key];

							const handleItemClick = () => {
								if (isSelectStatic(item) && SelectStaticBehaviors[key]) {
									onChange(SelectStaticBehaviors[key].onSelect(value));
								} else {
									onChange(item);
								}

								setInputValue(renderedValue);
								toggleMenu();
							};

							return (
								<MenuItem
									className={cx('', {
										active: highlightedIndex === index,
										selected: equals(value, item),
									})}
									{...getItemProps({ item, key, index })}
									onClick={handleItemClick}
								>
									<Formatter>{item}</Formatter>
								</MenuItem>
							);
						}),
						sortWith([
							ascend(getSelectSortCharacter),
							...(disableLexicographicOrder ? [] : [ascend(Formatter.formatAsPrimitive)]),
						]),
						filter(item => {
							if (item.hidden) {
								return false;
							}

							if (!inputValue || showAllItems) {
								return true;
							}

							const key = getItemKey(item);
							const renderedValue = renderedValues[key];

							return includes(toLower(inputValue), toLower(renderedValue));
						}),
						reject(isNil)
					);

					// TODO: For a11y, we should pass `getLabelProps()` to the label.
					// TODO: `getRootProps()` should probably be used somehow to avoid console warnings.
					return (
						<div ref={ref}>
							<TextInput
								{...getInputProps({
									...otherProps,
									disabled,
									hasFloatingLabel: false,
									className: cx({ 'form-control--active': isOpen }, className),
									value: inputValue,
									onBlur: noop,
									onClick: handleTextInputClick,
									onFocus: handleTextInputFocus,
									name: `select-${generatedId}`,
								})}
								// This needs to be set because browser is setting this to 20 by default
								// and flex shrink is not working
								size="1"
							/>
							{!disabled && isOpen && (
								<Menu {...getMenuProps()}>{renderItems([...statics, ...items])}</Menu>
							)}
						</div>
					);
				}}
			</Downshift>
			{/* HACK: This is to fix the input value not being translated when only a single item
					is present, resulting in the item being preselected without a fetched translation.
					`intl.formatMessage` itself is incapable of fetching translations. We have no other
					place to render a `<Message />` until the menu is opened. Prefetching messages is the
					best solution to this issue, but only applicable for lookups, not generic messages. */}
			<div style={{ display: 'none' }}>
				{[...statics, ...items].map(item => (
					<Formatter key={getItemKey(item)}>{item}</Formatter>
				))}
			</div>
		</Fragment>
	);
});

SelectControlAutocomplete.propTypes = {
	className: PropTypes.string,
	disableLexicographicOrder: PropTypes.bool,
	disabled: PropTypes.bool,
	formatter: PropTypes.elementType,
	items: PropTypes.arrayOf(stringOrNumberPropType).isRequired,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	statics: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.any,
};

SelectControlAutocomplete.displayName = 'SelectControlAutocomplete';

export default SelectControlAutocomplete;
