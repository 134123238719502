import React from 'react';
import PropTypes from 'prop-types';
import { times } from 'ramda';

import Grid from '../Grid';
import SkeletorInput from '../SkeletorInput';
import SkeletorHeading from '../SkeletorHeading';
import SkeletorFormFooter from '../SkeletorFormFooter';

const SkeletorForm = ({
	addFooter,
	addHeading,
	rowCount = 1,
	columnCount = 1,
	actionButtonCount = 1,
}) => (
	<Grid container>
		{addHeading && <SkeletorHeading />}
		<Grid row mb={3}>
			{times(
				key => (
					<Grid key={key} colFlex>
						{times(
							key => (
								<SkeletorInput key={key} mb={2} />
							),
							rowCount
						)}
					</Grid>
				),
				columnCount
			)}
		</Grid>
		{addFooter && <SkeletorFormFooter actionButtonCount={actionButtonCount} />}
	</Grid>
);

SkeletorForm.propTypes = {
	/** Count of buttons in the footer section. */
	actionButtonCount: PropTypes.number,
	/** If `true`, it generates footer. You can use `actionButtonCount` to specify number of buttons in the footer. */
	addFooter: PropTypes.bool,
	/** If `true`, it generates heading. */
	addHeading: PropTypes.bool,
	/** Count of form columns. */
	columnCount: PropTypes.number,
	/** Count of form rows. */
	rowCount: PropTypes.number,
};

export default SkeletorForm;
