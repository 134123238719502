import { composeMiddleware, composeReducers } from '@ci/utils';
import { makeRequestMiddleware } from './middleware';
import { downloadingReducer, makeDownloadingMiddleware } from './features/downloading';
import { controlFlowIntegrationMiddleware } from './features/controlFlow';
import { localRequestIdMiddleware } from './features/requestIds';
import { requestTimestampsReducer, timestampMiddleware } from './features/timestamps';
import { pendingRequestsReducer } from './features/pendingRequests';
import { pendingThunksReducer } from './features/thunks/pendingThunks';

export * from './actions';
export * from './utils';
export * from './features/filtering';
export { download, ActionTypes as DownloadingActionTypes } from './features/downloading';
export { promiseLikeRequestMiddleware, IGNORE_RESPONSE_ACTION } from './features/promiseLike';
export {
	isFetching,
	isFetchingBy,
	selectPendingRequests,
	selectPendingRequestsByType,
} from './features/pendingRequests';
export { selectIsThunkPending, selectIsThunkPendingBy } from './features/thunks/pendingThunks';
export { getTimestamp } from './features/timestamps';
export { createThunk } from './features/thunks/createThunk';
export type { Thunk, ThunkApi, ThunkFactory } from './features/thunks/types';
export {
	useThunkPolling,
	getIsThunkPoll,
	getIsThunkFirstPoll,
	selectIsThunkPollPending,
	selectIsThunkFirstPollPending,
	selectIsThunkNotPollPending,
} from './features/thunks/thunkPolling';

interface ApiMiddlewareConfig {
	baseUrl: string;
	credentials?: RequestCredentials;
	getDownloadedChunkResponseData: Function;
	/**
	 * Predicate indicating that the response is handled by some custom middleware and the default
	 * thunk toast mechanism shouldn't take place. Useful for field-level validations or toasts
	 * which should have some special structure, e.g. unexpected errors with track IDs.
	 */
	getHasGenericErrorHandling?: (response: any) => boolean;
	getParsedErrorPayload: Function;
	getParsedResponseMeta?: Function;
	selectHeaders: Function;
}

export const makeApiMiddleware = (config: ApiMiddlewareConfig) =>
	composeMiddleware(
		localRequestIdMiddleware,
		timestampMiddleware,
		makeRequestMiddleware(config as any),
		controlFlowIntegrationMiddleware,
		makeDownloadingMiddleware(config)
	);

export const apiReducer = composeReducers(
	downloadingReducer,
	requestTimestampsReducer,
	pendingRequestsReducer,
	pendingThunksReducer
);
