import { BreakpointMediaQueries } from './types';

export const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;

export const breakpointThresholds = {
	xs: '0px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1400px',
} as const;

export const breakpointMediaQueries: BreakpointMediaQueries = Object.keys(
	breakpointThresholds
).reduce<any>(
	(mediaQueries, breakpoint) => ({
		...mediaQueries,
		[breakpoint]: `@media (min-width: ${breakpointThresholds[breakpoint]})`,
	}),
	{}
);
