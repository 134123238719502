import { ReactNode } from 'react';
import { DefaultSlideModalFooter } from './DefaultSlideModalFooter';

import { DEFAULT_SLIDE_MODAL_SIZE, SlideModal, SlideModalProps } from './SlideModal';
import { SlideModalContent } from './SlideModalContent';
import { SlideModalFooter } from './SlideModalFooter';
import { SlideModalHeader } from './SlideModalHeader';
import { SlideModalInner } from './SlideModalInner';

export interface BasicSlideModalProps extends SlideModalProps {
	details?: ReactNode;
	footer?: ReactNode;
	subtitle?: ReactNode;
	title?: ReactNode;
}

export const BasicSlideModal = ({
	footer,
	children,
	title,
	subtitle,
	details,
	size = DEFAULT_SLIDE_MODAL_SIZE,
	onCloseButtonClick,
	...otherProps
}: BasicSlideModalProps) => (
	<SlideModal size={size} onCloseButtonClick={onCloseButtonClick} {...otherProps}>
		<SlideModalInner>
			{title && <SlideModalHeader title={title} subtitle={subtitle} details={details} />}
			<SlideModalContent isBeforeFooter={Boolean(footer)}>{children}</SlideModalContent>
			{footer ? (
				<SlideModalFooter>{footer}</SlideModalFooter>
			) : (
				<DefaultSlideModalFooter onCloseButtonClick={onCloseButtonClick} />
			)}
		</SlideModalInner>
	</SlideModal>
);
