import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

const Hidden = ({ isVisible, ...rest }) => <Box display={isVisible ? 'block' : 'none'} {...rest} />;

Hidden.propTypes = {
	isVisible: PropTypes.bool,
};

export default Hidden;
