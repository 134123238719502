import PropTypes from 'prop-types';
import { all, any, none } from 'ramda';

import { usePermissions } from '../hooks';
import { permissionsType } from '../types';

const Permissions = ({
	all: allPermissions,
	any: anyPermissions,
	none: nonePermissions,
	children,
}) => {
	const hasAnyPermissions = usePermissions(any, anyPermissions);
	const hasAllPermissions = usePermissions(all, allPermissions);
	const hasNonePermissions = usePermissions(none, nonePermissions);

	return hasAnyPermissions && hasAllPermissions && hasNonePermissions ? children : null;
};

Permissions.propTypes = {
	all: permissionsType,
	any: permissionsType,
	children: PropTypes.node,
	none: permissionsType,
};

export default Permissions;
