import { useCallback } from 'react';

import useCommittedRef from './useCommittedRef';

const useEventCallback = fn => {
	const ref = useCommittedRef(fn);

	return useCallback((...args) => ref.current && ref.current(...args), [ref]);
};

export default useEventCallback;
