import { createDataManager } from '@ci/entities';
import { createThunk, request } from '@ci/api';

interface BackOfficeUser {
	active: boolean;
	adminId: number;
	dateCreated: string;
	lastPasswordChange: string;
	lastSuccessfulLogin: string;
	name: string;
	passwordChangeRequired: boolean;
	receiveNotifications: boolean;
	status: string;
	type: string;
	username: string;
}

export const backOfficeUserManager = createDataManager<BackOfficeUser>('backOfficeUser');

export const fetchBoAccountData = createThunk(
	{
		originType: '@adminManagement/admins/fetchBoAccountData',
	},
	async ({ dispatch }) => {
		const responseAction = await dispatch(
			request({
				url: `/account`,
				method: 'GET',
			})
		);
		dispatch(backOfficeUserManager.clearAndInsert([responseAction.payload]));
	}
);
