import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import Radio from '../Radio';

import './Switch.scss';

const Switch = ({ className, startOption, endOption, name, value, onClick }) => (
	<Box className={cx(className, 'switch')}>
		<Radio
			label={startOption.label}
			name={`switch-${name}`}
			labelProps={{
				'data-label': startOption.label,
			}}
			checked={value === startOption.value}
			onChange={onClick}
		/>
		<Box
			className={cx('switch__button', { 'switch__button--checked': value === startOption.value })}
			onClick={onClick}
		/>
		<Radio
			label={endOption.label}
			name={`switch-${name}`}
			labelProps={{
				'data-label': endOption.label,
			}}
			checked={value === endOption.value}
			onChange={onClick}
		/>
	</Box>
);

const OptionShape = PropTypes.shape({ label: PropTypes.string, value: PropTypes.string });

Switch.propTypes = {
	className: PropTypes.string,
	endOption: OptionShape,
	name: PropTypes.string,
	onClick: PropTypes.func,
	startOption: OptionShape,
	value: PropTypes.string,
};

export default Switch;
