import { defineMessages } from 'react-intl';

export default defineMessages({
	walletFetchFailed: {
		id: 'userSettings.walletFetchFailed',
		defaultMessage: 'User wallet fetch failed.',
		description: 'When api call to /Wallet/Balance fails.',
	},
	transactionFetchFailed: {
		id: 'topUpBalanceFailed.transactionFetchFailed',
		defaultMessage: 'Wallet transactions fetch failed.',
		description: 'When api call to /Wallet/Transactions fails.',
	},
	topUpBalanceFailed: {
		id: 'topUpBalanceFailed.topUpBalanceFailed',
		defaultMessage: 'Failed to call Balance Top Balance API.',
		description: 'When api call to /Wallet/Funds fails.',
	},
	changeSettingsSuccess: {
		id: 'myciUserSettings.changeSettingsSuccess',
		defaultMessage: 'Your settings has been successfully changed.',
		description: 'Change success notification',
	},
	changeSettingsFail: {
		id: 'myciUserSettings.changeSettingsFail',
		defaultMessage: 'Settings change failed',
		description: 'Change fail notification',
	},
	changePasswordSuccess: {
		id: 'myciUserSettings.changePasswordSuccess',
		defaultMessage: 'Your password has been successfully changed.',
		description: 'Change success notification',
	},
	changePasswordFail: {
		id: 'myciUserSettings.changePasswordFail',
		defaultMessage: 'Password change failed',
		description: 'Change fail notification',
	},
	changeEmailSuccess: {
		id: 'myciUserSettings.changeEmailSuccess',
		defaultMessage: 'Your email has been successfully changed.',
		description: 'Change success notification',
	},
	changeEmailFail: {
		id: 'myciUserSettings.changeEmailFail',
		defaultMessage: 'Email change failed',
		description: 'Change fail notification',
	},
	changePhoneSuccess: {
		id: 'myciUserSettings.changePhoneSuccess',
		defaultMessage: 'Your phone number has been successfully changed.',
		description: 'Change phone number success notification',
	},
	changePhoneFail: {
		id: 'myciUserSettings.changePhoneFail',
		defaultMessage: 'Phone number change failed',
		description: 'Change phone number fail notification',
	},
	sendEmailCodeSuccess: {
		id: 'myciUserSettings.sendEmailCodeSuccess',
		defaultMessage: 'Verification code has been successfully sent to {email}.',
		description: 'Verification code success notification',
	},
	verifyEmailSuccess: {
		id: 'myciUserSettings.verifyEmailSuccess',
		defaultMessage: '{email} was successfully verified.',
		description: 'Change success notification',
	},
	sendPhoneCodeSuccess: {
		id: 'myciUserSettings.verifyPhoneSuccess',
		defaultMessage: 'Verification code has been successfully sent to {phone}.',
		description: 'Change phone number success notification',
	},
	verifyEmailFail: {
		id: 'myciUserSettings.verifyEmailFail',
		defaultMessage: 'Email verify failed',
		description: 'Change fail notification',
	},
	verifyPhoneFail: {
		id: 'myciUserSettings.verifyPhoneFail',
		defaultMessage: 'Phone number verify failed',
		description: 'Change phone number fail notification',
	},
	paymentSuccesful: {
		id: 'myciUserSettings.paymentSuccesful',
		defaultMessage: 'Payment successful',
		description: 'Payment success heading',
	},
	addedToBalance: {
		id: 'myciUserSettings.addedToBalance',
		defaultMessage: 'Funds has been added to your balance',
		description: 'Payment success text',
	},
	ok: {
		id: 'myciUserSettings.ok',
		defaultMessage: 'OK',
		description: 'Payment success button label',
	},
	paymentFailed: {
		id: 'myciUserSettings.paymentFailed',
		defaultMessage: 'Payment failed',
		description: 'Payment fail heading',
	},
	paymentFailedText: {
		id: 'myciUserSettings.paymentFailedText',
		defaultMessage: 'Your payment failed',
		description: 'Payment fail text',
	},
	privacyPolicyUpdateError: {
		id: 'myciUserSettings.privacyPolicyUpdateError',
		defaultMessage: 'Consent confirmation failed',
		description: 'Privacy Policy Change Agree error text',
	},
	verificationError_EmailNotUnique: {
		id: 'myciUserSettings.verificationError_EmailNotUnique',
		defaultMessage: 'This email is already in use.',
		description: 'Validation message for email already in use',
	},
	verificationError_PhoneNumberNotUnique: {
		id: 'myciUserSettings.verificationError_PhoneNumberNotUnique',
		defaultMessage: 'This phone number is already in use.',
		description: 'Validation message for phone already in use',
	},
	verificationError_UserNameNotUnique: {
		id: 'myciUserSettings.verificationError_UserNameNotUnique',
		defaultMessage: 'This username is already in use.',
		description: 'Validation message for email already in use',
	},
});
