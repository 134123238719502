import { reduce, map, sum, o, length } from 'ramda';
import { isArray } from 'ramda-extension';
import { downloadFile } from './downloadFile';

/**
 * Offers a Base64 encoded file for download to the user
 *
 * @param {string|string[]} base64Content Base64 string or array of Base64 chunks with the file data
 * @param {string} filename Filename to download the file with
 */
export const downloadBase64File = (base64Content, filename) => {
	try {
		const blob = new Blob([convertBase64ToArrayBuffer(base64Content)], {
			type: 'application/octet-stream',
		});
		downloadFile(blob, filename);
	} catch (error) {
		console.error(error);
	}
};

const convertBase64ToArrayBuffer = base64 => {
	const base64Chunks = isArray(base64) ? base64 : [base64];
	const binaryChunks = map(window.atob, base64Chunks);
	const binarySize = o(sum, map(length))(binaryChunks);
	const bytes = new Uint8Array(binarySize);

	reduce(
		(offset, chunk) => {
			const chunkSize = length(chunk);

			for (let charIndex = 0; charIndex < chunkSize; charIndex++) {
				bytes[offset + charIndex] = chunk.charCodeAt(charIndex);
			}

			return offset + chunkSize;
		},
		0,
		binaryChunks
	);

	return bytes.buffer;
};
