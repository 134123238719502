import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';

export const LayoutContext = createContext({});

export const LayoutContextProvider = ({ children, navigationModules }) => {
	const [footer, setFooter] = useState(null);
	const [showBack, setShowBack] = useState(false);
	const [breadcrumbs, setBreadcrumbs] = useState(null);

	const value = useMemo(
		() => ({
			breadcrumbs,
			setBreadcrumbs,
			footer,
			setFooter,
			showBack,
			setShowBack,
			navigationModules,
		}),
		[showBack, breadcrumbs, footer]
	);

	return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

LayoutContextProvider.propTypes = {
	children: PropTypes.node,
	navigationModules: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Route = ({ element }) => element;

export const useShowBackButton = showBackButton => {
	const { setShowBack } = useContext(LayoutContext);

	useEffect(() => {
		setShowBack(showBackButton);

		return () => setShowBack(false);
	}, [setShowBack]);
};

export const useLayoutBreadcrumbs = (Breadcrumbs = null, props) => {
	const { setBreadcrumbs } = useContext(LayoutContext);
	const { path, location } = props;

	invariant(path, 'Prop path is missing, did you pass props from router?');

	useEffect(() => {
		setBreadcrumbs(<Route path={path} element={<Breadcrumbs {...props} />} />);

		return () => setBreadcrumbs(null);
		// NOTE: we want to update breadcrumbs with new props on location change
	}, [setBreadcrumbs, location]);
};

export const useLayoutFooter = (Footer = null, props) => {
	const { setFooter } = useContext(LayoutContext);
	const { path } = props;

	invariant(path, 'Prop path is missing, did you pass props from router?');

	useEffect(() => {
		setFooter(<Route path={path} element={<Footer {...props} />} />);

		return () => setFooter(null);
	}, []);
};

export const useLayoutNavigationModules = () => {
	const { navigationModules } = useContext(LayoutContext);

	return navigationModules;
};
