import React from 'react';

import Button from '../Button';
import ClientLink from '../ClientLink';

const ButtonClientLink = props => <Button {...props} />;

ButtonClientLink.defaultProps = {
	LinkComponent: ClientLink,
};

export default ButtonClientLink;
