import React from 'react';
import { Match, navigate } from '@gatsbyjs/reach-router';

import PropTypes from 'prop-types';

import Tab from '../Tab';
import TabBar from '../TabBar';

const TabList = ({ labels, ...otherProps }) => (
	<TabBar {...otherProps}>
		{labels.map(({ name, path, ...rest }, i) => (
			<Match path={path} key={i}>
				{({ match }) => (
					<Tab
						key={i}
						label={name}
						isActive={Boolean(match)}
						onClick={e => {
							e.preventDefault();
							navigate(path);
						}}
						{...rest}
					/>
				)}
			</Match>
		))}
	</TabBar>
);

TabList.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TabList;
