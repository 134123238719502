import { makeActionTypes, makeSimpleActionCreator } from 'redux-syringe';
import { getIsSuccessResponseAction, request } from '@ci/api';
import { schema } from 'normalizr';
import { prop, toUpper } from 'ramda';
import { composeMiddleware, makeMiddleware, typeEq } from '@myci/utils';
import { storeEntities } from '@ci/entities';

import { SCOPE } from './constants';

export const ActionTypes = makeActionTypes(SCOPE, ['FETCH_ENTITY']);

export const fetchEntity = makeSimpleActionCreator(ActionTypes.FETCH_ENTITY);

const createSchema = ({ formValueProp, key, entityName }) => {
	const entitySchema = new schema.Entity(
		key,
		{},
		{
			idAttribute: prop(formValueProp),
		}
	);

	return {
		[entityName]: entitySchema,
		[`${entityName}_ARRAY`]: [entitySchema],
	};
};

const fetchEntityMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_ENTITY),
	({ dispatch }) =>
		action => {
			const { url } = action.payload;

			dispatch(request({ url }, { origin: action }));
		}
);

const fetchEntitySuccessMiddleware = makeMiddleware(
	getIsSuccessResponseAction(ActionTypes.FETCH_ENTITY),
	({ dispatch }) =>
		action => {
			const { formValueProp, key = '' } = action.meta.origin.payload;
			const entityName = toUpper(key);
			const Schemas = createSchema({ key, entityName, formValueProp });

			return dispatch(
				storeEntities(
					Schemas[`${entityName}_ARRAY`],
					`SimpleEntitySelectField_${entityName}`,
					action.payload
				)
			);
		}
);

export const entitySelectMiddleware = composeMiddleware(
	fetchEntityMiddleware,
	fetchEntitySuccessMiddleware
);
