import { adjust, identity, is, map, o, prop, splitEvery } from 'ramda';

const getDataListRow = (props, wrap = identity) =>
	o(
		adjust(0, wrap),
		adjust(1, nameOrFunc => (is(String, nameOrFunc) ? prop(nameOrFunc, props) : nameOrFunc(props)))
	);

export const getDataListRows = (props, data, wrap = identity) =>
	o(splitEvery(2), map(getDataListRow(props, wrap)))(data);
