import { downloadFile } from '@ci/utils';

export const downloadBase64File = async (base64Content, filename) => {
	try {
		const blob = new Blob([convertBase64ToArrayBuffer(base64Content)], {
			type: 'application/octet-stream',
		});
		downloadFile(blob, filename);
		return true;
	} catch (e) {
		console.error(e);
		return false;
	}
};

const convertBase64ToArrayBuffer = base64 => {
	const binaryString = window.atob(base64);
	const length = binaryString.length;
	const bytes = new Uint8Array(length);
	for (let i = 0; i < length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes.buffer;
};
