/* eslint-disable camelcase */
// eslint-disable-next-line import/no-extraneous-dependencies, no-restricted-imports
import { USERS_DICT, USER_TYPE } from '@myci/domain-subscriptions/src/constants';
import { addToast } from '@ci/toasts';
import {
	InstanceTypes,
	composeMiddleware,
	currentInstanceType,
	getEnvironmentVariable,
	isInBrowser,
	makeMiddleware,
	typeEq,
} from '@myci/utils';
import { createThunk, isFetching, request, selectIsThunkPending } from '@ci/api';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { navigate } from '@myci/navigation';
import { reset } from 'redux-form';
import { run } from '@ci/control-flow';
import { startSubmitForm, stopSubmitForm } from '@myci/ui-components-redux';
import { Lookups } from '@myci/domain-lookups';
import { selectLookupNormalizations } from '@ci/lookups';
import { makeActionTypes, makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';
import { equals, last, mergeDeepLeft, o, path, values } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { logOut, setAccessToken } from '@myci/authentication';

import { FREE_PLAN_CODE } from '../constants';
import m from '../messages';

interface AccountState {
	accountEndingDate?: string;
	accountStatus?: string;
	accountType?: string;
	accountTypeLocalized?: string;
	areaName?: string;
	attachment1?: string;
	attachment2?: string;
	attachment3?: string;
	attachment4?: string;
	attachment5?: string;
	city?: string;
	companyType?: string;
	confirmPassword?: string;
	country?: number;
	courtCode?: string;
	culture?: string;
	dateOfBirth?: string;
	district?: string;
	email?: string;
	freeReports?: number;
	idCardScan?: string;
	idCardScan_backSide?: string;
	idCardScan_frontSide?: string;
	idCardScan_selfie?: string;
	idCardScan_sideB?: string;
	messagingChannel?: string;
	name?: string;
	nationalId?: string;
	needsToChangePassword?: boolean;
	numberOfFlat?: string;
	password?: string;
	paymentWaiting?: boolean;
	phoneNumber?: string;
	placeOfBirth?: string;
	plan?: string;
	postalCode?: string;
	privacyPolicyChanged: boolean;
	privacyPolicyVersion: string;
	province?: string;
	reasonOfRegistration?: number;
	region?: string;
	registrationNumber?: string;
	registrationNumberFirstPart?: string;
	street?: string;
	streetNumber?: string;
	userName?: string;
	userType?: string;
}

interface GlobalState {
	account: {
		settings: {
			account: AccountState;
		};
	};
}

const ActionTypes = makeActionTypes('@account', [
	'CHANGE_PERSONAL_INFORMATION',
	'CHANGE_NOTIFICATIONS_SETTINGS',
	'FETCH_USER_ACCOUNT',
	'SET_INITIALIZED',
	'SET_USER_ACCOUNT',
	'SET_ACCOUNT_STATE',
]);

export const setAccountState = createAction<{ needsToChangePassword: boolean }>(
	'@account/setAccountState'
);
export const setUserAccount = createAction<{ result: AccountState }>('@account/setUserAccount');
export const setIntialized = createAction<boolean>('@account/setIntialized');
export const setHasShownPrivacyPolicyChangedModal = createAction<boolean>(
	'@account/hasShownPrivacyPolicyChangedModal'
);

export const fetchUserAccount = makeConstantActionCreator(ActionTypes.FETCH_USER_ACCOUNT);
export const changePersonalInformation = makeSimpleActionCreator(
	ActionTypes.CHANGE_PERSONAL_INFORMATION
);
export const changeNotificationsSettings = makeSimpleActionCreator(
	ActionTypes.CHANGE_NOTIFICATIONS_SETTINGS
);

const locale = getEnvironmentVariable('GATSBY_DEFAULT_LOCALE');

const initialState = {
	initialized: false,
	hasShownPrivacyPolicyChangedModal: false,
	settings: {
		account: {
			accountEndingDate: 'MISSING-FROM-API',
			postalCode: 'MISSING-FROM-API',
		},
		languageSettings: {
			culture: locale,
		},
		permissions: null,
		personalInfo: null,
		userSettings: null,
	},
};

export const acceptUpdatedPrivacyPolicy = createThunk(
	{
		originType: '@account/acceptUpdatedPrivacyPolicy',
		errorMessage: m.privacyPolicyUpdateError,
	},
	async ({ dispatch }, privacyPolicyVersion: string) => {
		await dispatch(
			request({ url: 'account/PrivacyPolicy', method: 'post', body: { privacyPolicyVersion } })
		);
	}
);
export const selectHasShownPrivacyPolicyChangedModal = state =>
	state.account?.hasShownPrivacyPolicyChangedModal;
export const selectIsAcceptingPrivacyPolicy = selectIsThunkPending(acceptUpdatedPrivacyPolicy);

const getSlice = path(['account']);

export const selectIsFetchingUserAccount = isFetching(ActionTypes.FETCH_USER_ACCOUNT);
export const selectIsUserAccountInitialized = o(path(['initialized']), getSlice);

export const selectIsChangingPersonalInfo = isFetching(ActionTypes.CHANGE_PERSONAL_INFORMATION);

export const selectIsPasswordChangeNecessary = o(
	path(['settings', 'account', 'needsToChangePassword']),
	getSlice
);

export const selectIsPrivacyPolicyChanged = (state: GlobalState) =>
	state.account?.settings?.account?.privacyPolicyChanged;

export const getAccount = (state: GlobalState) => o(path(['settings', 'account']), getSlice)(state);
export const getAccountStatus = o(path(['settings', 'account', 'accountStatus']), getSlice);
export const getUser = o(path(['settings', 'account', 'name']), getSlice);
export const getUserType = (state: GlobalState) => state.account?.settings?.account?.userType;
export const getUsername = o(path(['settings', 'account', 'userName']), getSlice);
export const getUserPlan = o(path<string>(['settings', 'account', 'plan']), getSlice);
export const getFreeReports = o(path(['settings', 'account', 'freeReports']), getSlice);
export const getSubscriberId = o(path(['settings', 'subscriberInfo', 'subscriberId']), getSlice);
export const getPermissions = o(path(['settings', 'permissions']), getSlice);
export const getUserCulture = o(path(['settings', 'languageSettings', 'culture']), getSlice);

export const selectHasFreePlan = state => {
	const userPlan = getUserPlan(state);

	return userPlan ? userPlan.includes(FREE_PLAN_CODE) : true;
};

export const selectIsPlanUpgradeable = state => {
	const userType = getUserType(state);
	const userPlan = getUserPlan(state);
	if (userType && userPlan) {
		if (userPlan.includes(FREE_PLAN_CODE)) {
			return true;
		}

		const currentUserPricingPlans = equals(userType, USERS_DICT[USER_TYPE.Individual])
			? selectLookupNormalizations(Lookups.INDIVIDUAL_PRICING_PLAN)(state)
			: selectLookupNormalizations(Lookups.COMPANY_PRICING_PLAN)(state);
		const planKeys = values(currentUserPricingPlans.byValue);
		const sortedPlanKeys = planKeys.sort((a, b) => Number(a) - Number(b));
		const currentPlanKey = currentUserPricingPlans.byValue[userPlan] ?? 0;

		const lastSortedPlanKey = last(sortedPlanKeys);
		return lastSortedPlanKey ? Number(currentPlanKey) < lastSortedPlanKey : false;
	}

	return false;
};

const fetchMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_USER_ACCOUNT),
	({ dispatch }) =>
		action => {
			dispatch(
				run(
					() => request({ url: '/account' }, { origin: action, ...action.meta }),
					({ payload }, { isError }) => {
						if (isError) {
							return;
						}
						dispatch(setUserAccount(payload));
					}
				)
			);
		}
);

const fetchUserAccountMiddleware = makeMiddleware(
	typeEq(setAccessToken.type),
	({ dispatch }) =>
		action => {
			const inBrowser = isInBrowser();

			if (
				(currentInstanceType === InstanceTypes.INSTANCE_FO || !inBrowser) &&
				isNotNil(action.payload)
			) {
				dispatch(fetchUserAccount());
			}
		}
);

const changePersonalInformationMiddleware = makeMiddleware(
	typeEq(ActionTypes.CHANGE_PERSONAL_INFORMATION),
	({ dispatch }) =>
		action => {
			const { form, onSuccess, ...body } = action.payload;

			dispatch(startSubmitForm(form));

			dispatch(
				run(
					() => request({ url: '/account', method: 'POST', body }, { origin: action, form }),
					(action, { isError }) => {
						dispatch(stopSubmitForm(action));

						if (isError) {
							dispatch(
								addToast({
									type: 'warning',
									content: { ...m.changeSettingsFail, defaultMessage: action?.payload },
								})
							);
						} else {
							dispatch(addToast({ content: m.changeSettingsSuccess, type: 'success' }));
							dispatch(reset(form));
							dispatch(fetchUserAccount());

							if (onSuccess) {
								onSuccess();
							}

							navigate('../settings');
						}
					}
				)
			);
		}
);

const changeNotificationsSettingsMiddleware = makeMiddleware(
	typeEq(ActionTypes.CHANGE_NOTIFICATIONS_SETTINGS),
	({ dispatch }) =>
		action => {
			const { form, ...body } = action.payload;

			dispatch(startSubmitForm(form));

			dispatch(
				run(
					() => request({ url: '/account', method: 'POST', body }, { origin: action, form }),
					(action, { isError }) => {
						dispatch(stopSubmitForm(action));

						if (isError) {
							dispatch(
								addToast({
									type: 'warning',
									content: { ...m.changeSettingsFail, defaultMessage: action?.payload },
								})
							);
						} else {
							dispatch(addToast({ content: m.changeSettingsSuccess, type: 'success' }));
							dispatch(fetchUserAccount());
						}
					}
				)
			);
		}
);

export const accountMiddleware = composeMiddleware(
	changePersonalInformationMiddleware,
	changeNotificationsSettingsMiddleware,
	fetchMiddleware,
	fetchUserAccountMiddleware
);

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(setHasShownPrivacyPolicyChangedModal, (state, action) => ({
			...state,
			hasShownPrivacyPolicyChangedModal: action.payload,
		}))
		.addCase(setAccountState, (state, action) => ({
			...state,
			settings: {
				...state.settings,
				account: {
					...state.settings.account,
					needsToChangePassword: action.payload.needsToChangePassword,
				},
			},
		}))
		.addCase(setIntialized, (state, action) => ({
			...state,
			initialized: action.payload,
		}))
		.addCase(setUserAccount, (state, action) =>
			mergeDeepLeft(
				{
					settings: {
						...action.payload.result,
					},
					initialized: true,
				},
				state
			)
		)
		.addCase(logOut.originType, () => initialState);
});
