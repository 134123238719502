// NOTE: Make sure to include the file extension in locale data dynamic imports.
// Our ContextReplacementPlugins require it in their RegExp.
import { getLanguageByLocale, isInBrowser } from '@myci/utils';
import moment from 'moment';
import { identity, toLower } from 'ramda';

const importMomentData = localeOrLanguageCode =>
	localeOrLanguageCode === 'en'
		? Promise.resolve()
		: import(
				/* webpackChunkName: "moment-data-[request]-[index]" */
				/* webpackMode: "lazy" */
				`../data/moment/${localeOrLanguageCode}.js`
		  );

const importIntlLocaleData = locale =>
	import(
		/* webpackChunkName: "intl-data-[request]-[index]" */
		/* webpackMode: "lazy" */
		`intl/locale-data/jsonp/${locale}.js`
	);

const importIntlLocale = locale =>
	!isInBrowser() || typeof window.IntlPolyfill === 'undefined'
		? Promise.resolve()
		: importIntlLocaleData(locale);

const importIntlPluralRulesPolyfill = locale => {
	if (Intl.PluralRules) {
		return Promise.resolve();
	}

	return import('@formatjs/intl-pluralrules/polyfill').then(() =>
		import(
			/* webpackChunkName: "react-intl-pluralrules-data-[request]-[index]" */
			/* webpackMode: "lazy" */
			`@formatjs/intl-pluralrules/locale-data/${getLanguageByLocale(locale)}.js`
		)
	);
};

const importIntlRelativeTimeFormatPolyfill = locale => {
	if (Intl.RelativeTimeFormat) {
		return Promise.resolve();
	}

	return import('@formatjs/intl-relativetimeformat/polyfill').then(() =>
		import(
			/* webpackChunkName: "react-intl-relativetimeformat-data-[request]-[index]" */
			/* webpackMode: "lazy" */
			`@formatjs/intl-relativetimeformat/locale-data/${getLanguageByLocale(locale)}.js`
		)
	);
};

const importIntlLocalePolyfill = () => {
	if (Intl.Locale) {
		return Promise.resolve();
	}

	return import('@formatjs/intl-locale/polyfill');
};

const importReactIntlLocale = locale =>
	importIntlLocalePolyfill().then(() =>
		Promise.all([
			importIntlPluralRulesPolyfill(locale),
			importIntlRelativeTimeFormatPolyfill(locale),
		])
	);

const setMomentLocale = locale => {
	if (locale.startsWith('ar')) {
		// NOTE: The default `postformat` in Arabic locales converts Western Arabic numerals to
		// Eastern Arabic numerals. Passing the `identity` function disables this behavior.
		// https://en.wikipedia.org/wiki/Arabic_numerals#Comparison_of_different_numerals
		moment.updateLocale(locale, { postformat: identity });
	}
	moment.locale(locale);
};

const importAndSetLocaleOrLanguageCodeToMoment = localeOrLanguageCode =>
	importMomentData(localeOrLanguageCode).then(() => setMomentLocale(localeOrLanguageCode));

/**
 * Moment uses both language codes "en", "cs" and  locales "en-gb".
 * We try to load locale first with fallback to language code.
 */
const importMomentLocale = locale =>
	importAndSetLocaleOrLanguageCodeToMoment(toLower(locale)).catch(() =>
		importAndSetLocaleOrLanguageCodeToMoment(getLanguageByLocale(locale))
	);

/**
 * Imports needed data for IntlPolyfill and React-intl libraries by the passed locale.
 *
 * @param {string} locale - must be always locale string not just locale code
 * @return {Promise}
 */
export const polyfillLocale = locale =>
	locale && isInBrowser()
		? importIntlLocale(locale)
				.then(() => importReactIntlLocale(locale))
				.then(() => importMomentLocale(locale))
		: Promise.resolve();
