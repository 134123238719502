export const getBase64OfFile = (file, callback) => {
	const fr = new FileReader();
	fr.addEventListener('load', () => {
		if (typeof callback === 'function') {
			callback(fr.result.split('base64,')[1]);
		}
	});

	fr.readAsDataURL(file);
};
