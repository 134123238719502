import React from 'react';
import PropTypes from 'prop-types';
import { always, cond, equals, is, range } from 'ramda';
import { Message } from '@myci/intl';

import Select from '../Select';
import Grid from '../Grid';
import FormGroup from '../FormGroup';
import m from '../../messages';

const Fragments = {
	YEAR: 'YEAR',
	MONTH: 'MONTH',
	QUARTAL: 'QUARTAL',
};

const getDaysCountByMonth = (year, month) => new Date(year, month, 0).getDate();

const getReduxStructureValue = (inputValue, reduxValue, fragment) => {
	const startDate = reduxValue ? reduxValue.startDate : '';
	const dateArr = is(Number, inputValue) ? startDate.split('-') : [];

	const getValues = cond([
		[
			equals(Fragments.YEAR),
			always({
				year: inputValue,
				monthStart: dateArr[1],
				monthEnd: dateArr[1],
			}),
		],
		[
			equals(Fragments.MONTH),
			always({
				year: dateArr[0],
				monthStart: inputValue,
				monthEnd: inputValue,
			}),
		],
		[
			equals(Fragments.QUARTAL),
			always({
				year: dateArr[0],
				monthStart: inputValue * 3 - 2,
				monthEnd: inputValue * 3,
			}),
		],
	]);

	const { year, monthStart, monthEnd } = getValues(fragment);

	const data = {
		startDate: `${year || ''}-${monthStart || ''}-01`,
		endDate: `${year}-${monthEnd}-${getDaysCountByMonth(year, monthEnd)}`,
	};

	return data;
};

const getInputValueFromRedux = (reduxValue, fragment) => {
	const startDate = reduxValue ? reduxValue.startDate : '';
	const dateArr = is(String, startDate) ? startDate.split('-') : [];

	const getValues = cond([
		[equals(Fragments.YEAR), always(dateArr[0])],
		[equals(Fragments.MONTH), always(dateArr[1])],
		[equals(Fragments.QUARTAL), always(dateArr[1] ? (parseInt(dateArr[1]) + 2) / 3 : '')],
	]);

	return getValues(fragment);
};

const YearFragmentSelect = ({
	yearStart = 1950,
	yearEnd = new Date().getFullYear(),
	onChange,
	value,
	quartal,
	...props
}) => (
	<FormGroup>
		<Grid row flexDirection={['column', '', '', 'row']}>
			<Grid col="6">
				<Select
					label={<Message {...m.year} />}
					name="year"
					items={range(yearStart, yearEnd + 1)}
					onChange={year => onChange(getReduxStructureValue(year, value, Fragments.YEAR))}
					value={getInputValueFromRedux(value, Fragments.YEAR)}
					{...props}
				/>
			</Grid>
			<Grid col="6">
				{quartal ? (
					<Select
						label={<Message {...m.quartal} />}
						name="quartal"
						items={range(1, 5)}
						onChange={period => onChange(getReduxStructureValue(period, value, Fragments.QUARTAL))}
						value={getInputValueFromRedux(value, Fragments.QUARTAL)}
						{...props}
					/>
				) : (
					<Select
						label={<Message {...m.month} />}
						name="month"
						items={range(1, 13)}
						onChange={month => onChange(getReduxStructureValue(month, value, Fragments.MONTH))}
						value={getInputValueFromRedux(value, Fragments.MONTH)}
						{...props}
					/>
				)}
			</Grid>
		</Grid>
	</FormGroup>
);

YearFragmentSelect.propTypes = {
	/** On change function */
	onChange: PropTypes.func,
	/** Quartal instead months */
	quartal: PropTypes.bool,
	/** Select data */
	value: PropTypes.object,
	/** Select end year */
	yearEnd: PropTypes.number,
	/** Select start year */
	yearStart: PropTypes.number,
};

export default YearFragmentSelect;
