import { clamp, compose, filter, head, keys, map, o, path, useWith } from 'ramda';
import { groupByProp } from 'ramda-extension';
import { isNotNilNorEmpty, pickValues, safeIncludes, unlessIsNil } from '@myci/utils';
import { getNamespaceByAction, getStateByFeatureAndNamespace } from 'redux-syringe';
import { getFormMeta, hasSubmitFailed } from 'redux-form';

import { getAllFormErrors } from '../forms';
import { FEATURE } from './constants';

const getPayload = path(['payload']);

export const getCurrentStep = path(['currentStep']);
export const getVisitedSteps = path(['visitedSteps']);
export const isInitialized = path(['isInitialized']);
export const getRegisteredFields = path(['registeredFields']);
export const getNumberOfSteps = path(['numberOfSteps']);
export const getRegisteredForms = path(['registeredForms']);
export const getShouldFocusFirstInvalidField = path(['shouldFocusFirstInvalidField']);

export const getTouchedFields = namespace =>
	compose(keys, filter(o(Boolean, path(['visited']))), getFormMeta(namespace));

const groupByStep = groupByProp('step');
const getDirtyStepsByDirtyFields = compose(map(Number), keys, groupByStep);

export const getDirtyFields = namespace => state => {
	const slice = getStateByFeatureAndNamespace(FEATURE, namespace, state);

	const registeredFields = getRegisteredFields(slice);
	const touchedFields = getTouchedFields(namespace)(state);

	return pickValues(touchedFields, registeredFields);
};

export const getDirtySteps = namespace => state => {
	const submitFailed = hasSubmitFailed(namespace)(state);
	if (!submitFailed) {
		return [];
	}

	// https://www.youtube.com/watch?v=C4wBLUBa8YI
	const dirtyFields = getDirtyFields(namespace)(state);

	return getDirtyStepsByDirtyFields(dirtyFields);
};

export const getErrorsByStep = namespace => state => {
	const slice = getStateByFeatureAndNamespace(FEATURE, namespace, state);

	const registeredFields = getRegisteredFields(slice);
	const invalidFields = keys(getAllFormErrors(namespace)(state));

	const foundInvalidFieldsOnStep = pickValues(invalidFields, registeredFields);

	return groupByStep(foundInvalidFieldsOnStep);
};

const getFirstInvalid = compose(unlessIsNil(Number), head, keys, filter(isNotNilNorEmpty));

export const getJourneyFirstInvalidStep = namespace =>
	o(getFirstInvalid, getErrorsByStep(namespace));

export const getHasJourneyStepErrors = namespace => state => {
	const errorsByStep = getErrorsByStep(namespace)(state);

	return step => isNotNilNorEmpty(errorsByStep[step]);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const areAnyFormsRegisteredForCurrentStep = useWith(safeIncludes, [
	getCurrentStep,
	getRegisteredForms,
]);

export const getCurrentStepByAction = (action, state) => {
	const slice = getStateByFeatureAndNamespace(FEATURE, getNamespaceByAction(action), state);

	return getCurrentStep(slice);
};

export const getNumberOfStepsByAction = (action, state) => {
	const slice = getStateByFeatureAndNamespace(FEATURE, getNamespaceByAction(action), state);

	return getNumberOfSteps(slice);
};

export const getNextStepByAction = (action, state) => {
	const currentStep = getCurrentStepByAction(action, state);
	const numberOfSteps = getNumberOfStepsByAction(action, state);

	return currentStep < numberOfSteps ? currentStep + 1 : currentStep;
};

export const getPreviousStepByAction = (action, state) => {
	const currentStep = getCurrentStepByAction(action, state);

	return currentStep > 1 ? currentStep - 1 : currentStep;
};

export const clampStepByAction = (action, state) => {
	const numberOfSteps = getNumberOfStepsByAction(action, state);
	const newStep = getPayload(action);

	return clamp(1, numberOfSteps)(newStep);
};

export const isFinalStepByAction = (action, state) => {
	const currentStep = getCurrentStepByAction(action, state);
	const numberOfSteps = getNumberOfStepsByAction(action, state);

	return currentStep === numberOfSteps;
};
