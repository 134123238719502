import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Heading from '../Heading';

/**
 * Layout component - represents heading of the Section.
 * @see Section
 */
const SectionHeading = ({ children, className, ...rest }) => (
	<Heading as="h3" weight="extra-bold" className={cx('section__heading', className)} {...rest}>
		{children}
	</Heading>
);

SectionHeading.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
};

export default SectionHeading;
