"use strict";

exports.__esModule = true;
exports.changeLocale = void 0;

var _reachRouter = require("@gatsbyjs/reach-router");

var _ramda = require("ramda");

var _utils = require("@myci/utils");

var _utils2 = require("./utils");

var removeLocalePart = function removeLocalePart(pathname) {
  return pathname.substring(pathname.indexOf('/', 1));
};

var getPathname = function getPathname(routed) {
  return (0, _ramda.o)(!routed ? _ramda.identity : removeLocalePart, _utils2.removePrefix);
};
/**
 * A function with side-effect that replaces your locale imperatively.
 *
 * Not at all pure.
 *
 * @param options.locale
 * @param options.to
 *
 * @sig { locale: String, to: String } -> ()
 *
 * @example
 *
 * changeLocale({ locale, to  })
 */


var changeLocale = function changeLocale(_ref) {
  var locale = _ref.locale,
      to = _ref.to;

  if ((0, _utils.isInNodeEnvironment)()) {
    return;
  }

  var _window = window,
      _window$___gatsbyIntl = _window.___gatsbyIntl,
      defaultLocale = _window$___gatsbyIntl.defaultLocale,
      routed = _window$___gatsbyIntl.routed,
      _window$location = _window.location,
      pathnameLocation = _window$location.pathname,
      search = _window$location.search;

  if (to && (0, _utils2.isAddressProbablyInvalid)(to)) {
    console.warn('[gatsby-plugin-intl] You have used the Link that adds locale into URLs. But you used relative Link instead. This component is really meant only for the absolute paths.');
  }

  var pathname = to || getPathname(routed)(pathnameLocation);
  var localePath = locale === defaultLocale ? '' : "" + (0, _utils2.toUrlLocale)(locale);
  var resolvedTo = ("/" + localePath + pathname + search).replace('//', '/');
  (0, _reachRouter.navigate)(resolvedTo);
};

exports.changeLocale = changeLocale;