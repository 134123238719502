import { ReactNode, useLayoutEffect, useState } from 'react';
import { FontWeight, useStyles } from '@creditinfo-ui/styles';

interface FontFaceLoaderProps {
	children: ReactNode;
	fontFamily?: string;
	fontWeights: FontWeight[];
}

declare global {
	interface Document {
		fonts?: {
			load(font: string): Promise<void>;
		};
	}
}

// https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet/load
const isFontLoadingSupported = typeof document !== 'undefined' && document.fonts;

const getPreferredFontFamily = (fontFamilyList: string) => fontFamilyList.split(',')[0];

export const FontFaceLoader = ({
	fontFamily: fontFamilyProp,
	fontWeights,
	children,
}: FontFaceLoaderProps) => {
	const { utils } = useStyles();
	const fontFamily = fontFamilyProp ?? getPreferredFontFamily(utils.fontFamilies.base);
	const [areFontFacesLoaded, setAreFontFacesLoaded] = useState(!isFontLoadingSupported);

	useLayoutEffect(() => {
		const loadFontFaces = async () => {
			if (!areFontFacesLoaded && isFontLoadingSupported) {
				try {
					await Promise.all(
						fontWeights.map(fontWeight => {
							const font = `${utils.fontWeights[fontWeight]} 1rem ${fontFamily}`;

							return document.fonts!.load(font);
						})
					);
				} finally {
					setAreFontFacesLoaded(true);
				}
			}
		};

		loadFontFaces();
	}, [fontFamily, fontWeights, utils, areFontFacesLoaded]);

	if (!areFontFacesLoaded) {
		return null;
	}

	return <>{children}</>;
};
