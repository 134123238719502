import React, { useEffect, useState } from 'react';
import { always, cond, includes } from 'ramda';
import { cx, isNilOrEmptyString } from 'ramda-extension';
import { getBase64DataUrl } from '@myci/utils';
import { Box } from '@myci/ui-components';

import { Dropzone } from '../Dropzone';

import { getBase64OfFile } from './utils';

const isPdfMimeType = includes('application/pdf');

const getDataUrlExtension: any = cond([
	[isPdfMimeType, always('.pdf')],
	[includes('image/jpeg'), always('.jpg')],
	[includes('image/png'), always('.png')],
]);

export interface FileInputControlProps {
	acceptedExtensions: string[];
	className?: string;
	defaultFileName: string;
	onChange: (data: string) => void;
	value: string;
}

export const FileInputControl = ({
	acceptedExtensions,
	className,
	defaultFileName,
	value,
	onChange,
}: FileInputControlProps) => {
	const [fileName, setFileName] = useState('');

	useEffect(() => {
		if (isNilOrEmptyString(fileName)) {
			setFileName(`${defaultFileName}${getDataUrlExtension(value)}`);
		}
	}, [value]);

	const handleDropAccepted = files => {
		const file = files[0];
		const { name, type } = file;
		setFileName(name);

		getBase64OfFile(file, base64 => {
			onChange(getBase64DataUrl(base64, type));
		});
	};

	return (
		<Box className={cx('file-input', className)}>
			<Dropzone
				acceptedExtensions={acceptedExtensions}
				fileName={fileName}
				onDropAccepted={handleDropAccepted}
				value={value}
			/>
		</Box>
	);
};
