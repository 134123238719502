import { useEffect } from 'react';
import { callIfFunction } from 'ramda-extension';

import useEventCallback from './useEventCallback';

/**
 * Attaches an event handler outside directly to specified DOM element
 * bypassing the react synthetic event system.
 *
 * @param element The target to listen for events on
 * @param event The DOM event name
 * @param handler An event handler
 * @param capture Whether or not to listen during the capture event phase
 */
const useEventListener = (eventTarget, event, listener, capture = false) => {
	const handler = useEventCallback(listener);

	useEffect(() => {
		const target = callIfFunction(eventTarget);

		target.addEventListener(event, handler, capture);

		return () => target.removeEventListener(event, handler, capture);
	}, [capture, event, eventTarget, handler]);
};

export default useEventListener;
