import { Message } from '@creditinfo-ui/messages';
import { Button } from '@creditinfo-ui/atoms';

import { SlideModalFooter } from './SlideModalFooter';
import { m } from '../../messages';

interface DefaultSlideModalFooterProps {
	onCloseButtonClick: () => void;
}

export const DefaultSlideModalFooter = ({ onCloseButtonClick }: DefaultSlideModalFooterProps) => (
	<SlideModalFooter>
		<Button variant={['secondary', { isOutlined: true }]} icon="close" onClick={onCloseButtonClick}>
			<Message {...m.close} />
		</Button>
	</SlideModalFooter>
);
