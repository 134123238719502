import { makeActionTypes } from 'redux-syringe';

import { SCOPE_PREFIX } from '../../constants';

export const FEATURE = 'journey';
export const SCOPE = `${SCOPE_PREFIX}-${FEATURE}`;

export const ActionTypes = makeActionTypes(SCOPE, [
	// public command Action types
	'SUBMIT',
	'GO_TO_STEP',
	'GO_TO_NEXT_STEP',
	'GO_TO_PREVIOUS_STEP',
	'RESET',

	// private document Action types
	'INITIALIZE',
	'DESTROY',
	'SET_STEP',
	'REGISTER_FIELD',
	'SET_NUMBER_OF_STEPS',
	'REGISTER_FORM',
	'UNREGISTER_FORM',
	'SET_SHOULD_FOCUS_FIRST_INVALID_FIELD',
]);
