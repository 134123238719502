import { ForwardedRef, forwardRef, useCallback } from 'react';
import { DefaultTabButton } from './DefaultTabButton';
import { StepperTabButton } from './StepperTabButton';
import { TabItem, TabKey, TabsVariant } from '../types';

export interface TabProps {
	activeIndex: number;
	activeKey: TabKey;
	index: number;
	onTabClick: (key: TabKey) => void;
	tab: TabItem;
	variant: TabsVariant;
}

export const Tab = forwardRef(
	(
		{
			activeIndex,
			activeKey,
			index,
			onTabClick,
			tab: { key, isDisabled = false, isInvalid = false, label },
			variant,
		}: TabProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const isActive = activeKey === key;

		const handleClick = useCallback(() => {
			onTabClick(key);
		}, [key, onTabClick]);

		return variant === 'stepper' ? (
			<StepperTabButton
				ref={ref}
				activeIndex={activeIndex}
				index={index}
				isActive={isActive}
				isInvalid={isInvalid}
				label={label}
				onClick={handleClick}
			/>
		) : (
			<DefaultTabButton
				ref={ref}
				isActive={isActive}
				isDisabled={isDisabled}
				label={label}
				onClick={handleClick}
			/>
		);
	}
);

Tab.displayName = 'Tab';
