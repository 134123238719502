import { Store } from 'redux';
import { getEnvironmentVariable, isInBrowser } from '@myci/utils';
import { performSessionTimeout } from './duck';

const userActivityEvents = [
	'mousemove',
	'mousedown',
	'keypress',
	'mousewheel',
	'touchmove',
	'DOMMouseScroll',
	'MSPointerMove',
];

const addInactivityListener = (handler: () => void, timeout: number) => {
	let timer: ReturnType<typeof setTimeout> | null = null;

	const startTimer = () => {
		timer = setTimeout(handler, timeout);
	};

	const clearTimer = () => {
		if (timer) {
			clearTimeout(timer);
		}
	};

	const resetTimer = () => {
		clearTimer();
		startTimer();
	};

	const handleUserActivity = () => {
		resetTimer();
	};

	userActivityEvents.forEach(eventName =>
		document.addEventListener(eventName, handleUserActivity, false)
	);

	startTimer();
};

export const initializeSessionTimeout = (store: Store) => {
	if (!isInBrowser()) return;

	const { dispatch } = store;
	const timeout = Number(getEnvironmentVariable('GATSBY_MYCI_SESSION_TIMEOUT'));

	if (timeout) {
		const handleInactivity = () => {
			dispatch(performSessionTimeout());
		};

		addInactivityListener(handleInactivity, timeout * 60 * 1000);
	}
};
