import { composeMiddleware } from '@myci/utils';
import { makeAuthenticationMiddleware } from '@ci/authentication';
import { alwaysNull } from 'ramda-extension';
import { F } from 'ramda';
import {
	attachAccessToken,
	fetchAccessToken,
	fetchRefreshToken,
	getAuthorizationCode,
	getIsAuthenticationError,
	getIsAuthenticationRequest,
	getIsLogoutRequest,
	logOut,
	selectAccessToken,
	selectIsAuthenticating,
	selectRefreshToken,
	middleware as tokenMiddleware,
} from './duck';

const authenticationMiddleware = makeAuthenticationMiddleware({
	attachAccessToken,
	selectAccessToken,
	getAuthorizationCode,
	selectRefreshToken,
	fetchAccessToken,
	fetchRefreshToken,
	getIsAuthenticationRequest,
	getIsAuthenticationError,
	selectIsAuthenticating,
	getIsLogoutRequest,
	getIsTokenRequired: F,
	logOut,
	getIncomingPkceState: alwaysNull,
	getStoredPkceState: alwaysNull,
});

export { default as Secure } from './Secure';

export {
	ActionTypes,
	reducer,
	changePassword,
	fetchResetPasswordToken,
	selectResetPasswordTokenEntity,
	selectIsResettingPassword,
	selectIsSigningIn,
	logOut,
	resetPassword,
	setAccessToken,
	setRefreshToken,
	signIn,
	selectIsFetchingResetPassword,
	selectIsFetchingResetPasswordToken,
	selectAccessToken,
	selectDidLogOut,
	setPostLoginRoute,
	selectIsChangingPassword,
	selectIsLoggingOut,
} from './duck';

export const middleware = composeMiddleware(authenticationMiddleware, tokenMiddleware);

middleware.initialize = authenticationMiddleware.initialize;
