import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@myci/intl';
import { Button, ButtonClientLink, Grid, LabeledIcon, useTheme } from '@myci/ui-components';

import m from '../../../messages';
import {
	useIsJourneyFormSubmitting,
	useJourneyControls,
	useJourneyCurrentStep,
	useJourneyNumberOfSteps,
} from '../hooks';

const defaultSubmitLabel = <Message {...m.submit} />;
const defaultCancelLabel = <Message {...m.cancel} />;
const defaultNextStepLabel = <Message {...m.nextStep} />;
const defaultPreviousStepLabel = <Message {...m.previousStep} />;

const JourneyButtons = ({
	submitLabel = defaultSubmitLabel,
	cancelLabel = defaultCancelLabel,
	nextStepLabel = defaultNextStepLabel,
	previousStepLabel = defaultPreviousStepLabel,
	cancelTo = '/',
	namespace,
	isSubmitDisabled,
	wizard,
	cancelButtonProps,
}) => {
	const { goToPreviousStep, submit } = useJourneyControls(namespace);

	const currentStep = useJourneyCurrentStep(namespace);
	const numberOfSteps = useJourneyNumberOfSteps(namespace);
	const isSubmitting = useIsJourneyFormSubmitting(namespace);

	const isNotAtFirstStep = currentStep > 1;
	const isAtLastStepOfMany = isNotAtFirstStep && currentStep === numberOfSteps;
	const isNotAtLastStep = currentStep < numberOfSteps;
	const showCancel = !wizard || !isNotAtFirstStep;
	const theme = useTheme();

	return (
		<Grid justifyContent="center" flexDirection={['column', 'row']} py={2}>
			{showCancel && (
				<ButtonClientLink
					to={cancelTo}
					outline
					kind="secondary"
					icon="rejected"
					order={[2, 1]}
					mr={['', 3]}
					disabled={isSubmitting}
					{...cancelButtonProps}
				>
					{cancelLabel}
				</ButtonClientLink>
			)}

			{wizard && isNotAtFirstStep && (
				<Button
					onClick={() => goToPreviousStep()}
					kind="secondary"
					icon="back"
					outline
					mr={['', 3]}
					order={[2, 1]}
					disabled={isSubmitting}
				>
					{previousStepLabel}
				</Button>
			)}

			{wizard && isNotAtLastStep && (
				<Button
					onClick={() => submit()}
					kind="primary"
					mb={[2, 0]}
					order={[1, 2]}
					disabled={isSubmitting || isSubmitDisabled}
				>
					<LabeledIcon
						iconType="next"
						iconProps={{ pl: theme.isRTL ? 0 : 1, pr: theme.isRTL ? 1 : 0, mr: 0, ml: 0 }}
					>
						{nextStepLabel}
					</LabeledIcon>
				</Button>
			)}

			{(!wizard || isAtLastStepOfMany) && (
				<Button
					onClick={() => submit()}
					kind="primary"
					icon="success"
					mb={[2, 0]}
					order={[1, 2]}
					disabled={isSubmitting || isSubmitDisabled}
				>
					{submitLabel}
				</Button>
			)}
		</Grid>
	);
};

JourneyButtons.propTypes = {
	cancelButtonProps: PropTypes.object,
	cancelLabel: PropTypes.node,
	cancelTo: PropTypes.string,
	isSubmitDisabled: PropTypes.bool,
	/** Used for connecting to the Journey if the component is used outside of its render tree. */
	namespace: PropTypes.string.isRequired,
	nextStepLabel: PropTypes.node,
	previousStepLabel: PropTypes.node,
	resetOnCancel: PropTypes.bool,
	submitLabel: PropTypes.node,
	/** If `true` component is switched into "wizard" mode. It shows buttons to go to the next and previous steps of the journey */
	wizard: PropTypes.bool,
};
export default JourneyButtons;
