import React from 'react';
import PropTypes from 'prop-types';

import CheckboxControl from '../CheckboxControl';
import FormField from '../FormField';
import './Toggle.scss';

const Toggle = ({ checkedLabel, uncheckedLabel, value, ...otherProps }) => (
	<FormField
		control={CheckboxControl}
		label={value ? checkedLabel : uncheckedLabel}
		kind="toggle"
		{...otherProps}
		checked={value}
	/>
);

Toggle.propTypes = {
	checkedLabel: PropTypes.node,
	uncheckedLabel: PropTypes.node,
	value: PropTypes.bool,
};

export default Toggle;
