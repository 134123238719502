import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { ReactNode } from 'react';

const slideModalInnerStyle = prepareStyle(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100vh',
}));

export interface SlideModalInnerProps {
	children?: ReactNode;
}

/**
 * Always use SlideModal with SlideModalInner. Omitting SlideModalInner would result in a broken layout.
 *
 * @example
 * <SlideModal>
 * 	<SlideModalInner>
 * 		<SlideModalHeader />
 * 		<SlideModalContent />
 * 		<SlideModalFooter />
 * 	</SlideModalInner>
 * </SlideModal>
 *
 * @example
 * <SlideModal>
 * 	<Form>
 * 		<SlideModalInner>
 * 			<SlideModalHeader />
 * 			<SlideModalContent />
 * 			<SlideModalFooter />
 * 		</SlideModalInner>
 * 	</Form>
 * </SlideModal>
 */
export const SlideModalInner = ({ children }: SlideModalInnerProps) => {
	const { applyStyle } = useStyles();

	return <div className={applyStyle(slideModalInnerStyle)}>{children}</div>;
};
