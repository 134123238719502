import { makeFormatter } from 'afformative';
import moment from 'moment';
import { ReactNode } from 'react';

const isReactNative = typeof navigator !== 'undefined' && navigator.product === 'ReactNative';

export const makeMomentFormatter = (format: string, displayName = 'momentFormatter') =>
	makeFormatter<moment.MomentInput, ReactNode, string>(
		(value: moment.MomentInput, suggestions) => {
			const formattedValue = value ? moment.parseZone(value).format(format) : '';

			if (suggestions.includes('primitive') || isReactNative) {
				return formattedValue;
			}

			// NOTE: We are never using any RTL format for dates, so we force the correct fragment order.
			return <span dir="ltr">{formattedValue}</span>;
		},
		{ displayName }
	);

export const dateTimeFormatter = makeMomentFormatter('lll', 'dateTimeFormatter');
export const dateFormatter = makeMomentFormatter('l', 'dateFormatter');

export const quarterFormatter = makeFormatter<moment.MomentInput, string>(
	value => {
		const date = moment.parseZone(value);

		return `${date.format('YYYY')}-Q${date.format('Q')}`;
	},
	{ displayName: 'quarterFormatter' }
);
