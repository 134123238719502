import { getEnvironmentVariable } from './getEnvironmentVariable';

export const configureTranslationWarnings = () => {
	if (getEnvironmentVariable('GATSBY_DISABLE_TRANSLATION_WARNINGS') === '1') {
		const consoleError = console.error.bind(console);
		console.error = (message, ...args) => {
			if (message?.code === 'MISSING_TRANSLATION') {
				return;
			}

			consoleError(message, ...args);
		};
	}
};
