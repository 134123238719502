import { formValueSelector } from 'redux-form';
import { curry } from 'ramda';

/**
 * Convenient adapter over `formValueSelector` from `react-redux`.
 * It lets you to put a state as last argument.
 * TIP: Great with combination with `R.applySpec`.
 *
 * @sig String -> [String] -> AppState -> a
 */
export const reduxFormSelector = curry((formId, fieldNames, state) =>
	formValueSelector(formId)(state, ...fieldNames)
);
