import { path } from 'ramda';

export const importIntlOptions = locale =>
	import(
		/* webpackChunkName: "intl-options-[request]-[index]" */
		/* webpackMode: "lazy" */
		`../data/intl-options/${locale}.json`
	)
		.then(path(['default', 'intlOptions']))
		.catch(error => {
			console.warn('Missing intl options', { locale, error });

			// TODO: Define fallbacks for missing intl options in all usages.
			return {};
		});
