import React from 'react';
import { addPropsRight } from '@myci/utils';
import { isObject } from 'ramda-extension';
import { Message } from '@myci/intl';

export const mapError = addPropsRight(({ meta: { error, touched }, label }) => ({
	error:
		touched && error ? (
			isObject(error) ? (
				<Message
					{...error.message}
					values={{
						...error.options,
						...error.messageValues,
						value: error.value,
						label,
					}}
				/>
			) : (
				error
			)
		) : null,
}));
