import {
	dateFormatter,
	dateTimeFormatter,
	identityFormatter,
	quarterFormatter,
} from '@creditinfo-ui/formatters';

export * from '@creditinfo-ui/formatters';

export * from './kinds/dateTime';
export * from './kinds/number';
export * from './kinds/phone';
export * from './kinds/redux';
export * from './kinds/utility';

/** @deprecated Use `dateFormatter` instead. */
export const DateFormatter = dateFormatter;

/** @deprecated Use `dateTimeFormatter` instead. */
export const DateTimeFormatter = dateTimeFormatter;

/** @deprecated Use `identityFormatter` instead. */
export const IdentityFormatter = identityFormatter;

/** @deprecated Use `quarterFormatter` instead. */
export const QuarterFormatter = quarterFormatter;
