import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';

/**
 * Layout component - represents section of the page.
 */
const Section = ({ id, kind = 'normal', className, ...rest }) => (
	<Box
		as="section"
		id={id}
		className={cx(
			'section',
			{ [`section--${id}`]: Boolean(id), 'section--header': kind === 'header' },
			className
		)}
		{...rest}
	/>
);

Section.propTypes = {
	/** Class name for root. */
	className: PropTypes.string,
	/** ID of section */
	id: PropTypes.string,
	/** Kind of the section for styling purposes.  */
	kind: PropTypes.oneOf(['header', 'normal']),
};

export default Section;
