import { Icon } from '@creditinfo-ui/atoms';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';

import { PredefinedModalPresenterProps } from '../Modal';
import { ModalPresenterRoot } from '../ModalPresenterRoot';

const modalStyle = prepareStyle(utils => ({
	backgroundColor: utils.colors.gray100,
	borderRadius: utils.borders.radii.basic,
	boxShadow: utils.boxShadows.basic,
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100px',
	paddingTop: utils.spacings.lg,
	position: 'relative',
	width: '100%',

	selectors: {
		[utils.breakpoints.sm]: {
			maxWidth: '580px',
		},
	},
}));

const closeButtonStyle = prepareStyle(utils => ({
	alignItems: 'center',
	cursor: 'pointer',
	height: utils.spacings.lg,
	insetInlineEnd: 0,
	justifyContent: 'center',
	position: 'absolute',
	top: 0,
	width: utils.spacings.lg,
}));

export interface StaticModalProps extends PredefinedModalPresenterProps {
	className?: string;
	customStyle?: Style;
}

export const StaticModal = ({
	children,
	className,
	customStyle,
	isAlwaysMounted,
	isModalInStack,
	onBackdropClick,
	onCloseButtonClick,
	onTransitionExited,
	zIndex,
}: StaticModalProps) => {
	const { applyStyle } = useStyles();

	return (
		<ModalPresenterRoot
			className={cx(applyStyle([modalStyle, customStyle]), className)}
			isAlwaysMounted={isAlwaysMounted}
			onBackdropClick={onBackdropClick}
			onTransitionExited={onTransitionExited}
			transitionSpeed="faster"
			transitionType="fade"
			visibilityTrigger={isModalInStack}
			zIndex={zIndex}
		>
			{children}
			<Icon
				color="gray400"
				customStyle={closeButtonStyle}
				onClick={onCloseButtonClick}
				size="md"
				type="close"
			/>
		</ModalPresenterRoot>
	);
};
