import { identity } from 'ramda';
import { rejectNil, isError } from 'ramda-extension';

/**
 * @deprecated Use `createAction` from `@reduxjs/toolkit` instead.
 */
export const makeBinaryActionCreator =
	<TType extends string>(
		type: TType,
		getPayload: Function = identity,
		getMeta: Function = identity
	) =>
	(payload?: any, meta?: any) =>
		rejectNil({
			type,
			payload: getPayload(payload),
			meta: getMeta(meta),
			// NOTE: We default to `undefined` and use `rejectNil` to avoid cluttering the objects
			// (mainly for tests and debugging, this should not matter in normal usage)
			error: isError(payload) || undefined,
		});
