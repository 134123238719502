import React from 'react';
import { Message } from '@myci/intl';

import m from '../../messages';
import Text from '../Text';

const MandatoryFieldsText = props => (
	<Text mt={3} mb={0} {...props}>
		<span className="color-danger">*</span> <Message {...m.mandatoryFields} />
	</Text>
);

export default MandatoryFieldsText;
