import {
	decapitalizeFirst,
	isArray,
	isFunction,
	isNotNilObject,
	mapKeysAndValues,
} from 'ramda-extension';
import { T, cond, head, identity, juxt, last, map, o } from 'ramda';

const decapitalizeObj = mapKeysAndValues(
	juxt([o(decapitalizeFirst, head), o(x => decapitalizeKeys(x), last)])
);

const decapitalizeArray = map(x => decapitalizeKeys(x));

export const decapitalizeKeys = cond([
	[isArray, decapitalizeArray],
	[isFunction, identity],
	[isNotNilObject, decapitalizeObj],
	[T, identity],
]);
