import { forEachObjIndexed } from 'ramda';

export const setBrowserLocationUsingPost = (location, data) => {
	const form = document.createElement('form');
	document.body.appendChild(form);
	form.method = 'post';
	form.action = location;

	forEachObjIndexed((value, name) => {
		const input = document.createElement('input');
		input.type = 'hidden';
		input.name = name;
		input.value = data[name];
		form.appendChild(input);
	}, data);

	form.submit();
};
