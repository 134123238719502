import { Button } from '@creditinfo-ui/atoms';
import { Message } from '@creditinfo-ui/messages';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';

import { PredefinedModalPresenterProps } from '../Modal';
import { ModalPresenterRoot } from '../ModalPresenterRoot';
import { m } from '../../messages';

export type SlideModalSize = 'sm' | 'md' | 'lg';

export const DEFAULT_SLIDE_MODAL_SIZE: SlideModalSize = 'sm';

interface ModalStyleProps {
	size: SlideModalSize;
}

const modalStyle = prepareStyle<ModalStyleProps>((utils, props) => ({
	backgroundColor: utils.colors.gray100,
	bottom: 0,
	boxShadow: utils.boxShadows.basic,
	insetInlineEnd: 0,
	maxWidth: '1020px',
	position: 'fixed',
	top: 0,
	width: '100%',

	selectors: {
		[utils.breakpoints.md]: {
			width: '100%',
			extend: [
				{
					condition: props.size === 'sm',
					style: {
						maxWidth: '768px',
					},
				},
				{
					condition: props.size === 'lg',
					style: {
						maxWidth: '100%',
					},
				},
			],
		},
		[utils.breakpoints.lg]: {
			extend: {
				condition: props.size === 'lg',
				style: {
					insetInlineStart: utils.sizes.navigation.expanded,
					maxWidth: 'none',
					width: 'auto',
				},
			},
		},
	},
}));

export const SLIDE_MODAL_MOBILE_CONTROLS_HEIGHT = '40px';
export const SLIDE_MODAL_MOBILE_PADDING_Y = '2rem';
export const SLIDE_MODAL_MOBILE_PADDING_X = '2rem';

export const SLIDE_MODAL_CONTROLS_HEIGHT = '60px';
export const SLIDE_MODAL_PADDING_Y = '3rem';
export const SLIDE_MODAL_PADDING_X = '4rem';

const modalControlsStyle = prepareStyle(utils => ({
	alignItems: 'center',
	display: 'flex',
	height: SLIDE_MODAL_MOBILE_CONTROLS_HEIGHT,
	justifyContent: 'flex-end',
	left: 0,
	padding: `${SLIDE_MODAL_MOBILE_PADDING_Y} ${SLIDE_MODAL_MOBILE_PADDING_X} 0`,
	position: 'absolute',
	right: 0,
	top: 0,

	selectors: {
		[utils.breakpoints.md]: {
			height: SLIDE_MODAL_CONTROLS_HEIGHT,
			padding: `${SLIDE_MODAL_PADDING_Y} ${SLIDE_MODAL_PADDING_X} 0`,
		},
	},
}));

export interface SlideModalProps extends PredefinedModalPresenterProps {
	className?: string;
	size?: SlideModalSize;
}

export const SlideModal = ({
	children,
	className,
	isAlwaysMounted,
	isModalInStack,
	onBackdropClick,
	onCloseButtonClick,
	onTransitionExited,
	size = DEFAULT_SLIDE_MODAL_SIZE,
	zIndex,
}: SlideModalProps) => {
	const { applyStyle } = useStyles();

	return (
		<ModalPresenterRoot
			className={cx(applyStyle(modalStyle, { size }), className)}
			isAlwaysMounted={isAlwaysMounted}
			onBackdropClick={onBackdropClick}
			onTransitionExited={onTransitionExited}
			transitionSpeed="slower"
			transitionType="slide"
			visibilityTrigger={isModalInStack}
			zIndex={zIndex}
		>
			<div className={applyStyle(modalControlsStyle)}>
				<Button
					variant="subtle"
					icon="close"
					iconProps={{ size: 'md' }}
					size="small"
					onClick={onCloseButtonClick}
					iconPlacement="end"
				>
					<Message {...m.close} />
				</Button>
			</div>
			{children}
		</ModalPresenterRoot>
	);
};
