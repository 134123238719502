import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

const StaticLink = forwardRef((props, ref) => <Link {...props} ref={ref} />);
StaticLink.displayName = 'forwardRef(StaticLink)';

/**
 * Use for static urls - for no SPA navigation.
 */
StaticLink.propTypes = {
	children: PropTypes.node,
	href: PropTypes.string.isRequired,
};

StaticLink.defaultProps = {
	as: 'a',
};

export default StaticLink;
