import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import { Message } from '@myci/intl';

import Box from '../Box';
import { useTheme } from '../ThemeProvider';
import m from '../../messages';
import Icon from '../Icon';
import Button from '../Button';

import './Collapsible.scss';

const defaultHideLabel = <Message {...m.filterHide} />;
const defaultShowLabel = <Message {...m.filterShow} />;

const Collapsible = ({
	children,
	className,
	hideLabel = defaultHideLabel,
	isInitiallyHidden = false,
	statusBar,
	showLabel = defaultShowLabel,
}) => {
	const [isHidden, setIsHidden] = useState(isInitiallyHidden);
	const theme = useTheme();

	return (
		<Box
			dir={theme.isRTL ? 'rtl' : ''}
			// NOTE: Do not spread `...otherProps` here. This component is expected to be wrapped in `reduxForm()`,
			// which passes tons of props down, resulting in warnings about unknown DOM attributes.
			className={cx('collapsible', { 'collapsible--hide': isHidden }, className)}
			// style={isHidden ? null : { maxHeight: contentHeight }}
		>
			<Button className="collapsible__hide-btn" onClick={() => setIsHidden(!isHidden)}>
				{isHidden ? showLabel : hideLabel}
				<Icon type={isHidden ? 'caret-down' : 'caret-up'} />
			</Button>
			<Box className="collapsible__content">
				<Box className="collapsible__content-inner">{children}</Box>
			</Box>
			{statusBar}
		</Box>
	);
};

Collapsible.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	hideLabel: PropTypes.string,
	isInitiallyHidden: PropTypes.bool,
	showLabel: PropTypes.object,
	statusBar: PropTypes.element.isRequired,
};

export default Collapsible;
