import { memo, useRef, useEffect, useMemo, useContext, ComponentType, PropsWithRef } from 'react';
import { connect } from 'react-redux';
import {
	Field,
	change,
	BaseFieldProps,
	WrappedFieldProps,
	WrappedFieldInputProps,
} from 'redux-form';
import { compose, applySpec } from 'ramda';
import { generateUuid, getDisplayName } from '@ci/utils';
import { FieldsDisabledContext } from './contexts';

import { reduxForm } from './reduxForm';
import { reduxFormSelector } from './reduxFormSelector';

interface SyncWithRemoteFieldProps {
	bag: BaseFieldProps;
	changeLocalValue: (value: any) => void;
	changeRemoteValue: (value: any) => void;
	remoteValue: any;
	value: any;
}

const SyncWithRemoteField = ({
	bag,
	changeLocalValue,
	changeRemoteValue,
	value,
	remoteValue,
}: SyncWithRemoteFieldProps) => {
	const previousValueRef = useRef(value);
	const previousRemoteValueRef = useRef(remoteValue);

	// NOTE: This `useEffect` synchronizes the remote and main fields upon changes.
	useEffect(() => {
		// NOTE: The order of this condition shouldn't matter, but only one of them should fire.
		if (remoteValue !== previousRemoteValueRef.current) {
			// NOTE: Updating the ref values should happen after condition but before side effect.
			previousRemoteValueRef.current = remoteValue;
			previousValueRef.current = value;

			if (remoteValue !== value) {
				changeLocalValue(remoteValue);
			}
		} else if (value !== previousValueRef.current) {
			previousRemoteValueRef.current = remoteValue;
			previousValueRef.current = value;

			if (remoteValue !== value) {
				changeRemoteValue(value);
			}
		}
	}, [value, remoteValue]);

	// NOTE: If a field gets mounted while the other is already mounted,
	// it should be initialized to the other field's value.
	useEffect(() => {
		if (value === undefined && remoteValue !== undefined) {
			changeLocalValue(remoteValue);
		}

		if (remoteValue === undefined && value !== undefined) {
			changeRemoteValue(value);
		}
	}, []);

	return <Field {...bag} />;
};

interface PassThroughFieldProps {
	bag: BaseFieldProps;
}

const PassThroughField = ({ bag }: PassThroughFieldProps) => <Field {...bag} />;

export interface RemoteFormFieldProps {
	remoteForm?: string;
	remoteFormProps?: object;
}

type WithFieldProps<TProps extends object> = Omit<
	TProps,
	| keyof WrappedFieldProps
	| keyof WrappedFieldInputProps
	| keyof BaseFieldProps<TProps>
	| keyof RemoteFormFieldProps
> &
	BaseFieldProps<TProps> &
	RemoteFormFieldProps;

export const withField = <TProps extends object>(
	Component: ComponentType<TProps>
): ComponentType<PropsWithRef<WithFieldProps<TProps>>> => {
	const WithField = ({ remoteForm, remoteFormProps, ...otherProps }: WithFieldProps<TProps>) => {
		const areAllFieldsDisabled = useContext(FieldsDisabledContext);

		const isDisabled =
			areAllFieldsDisabled || (otherProps as any).isDisabled || (otherProps as any).disabled;

		const remoteFormUniqueSuffix = useRef(generateUuid());
		const remoteFormId = `${remoteForm}__${otherProps.name}-${remoteFormUniqueSuffix.current}`;

		const ReduxFormField = useMemo(() => {
			if (!remoteForm) {
				return PassThroughField;
			}

			const getValue = reduxFormSelector(remoteForm)([otherProps.name]); // Value in the main form
			const getRemoteValue = reduxFormSelector(remoteFormId)([otherProps.name]); // Value of the detached field

			// TODO: Rewrite this mechanism so it doesn't use `connect` and is simpler to type.
			return (compose as any)(
				connect(
					applySpec({
						value: getValue,
						remoteValue: getRemoteValue,
					}),
					{
						changeRemoteValue: newValue => change(remoteFormId, otherProps.name, newValue),
						changeLocalValue: newValue => change(remoteForm, otherProps.name, newValue),
					}
				),
				reduxForm({
					form: remoteFormId,
					...remoteFormProps,
				})
			)(SyncWithRemoteField);
		}, [remoteForm, otherProps.name, remoteFormProps]);

		return (
			<ReduxFormField
				bag={{
					component: Component,
					disabled: isDisabled,
					...otherProps,
				}}
			/>
		);
	};

	WithField.displayName = `WithField(${getDisplayName(Component)})`;

	return memo(WithField);
};
