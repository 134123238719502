import { reverse } from 'ramda';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { toUpperFirst } from 'ramda-extension';

import { Select } from '@creditinfo-ui/atoms';
import { prepareStyle } from '@creditinfo-ui/styles';
import { stopPropagation } from '@creditinfo-ui/utils';

const createYearRange = (start: number, count: number) =>
	Array.apply(0, Array(count)).map((element, index) => index + start);

const getYears = (yearSelectDirection: YearSelectDirection) => {
	const currentYear = moment().year();
	const from = yearSelectDirection === 'future' ? currentYear : currentYear - 99;

	return yearSelectDirection === 'past'
		? reverse(createYearRange(from, 100))
		: createYearRange(from, yearSelectDirection === 'both' ? 200 : 100);
};

const CalendarWeekHeader = () => {
	// NOTE: Full Arabic weekdays take up little space compared to other locales.
	const weekdays = moment.locale().startsWith('ar')
		? moment.weekdays(true)
		: moment.weekdaysShort(true);

	return (
		<div className="DayPicker_weekHeader">
			<ul className="DayPicker_weekHeader_ul">
				{weekdays.map(weekday => (
					// HACK: Width is calculated from weekday count and DateInput width.
					// Sadly, we don't really have access to the internal width.
					<li key={weekday} className="DayPicker_weekHeader_li" style={{ width: '39px' }}>
						<small>{weekday}</small>
					</li>
				))}
			</ul>
		</div>
	);
};

const textInputCustomStyle = prepareStyle(() => ({ width: '6.5rem' }));
const textInputProps = { customStyle: textInputCustomStyle };

const menuProps = { maxHeight: '240px' };

export type YearSelectDirection = 'past' | 'future' | 'both';

export interface CalendarHeaderProps {
	month: Moment;
	onYearSelect: (currentMonth: Moment, nextValue: number) => void;
	validYears?: number[];
	yearSelectDirection: YearSelectDirection;
}

export const CalendarHeader = ({
	month,
	onYearSelect,
	validYears,
	yearSelectDirection,
}: CalendarHeaderProps) => {
	const handleChange = useCallback(year => onYearSelect(month, year), [month, onYearSelect]);
	const items = useMemo(
		() => validYears ?? getYears(yearSelectDirection),
		[yearSelectDirection, validYears]
	);

	return (
		// NOTE: Necessary so Select interaction doesn't affect the DateInput.
		<div onKeyDown={stopPropagation} onChange={stopPropagation} onFocus={stopPropagation}>
			<CalendarWeekHeader />
			{/* NOTE: The space after the month name is intentional. */}
			{`${toUpperFirst(month.format('MMMM'))} `}
			<Select
				items={items}
				menuProps={menuProps}
				onChange={handleChange}
				shouldCheckItemAvailability={false}
				sorting="none"
				textInputProps={textInputProps}
				value={month.year()}
				variant="subtle"
			/>
		</div>
	);
};
