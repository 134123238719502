import type * as CSS from 'csstype';
import { isNotNil } from 'ramda-extension';
import { MessageDescriptor } from 'react-intl';
import { AriaAttributes, ReactNode, createElement, forwardRef } from 'react';

import {
	Color,
	FontSize,
	FontWeight,
	LineHeight,
	Style,
	StyleObject,
	mergeStyles,
	prepareStyle,
	prepareStyleFactory,
	useStyles,
} from '@creditinfo-ui/styles';
import { Message } from '@creditinfo-ui/messages';

import { resolveVerticalMargin } from '../utils';
import { Icon, IconProps, IconSize, IconType } from './Icon';
import { IconPlacement, Spacing, Tag, VerticalSpacing } from '../types';

export type TextAlign = 'start' | 'end' | 'center' | 'justify';
export type TextTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'none';

export type TextVariant =
	/** Should be used as the primary heading of a view. */
	| 'viewTitle'
	/** Should be used as an additional prominent heading inside a view, especially in modals. */
	| 'extraTitle'
	/** Should be used as a title of a form section or a content section, e.g. in reports. */
	| 'sectionTitle'
	/** Should be used for "no data" or "loading" messages. https://material.io/design/communication/empty-states.html#content */
	| 'tagline';

export interface TextProps extends AriaAttributes {
	align?: TextAlign;
	children?: ReactNode;
	color?: Color;
	customStyle?: Style<TextStyleProps>;
	/**
	 * Icon to render next to the children. Causes the root element to become a flex container with
	 * `alignItems: 'center'` for multiline vertical alignment of the icon. This behaviour can be
	 * disabled using the `isIconInline` prop.
	 */
	icon?: IconType;
	iconPlacement?: IconPlacement;
	iconProps?: Partial<IconProps>;
	/** When `true`, the root element won't become a flex container when the `icon` prop is passed. */
	isIconInline?: boolean;
	lineHeight?: LineHeight;
	message?: MessageDescriptor;
	size?: FontSize;
	tag?: Tag;
	testId?: string;
	transform?: TextTransform;
	variant?: TextVariant;
	verticalMargin?: VerticalSpacing;
	weight?: FontWeight;
	whiteSpace?: CSS.Property.WhiteSpace;
}

export interface TextStyleProps {
	align?: TextAlign;
	color?: Color;
	hasIcon: boolean;
	isIconInline: boolean;
	lineHeight?: LineHeight;
	marginBottom: Spacing;
	marginTop: Spacing;
	size?: FontSize;
	transform?: TextTransform;
	variant?: TextVariant;
	weight?: FontWeight;
	whiteSpace?: CSS.Property.WhiteSpace;
}

export const textStyle = prepareStyle<TextStyleProps>(
	(
		utils,
		{
			align,
			color,
			hasIcon,
			isIconInline,
			lineHeight,
			marginBottom,
			marginTop,
			size,
			transform,
			variant,
			weight,
			whiteSpace,
		}
	) => {
		const styleObjectsByVariant: Record<
			TextVariant,
			Pick<StyleObject, 'color' | 'fontSize' | 'fontWeight' | 'lineHeight'>
		> = {
			extraTitle: {
				color: utils.colors.gray800,
				fontSize: utils.fontSizes.extraTitle,
				fontWeight: utils.fontWeights.extraBold,
				lineHeight: utils.lineHeights.title,
			},
			sectionTitle: {
				color: utils.colors.gray600,
				fontSize: utils.fontSizes.sectionTitle,
				fontWeight: utils.fontWeights.semiBold,
				lineHeight: utils.lineHeights.base,
			},
			tagline: {
				color: utils.colors.gray600,
				fontSize: utils.fontSizes.tagline,
				fontWeight: utils.fontWeights.normal,
				lineHeight: utils.lineHeights.base,
			},
			viewTitle: {
				color: utils.colors.gray800,
				fontSize: utils.fontSizes.viewTitle,
				fontWeight: utils.fontWeights.extraBold,
				lineHeight: utils.lineHeights.title,
			},
		};

		const variantStyleObject = variant ? styleObjectsByVariant[variant] : {};

		const {
			color: colorValue,
			fontSize: fontSizeValue = 'inherit',
			fontWeight: fontWeightValue = 'inherit',
			lineHeight: lineHeightValue = 'inherit',
		} = variantStyleObject;

		return {
			color: color ? utils.colors[color] : colorValue,
			fontSize: size ? utils.fontSizes[size] : fontSizeValue,
			fontWeight: weight ? utils.fontWeights[weight] : fontWeightValue,
			lineHeight: lineHeight ? utils.lineHeights[lineHeight] : lineHeightValue,
			// NOTE: We don't want this component to have any margins itself, they should be handled
			// by the user.
			margin: 0,
			textAlign: align,
			textTransform: transform,
			whiteSpace,

			extend: [
				{
					condition: hasIcon && !isIconInline,
					style: {
						alignItems: 'center',
						display: 'flex',
						justifyContent: align,
					},
				},
				{
					condition: marginBottom !== 'none',
					style: {
						marginBottom: utils.spacings[marginBottom],
					},
				},
				{
					condition: marginTop !== 'none',
					style: {
						marginTop: utils.spacings[marginTop],
					},
				},
			],
		};
	}
);

interface IconStyleProps {
	iconPlacement: IconPlacement;
}

const iconMarginValue = '0.5em';

const iconStyle = prepareStyleFactory<IconStyleProps>((utils, { iconPlacement }) =>
	iconPlacement === 'start'
		? { marginInlineEnd: iconMarginValue }
		: { marginInlineStart: iconMarginValue }
);

const getDefaultTagByVariant = (variant?: TextVariant): Tag => {
	switch (variant) {
		case 'viewTitle':
			return 'h1';
		case 'extraTitle':
			return 'h2';
		default:
			return 'span';
	}
};

// NOTE: Some variants look better with slightly larger icons rather than the inherited size.
const iconSizesByVariant: Partial<Record<TextVariant, IconSize>> = {
	sectionTitle: 'lg',
};

export const Text = forwardRef<HTMLElement, TextProps>(
	(
		{
			align,
			transform,
			children,
			color,
			customStyle,
			icon,
			iconPlacement = 'start',
			iconProps,
			isIconInline = false,
			lineHeight,
			message,
			size,
			tag: tagProp,
			testId,
			variant,
			verticalMargin,
			weight,
			whiteSpace,
			...otherProps
		},
		ref
	) => {
		const { applyStyle } = useStyles();
		const { marginBottom, marginTop } = resolveVerticalMargin(verticalMargin);

		const tag = tagProp ?? getDefaultTagByVariant(variant);
		const contentNode = children ?? (message ? <Message {...message} /> : null);

		const hasIcon = isNotNil(icon);
		const mergedIconStyle = mergeStyles([iconStyle({ iconPlacement }), iconProps?.customStyle]);
		const iconSize = (variant ? iconSizesByVariant[variant] : undefined) ?? iconProps?.size;

		const iconNode = hasIcon && (
			<Icon isLabeled type={icon} {...iconProps} size={iconSize} customStyle={mergedIconStyle} />
		);

		return createElement(
			tag,
			{
				...otherProps,
				className: applyStyle([textStyle, customStyle], {
					align,
					color,
					hasIcon,
					isIconInline,
					lineHeight,
					marginBottom,
					marginTop,
					size,
					transform,
					variant,
					weight,
					whiteSpace,
				}),
				'data-testid': testId,
				ref,
			},
			...(iconPlacement === 'start' ? [iconNode, contentNode] : [contentNode, iconNode])
		);
	}
);
