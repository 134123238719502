import { generateUuid, typeEq } from '@ci/utils';
import invariant from 'invariant';
import { isNotEmpty } from 'ramda-extension';
import { isNil, dissoc, equals, isEmpty, findIndex, update, forEach } from 'ramda';
import { MethodActionTypes, taskSuccessEvent, taskErrorEvent } from '../actions';
import {
	assocTaskId,
	getControlFlowId,
	getTaskId,
	isTaskError,
	isTaskEvent,
	makeCallback,
	isAuthenticationError,
} from '../utils';

const middleware = ({ dispatch }) => {
	const tasksByControlFlowId = {};
	const callbacksByControlFlowId = {};
	const controlFlowIdsByTaskId = {};
	const resultsByControlFlowId = {};
	const hasControlFlowIdErrored = {};
	const delegatedTaskIdsByControlFlowId = {};

	return next => action => {
		next(action);

		if (typeEq(MethodActionTypes.PARALLEL, action)) {
			const { tasks, callback: userCallback } = action.payload;

			invariant(isNotEmpty(tasks), 'You must pass at least one task to parallel.');

			const controlFlowId = getControlFlowId(action);
			const delegatedTaskId = getTaskId(action);

			const tasksByID = {};
			resultsByControlFlowId[controlFlowId] = [];
			hasControlFlowIdErrored[controlFlowId] = false;

			const taskActions = [];

			forEach(task => {
				const taskId = generateUuid();
				tasksByID[taskId] = task;
				controlFlowIdsByTaskId[taskId] = controlFlowId;
				resultsByControlFlowId[controlFlowId].push(taskId);
				taskActions.push(assocTaskId(taskId, task()));
			}, tasks);

			tasksByControlFlowId[controlFlowId] = tasksByID;
			callbacksByControlFlowId[controlFlowId] = makeCallback(userCallback, dispatch);
			delegatedTaskIdsByControlFlowId[controlFlowId] = delegatedTaskId;

			forEach(dispatch, taskActions);
		}

		if (isTaskEvent(action)) {
			const { taskId, event } = action.payload;
			const controlFlowId = controlFlowIdsByTaskId[taskId];

			if (isNil(controlFlowId)) {
				return;
			}

			tasksByControlFlowId[controlFlowId] = dissoc(taskId, tasksByControlFlowId[controlFlowId]);
			resultsByControlFlowId[controlFlowId] = update(
				findIndex(equals(taskId), resultsByControlFlowId[controlFlowId]),
				action.payload.event,
				resultsByControlFlowId[controlFlowId]
			);

			const callback = callbacksByControlFlowId[controlFlowId];

			if (isTaskError(action) && !isAuthenticationError(event)) {
				const isFirstError = !hasControlFlowIdErrored[controlFlowId];
				hasControlFlowIdErrored[controlFlowId] = true;
				callback(action, { isFirstError });
			}

			if (isEmpty(tasksByControlFlowId[controlFlowId])) {
				const hasOnlySucceeded = !hasControlFlowIdErrored[controlFlowId];

				if (hasOnlySucceeded) {
					callback(taskSuccessEvent(getTaskId(action), resultsByControlFlowId[controlFlowId]));
				}

				const delegatedTaskId = delegatedTaskIdsByControlFlowId[controlFlowId];

				if (hasOnlySucceeded) {
					dispatch(taskSuccessEvent(delegatedTaskId, resultsByControlFlowId[controlFlowId]));
				} else {
					dispatch(taskErrorEvent(delegatedTaskId, resultsByControlFlowId[controlFlowId]));
				}
			}
		}
	};
};

export default middleware;
