import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useShowBackButton } from 'gatsby-theme-myci/src/features/layout';

import EditableHeading from '../EditableHeading';
import Grid from '../Grid';
import Heading from '../Heading';
import MandatoryFieldsText from '../MandatoryFieldsText';
import Section from '../Section';

const ViewWrapper = ({
	children,
	hasDefaultHeading = false,
	customHeading,
	hasMandatoryFieldsText,
	editableTitle,
	title,
	showBackButton = false,
}) => {
	useShowBackButton(showBackButton);

	return (
		<Section pb={{ xs: 4, lg: 0 }} pt={1}>
			<Grid container fluid>
				{hasDefaultHeading && (
					<Grid row mt={{ lg: 2 }}>
						<Grid col="auto" mb={{ xs: 2, lg: 0 }}>
							{editableTitle ? (
								<EditableHeading name="heading" value={editableTitle} iconSize={30} />
							) : (
								<Heading as="h1">{title}</Heading>
							)}
							{hasMandatoryFieldsText && <MandatoryFieldsText color="info" mb={{ xs: 2, md: 4 }} />}
						</Grid>
					</Grid>
				)}
				{!hasDefaultHeading && customHeading && customHeading}
				{children}
			</Grid>
		</Section>
	);
};

ViewWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	customHeading: PropTypes.node,
	editableTitle: PropTypes.string,
	hasDefaultHeading: PropTypes.bool,
	hasMandatoryFieldsText: PropTypes.bool,
	showBackButton: PropTypes.bool,
	title: PropTypes.node,
};

export default ViewWrapper;
