import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { cx } from 'ramda-extension';

import './Stepper.scss';
import Dropdown from '../Dropdown';
import Box from '../Box';
import Grid from '../Grid';
import Text from '../Text';

const Stepper = ({
	className,
	activeStepIndex,
	steps,
	numberedList,
	renderStep,
	...otherProps
}) => {
	const countProgress = (steps, total) => (steps / total) * 100;

	const renderItem = (item, index) => (
		<Fragment>
			{numberedList && (
				<Text as="strong" mr={1}>
					{index + 1}
				</Text>
			)}
			{renderStep(item)}
		</Fragment>
	);

	return (
		<Grid alignItems="center" className={cx(className, 'stepper')}>
			<Box className="progress-circle-holder" mr={1}>
				<svg className="progress-circle" viewBox="0 0 36 36">
					<path
						className="progress-circle__bg"
						d="M18 2.0845
							a 15.9155 15.9155 0 0 1 0 31.831
							a 15.9155 15.9155 0 0 1 0 -31.831"
					/>
					<path
						className="progress-circle__bar"
						strokeDasharray={`${countProgress(activeStepIndex, steps.length)}, 100`}
						d="M18 2.0845
							a 15.9155 15.9155 0 0 1 0 31.831
							a 15.9155 15.9155 0 0 1 0 -31.831"
					/>
				</svg>
				<Box className="progress-circle__status">
					{activeStepIndex}/{steps.length}
				</Box>
			</Box>
			<Dropdown
				className="d-inline-flex stepper__dropdown"
				options={steps}
				renderItem={renderItem}
				{...otherProps}
			/>
		</Grid>
	);
};

Stepper.defaultProps = {
	renderStep: identity,
};

Stepper.propTypes = {
	activeStepIndex: PropTypes.number.isRequired,
	className: PropTypes.string,
	numberedList: PropTypes.bool,
	renderStep: PropTypes.func,
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			isActive: PropTypes.bool,
			isDisabled: PropTypes.bool,
			isDone: PropTypes.bool,
			isInvalid: PropTypes.bool,
			label: PropTypes.element,
			stepNumber: PropTypes.number,
		})
	).isRequired,
};

export default Stepper;
