import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { always, compose, filter, identity, sortBy } from 'ramda';
import { cx, isNilOrEmpty, isNotNil, isObject, mapIndexed } from 'ramda-extension';
import { stringOrNumberPropType } from '@ci/react-utils';
import { IdentityFormatter } from '@ci/formatters';

import Button from '../Button';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import { selectSorter, useStaticAwareFormatter } from '../../constants';

const defaultSorter = getRenderedValue => sortBy(selectSorter(getRenderedValue));

const SelectControlStatic = forwardRef(
	(
		{
			className,
			disabled,
			onChange,
			value,
			items,
			formatter = IdentityFormatter,
			sorter = defaultSorter,
			statics = [],
			name,
			id,
			disableLexicographicOrder = false,
		},
		ref
	) => {
		const [defaultStatic, setDefaultStatic] = useState(null);
		const Formatter = useStaticAwareFormatter(formatter);

		// NOTE: Can't use `static` because it's a keyword.
		useEffect(() => statics.forEach(s => s.default && setDefaultStatic(s)), [statics]);

		useEffect(() => {
			if (isNilOrEmpty(value)) {
				onChange(defaultStatic);
			}
		}, [value, defaultStatic]);

		const getSorter =
			disableLexicographicOrder && sorter === defaultSorter ? always(identity) : sorter;

		return (
			<Downshift
				// NOTE: This is just to suppress some downshift warnings (we use formatters!)
				itemToString={identity}
				selectedItem={value}
				onChange={selectedItem => onChange(selectedItem)}
			>
				{({ getToggleButtonProps, isOpen, getMenuProps, highlightedIndex, getItemProps }) => {
					const renderItems = compose(
						mapIndexed((item, index) => {
							const key = isObject(item) ? item.key : item;

							return (
								<MenuItem
									className={cx('', {
										active: highlightedIndex === index,
										selected: item === value,
									})}
									{...getItemProps({
										item,
										key,
										index,
									})}
								>
									<Formatter>{item}</Formatter>
								</MenuItem>
							);
						}),
						getSorter(Formatter.formatAsPrimitive),
						filter(item => !item.hidden)
					);

					// TODO: For a11y, we should render an input and pass `getInputProps()` to it.
					// TODO: For a11y, we should pass `getLabelProps()` to the label.
					// TODO: For a11y, we should pass `getRootProps()` to the root element.
					return (
						<div ref={ref} data-test-id={name}>
							<Button
								className={cx({ 'form-control--active': isOpen }, className)}
								disabled={disabled}
								tabIndex="0"
								data-test-id={`${name}_label`}
								{...getToggleButtonProps({ name, id })}
							>
								{isNotNil(value) && <Formatter>{value}</Formatter>}
							</Button>
							{!disabled && isOpen && (
								<Menu {...getMenuProps()}>{items && renderItems([...statics, ...items])}</Menu>
							)}
						</div>
					);
				}}
			</Downshift>
		);
	}
);

SelectControlStatic.propTypes = {
	className: PropTypes.string,
	disableLexicographicOrder: PropTypes.bool,
	disabled: PropTypes.bool,
	formatter: PropTypes.elementType,
	id: PropTypes.string,
	items: PropTypes.arrayOf(stringOrNumberPropType).isRequired,
	name: PropTypes.string,
	onChange: PropTypes.func,
	sorter: PropTypes.func,
	statics: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.any,
};

SelectControlStatic.displayName = 'SelectControlStatic';

export default SelectControlStatic;
