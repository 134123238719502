import { ReactNode } from 'react';

import { StaticModal, StaticModalProps } from './StaticModal';
import { StaticModalContent } from './StaticModalContent';
import { StaticModalFooter } from './StaticModalFooter';
import { StaticModalHeader } from './StaticModalHeader';

export interface BasicStaticModalProps extends StaticModalProps {
	canContentOverflow?: boolean;
	footer?: ReactNode;
	isTitleCentered?: boolean;
	title?: ReactNode;
}

export const BasicStaticModal = ({
	canContentOverflow,
	children,
	footer,
	isTitleCentered,
	title,
	...otherProps
}: BasicStaticModalProps) => (
	<StaticModal {...otherProps}>
		{title && <StaticModalHeader isCentered={isTitleCentered} title={title} />}
		{children && (
			<StaticModalContent canContentOverflow={canContentOverflow} isBeforeFooter={Boolean(footer)}>
				{children}
			</StaticModalContent>
		)}
		{footer && <StaticModalFooter>{footer}</StaticModalFooter>}
	</StaticModal>
);
