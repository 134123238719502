import React from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';

const Resizer = ({ axis, ...otherProps }) => (
	<Draggable
		axis={axis}
		defaultClassName="resizer"
		defaultClassNameDragging=""
		// NOTE: `position` is necessary to make <Resizer /> controlled.
		// We want it to always be in the same position within the containing component.
		position={{ [axis]: 0 }}
		{...otherProps}
	>
		<span role="separator" />
	</Draggable>
);

Resizer.propTypes = {
	axis: PropTypes.oneOf(['x', 'y']).isRequired,
};

export default Resizer;
