import { Backdrop } from '@creditinfo-ui/atoms';
import { TransitionSpeed } from '@creditinfo-ui/styles';
import {
	Transition,
	useFadeTransitionStyles,
	useSlideTransitionStyles,
} from '@creditinfo-ui/transitions';
import { stopPropagation } from '@creditinfo-ui/utils';
import { cx } from 'ramda-extension';
import { ReactNode } from 'react';

type ModalTransitionType = 'slide' | 'fade';

export interface ModalPresenterRootProps {
	children: ReactNode;
	className: string;
	isAlwaysMounted: boolean;
	onBackdropClick: () => void;
	onTransitionExited: () => void;
	transitionSpeed: TransitionSpeed;
	transitionType: ModalTransitionType;
	visibilityTrigger: boolean;
	zIndex?: number;
}

export const ModalPresenterRoot = ({
	children,
	className,
	isAlwaysMounted,
	onBackdropClick,
	onTransitionExited,
	transitionSpeed,
	transitionType,
	visibilityTrigger,
	zIndex,
}: ModalPresenterRootProps) => {
	const transitions = {
		fade: useFadeTransitionStyles(transitionSpeed),
		slide: useSlideTransitionStyles(transitionSpeed),
	};

	const transition = transitions[transitionType];
	const { classNames, baseClassName } = transition;
	const { classNames: backdropClassNames, baseClassName: backdropBaseClassName } = transitions.fade;

	return (
		<Transition in={visibilityTrigger} onExited={onTransitionExited} speed={transitionSpeed}>
			{state => (
				<Backdrop
					className={cx(backdropBaseClassName, backdropClassNames[state])}
					isExternallyHidden={state !== 'entered'}
					onClick={onBackdropClick}
					zIndex={zIndex}
				>
					<div
						className={cx(baseClassName, classNames[state], className)}
						onClick={stopPropagation}
					>
						{state === 'exited' && !isAlwaysMounted ? null : children}
					</div>
				</Backdrop>
			)}
		</Transition>
	);
};
