import { Sha256 } from '@aws-crypto/sha256-js';
import { base64UrlEncode } from '@ci/utils';

const hasBrowserCryptoSupport = typeof window !== 'undefined' && Boolean(window.crypto?.subtle);

/*
 * Generate a secure random string using the browser crypto functions
 */
export const generateRandomString = (): string => {
	if (hasBrowserCryptoSupport) {
		const array = new Uint32Array(28);
		window.crypto.getRandomValues(array);

		return Array.from(array, dec => `0${dec.toString(16)}`.substr(-2)).join('');
	}

	let result = '';

	for (let index = 0; index < 28; index++) {
		result += `0${Math.floor(Math.random() * 256).toString(16)}`.substr(-2);
	}

	return result;
};

/*
 * Calculate the SHA256 hash of the input text.
 * Returns a promise that resolves to an ArrayBuffer
 */
export const sha256 = async (plain: string): Promise<string> => {
	if (hasBrowserCryptoSupport) {
		const encoder = new TextEncoder();
		const data = encoder.encode(plain);
		const digest = await window.crypto.subtle.digest('SHA-256', data);

		return base64UrlEncode(digest);
	}

	const hash = new Sha256();
	hash.update(plain);
	const digest = await hash.digest();

	return base64UrlEncode(digest.buffer);
};
