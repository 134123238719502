import { createContext } from 'react';
import { T } from 'ramda';
import { noop } from 'ramda-extension';
import { MessageId } from './types';

export const TranslationModeContext = createContext<boolean>(false);

export interface TranslationFetchingContextValue {
	getIsTranslationFetched: (id: MessageId) => boolean;
	registerMessage: (id: MessageId) => void;
}

export const TranslationFetchingContext = createContext<TranslationFetchingContextValue>({
	registerMessage: noop,
	getIsTranslationFetched: T,
});
