import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import TooltipTrigger from 'react-popper-tooltip';
import { isTouchingEvent } from '@myci/utils';
import { cx, noop } from 'ramda-extension';

import { useTheme } from '../ThemeProvider';

import './Tooltip.scss';

const getPlacement = (placement, isRTL) => {
	if (isRTL) {
		if (placement === TooltipPlacements.left) {
			return TooltipPlacements.right;
		}
		if (placement === TooltipPlacements.right) {
			return TooltipPlacements.left;
		}
	}

	return placement;
};

const Tooltip = ({
	children,
	tooltip,
	showArrow,
	showTooltip,
	placement,
	onTooltipVisibilityChange,
	isUpperCase,
	...rest
}) => {
	const { isRTL } = useTheme();
	const realPlacement = getPlacement(placement, isRTL);

	return (
		<TooltipTrigger
			{...rest}
			defaultTooltipShown={showTooltip}
			onVisibilityChange={onTooltipVisibilityChange}
			placement={realPlacement}
			tooltip={({ getArrowProps, arrowRef, getTooltipProps, tooltipRef }) => (
				<div
					{...getTooltipProps({
						ref: tooltipRef,
						className: cx('tooltip', { 'upper-case': isUpperCase }),
					})}
				>
					{showArrow && (
						<div
							{...getArrowProps({
								ref: arrowRef,
								className: 'tooltip-arrow',
								'data-placement': realPlacement,
							})}
						/>
					)}
					<div className="tooltip-body">{tooltip}</div>
				</div>
			)}
		>
			{({ getTriggerProps, triggerRef }) => {
				const { onMouseEnter, ...otherTriggerProps } = getTriggerProps();

				const handleMouseEnter = e => {
					if (!isTouchingEvent()) {
						onMouseEnter(e);
					}
				};

				return cloneElement(children, {
					ref: triggerRef,
					onMouseEnter: handleMouseEnter,
					...otherTriggerProps,
				});
			}}
		</TooltipTrigger>
	);
};

export const TooltipPlacements = {
	top: 'top',
	right: 'right',
	bottom: 'bottom',
	left: 'left',
};

export const Triggers = ['hover', 'click', 'right-click'];

Tooltip.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Tooltip upper case or not */
	isUpperCase: PropTypes.bool,
	/** Function called on state change */
	onTooltipVisibilityChange: PropTypes.func,
	/** Tooltip position - top, right, bottom or left */
	placement: PropTypes.oneOf(Object.keys(TooltipPlacements)),
	/** Show tooltip arrow or not */
	showArrow: PropTypes.bool,
	/** Show tooltip by default or not */
	showTooltip: PropTypes.bool,
	/** Content of tooltip */
	tooltip: PropTypes.node,
	/** Tooltip trigger - hover, click or right-click */
	trigger: PropTypes.oneOf(Triggers),
};

Tooltip.defaultProps = {
	isUpperCase: true,
	placement: 'bottom',
	trigger: 'hover',
	showTooltip: false,
	showArrow: true,
	onTooltipVisibilityChange: noop,
};

export default Tooltip;
