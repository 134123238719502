"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.changeLocale = exports.Link = void 0;

var _Link = _interopRequireDefault(require("./Link"));

exports.Link = _Link.default;

var _changeLocale = require("./changeLocale");

exports.changeLocale = _changeLocale.changeLocale;