import {
	InstanceTypes,
	currentInstanceType,
	getCurrentInstanceEnv,
	getEnvironmentVariable,
	makeMiddleware,
} from '@myci/utils';
import { forEach, pick } from 'ramda';
import { getIsAnyResponseAction, makeApiMiddleware } from '@ci/api';
import { addToast } from '@ci/toasts';
import { getErrorMessageFromResponse, makeProcessApiErrors } from './utils';
import { errorSeverities } from './constants';
import m from './messages';

const { baseURL, applicationKey } = getCurrentInstanceEnv();
const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

export const makeApiMiddlewareByApplicationType = applicationType =>
	makeApiMiddleware({
		baseUrl: baseURL,
		getDownloadedChunkResponseData: response =>
			isBoInstance ? response.data : response.result.data.data.data,
		getParsedErrorPayload: response => {
			const errorMessage = getErrorMessageFromResponse(response);

			return errorMessage;
		},
		getParsedResponseMeta: response => {
			const errorMessage = getErrorMessageFromResponse(response);
			const responseMetadata = pick(['error', 'status', 'requestId'])(response);

			return {
				...responseMetadata,
				errors: makeProcessApiErrors(applicationType)(response),
				errorMessage,
			};
		},
		selectHeaders: () => ({
			'X-Application-Key': applicationKey,
		}),
	});

const toastTypesBySeverity = {
	[errorSeverities.unexpectedError]: 'danger',
	[errorSeverities.warning]: 'warning',
};

const messagesBySeverity = {
	[errorSeverities.unexpectedError]: m.apiError,
	[errorSeverities.warning]: m.apiWarning,
};

export interface ApiError {
	message: string;
	severity: string;
}

export const apiErrorToastMiddleware = makeMiddleware(
	getIsAnyResponseAction,
	({ dispatch }) =>
		action => {
			const { errors, requestId } = action.meta;

			if (errors) {
				forEach((error: ApiError) => {
					if (
						error.severity === errorSeverities.warning &&
						getEnvironmentVariable('GATSBY_DISABLE_NOTIFICATION_WARNINGS') === '1'
					) {
						return;
					}

					const message = messagesBySeverity[error.severity];
					const toastType = toastTypesBySeverity[error.severity];

					if (toastType && message) {
						dispatch(
							addToast({
								type: toastType,
								content: message,
								values: {
									message: error.message,
									requestId,
								},
							})
						);
					}
				}, errors);
			}
		}
);
