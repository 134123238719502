import React from 'react';
import PropTypes from 'prop-types';
import { T, cond, identity } from 'ramda';
import { alwaysNull, cx, isNotNil, isString, mapIndexed, noop } from 'ramda-extension';
import Downshift from 'downshift';

import Icon from '../Icon';
import Button from '../Button';
import Menu from '../Menu';
import MenuItem from '../MenuItem';

import './Dropdown.scss';

const getIconElement = cond([
	[isString, type => <Icon type={type} />],
	[isNotNil, IconElement => <IconElement />],
	[T, alwaysNull],
]);

const renderDropdownItems = ({
	options,
	highlightedIndex,
	getItemProps,
	getKey,
	disabledItems,
	renderItem,
}) =>
	mapIndexed((item, index) => (
		<MenuItem
			className={cx('dropdown__item', {
				'dropdown__item--active': highlightedIndex === index,
				'dropdown__item--disabled': disabledItems && disabledItems[index],
			})}
			{...getItemProps({
				item,
				key: getKey && getKey(item),
				index,
			})}
		>
			{renderItem(item, index)}
		</MenuItem>
	))(options);

const Dropdown = ({
	getKey = identity,
	className,
	renderItem = identity,
	disabledItems,
	icon,
	kind,
	label,
	options,
	outline,
	size,
	onChange = noop,
	onSelect = noop,
	disabled,
	menuHeight,
	...otherProps
}) => {
	const iconElement = getIconElement(icon);

	return (
		<Downshift
			onChange={event => (isNotNil(onChange) ? onChange(event, getKey(event), event) : null)}
			onSelect={event => (isNotNil(onSelect) ? onSelect(event, getKey(event), event) : null)}
			itemToString={selectedItem => (selectedItem ? selectedItem.localName : '')}
		>
			{({ getMenuProps, getItemProps, isOpen, highlightedIndex, getToggleButtonProps }) => (
				<div
					className={cx(
						'dropdown',
						{ open: isOpen },
						{
							[`dropdown--${kind}`]: kind && !outline,
							[`dropdown--${size}`]: size,
							[`dropdown--outline-${kind}`]: outline,
						},
						className
					)}
					{...otherProps}
				>
					<Button tabIndex="0" className="dropdown__selected" {...getToggleButtonProps()}>
						{iconElement}
						{label}
					</Button>
					{!disabled && isOpen && (
						<Menu className="dropdown-menu" {...getMenuProps({ style: { maxHeight: menuHeight } })}>
							{options &&
								renderDropdownItems({
									options,
									highlightedIndex,
									getItemProps,
									getKey,
									disabledItems,
									renderItem,
								})}
						</Menu>
					)}
				</div>
			)}
		</Downshift>
	);
};

Dropdown.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	disabledItems: PropTypes.arrayOf(PropTypes.bool),
	getKey: PropTypes.func,
	icon: PropTypes.string,
	kind: PropTypes.string,
	label: PropTypes.node,
	menuHeight: PropTypes.number,
	onChange: PropTypes.func,
	onSelect: PropTypes.func,
	options: PropTypes.array,
	outline: PropTypes.bool,
	renderItem: PropTypes.func,
	size: PropTypes.string,
	type: PropTypes.string,
};

export default Dropdown;
