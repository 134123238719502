import { useCallback, useContext, useMemo } from 'react';
import { RendererContext, ThemeContext } from 'react-fela';
import { isArray } from 'ramda-extension';
import { darken, lighten, transparentize } from 'polished';
import { breakpointMediaQueries } from './breakpoints';
import {
	BaseNativeTheme,
	BaseTheme,
	NativeStyle,
	NativeStyleObject,
	NativeStyleOrStylesParam,
	NativeStyleUtils,
	Style,
	StyleOrStylesParam,
	StyleUtils,
} from './types';
import { getValueAndUnit, multiply, sum } from './utils';
import { processNativeStyles, processStyles } from './processStyles';
import { mediaQueries } from './mediaQueries';
import { useDirection } from './useDirection';

export const useStyles = () => {
	const renderer = useContext(RendererContext);

	if (!renderer) {
		throw new Error('The `useStyles()` hook can only be used inside a `StylesProvider`.');
	}

	const theme = useContext(ThemeContext) as BaseTheme;
	const nativeTheme = useContext(ThemeContext) as BaseNativeTheme;
	const directionUtils = useDirection();

	const utils: StyleUtils = useMemo(
		() => ({
			...theme,
			...directionUtils,
			breakpoints: breakpointMediaQueries,
			darken,
			getValueAndUnit,
			lighten,
			media: mediaQueries,
			multiply,
			sum,
			transparentize,
		}),
		[directionUtils, theme]
	);

	const nativeUtils: NativeStyleUtils = useMemo(
		() => ({
			...nativeTheme,
			...directionUtils,
			darken,
			lighten,
			transparentize,
		}),
		[directionUtils, nativeTheme]
	);

	const applyStyle = <TProps extends object>(
		...params: {} extends TProps
			? [styleOrStyles: StyleOrStylesParam<TProps>, props?: TProps]
			: [styleOrStyles: StyleOrStylesParam<TProps>, props: TProps]
	) => {
		const [styleOrStyles, props] = params;

		const invokeStyle = (style: Style<any>) => style(utils, props ?? {});

		const rule = () => {
			if (isArray(styleOrStyles)) {
				return processStyles(styleOrStyles, invokeStyle);
			}

			return processStyles([styleOrStyles], invokeStyle);
		};

		// NOTE: `fela-plugin-bidi` depends on `theme.direction` being set.
		// NOTE: The default behaviour of `useFela` is to pass `propsWithTheme` down. Our props will
		// never have a standardized structure, so we don't want any of our plugins to depend on them.
		return renderer.renderRule(rule as any, { theme: { direction: directionUtils.direction } });
	};

	const applyNativeStyle = <TProps extends object>(
		...params: {} extends TProps
			? [styleOrStyles: NativeStyleOrStylesParam<TProps>, props?: TProps]
			: [styleOrStyles: NativeStyleOrStylesParam<TProps>, props: TProps]
	) => {
		const [styleOrStyles, props] = params;

		const invokeStyle = (style: NativeStyle<any>) => style(nativeUtils, props ?? {});

		const rule = () => {
			if (isArray(styleOrStyles)) {
				return processNativeStyles(styleOrStyles, invokeStyle);
			}

			return processNativeStyles([styleOrStyles], invokeStyle);
		};

		return renderer.renderRule(rule as any, {}) as NativeStyleObject;
	};

	const applyGlobalStyle = (style: Style, selector: string) =>
		renderer.renderStatic(style(utils, {}) as any, selector);

	const memoizedApplyStyle = useCallback(applyStyle, [renderer, utils, directionUtils]);
	const memoizedApplyNativeStyle = useCallback(applyNativeStyle, [renderer, nativeUtils]);
	const memoizedApplyGlobalStyle = useCallback(applyGlobalStyle, [renderer, utils]);

	return {
		applyStyle: memoizedApplyStyle,
		applyNativeStyle: memoizedApplyNativeStyle,
		applyGlobalStyle: memoizedApplyGlobalStyle,
		utils,
		nativeUtils,
	};
};
