import * as R from 'ramda';
import { equalsLength, isString } from 'ramda-extension';

export const parseQueryParams = R.compose(
	R.map(R.when(isString, decodeURIComponent)),
	R.fromPairs,
	R.map(R.when(equalsLength(1), R.append(true))),
	R.map(R.split('=')),
	R.split('&'),
	R.when(R.startsWith('?'), R.tail)
);
