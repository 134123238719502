import { makeActionTypes, makeReducer, makeSimpleActionCreator } from 'redux-syringe';

export const ActionTypes = makeActionTypes('@nav', [
	'SET_IS_LINK_OPEN',
	'SET_IS_NAVIGATION_XS_OPEN',
	'SET_IS_WIDE_NAVIGATION',
	'SET_IS_LANGUAGE_SWITCHER_OPEN',
	'SET_IS_BACKDROP_VISIBLE',
	'SET_IS_USER_MENU_OPEN',
	'SET_SLUG',
]);

export const setIsLinkOpen = makeSimpleActionCreator(ActionTypes.SET_IS_LINK_OPEN);
export const setIsNavigationXSOpen = makeSimpleActionCreator(ActionTypes.SET_IS_NAVIGATION_XS_OPEN);
export const setIsWideNavigation = makeSimpleActionCreator(ActionTypes.SET_IS_WIDE_NAVIGATION);
export const setIsLanguageSwitcherOpen = makeSimpleActionCreator(
	ActionTypes.SET_IS_LANGUAGE_SWITCHER_OPEN
);
export const setIsBackdropVisible = makeSimpleActionCreator(ActionTypes.SET_IS_BACKDROP_VISIBLE);
export const setIsUserMenuOpen = makeSimpleActionCreator(ActionTypes.SET_IS_USER_MENU_OPEN);
export const setSlug = makeSimpleActionCreator(ActionTypes.SET_SLUG);

export const initialState = {
	isLinkOpen: false,
	isNavigationXSOpen: false,
	isWideNavigation: false,
	isLanguageSwitcherOpen: false,
	isBackdropVisible: false,
	isUserMenuOpen: false,
	slug: null,
};

export const reducer = makeReducer(
	[
		[ActionTypes.SET_IS_LINK_OPEN, (state, { payload }) => ({ ...state, isLinkOpen: payload })],
		[
			ActionTypes.SET_IS_NAVIGATION_XS_OPEN,
			(state, { payload }) => ({ ...state, isNavigationXSOpen: payload }),
		],
		[
			ActionTypes.SET_IS_WIDE_NAVIGATION,
			(state, { payload }) => ({ ...state, isWideNavigation: payload }),
		],
		[
			ActionTypes.SET_IS_LANGUAGE_SWITCHER_OPEN,
			(state, { payload }) => ({ ...state, isLanguageSwitcherOpen: payload }),
		],
		[
			ActionTypes.SET_IS_BACKDROP_VISIBLE,
			(state, { payload }) => ({ ...state, isBackdropVisible: payload }),
		],
		[
			ActionTypes.SET_IS_USER_MENU_OPEN,
			(state, { payload }) => ({ ...state, isUserMenuOpen: payload }),
		],
		[ActionTypes.SET_SLUG, (state, { payload }) => ({ ...state, slug: payload })],
	],
	{}
);
