"use strict";

exports.__esModule = true;
exports.toUrlLocale = exports.removePrefix = exports.isAddressProbablyInvalid = void 0;

var _ramda = require("ramda");

var _ramdaExtension = require("ramda-extension");

var toUrlLocale = (0, _ramda.compose)((0, _ramda.join)('-'), (0, _ramda.slice)(0, 2), (0, _ramda.split)('-'), (0, _ramda.replace)('_', '-'), _ramda.toLower);
exports.toUrlLocale = toUrlLocale;
var isAddressProbablyInvalid = (0, _ramda.complement)((0, _ramdaExtension.startsWithPrefix)('/'));
exports.isAddressProbablyInvalid = isAddressProbablyInvalid;

var removePrefix = function removePrefix(pathname) {
  var base = typeof __BASE_PATH__ !== 'undefined' ? __BASE_PATH__ : __PATH_PREFIX__;
  return base && pathname.indexOf(base) === 0 ? pathname.slice(base.length) : pathname;
};

exports.removePrefix = removePrefix;