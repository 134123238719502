import { hasPath, once } from 'ramda';

/**
 * Polyfills [INTL feature](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global\_Objects/Intl).
 * @return {Promise}
 */
export const polyfillIntl = once(() => {
	if (typeof window.Intl === 'undefined') {
		return import('intl');
	}

	// NOTE: There is no better reasonable way to polyfill `formatToParts` in Microsoft browsers.
	// Polyfill should be here: https://github.com/tc39/proposal-intl-formatToParts/issues/4
	// Relevant issue in `intl` package: https://github.com/andyearnshaw/Intl.js/issues/260
	// Idea for deleting `window.Intl`: https://github.com/Financial-Times/polyfill-service/pull/1750
	if (!hasPath(['NumberFormat', 'prototype', 'formatToParts'], window.Intl)) {
		delete window.Intl;

		return import('intl');
	}

	return Promise.resolve();
});
