import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { cx, isNotNil } from 'ramda-extension';
import { isEmpty, map } from 'ramda';

import ClientLink from '../ClientLink';
import Icon from '../Icon';

import { getChildrenBySlug } from './utils';

const Navigation = ({
	breakpoint,
	currentSlug,
	customNavigation = [],
	isLanguageSwitcherOpen,
	isLinkOpen,
	isNavigationXSOpen,
	isUserMenuOpen,
	isWideNavigation,
	navigationLogoLink = 'app',
	navigationModules = [],
	onActive,
	onBackdropVisible,
	onLanguageSwitcherOpen,
	onLinkOpen,
	onNavigationXSOpen,
	onUserMenuOpen,
	onWideNavigation,
	renderHelp: Help,
	renderLogo: NavLogo,
	renderNavigationLanguageSwitcher: LanguageSwitcher,
	renderSearch: Search,
	renderUserMenu: Signout,
}) => {
	const innerNav = useRef(null);
	const scrollInnerNavDown = () => {
		innerNav.current.scrollTop = 10000;
	};
	const handleToggleClick = () => onNavigationXSOpen(!isNavigationXSOpen);
	const slugLinks = getChildrenBySlug(currentSlug, navigationModules);

	const handleItemHover = slug => {
		if (isEmpty(getChildrenBySlug(slug, navigationModules))) {
			return onActive(slug);
		}

		onActive(slug);
		onLinkOpen(!currentSlug || slug !== currentSlug || !isLinkOpen);
		onBackdropVisible(!isLinkOpen || slug !== currentSlug);
	};

	const handleItemHoverOut = () => {
		onActive(null);
		onLinkOpen(false);
		onBackdropVisible(false);
	};

	const handleItemClick = slug => e => {
		if (breakpoint === 'xs') {
			e.stopPropagation();
			onLanguageSwitcherOpen(false);
			onUserMenuOpen(false);
		}
		onActive(slug);
	};

	const handleSubItemClick = () => {
		onBackdropVisible(false);
		onLinkOpen(false);
		if (breakpoint === 'xs') {
			onNavigationXSOpen(!isNavigationXSOpen);
		}
	};

	const handleLogoClick = () => {
		onActive(null);
		onLinkOpen(false);
		if (breakpoint === 'xs') {
			onLanguageSwitcherOpen(false);
			onUserMenuOpen(false);
		}
	};

	useEffect(() => {
		if (isUserMenuOpen && breakpoint === 'xs') {
			scrollInnerNavDown();
		}
	}, [isUserMenuOpen, breakpoint]);

	const isTabletView = breakpoint === 'sm' || breakpoint === 'md';

	return (
		<nav
			className={cx('main-nav', {
				'main-nav--open': isLinkOpen || isNavigationXSOpen,
				'main-nav--wide': isWideNavigation,
			})}
		>
			<div className="main-nav__logo" onClick={handleLogoClick}>
				{isTabletView && (
					<Icon
						className="main-nav__tablet-switch"
						type="chevron-right"
						onClick={() => onWideNavigation(!isWideNavigation)}
					/>
				)}
				{(isWideNavigation || !isTabletView) && NavLogo && <NavLogo to={navigationLogoLink} />}
			</div>

			{breakpoint === 'xs' && (
				<div className="main-nav__mobile-toggle" onClick={handleToggleClick}>
					<span />
					<span />
					<span />
				</div>
			)}

			<div className="main-nav__holder">
				<div ref={innerNav} className="main-nav__inner">
					<ul className="main-nav__list">
						{Search && (
							<li onClick={() => onLinkOpen(false)}>
								<Search />
							</li>
						)}

						{map(({ icon, messageElement, slug, sublinks }) => {
							const isCurrent = slug === currentSlug;

							return (
								<li
									key={slug}
									onMouseEnter={() => handleItemHover(slug)}
									onMouseLeave={handleItemHoverOut}
								>
									<ClientLink
										to={slug}
										getProps={({ isPartiallyCurrent }) => ({
											className: cx('main-nav__link', {
												active: (isPartiallyCurrent && !currentSlug) || isCurrent,
												open: isLinkOpen && breakpoint === 'xs' && isCurrent,
											}),
										})}
										onClick={handleItemClick(slug)}
									>
										<Icon type={icon || slug} />
										{messageElement}
									</ClientLink>

									{breakpoint !== 'xs' && isLinkOpen && isCurrent && slugLinks && (
										<div className="sub-nav">
											<ul className="sub-nav__list">
												{map(({ messageElement, slug }) => (
													<li key={slug}>
														<ClientLink
															to={`${currentSlug}/${slug}`}
															getProps={({ isPartiallyCurrent }) => ({
																className: cx('sub-nav__link', { active: isPartiallyCurrent }),
															})}
															onClick={handleSubItemClick}
														>
															{messageElement}
														</ClientLink>
													</li>
												))(slugLinks)}
											</ul>
										</div>
									)}

									{breakpoint === 'xs' && isNotNil(sublinks) && (
										<div className="sub-nav-mobile">
											<ul className="sub-nav-mobile__list">
												{map(({ messageElement, slug }) => (
													<li key={slug}>
														<ClientLink
															to={`${slug}/${slug}`}
															getProps={({ isPartiallyCurrent }) => ({
																className: cx('sub-nav__link', {
																	active: isPartiallyCurrent,
																}),
															})}
															onClick={handleSubItemClick}
														>
															{messageElement}
														</ClientLink>
													</li>
												))(sublinks)}
											</ul>
										</div>
									)}
								</li>
							);
						})(navigationModules)}
					</ul>

					{Help && <Help />}

					{customNavigation.map(({ component: Component, name }) => (
						<Component isNavigationCollapsed={isTabletView && !isWideNavigation} key={name} />
					))}

					{breakpoint === 'xs' && LanguageSwitcher && (
						<LanguageSwitcher
							isLanguageSwitcherOpen={isLanguageSwitcherOpen}
							onLanguageSwitcherOpen={onLanguageSwitcherOpen}
						/>
					)}

					{breakpoint === 'xs' && Signout && (
						<Signout
							isUserMenuOpen={isUserMenuOpen}
							onNavigationXSOpen={onNavigationXSOpen}
							onUserMenuOpen={onUserMenuOpen}
						/>
					)}
				</div>
			</div>
		</nav>
	);
};

Navigation.propTypes = {
	breakpoint: PropTypes.string,
	currentSlug: PropTypes.string,
	customNavigation: PropTypes.array,
	isLanguageSwitcherOpen: PropTypes.bool,
	/** Is submenu for the link open */
	isLinkOpen: PropTypes.bool,
	isNavigationXSOpen: PropTypes.bool,
	isUserMenuOpen: PropTypes.bool,
	isWideNavigation: PropTypes.bool,
	isXS: PropTypes.bool,
	navigationLogoLink: PropTypes.string,
	navigationModules: PropTypes.array,
	/** Action for link activation */
	onActive: PropTypes.func,
	onBackdropVisible: PropTypes.func,
	onLanguageSwitcherOpen: PropTypes.func,
	/** Action for submenu opening */
	onLinkOpen: PropTypes.func,
	onNavigationXSOpen: PropTypes.func,
	onUserMenuOpen: PropTypes.func,
	onWideNavigation: PropTypes.func,
	renderHelp: PropTypes.elementType,
	renderLogo: PropTypes.elementType,
	renderNavigationLanguageSwitcher: PropTypes.elementType,
	renderSearch: PropTypes.elementType,
	renderUserMenu: PropTypes.elementType,
};

export default Navigation;
