import { prop, curry, filter, compose, o, assocPath, dissocPath, path } from 'ramda';
import { makeReducer } from 'redux-syringe';
import { isNotNilNorEmpty, scopeReducer } from '@ci/utils';
import { defaultToEmptyObject } from 'ramda-extension';

import { getLocalRequestId } from './requestIds';
import { getOriginType, getRequest } from '../utils';
import { ActionTypes } from '../actions';

export const selectPendingRequests = path(['api', 'pendingRequests']);

export const selectPendingRequestsByType = curry((type, state) =>
	compose(prop(type), selectPendingRequests)(state)
);

/**
 * @deprecated Use `selectIsThunkPending` instead.
 */
export const isFetching = curry((type, state) =>
	o(isNotNilNorEmpty, selectPendingRequestsByType(type))(state)
);

/**
 * @deprecated Use `selectIsThunkPendingBy` instead.
 */
export const isFetchingBy = curry((type, requestPredicate, state) =>
	compose(
		isNotNilNorEmpty,
		filter(requestPredicate),
		defaultToEmptyObject,
		selectPendingRequestsByType(type)
	)(state)
);

const getPendingRequestPath = requestAction => [
	getOriginType(requestAction),
	getLocalRequestId(requestAction),
];

export const pendingRequestsReducer = scopeReducer(
	'pendingRequests',
	makeReducer(
		[
			[
				ActionTypes.ADD_PENDING_REQUEST,
				(state, action) => assocPath(getPendingRequestPath(action.payload), action.payload, state),
			],
			[
				ActionTypes.REMOVE_PENDING_REQUEST,
				(state, action) => dissocPath(getPendingRequestPath(getRequest(action.payload)), state),
			],
		],
		{}
	)
);
