import { ReactNode } from 'react';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

const tabButtonLabelStyle = prepareStyle(utils => ({
	fontSize: utils.fontSizes.tabLabel,
	lineHeight: utils.lineHeights.base,
	whiteSpace: 'nowrap',
}));

export interface TabButtonLabelProps {
	children: ReactNode;
}

export const TabButtonLabel = ({ children }: TabButtonLabelProps) => {
	const { applyStyle } = useStyles();

	return <span className={applyStyle(tabButtonLabelStyle)}>{children}</span>;
};
