import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';

import Grid from '../Grid';
import HTMLDirection from '../HTMLDirection';
import Navigation from '../Navigation';
import SafeSuspense from '../SafeSuspense';
import Spinner from '../Spinner';
import { TemplatePageFooter } from '../TemplatePageFooter';
import { TemplatePageFooterContext } from '../../contexts';

import './TemplatePage.scss';

// TODO:
// eslint-disable-next-line react/display-name
const ApplicationHeader = memo(({ children }) => <header className="header">{children}</header>);

ApplicationHeader.propTypes = {
	children: PropTypes.node,
};

const TemplatePage = ({
	children,
	customNavigation = [],
	hasGlobalFooter = true,
	innerFallback = <Spinner />,
	navigationLogoLink,
	navigationModules = [],
	renderBackButton: BackButton,
	renderBreadcrumbs: Breadcrumbs,
	renderHeader: Header,
	renderHelp,
	renderLogo,
	renderNavigationLanguageSwitcher,
	renderPageFooter,
	renderSearch,
	renderUserMenu,
	rootFallback = <Spinner />,
	title,
}) => {
	const footerRef = useRef(null);

	return (
		<SafeSuspense fallback={rootFallback}>
			<HTMLDirection />

			<Helmet title={title}>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
				/>
			</Helmet>

			<Navigation
				customNavigation={customNavigation}
				navigationModules={navigationModules}
				navigationLogoLink={navigationLogoLink}
				renderHelp={renderHelp}
				renderLogo={renderLogo}
				renderNavigationLanguageSwitcher={renderNavigationLanguageSwitcher}
				renderSearch={renderSearch}
				renderUserMenu={renderUserMenu}
			/>

			<Grid className="min-h-100" flexDirection="column" justifyContent="between">
				<main className="content">
					<Grid container fluid>
						{Header && (
							<ApplicationHeader>
								<Header />
							</ApplicationHeader>
						)}

						{BackButton && <BackButton />}
						{Breadcrumbs && <Breadcrumbs />}

						<TemplatePageFooterContext.Provider value={footerRef}>
							<SafeSuspense fallback={innerFallback}>{children}</SafeSuspense>
						</TemplatePageFooterContext.Provider>
					</Grid>
				</main>

				<TemplatePageFooter
					hasGlobalFooter={hasGlobalFooter}
					renderPageFooter={renderPageFooter}
					ref={footerRef}
				/>
			</Grid>
		</SafeSuspense>
	);
};

TemplatePage.propTypes = {
	children: PropTypes.node,
	customNavigation: PropTypes.array,
	hasGlobalFooter: PropTypes.bool,
	innerFallback: PropTypes.node,
	instance: PropTypes.string,
	navigationLogoLink: PropTypes.string,
	navigationModules: PropTypes.array,
	renderBackButton: PropTypes.elementType,
	renderBreadcrumbs: PropTypes.elementType,
	renderHeader: PropTypes.elementType,
	renderHelp: PropTypes.elementType,
	renderLogo: PropTypes.elementType,
	renderNavigationLanguageSwitcher: PropTypes.elementType,
	renderPageFooter: PropTypes.elementType,
	renderSearch: PropTypes.elementType,
	renderUserMenu: PropTypes.elementType,
	rootFallback: PropTypes.node,
	title: PropTypes.string,
	user: PropTypes.string,
};

export default memo(TemplatePage);
