import { OPEN_UP } from 'react-dates/constants';

import { prepareStyle } from '@creditinfo-ui/styles';
import { TextInputStyleProps, textInputStyle } from '@creditinfo-ui/atoms';

const DATE_PICKER_OFFSET = 9;

export interface DateInputStyleProps {
	openDirection: string;
	textInputStyleProps: TextInputStyleProps;
}

export const dateInputStyle = prepareStyle<DateInputStyleProps>(
	(utils, { openDirection, textInputStyleProps }) => ({
		selectors: {
			'& .SingleDatePicker': {
				width: '100%',
			},
			'& .SingleDatePicker_picker': {
				background: 'none',
				zIndex: utils.zIndices.datePicker,
			},
			'& .DayPicker': {
				// NOTE: This is to fix tiny gaps between the calendar and the fang (caret pointing at input).
				transform: `translate(0, -${DATE_PICKER_OFFSET + (openDirection === OPEN_UP ? -1 : 1)}px)`,
			},
			'& .DateInput_fang': {
				// NOTE: `react-dates/lib/css/_datepicker.css` contains `left: 22px`.
				insetInlineStart: '22px',
				transform: `translate(0, -${DATE_PICKER_OFFSET}px)`,
			},
			'& .SingleDatePickerInput': {
				background: 'transparent',
				border: 0,
				borderRadius: 0,
				display: 'block',
			},
			'& .DateInput': {
				background: 'transparent',
				display: 'block',
				width: 'auto',
			},
			'& .CalendarDay__selected': {
				backgroundColor: utils.colors.primary,
				borderColor: utils.colors.primary,
			},
			'& .DayPicker_weekHeader_ul_1': {
				display: 'none',
			},
			'& .DateInput_input__disabled': {
				fontStyle: 'normal',
			},
			'& .DateInput_input': textInputStyle(utils, textInputStyleProps),
		},
	})
);
