import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import Grid from '../Grid';
import Icon from '../Icon';
import TextInput from '../TextInput';
import Button from '../Button';

import '../EditableText/EditableText.scss';
import useEditableText from './useEditableText';

const EditableText = ({ value, as: Wrapper, onChange, className, iconSize, ...otherProps }) => {
	const rootRef = useRef();

	const { inputProps, isEdit, onCancel, onConfirm, onClick } = useEditableText({
		rootRef,
		onChange,
		value,
	});

	return (
		<div
			ref={rootRef}
			className={cx(
				className,
				isEdit && 'editable-text--is-editing',
				'editable-text',
				`editable-text--type-${Wrapper}`
			)}
		>
			{isEdit ? (
				<Fragment>
					<TextInput
						className={cx(className, 'form-control--editable')}
						autoComplete="off"
						autoFocus
						{...otherProps}
						{...inputProps}
					/>
					<Box className="editable-text__check">
						<Button kind="blank" size="xs" onClick={() => onConfirm()}>
							<Icon size={14} type="success" />
						</Button>
						<Button kind="blank" size="xs" onClick={() => onCancel()}>
							<Icon size={14} type="cancel" />
						</Button>
					</Box>
				</Fragment>
			) : (
				<Grid flex alignItems="baseline" onClick={() => onClick()}>
					<Wrapper className={cx(className, 'editable-text__text')}>{value}</Wrapper>
					<Icon className="editable-text__icon" type="pencil" size={iconSize} />
				</Grid>
			)}
		</div>
	);
};

EditableText.defaultProps = {
	iconSize: 16,
	as: 'span',
};

EditableText.propTypes = {
	as: PropTypes.oneOf(['h1', 'span']),
	className: PropTypes.string,
	iconSize: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
};

export default EditableText;
