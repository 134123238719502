import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import './Timeline.scss';

const Timeline = ({ children, className, isClosed, isClosedByUser, double, ...otherProps }) => (
	<Box
		className={cx(
			className,
			'timeline',
			double && 'timeline--double',
			isClosed && 'timeline--closed',
			isClosedByUser && 'timeline--closed-by-user'
		)}
		{...otherProps}
	>
		{children}
	</Box>
);

Timeline.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	double: PropTypes.bool,
	isClosed: PropTypes.bool,
	isClosedByUser: PropTypes.bool,
};

export default Timeline;
