export const EntityDefinitions = {
	ACCOUNT_STATUS: {
		url: '/dataApi/accountStatus',
		key: 'accountStatus',
		formValueProp: 'value',
		formLabelProp: 'value',
	},
	SUBSCRIPTIONS_INDIVIDUAL: {
		url: '/subscriptions/individual',
		key: 'subscriptionsIndividual',
		formValueProp: 'key',
		formLabelProp: 'key',
	},
	SUBSCRIPTIONS_COMPANY: {
		url: '/subscriptions/company',
		key: 'subscriptionsCompany',
		formValueProp: 'key',
		formLabelProp: 'key',
	},
	REASONS_OF_REGISTRATION: {
		url: 'dataApi/reasonOfRegistration',
		key: 'reasonsOfRegistration',
		formValueProp: 'key',
		formLabelProp: 'value',
	},
};
