import { scopeReducer } from '@ci/utils';
import { makeReducer } from 'redux-syringe';
import { assocPath, curry, path } from 'ramda';
import { ActionTypes, RequestAction } from '../actions';
import { getOriginType, getIsAnyRequestAction } from '../utils';

export const timestampMiddleware = () => next => action => {
	if (getIsAnyRequestAction(action)) {
		return next(assocPath(['meta', 'timestamp'], Date.now(), action));
	}

	return next(action);
};

export const getTimestamp = path<number>(['meta', 'timestamp']);

export const selectIsRequestOutdated = curry(
	(requestAction: RequestAction, state) =>
		getTimestamp(requestAction)! <
		(path<number>(['api', 'requestTimestamps', getOriginType(requestAction)!], state) ?? 0)
);

export const requestTimestampsReducer = scopeReducer(
	'requestTimestamps',
	makeReducer(
		[
			[
				ActionTypes.REQUEST,
				(state, action) => ({
					...state,
					[getOriginType(action)!]: getTimestamp(action),
				}),
			],
		],
		{}
	)
);
