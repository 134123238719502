import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Text from '../Text';
import Grid from '../Grid';
import './Spinner.scss';

class Spinner extends React.Component {
	constructor(props) {
		super(props);

		const { visibilityDelay } = this.props;

		this.state = {
			showLoader: visibilityDelay <= 0,
		};
	}

	componentDidMount() {
		const { visibilityDelay } = this.props;
		this.timer = setTimeout(this.changeVisibility, visibilityDelay);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	changeVisibility = () => {
		this.setState({
			showLoader: true,
		});
	};

	render() {
		const { children, className, animationDisabled, size } = this.props;
		const { showLoader } = this.state;
		const isSmall = size === 'sm';
		const gridClasses = cx(['data-status', ...(animationDisabled ? [] : ['fade-in'])]);

		if (!showLoader) {
			return null;
		}

		const svg = (
			<svg
				className={cx(className, 'loader', { 'loader--sm': isSmall })}
				viewBox="0 0 60 60"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					className="loader-circle"
					cx="30"
					cy="30"
					r={isSmall ? '25' : '20'}
					stroke="#70c542"
					strokeWidth={isSmall ? '5' : '3'}
				/>
			</svg>
		);

		if (!children) {
			return svg;
		}

		return (
			<Grid alignItems="center" justifyContent="center" className={gridClasses}>
				{svg}
				<Text mb={0}>{children}</Text>
			</Grid>
		);
	}
}

Spinner.propTypes = {
	/** Determines wheter to animate or not. */
	animationDisabled: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	size: PropTypes.oneOf(['sm', 'lg']),
	/** Delay for showing loader in ms. */
	visibilityDelay: PropTypes.number,
};

Spinner.defaultProps = {
	visibilityDelay: 3000,
	size: 'lg',
};

export default Spinner;
