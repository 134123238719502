import { createThunk, request, selectIsThunkPending } from '@ci/api';
import { addToast } from '@ci/toasts';
import { InstanceTypes, currentInstanceType } from '@myci/utils';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { getLocale } from '@myci/intl';

import m from './messages';

export interface IdentifierDetail {
	allowedCountries: Array<{ key: string; value: string }>;
	name: string;
	requiredProperties: string[];
}

interface PrivacyPolicy {
	data: string;
	version: string;
}

export type UserType = number;

type IdentifierDetails = Record<UserType, IdentifierDetail[] | null>;

type DisputeReasonsType =
	| 'bouncedcheque'
	| 'contract'
	| 'debit'
	| 'telcopostpaid'
	| 'telcoprepaid'
	| 'utilities';

interface DisputeReason {
	key: DisputeReasonsType;
	value: string[];
}

export type DisputeReasons = DisputeReason[];

export interface City {
	code: string;
	name: string;
}

export interface DataApiState {
	apiVersion: string;
	cities: City[] | null;
	disputeReasons: DisputeReasons | null;
	foBaseUrl: string | null;
	identifierDetails: IdentifierDetails;
	isApiReachable: boolean;
	minAndroidVersion: string;
	minIosVersion: string;
	notificationSettings: NotificationSettingsData[];
	privacyPolicy: PrivacyPolicy | null;
}

interface GlobalState {
	dataApi: DataApiState;
}

interface VersionData {
	buildVersion: string;
	minAndroidVersion: string;
	minIosVersion: string;
}

export interface NotificationSettingsData {
	enabled: boolean;
	name: string;
}

const storeVersions = createAction<VersionData>('@dataApi/storeVersions');
const storeIsApiReachable = createAction<boolean>('@dataApi/storeIsApiReachable');
const storePrivacyPolicy = createAction<PrivacyPolicy>('@dataApi/storePrivacyPolicy');
const storeDisputeReasons = createAction<DisputeReasons>('@dataApi/storeDisputeReasons');
const storeIdentifierDetails = createAction<IdentifierDetails>('@dataApi/storeIdentifierDetails');
const storeNotificationSettings = createAction<NotificationSettingsData[]>(
	'@dataApi/storeNotificationSettings'
);
const storeCities = createAction<City[]>('@dataApi/storeCities');
const storeFoBaseUrl = createAction<string>('@dataApi/storeFoBaseUrl');

export const selectApiVersion = (state: GlobalState) => state.dataApi.apiVersion;
export const selectIsApiReachable = (state: GlobalState) => state.dataApi.isApiReachable;
export const selectMinAndroidVersion = (state: GlobalState) => state.dataApi.minAndroidVersion;
export const selectMinIosVersion = (state: GlobalState) => state.dataApi.minIosVersion;
export const selectPrivacyPolicy = (state: GlobalState) => state.dataApi.privacyPolicy;
export const selectDisputeReasons = (state: GlobalState) => state.dataApi.disputeReasons;
export const selectNotificationSettings = (state: GlobalState) =>
	state.dataApi.notificationSettings;
export const selectAllIdentifierDetails = (state: GlobalState) => state.dataApi.identifierDetails;
export const makeSelectIdentifierDetailsByUserType = (userType: UserType) => (state: GlobalState) =>
	selectAllIdentifierDetails(state)[userType];
export const selectCities = (state: GlobalState) => state.dataApi.cities;
export const selectFoBaseUrl = (state: GlobalState) => state.dataApi.foBaseUrl;

const initialState: DataApiState = {
	apiVersion: '',
	cities: null,
	disputeReasons: null,
	foBaseUrl: null,
	isApiReachable: false,
	minAndroidVersion: '',
	minIosVersion: '',
	notificationSettings: [],
	identifierDetails: {},
	privacyPolicy: null,
};

const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

export const fetchVersions = createThunk('@dataApi/fetchVersions', async ({ dispatch }) => {
	try {
		const successAction = await dispatch(
			request({
				url: '/dataApi/version',
				method: 'GET',
			})
		);

		dispatch(
			storeVersions(isBoInstance ? successAction.payload : successAction.payload.result.data)
		);
		dispatch(
			storeFoBaseUrl(
				isBoInstance
					? successAction.payload.baseUrlUiFo
					: successAction.payload.result.data.baseUrlUiFo
			)
		);
	} catch {
		dispatch(
			addToast({
				type: 'warning',
				content: m.apiVersionError,
			})
		);
	}
});

export const fetchIsApiReachable = createThunk(
	'@dataApi/fetchIsApiReachable',
	async ({ dispatch }) => {
		try {
			await dispatch(
				request({
					url: '/dataApi/version',
					method: 'GET',
				})
			);
			dispatch(storeIsApiReachable(true));
		} catch {
			dispatch(storeIsApiReachable(false));
		}
	}
);
export const selectIsFetchingApiReachable = selectIsThunkPending(fetchIsApiReachable);

export const fetchPrivacyPolicy = createThunk(
	'@dataApi/fetchPrivacyPolicy',
	async ({ dispatch, getState }) => {
		const languageCode = getLocale(getState());
		try {
			const successAction = await dispatch(
				request(
					{
						url: `/dataApi/PrivacyPolicy/${languageCode}`,
						method: 'GET',
					},
					{}
				)
			);
			const {
				data: { key, value },
			} = successAction.payload.result;

			dispatch(
				storePrivacyPolicy({
					data: value,
					version: key,
				})
			);
		} catch {
			dispatch(
				addToast({
					type: 'warning',
					content: m.privacyPolicyError,
				})
			);
		}
	}
);
export const selectIsFetchingPrivacyPolicy = selectIsThunkPending(fetchPrivacyPolicy);

export const fetchDisputeReasons = createThunk(
	'@dataApi/fetchDisputeReasons',
	async ({ dispatch }) => {
		try {
			const successAction = await dispatch(
				request(
					{
						url: `/dataApi/DisputeSettings`,
						method: 'GET',
					},
					{}
				)
			);
			const { data } = successAction.payload.result;

			dispatch(storeDisputeReasons(data));
		} catch {
			dispatch(
				addToast({
					type: 'warning',
					content: m.disputeReasonError,
				})
			);
		}
	}
);
export const selectIsFetchingDisputeReasons = selectIsThunkPending(fetchDisputeReasons);

export const fetchIdentifierDetails = createThunk(
	'@dataApi/fetchIdentifierDetails',
	async ({ dispatch }, userType: UserType) => {
		try {
			const successAction = await dispatch(
				request({
					url: `/dataApi/identifierDetails/${userType}`,
					method: 'GET',
				})
			);
			dispatch(
				storeIdentifierDetails({
					[userType]: isBoInstance ? successAction.payload : successAction.payload.result.data,
				})
			);
		} catch {
			dispatch(
				addToast({
					type: 'warning',
					content: m.idNumberConfigError,
				})
			);
		}
	}
);

export const fetchNotificationsSettings = createThunk(
	{
		originType: '@dataApi/fetchNotificationsSettings',
	},
	async ({ dispatch }) => {
		const successAction = await dispatch(request({ url: '/dataApi/messagingChannels' }));
		const { data } = successAction.payload.result;
		dispatch(storeNotificationSettings(data));
	}
);

export const selectIsFetchingNotificationsSettings = selectIsThunkPending(
	fetchNotificationsSettings
);

export const fetchCities = createThunk(
	{
		originType: '@dataApi/fetchCities',
		errorMessage: m.citiesError,
	},
	async ({ dispatch }) => {
		const successAction = await dispatch(
			request({
				url: '/dataApi/cities',
				method: 'GET',
			})
		);
		dispatch(storeCities(isBoInstance ? successAction.payload : successAction.payload.result.data));
	}
);
export const selectIsFetchingCities = selectIsThunkPending(fetchCities);

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(storeVersions, (state, action) => ({
			...state,
			apiVersion: action.payload.buildVersion,
			minAndroidVersion: action.payload.minAndroidVersion,
			minIosVersion: action.payload.minIosVersion,
		}))
		.addCase(storeIsApiReachable, (state, action) => ({
			...state,
			isApiReachable: action.payload,
		}))
		.addCase(storePrivacyPolicy, (state, action) => ({
			...state,
			privacyPolicy: action.payload,
		}))
		.addCase(storeDisputeReasons, (state, action) => ({
			...state,
			disputeReasons: action.payload,
		}))
		.addCase(storeNotificationSettings, (state, action) => ({
			...state,
			notificationSettings: action.payload,
		}))
		.addCase(storeCities, (state, action) => ({
			...state,
			cities: action.payload,
		}))
		.addCase(storeFoBaseUrl, (state, action) => ({
			...state,
			foBaseUrl: action.payload,
		}))
		.addCase(storeIdentifierDetails, (state, action) => ({
			...state,
			identifierDetails: {
				...state.identifierDetails,
				...action.payload,
			},
		}));
});
