import { defineMessages } from 'react-intl';

export default defineMessages({
	errorReportCounter: {
		id: 'planManagement.errorReportCounter',
		defaultMessage: 'Error loading report counter',
		description: 'Error msg report counter',
	},
	errorPricingPlansConfig: {
		id: 'planManagement.errorPricingPlansConfig',
		defaultMessage: 'Error loading pricing plan config',
		description: 'Error msg report counter',
	},
});
