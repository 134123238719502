import React from 'react';
import PropTypes from 'prop-types';
import { juxt } from 'ramda';
import { cx, noop } from 'ramda-extension';

import Link from '../Link';

const handleClick = id => event => {
	event.preventDefault();
	const element = document.getElementById(id) || null;

	if (!element) {
		return;
	}

	window.scrollTo({
		top: element.offsetTop - 70,
		behavior: 'smooth',
	});
};

const AnchorLink = ({ children, className, id, onClick = noop, ...rest }) => (
	<Link
		className={cx(className)}
		onClick={juxt([onClick, handleClick(id)])}
		href={`#${id}`}
		{...rest}
	>
		{children}
	</Link>
);

AnchorLink.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** Identifier of target element */
	id: PropTypes.string,
	/**
	 * Click handler
	 * @sig String -> Event -> a
	 */
	onClick: PropTypes.func,
};

export default AnchorLink;
