import React from 'react';
import { cx, noop } from 'ramda-extension';
import PropTypes from 'prop-types';

import Box from '../Box';
import Grid from '../Grid';
import Button from '../Button';
import Icon from '../Icon';
import PortalWithBackdrop from '../PortalWithBackdrop';

import './SlideModal.scss';

export const SLIDE_MODAL_CLASS = 'slide-modal';

const stopPropagation = e => e.stopPropagation();

export const ModalPropTypes = {
	/** Child content for modal window */
	children: PropTypes.any,
	/** Root class name */
	className: PropTypes.string,
	/** Has modal back button */
	hasBackButton: PropTypes.bool,
	/** Is modal visible */
	isVisible: PropTypes.bool,
	/** Modal id */
	modalId: PropTypes.string,
	/** On back function */
	onBack: PropTypes.func,
	/** On cancel function */
	onCancel: PropTypes.func,
	/** Size (width) */
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	/** Subtitle */
	subtitle: PropTypes.node,
	/** Title */
	title: PropTypes.node,
	/** Applied to root element. */
	wrapperProps: PropTypes.object,
};

const SlideModalBase = ({
	isVisible,
	className,
	hasBackButton,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	modalId,
	renderContent = noop,
	onBack = noop,
	onCancel = noop,
	size = 'sm',
	wrapperProps = {},
	...rest
}) => (
	<PortalWithBackdrop isVisible={isVisible} onBackdropClick={onCancel}>
		<Box
			className={cx(SLIDE_MODAL_CLASS, `${SLIDE_MODAL_CLASS}--${size}`, className)}
			key="slide-modal"
			onClick={stopPropagation}
			{...wrapperProps}
		>
			<Grid
				justifyContent="end"
				alignItems="center"
				className={cx(`${SLIDE_MODAL_CLASS}__controls`)}
			>
				{hasBackButton && (
					<Button size="xs" onClick={onBack} className="mr-auto">
						<Icon type="back" size={22} />
						Back
					</Button>
				)}
				<Button kind="blank" size="xs" onClick={onCancel}>
					<Icon type="cancel" size={18} />
				</Button>
			</Grid>
			{renderContent({ className: `${SLIDE_MODAL_CLASS}__inner`, ...rest })}
		</Box>
	</PortalWithBackdrop>
);

SlideModalBase.propTypes = { ...ModalPropTypes };

export default SlideModalBase;
