import React, { Fragment, useRef } from 'react';
import { createPortal } from 'react-dom';
import { cx, noop } from 'ramda-extension';
import PropTypes from 'prop-types';
import { Manager, Popper, Reference } from 'react-popper';
import { mergeRefs, useOnClickOutside } from '@ci/react-utils';

import useVisibilityController from '../../hooks/useVisibilityController';
import Box from '../Box';
import Grid from '../Grid';
import PopupTriggerIcon from '../PopupTriggerIcon';
import './Popup.scss';

const defaultPortalContainer = typeof document !== 'undefined' ? document.body : null;

const Popup = ({
	arrowProps: { className: arrowClassName, ...otherArrowProps } = {},
	wrapperProps: { className: wrapperClassName, ...otherWrapperProps } = {},
	children,
	placement = 'bottom',
	popperProps = {},
	portalContainer = defaultPortalContainer,
	referenceProps,
	renderIconWrapper = Fragment,
	renderTrigger: Trigger,
	iconClassName,
	iconProps,
	iconType = 'menu',
	iconSize,
	size,
	searchBarRef,
	searchBar,
	title,
	sortRef,
	sortArrows,
	visibility,
	onChangeVisibility = noop,
	onAfterVisibilityChange = noop,
}) => {
	const { isOpen, close, toggleVisibility } = useVisibilityController({
		visibility,
		onChangeVisibility,
		onAfterVisibilityChange,
	});

	const holderRef = useRef(null);
	const iconRef = useRef(null);

	useOnClickOutside([holderRef, iconRef], close);

	return (
		<Manager>
			<Reference {...referenceProps}>
				{({ ref }) => {
					const mergedRefs = mergeRefs([ref, iconRef]);

					const triggerProps = {
						ref: mergedRefs,
						onClick: toggleVisibility,
					};

					const iconWithWrapperProps = {
						iconClassName,
						iconSize,
						iconType,
						iconProps,
						renderIconWrapper,
						...triggerProps,
					};

					return Trigger ? (
						<Trigger {...triggerProps} />
					) : (
						<PopupTriggerIcon {...iconWithWrapperProps} />
					);
				}}
			</Reference>

			{(visibility || isOpen) &&
				createPortal(
					<Popper placement={placement} {...popperProps}>
						{({ arrowProps, placement, ref, style }) => (
							<Box
								className={cx({ popup: true, [`popup--size-${size}`]: size }, wrapperClassName)}
								data-placement={placement}
								ref={mergeRefs([ref, holderRef])}
								{...otherWrapperProps}
								style={{ ...style, ...popperProps.style }}
							>
								<Box
									{...arrowProps}
									{...otherArrowProps}
									className={cx('popup__arrow', arrowClassName)}
									data-placement={placement}
								/>
								<Box className="popup-menu">
									{searchBar && (
										<Box ref={searchBarRef} className="popup-menu__search">
											{searchBar}
										</Box>
									)}
									{(title || sortArrows) && (
										<Box ref={sortRef} className="popup-menu__sort">
											<Grid alignItems="center">
												{title}
												{sortArrows}
											</Grid>
										</Box>
									)}
									{children}
								</Box>
							</Box>
						)}
					</Popper>,
					portalContainer
				)}
		</Manager>
	);
};

Popup.propTypes = {
	arrowProps: PropTypes.object,
	children: PropTypes.node,
	iconClassName: PropTypes.string,
	iconProps: PropTypes.object,
	iconSize: PropTypes.string,
	iconType: PropTypes.string,
	onAfterVisibilityChange: PropTypes.func,
	onChangeVisibility: PropTypes.func,
	placement: PropTypes.string,
	popperProps: PropTypes.object,
	portalContainer: PropTypes.object,
	referenceProps: PropTypes.object,
	renderIconWrapper: PropTypes.elementType,
	renderTrigger: PropTypes.elementType,
	searchBar: PropTypes.node,
	searchBarRef: PropTypes.object,
	size: PropTypes.oneOf(['sm']),
	sortArrows: PropTypes.node,
	sortRef: PropTypes.object,
	title: PropTypes.node,
	/** Use together with onChangeVisibility to control the visibility of popup */
	visibility: PropTypes.bool,
	wrapperProps: PropTypes.object,
};

Popup.defaultProps = {};

export default Popup;
