import { TransitionSpeed, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { useMemo } from 'react';

import { ClassNamesByTransitionState, UseTransitionStyles } from '../types';

const transitionExitedStyle = prepareStyle(() => ({
	opacity: 0,
	pointerEvents: 'none',
	// HACK: Hide it, we can't use display: none because it will break animation
	transform: 'scale(0)',
}));

const transitionExitingStyle = prepareStyle(() => ({
	opacity: 0,
	pointerEvents: 'none',
}));

const transitionEnterStyle = prepareStyle(() => ({
	opacity: 1,
}));

interface TransitionBaseStyleProps {
	speed: TransitionSpeed;
}

const transitionBaseStyle = prepareStyle<TransitionBaseStyleProps>(
	({ transitions }, { speed }) => ({
		transitionDuration: transitions.speeds[speed],
		transitionProperty: 'opacity',
		transitionTimingFunction: transitions.easing,
	})
);

export const useFadeTransitionStyles: UseTransitionStyles = speed => {
	const { applyStyle } = useStyles();
	const classNames: ClassNamesByTransitionState = useMemo(
		() => ({
			entering: applyStyle(transitionEnterStyle),
			entered: applyStyle(transitionEnterStyle),
			exiting: applyStyle(transitionExitingStyle),
			exited: applyStyle(transitionExitedStyle),
		}),
		[applyStyle]
	);

	const baseClassName = applyStyle(transitionBaseStyle, { speed });

	return { classNames, baseClassName };
};
