import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, isEmpty, pathOr, pluck, reject, values } from 'ramda';
import { connect } from 'react-redux';
import { flipIncludes, memoizeWithIdentity } from 'ramda-extension';
import { getEntities } from '@ci/entities';
import { makeFormatter } from 'afformative';

import { SelectField } from '../../../forms';
import { fetchEntity } from '../../duck';

const memoizedPluck = memoizeWithIdentity(pluck);

// Please do not extend this mildly stupid functionality, this is for only simple cases
// when you are lazy enough to fetch and display entities in the select by yourself
const EntitySelectField = ({ fetchEntity, items, definition, ignoredItems = [], ...rest }) => {
	useEffect(() => {
		if (isEmpty(items)) {
			fetchEntity(definition);
		}
	}, [definition, fetchEntity, items]);

	return (
		<SelectField
			formatter={makeFormatter(value => pathOr(value, [value, definition.formLabelProp])(items))}
			items={compose(
				reject(flipIncludes(ignoredItems)),
				values,
				memoizedPluck(definition.formValueProp)
			)(items)}
			{...rest}
		/>
	);
};

EntitySelectField.propTypes = {
	definition: PropTypes.shape({
		formValueProp: PropTypes.string.isRequired,
		formLabelProp: PropTypes.string.isRequired,
		key: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
	}).isRequired,
	entity: PropTypes.string,
	fetchEntity: PropTypes.func,
	ignoredItems: PropTypes.arrayOf(PropTypes.number),
	items: PropTypes.object,
};

export default connect(
	(state, { definition }) => ({
		items: getEntities(definition.key)(state),
	}),
	{ fetchEntity }
)(EntitySelectField);
