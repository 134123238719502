import { typeEq } from '@ci/utils';
import { isEmpty, isNil, reject, length } from 'ramda';

import { MethodActionTypes } from '../actions';
import { isTaskEvent, makeCallback, getTaskId, getControlFlowId, assocTaskId } from '../utils';

const middleware = ({ dispatch }) => {
	const callbacksByTaskId = {};
	const keysByTaskId = {};
	const tasksByKey = {};
	const taskIdsByTask = new WeakMap();

	return next => action => {
		next(action);

		if (typeEq(MethodActionTypes.ENQUEUE, action)) {
			const { key, task, callback: userCallback } = action.payload;
			// NOTE: This control flow consists of a single task, so we are simplifying here.
			const taskId = getControlFlowId(action);
			const delegatedTaskId = getTaskId(action);

			// NOTE: To prevent issues when the task is defined statically.
			const nextTask = (...args) => task(...args);

			taskIdsByTask.set(nextTask, taskId);
			keysByTaskId[taskId] = key;
			callbacksByTaskId[taskId] = makeCallback(userCallback, dispatch, delegatedTaskId);

			if (tasksByKey[key]) {
				tasksByKey[key].push(nextTask);
			} else {
				tasksByKey[key] = [];
				const nextAction = nextTask();
				dispatch(assocTaskId(taskId, nextAction));
			}
		}

		if (typeEq(MethodActionTypes.ENQUEUE_REMOVE, action)) {
			const taskId = action.payload;
			const key = keysByTaskId[taskId];
			const tasks = tasksByKey[key];

			if (tasks) {
				const prevLength = length(tasks);
				tasksByKey[key] = reject(task => taskIdsByTask.get(task) === taskId, tasks);
				const nextLength = length(tasksByKey[key]);

				// NOTE: This is to check that there was indeed a task to remove.
				if (prevLength !== nextLength) {
					const callback = callbacksByTaskId[taskId];

					callback(action, { isCancelled: true });
				}
			}
		}

		if (typeEq(MethodActionTypes.ENQUEUE_CLEAR, action)) {
			const key = action.payload;

			if (tasksByKey[key]) {
				tasksByKey[key] = [];
			}
		}

		if (isTaskEvent(action)) {
			const previousTaskId = action.payload.taskId;
			const key = keysByTaskId[previousTaskId];

			// NOTE: An empty array signalizes that the queue is finished.
			// Missing array means that this task was not ours (but of another control flow method).
			if (isNil(tasksByKey[key])) {
				return;
			}

			const isLast = isEmpty(tasksByKey[key]);

			if (isLast) {
				delete tasksByKey[key];
			} else {
				const nextTask = tasksByKey[key].shift();
				const nextTaskId = taskIdsByTask.get(nextTask);
				const nextAction = nextTask(action.payload.event);
				dispatch(assocTaskId(nextTaskId, nextAction));
			}

			const callback = callbacksByTaskId[previousTaskId];
			callback(action, { isLast });
		}
	};
};

export default middleware;
