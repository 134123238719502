import { isInBrowser } from '@myci/utils';
import { noop } from 'ramda-extension';

export const getInitialState = (accessTokenKey, refreshTokenKey, postLoginRouteKey) => ({
	accessToken: (isInBrowser() && localStorage.getItem(accessTokenKey)) || null,
	refreshToken: (isInBrowser() && localStorage.getItem(refreshTokenKey)) || null,
	postLoginRoute: (isInBrowser() && localStorage.getItem(postLoginRouteKey)) || null,
});

const dummyStorage = {
	getItem: noop,
	removeItem: noop,
	setItem: noop,
};

export const Storage = isInBrowser() ? localStorage : dummyStorage;
