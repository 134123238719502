import React from 'react';
import PropTypes from 'prop-types';
import {
	compose,
	identity,
	init,
	isEmpty,
	isNil,
	last,
	map,
	path,
	prepend,
	reduce,
	reject,
	split,
} from 'ramda';
import { Match } from '@gatsbyjs/reach-router';
import Breadcrumbs from '../Breadcrumbs';
import Breadcrumb from '../Breadcrumb';

const getRouteParts = compose(reject(isEmpty), split('/'), path(['*']));

// NOTE: first draft, now the component is not really extensible
const ApplicationBreadcrumbs = ({ prefix, index, routes, ...rest }) => {
	const prefixComponent = prefix && (
		<Breadcrumb to="" key="prefix$$">
			{prefix}
		</Breadcrumb>
	);
	const indexComponent = index && (
		<Breadcrumb to="" key="index$$">
			{index}
		</Breadcrumb>
	);
	const routeParts = getRouteParts(rest);

	return (
		<Breadcrumbs>
			{isEmpty(routeParts)
				? prefixComponent
				: compose(
						reject(isNil),
						prefix ? prepend(prefixComponent) : identity,
						index ? prepend(indexComponent) : identity,
						map(part =>
							routes[part] ? (
								<Match key={part} path={`${part}/*`}>
									{ps =>
										ps.match ? (
											<Breadcrumb key={part} to={part}>
												{routes[part]}
											</Breadcrumb>
										) : null
									}
								</Match>
							) : null
						),
						init,
						reduce((acc, next) => (acc ? [...acc, `${last(acc)}/${next}`] : [next]), null)
				  )(routeParts)}
		</Breadcrumbs>
	);
};

ApplicationBreadcrumbs.propTypes = {
	/** Property passed by a @react/router */
	'*': PropTypes.string,
	index: PropTypes.node,
	/** Virtual category breadcrumb rendered before actual dynamic part of breadcrumbs. */
	prefix: PropTypes.node,
	/** Mapping from route to label */
	routes: PropTypes.object,
};

export default ApplicationBreadcrumbs;
