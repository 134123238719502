import { Stepper, Text } from '@myci/ui-components';
import PropTypes from 'prop-types';
import { compose, length, map, path, prop, reject } from 'ramda';
import { isNilOrEmpty, mapIndexed } from 'ramda-extension';
import React, { Children, useEffect, useRef } from 'react';

import {
	useHasJourneyStepErrors,
	useIsJourneyStepDirty,
	useJourneyControls,
	useJourneyCurrentStep,
} from '../hooks';

const JourneyStep = ({ label, isActive, isInvalid }) => (
	<Text as="span" weight={isActive && 'bold'} color={isInvalid && 'danger'}>
		{label}
	</Text>
);

JourneyStep.propTypes = {
	isActive: PropTypes.bool.isRequired,
	isInvalid: PropTypes.bool.isRequired,
	label: PropTypes.node.isRequired,
};

const JourneyStepper = ({ children, wizard, canGoToPreviousSteps = true, namespace }) => {
	const { setNumberOfSteps, goToStep } = useJourneyControls(namespace);

	const currentStep = useJourneyCurrentStep(namespace);
	const isStepDirty = useIsJourneyStepDirty(namespace);
	const hasJourneyStepErrors = useHasJourneyStepErrors(namespace);

	const numberOfStepsRef = useRef();

	const steps = compose(
		mapIndexed((child, index) => {
			const step = index + 1;

			return {
				label: child,
				stepNumber: step,
				isDisabled: wizard && (canGoToPreviousSteps ? currentStep < step : currentStep !== step),
				isDone: wizard && currentStep > step,
				isActive: currentStep === step,
				isInvalid: isStepDirty(step) && hasJourneyStepErrors(step),
			};
		}),
		reject(isNilOrEmpty),
		Children.toArray
	)(children);

	const currentNumberOfSteps = length(steps);
	const disabledItems = map(prop('isDisabled'), steps);
	const currentStepLabel = path([currentStep - 1, 'label'], steps);

	useEffect(() => {
		if (currentNumberOfSteps && !numberOfStepsRef.current) {
			numberOfStepsRef.current = currentNumberOfSteps;
			setNumberOfSteps(currentNumberOfSteps);
		}
	}, [currentNumberOfSteps]);

	return (
		<Stepper
			activeStepIndex={currentStep}
			disabledItems={disabledItems}
			label={currentStepLabel}
			numberedList
			onChange={canGoToPreviousSteps ? ({ stepNumber }) => goToStep(stepNumber) : null}
			renderStep={JourneyStep}
			steps={steps}
		/>
	);
};

JourneyStepper.propTypes = {
	canGoToPreviousSteps: PropTypes.bool,
	children: PropTypes.node,
	namespace: PropTypes.string,
	wizard: PropTypes.bool,
};

export default JourneyStepper;
