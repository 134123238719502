import Tippy from '@tippyjs/react';
import type * as CSS from 'csstype';

import { ReactElement, ReactNode } from 'react';

import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

export { hideAll as hideAllPopovers } from 'tippy.js';
export type { Instance as PopoverInstance } from 'tippy.js';

export interface PopoverProps {
	appendTo?: 'parent' | HTMLElement;
	children: ReactElement;
	minWidth?: CSS.Property.MinWidth;
	offset?: number;
	onHide?: () => void;
	onShow?: () => void;
	popover: ReactNode;
	trigger?: string;
}

// NOTE: This means the popover will be placed 20px away from the trigger element (to reserve enough space for the arrow element).
const POPOVER_OFFSET = 20;
const POPOVER_MIN_WIDTH = '250px';

const popoverStyle = prepareStyle<{ minWidth: CSS.Property.MinWidth }>((utils, { minWidth }) => ({
	backgroundColor: utils.colors.white,
	border: 'none',
	borderRadius: utils.borders.radii.basic,
	boxShadow: utils.boxShadows.menu,
	minWidth,
	position: 'relative',
	zIndex: utils.zIndices.popover,
	selectors: {
		'& .tippy-content': {
			position: 'relative',
			zIndex: 1,
		},
		'& .tippy-arrow::before': {
			borderColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: utils.spacings.sm,
			content: "''",
			left: `-${utils.spacings.sm}`,
			position: 'absolute',
		},
		'&[data-placement="top"] > .tippy-arrow': { bottom: 0 },
		'&[data-placement="bottom"] > .tippy-arrow': { top: 0 },
		'&[data-placement="top"] > .tippy-arrow::before': {
			borderBottomWidth: 0,
			borderTopColor: utils.colors.white,
			bottom: `-${utils.spacings.sm}`,
			transformOrigin: 'center top',
		},
		'&[data-placement="bottom"] > .tippy-arrow::before': {
			borderBottomColor: utils.colors.white,
			borderTopWidth: 0,
			top: `-${utils.spacings.sm}`,
			transformOrigin: 'center bottom',
		},
	},
}));

export const Popover = ({
	appendTo = 'parent',
	children,
	minWidth = POPOVER_MIN_WIDTH,
	offset,
	onHide,
	onShow,
	popover,
	trigger = 'click',
}: PopoverProps) => {
	const { applyStyle, utils } = useStyles();

	return (
		<Tippy
			interactive
			// NOTE: Tippy wants to call `onShow` & `onHide` even when they are undefined.
			{...(onShow && { onShow })}
			{...(onHide && { onHide })}
			maxWidth="none"
			trigger={trigger}
			animation={false}
			placement="bottom"
			content={popover}
			offset={[0, offset ?? POPOVER_OFFSET]}
			zIndex={utils.zIndices.popover}
			className={applyStyle(popoverStyle, { minWidth })}
			appendTo={appendTo}
		>
			{children}
		</Tippy>
	);
};
