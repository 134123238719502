import React, { memo } from 'react';

import Skeletor from '../Skeletor';
import Box from '../Box';

const SkeletorInput = props => (
	<Box {...props}>
		<Skeletor height={20} width={150} mb={1} />
		<Skeletor height={40} />
	</Box>
);

export default memo(SkeletorInput);
