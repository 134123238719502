import PropTypes from 'prop-types';
import { mergeRight } from 'ramda';
import React from 'react';

import Box from '../Box';
import Checkbox from '../Checkbox';
import Grid from '../Grid';
import Icon from '../Icon';
import Text from '../Text';

const InfoCheckbox = ({ info, iconProps, ...otherProps }) => {
	const mergedIconProps = mergeRight(
		{
			type: 'info',
			color: 'blue',
			size: 18,
			mr: 2,
		},
		iconProps
	);

	return (
		<Grid flex="true" alignItems="baseline" mb={{ xs: 3, lg: 4 }}>
			<Icon {...mergedIconProps} />
			<Box>
				<Text>{info}</Text>
				<Checkbox {...otherProps} />
			</Box>
		</Grid>
	);
};

InfoCheckbox.propTypes = {
	/** Icon props */
	iconProps: PropTypes.object,
	/** Content of the info block. */
	info: PropTypes.node.isRequired,
};

export default InfoCheckbox;
