import { ReactNode } from 'react';

const spanStyle = {
	backgroundColor: 'rgba(255, 255, 0, 0.5)',
	cursor: 'alias',
};

export interface ExposedMessageProps {
	children: ReactNode;
	title: string;
}

export const ExposedMessage = (props: ExposedMessageProps) => <span style={spanStyle} {...props} />;
