import { serializeQueryParams } from '@ci/utils';
import { o, prop } from 'ramda';
import { defaultToEmptyObject } from 'ramda-extension';

const paginationToQueryParams = ({ pageSize, pageNumber }) => ({
	// NOTE: `pageNumber` is more related to UI, hence is indexed from 1.
	$skip: (pageNumber - 1) * pageSize,
	$top: pageSize,
});

export const getPaginationQueryString = pagination =>
	pagination?.pageSize ? serializeQueryParams(paginationToQueryParams(pagination)) : null;

export const getListData = prop('rows');
export const getPaginationData = o(defaultToEmptyObject, prop('pagination'));
