import { invertObj } from 'ramda';

export const Lookups = {
	ACCOUNT_STATUS: 'ACCOUNT_STATUS',
	ADMIN_STATUS: 'ADMIN_STATUS',
	ADMIN_TYPE: 'ADMIN_TYPE',
	APPLICATION_TYPE: 'APPLICATION_TYPE',
	BILLING_TAXES: 'BILLING_TAXES',
	BOOLEAN: 'BOOLEAN',
	CBS_BANKING_ACCOUNT_STATUS: 'CBS_BANKING_ACCOUNT_STATUS',
	CBS_BANKING_BANKING_TYPE: 'CBS_BANKING_BANKING_TYPE',
	CBS_BANKING_CHEQUE_ACCOUNT_TYPE: 'CBS_BANKING_CHEQUE_ACCOUNT_TYPE',
	CBS_BANKING_CHEQUE_BOUNCE_REASON: 'CBS_BANKING_CHEQUE_BOUNCE_REASON',
	CBS_BANKING_CHEQUE_STATUS: 'CBS_BANKING_CHEQUE_STATUS',
	CBS_BANKING_CONTRACT_SECURITY_TYPE: 'CBS_BANKING_CONTRACT_SECURITY_TYPE',
	CBS_BANKING_CONTRACT_STATUS: 'CBS_BANKING_CONTRACT_STATUS',
	CBS_BANKING_CONTRACT_SUBTYPE: 'CBS_BANKING_CONTRACT_SUBTYPE',
	CBS_BANKING_COURT_CODE: 'CBS_BANKING_COURT_CODE',
	CBS_BANKING_CREDIT_CLASSIFICATION: 'CBS_BANKING_CREDIT_CLASSIFICATION',
	CBS_BANKING_CURRENCY: 'CBS_BANKING_CURRENCY',
	CBS_BANKING_EXEMPTION_CATEGORY: 'CBS_BANKING_EXEMPTION_CATEGORY',
	CBS_BANKING_FUNDING: 'CBS_BANKING_FUNDING',
	CBS_BANKING_IFRS9_CLASSIFICATION: 'CBS_BANKING_IFRS9_CLASSIFICATION',
	CBS_BANKING_INFORMATION_SOURCE_TYPE: 'CBS_BANKING_INFORMATION_SOURCE_TYPE',
	CBS_BANKING_METHOD_OF_PAYMENT: 'CBS_BANKING_METHOD_OF_PAYMENT',
	CBS_BANKING_NEGATIVE_INFORMATION_TYPE: 'CBS_BANKING_NEGATIVE_INFORMATION_TYPE',
	CBS_BANKING_NEGATIVE_STATUS_OF_CONTRACT: 'CBS_BANKING_NEGATIVE_STATUS_OF_CONTRACT',
	CBS_BANKING_OWNERSHIP_TYPE: 'CBS_BANKING_OWNERSHIP_TYPE',
	CBS_BANKING_PAYMENT_PERIODICITY: 'CBS_BANKING_PAYMENT_PERIODICITY',
	CBS_BANKING_PHASE_OF_CONTRACT: 'CBS_BANKING_PHASE_OF_CONTRACT',
	CBS_BANKING_ROLE_OF_CUSTOMER: 'CBS_BANKING_ROLE_OF_CUSTOMER',
	CBS_BANKING_SECURITY_TYPE: 'CBS_BANKING_SECURITY_TYPE',
	CBS_BANKING_SYNDICATED_LOAN: 'CBS_BANKING_SYNDICATED_LOAN',
	CBS_BANKING_TELCO_POSTPAID_BILLING_PERIODICITY: 'CBS_BANKING_TELCO_POSTPAID_BILLING_PERIODICITY',
	CBS_BANKING_TELECOM_ACCOUNT_SERVICE_TYPE: 'CBS_BANKING_TELECOM_ACCOUNT_SERVICE_TYPE',
	CBS_BANKING_TELECOM_ACCOUNT_STATUS: 'CBS_BANKING_TELECOM_ACCOUNT_STATUS',
	CBS_BANKING_TELECOM_ACCOUNT_TYPE: 'CBS_BANKING_TELECOM_ACCOUNT_TYPE',
	CBS_BANKING_TELECOM_OWNERSHIP_TYPE: 'CBS_BANKING_TELECOM_OWNERSHIP_TYPE',
	CBS_BANKING_TELECOM_PHASE_OF_ACCOUNT: 'CBS_BANKING_TELECOM_PHASE_OF_ACCOUNT',
	CBS_BANKING_TELECOM_ROLE_OF_CUSTOMER: 'CBS_BANKING_TELECOM_ROLE_OF_CUSTOMER',
	CBS_BANKING_TELECOM_SERVICE_TYPE: 'CBS_BANKING_TELECOM_SERVICE_TYPE',
	CBS_BANKING_TYPE_OF_CONTRACT: 'CBS_BANKING_TYPE_OF_CONTRACT',
	CBS_BANKING_TYPE_OF_ISLAMIC_FACILITY: 'CBS_BANKING_TYPE_OF_ISLAMIC_FACILITY',
	CBS_BANKING_TYPE_OF_MSME_FACILITY: 'CBS_BANKING_TYPE_OF_MSME_FACILITY',
	CBS_DISPUTE_REASON: 'CBS_DISPUTE_REASON',
	CBS_SECTOR: 'CBS_SECTOR',
	COMPANY_PRICING_PLAN: 'COMPANY_PRICING_PLAN',
	COMPANY_TYPE: 'COMPANY_TYPE',
	CONVERSATION_STATUSES: 'CONVERSATION_STATUSES',
	CONVERSATION_SUBJECTS: 'CONVERSATION_SUBJECTS',
	COUNTRY_SUBDIVISIONS: 'COUNTRY_SUBDIVISIONS',
	COUNTRY: 'COUNTRY',
	DISPUTE_ENTITY_TYPE: 'DISPUTE_ENTITY_TYPE',
	DISPUTE_WORKFLOW_STATUS: 'DISPUTE_WORKFLOW_STATUS',
	EXAMPLE_COUNTRY: 'EXAMPLE_COUNTRY',
	INDIVIDUAL_PRICING_PLAN: 'INDIVIDUAL_PRICING_PLAN',
	NOTIFICATION_ALERT_TYPE: 'NOTIFICATION_ALERT_TYPE',
	PAST_DUE_AMOUNT: 'PAST_DUE_AMOUNT',
	PAST_DUE_DAYS: 'PAST_DUE_DAYS',
	PAYMENT_STATUS: 'PAYMENT_STATUS',
	PERIOD_TYPES: 'PERIOD_TYPES',
	PRICING_PLAN: 'PRICING_PLAN',
	REASON_OF_REGISTRATION: 'REASON_OF_REGISTRATION',
	REPORT_TYPE: 'REPORT_TYPE',
	SUBSCRIPTION_STATUS: 'SUBSCRIPTION_STATUS',
	TODO: 'TODO',
	USER_TYPE: 'USER_TYPE',
	USERNAME_DEPENDENCY: 'USERNAME_DEPENDENCY',
};

export const RemoteKeys = {
	ACCOUNT_STATUS: 'AccountStatus',
	BILLING_TAXES: 'Billing/Taxes',
	CBS_BANKING_ACCOUNT_STATUS: 'cbs/Banking.AccountStatus',
	CBS_BANKING_BANKING_TYPE: 'cbs/Banking.BankingType',
	CBS_BANKING_CHEQUE_ACCOUNT_TYPE: 'cbs/Banking.ChequeAccountType',
	CBS_BANKING_CHEQUE_BOUNCE_REASON: 'cbs/Banking.ChequeBounceReason',
	CBS_BANKING_CHEQUE_STATUS: 'cbs/Banking.ChequeStatus',
	CBS_BANKING_CONTRACT_SECURITY_TYPE: 'cbs/Banking.ContractSecurityType',
	CBS_BANKING_CONTRACT_STATUS: 'cbs/Banking.ContractStatus',
	CBS_BANKING_CONTRACT_SUBTYPE: 'cbs/Banking.ContractSubtype',
	CBS_BANKING_COURT_CODE: 'cbs/Banking.CourtCode',
	CBS_BANKING_CREDIT_CLASSIFICATION: 'cbs/Banking.CreditClassification',
	CBS_BANKING_CURRENCY: 'cbs/Banking.Currency',
	CBS_BANKING_EXEMPTION_CATEGORY: 'cbs/Banking.ExemptionCategory',
	CBS_BANKING_FUNDING: 'cbs/Banking.Funding',
	CBS_BANKING_IFRS9_CLASSIFICATION: 'cbs/Banking.IFRS9Classification',
	CBS_BANKING_INFORMATION_SOURCE_TYPE: 'cbs/Banking.InformationSourceType',
	CBS_BANKING_METHOD_OF_PAYMENT: 'cbs/Banking.MethodOfPayment',
	CBS_BANKING_NEGATIVE_INFORMATION_TYPE: 'cbs/Banking.NegativeInformationType',
	CBS_BANKING_NEGATIVE_STATUS_OF_CONTRACT: 'cbs/Banking.NegativeStatusOfContract',
	CBS_BANKING_OWNERSHIP_TYPE: 'cbs/Banking.OwnershipType',
	CBS_BANKING_PAYMENT_PERIODICITY: 'cbs/Banking.PaymentPeriodicity',
	CBS_BANKING_PHASE_OF_CONTRACT: 'cbs/Banking.PhaseOfContract',
	CBS_BANKING_ROLE_OF_CUSTOMER: 'cbs/Banking.RoleOfCustomer',
	CBS_BANKING_SECURITY_TYPE: 'cbs/Banking.SecurityType',
	CBS_BANKING_SYNDICATED_LOAN: 'cbs/Banking.SyndicatedLoan',
	CBS_BANKING_TELCO_POSTPAID_BILLING_PERIODICITY: 'cbs/Banking.TelcoPostpaidBillingPeriodicity',
	CBS_BANKING_TELECOM_ACCOUNT_SERVICE_TYPE: 'cbs/Banking.TelecomAccountServiceType',
	CBS_BANKING_TELECOM_ACCOUNT_STATUS: 'cbs/Banking.TelecomAccountStatus',
	CBS_BANKING_TELECOM_ACCOUNT_TYPE: 'cbs/Banking.TelecomAccountType',
	CBS_BANKING_TELECOM_OWNERSHIP_TYPE: 'cbs/Banking.TelecomOwnershipType',
	CBS_BANKING_TELECOM_PHASE_OF_ACCOUNT: 'cbs/Banking.TelecomPhaseOfAccount',
	CBS_BANKING_TELECOM_ROLE_OF_CUSTOMER: 'cbs/Banking.TelecomRoleOfCustomer',
	CBS_BANKING_TELECOM_SERVICE_TYPE: 'cbs/Banking.TelecomServiceType',
	CBS_BANKING_TYPE_OF_CONTRACT: 'cbs/Banking.TypeOfContract',
	CBS_BANKING_TYPE_OF_ISLAMIC_FACILITY: 'cbs/Banking.TypeOfIslamicFacility',
	CBS_BANKING_TYPE_OF_MSME_FACILITY: 'cbs/Banking.TypeOfMsmeFacility',
	CBS_DISPUTE_REASON: 'cbs/Master.DisputeReason',
	CBS_SECTOR: 'cbs/Master.Sector',
	COMPANY_TYPE: 'CompanyType',
	COUNTRY_SUBDIVISIONS: 'CountrySubdivisions',
	COUNTRY: 'Countries',
	CONVERSATION_STATUSES: 'ConversationStatuses',
	CONVERSATION_SUBJECTS: 'ConversationSubjects',
	NOTIFICATION_ALERT_TYPE: 'NotificationAlertType',
	PAYMENT_STATUS: 'PaymentStatus',
	REASON_OF_REGISTRATION: 'ReasonOfRegistration',
	USER_TYPE: 'UserType',
	USERNAME_DEPENDENCY: 'UsernameDependency',
};

export const LocalKeys = invertObj(RemoteKeys);

const COMPANY_PRICING_PLANS = ['company-basic', 'company-standard', 'company-premium'];
const INDIVIDUAL_PRICING_PLANS = ['individual-basic', 'individual-standard', 'individual-premium'];
const ADMIN_TYPES = ['WS MYCI Admin', 'WS Payment'];
const ADMIN_STATUSES = ['Active', 'Expired'];

export const Statics = {
	// Lookup which can be used as a placeholder when prototyping and the correct lookup is not yet known
	ADMIN_STATUS: ADMIN_STATUSES,
	ADMIN_TYPE: ADMIN_TYPES,
	APPLICATION_TYPE: ['BackOffice', 'FrontOffice'],
	BOOLEAN: ['Yes', 'No', 'NotSpecified'],
	COMPANY_PRICING_PLAN: COMPANY_PRICING_PLANS,
	DISPUTE_ENTITY_TYPE: [
		'bouncedcheque',
		'contract',
		'debit',
		'subject',
		'telcopostpaid',
		'telcoprepaid',
		'utilities',
	],
	DISPUTE_WORKFLOW_STATUS: [
		'AssignedToBureau',
		'AssignedToBureauAsTechnicalIssue',
		'AssignedToBureauRequestToClose',
		'AssignedToBureauWaitingToBeApproved',
		'AssignedToMyCIUser',
		'AssignedToSubscriber',
		'Closed',
		'Rejected',
	],
	EXAMPLE_COUNTRY: { 124: 'Sri Lanka', 164: 'Oman' },
	INDIVIDUAL_PRICING_PLAN: INDIVIDUAL_PRICING_PLANS,
	PAST_DUE_AMOUNT: [],
	PAST_DUE_DAYS: [],
	PERIOD_TYPES: ['Days', 'Months', 'Years'],
	PRICING_PLAN: ['free', ...INDIVIDUAL_PRICING_PLANS, ...COMPANY_PRICING_PLANS],
	REPORT_TYPE: ['Basic', 'Advanced'],
	SUBSCRIPTION_STATUS: ['Finished', 'Ongoing', 'Upcoming'],
	TODO: ['TODOuseAcorrectLOOKUP'],
};

export const IconMapping = {
	SUBJECT_TYPE: {
		Company: { type: 'subscriber', color: 'info' },
		Individual: { type: 'person', color: 'info' },
	},
	EVENT_TYPE: {
		Insert: { type: 'status-success', color: 'success' },
		Update: { type: 'status-info', color: 'info' },
		Delete: { type: 'status-rejected', color: 'danger' },
	},
	CBS_BANKING_TELECOM_PHASE_OF_ACCOUNT: {
		Open: { type: 'status-success', color: 'success' },
		Closed: { type: 'status-rejected', color: 'danger' },
	},
	SUBSCRIPTION_STATUS: {
		Finished: { type: 'status-not-approved', color: 'icon' },
		Ongoing: { type: 'status-success', color: 'success' },
		Upcoming: { type: 'status-waiting', color: 'blue' },
	},
};
