import { T, map, equals, always, cond, curry } from 'ramda';
import { alwaysNull } from 'ramda-extension';

// NOTE: `useWith` is broken with `cond`.

/**
 * Translates x according to translation table xs
 *
 * @param {Array} translation table (array of tuples key,value)
 * @param {string} item to be translated
 * @return {string}
 * @example
 *	convert([['A', 'B'], ['X', 'Y']])('A') === 'B'
 */
export const convert = curry((pairs, value) =>
	cond([...map(([from, to]) => [equals(from), always(to)], pairs), [T, alwaysNull]])(value)
);
