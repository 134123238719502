import PropTypes from 'prop-types';
import React from 'react';

const Img = ({ src, alt, ...rest }) => <img src={src} alt={alt} {...rest} />;

Img.propTypes = {
	/** Alternate text for an image */
	alt: PropTypes.string,
	/** Path to resource */
	src: PropTypes.string.isRequired,
};

export default Img;
