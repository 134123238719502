import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Tooltip from '../Tooltip';

const ButtonTooltip = forwardRef(({ children, tooltipProps, ...rest }, ref) => (
	<Tooltip {...tooltipProps}>
		<Button {...rest} ref={ref}>
			{children}
		</Button>
	</Tooltip>
));

ButtonTooltip.displayName = 'forwardRef(ButtonTooltip)';

ButtonTooltip.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** TooltipProps. */
	tooltipProps: PropTypes.object,
};

export default ButtonTooltip;
