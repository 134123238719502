import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import React, { forwardRef } from 'react';

import Grid from '../Grid';

/**
 * Content box - alias for <Grid className="content-box">
 */
const ContentBox = forwardRef(({ className, ...rest }, ref) => (
	<Grid ref={ref} className={cx('content-box', className)} {...rest} />
));

ContentBox.displayName = 'forwardRef(ContentBox)';

ContentBox.propTypes = {
	/** Class to be added after the content box default classes */
	className: PropTypes.string,
};

export default ContentBox;
