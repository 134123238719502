import React, { Fragment } from 'react';
import { compose, head } from 'ramda';
import { splitByDot } from 'ramda-extension';

// https://github.com/formatjs/react-intl/issues/1467
export const safeIntlComponent =
	render =>
	(...chunks) =>
		render(chunks.map((chunk, i) => <Fragment key={i}>{chunk}</Fragment>));

export const getMessageIdNamespace = compose(head, splitByDot, String);

export const getLanguageDisplayName = languageCode => {
	const languageNames = new Intl.DisplayNames([languageCode], { type: 'language', style: 'short' });
	return languageNames.of(languageCode);
};
