import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@myci/intl';

import LabeledIcon from '../LabeledIcon';
import m from '../../messages';

const AccordionPanelRulesIcon = ({ hasRules }) =>
	hasRules ? (
		<LabeledIcon iconType="status-success" iconColor="success">
			<Message {...m.accordionPanelRulesApplied} />
		</LabeledIcon>
	) : (
		<LabeledIcon iconType="status-not-approved" iconColor="danger">
			<Message {...m.accordionPanelNoRules} />
		</LabeledIcon>
	);

AccordionPanelRulesIcon.propTypes = {
	hasRules: PropTypes.bool,
};

export default AccordionPanelRulesIcon;
