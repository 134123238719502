import { ReactNode, memo, useMemo } from 'react';

import {
	Spacing,
	VerticalSpacing,
	resolveVerticalPadding,
	visuallyHiddenStyle,
} from '@creditinfo-ui/atoms';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

import { TabControlsContext } from '../hooks';
import { HorizontalInset, TabKey } from '../types';
import { resolveHorizontalInset } from '../utils';

interface TabContentStyleProps {
	hasInsetShadow: boolean;
	horizontalInset: HorizontalInset;
	paddingBottom: Spacing;
	paddingTop: Spacing;
}

const TAB_CONTENT_SHADOW = 'inset 0 10px 15px -5px rgb(0 0 0 / 4%)';

const tabContentStyle = prepareStyle<TabContentStyleProps>(
	(utils, { hasInsetShadow, horizontalInset, paddingBottom, paddingTop }) => ({
		...resolveHorizontalInset(horizontalInset, utils),
		extend: [
			{
				condition: hasInsetShadow,
				style: {
					boxShadow: TAB_CONTENT_SHADOW,
				},
			},
			{
				condition: paddingBottom !== 'none',
				style: {
					paddingBottom: utils.spacings[paddingBottom],
				},
			},
			{
				condition: paddingTop !== 'none',
				style: {
					paddingTop: utils.spacings[paddingTop],
				},
			},
		],
	})
);

export interface TabContentProps {
	children: ReactNode;
	hasInsetShadow: boolean;
	horizontalInset: HorizontalInset;
	isFirst: boolean;
	isLast: boolean;
	isVisible: boolean;
	next: () => void;
	previous: () => void;
	setActiveKey: (key: TabKey) => void;
	verticalPadding: VerticalSpacing;
}

const PureTabContent = ({
	hasInsetShadow,
	horizontalInset,
	children,
	isFirst,
	isLast,
	isVisible,
	next,
	previous,
	setActiveKey,
	verticalPadding,
}: TabContentProps) => {
	const { applyStyle } = useStyles();

	const { paddingBottom, paddingTop } = resolveVerticalPadding(verticalPadding);

	const contextValue = useMemo(
		() => ({ isFirst, isLast, isVisible, next, previous, setActiveKey }),
		[isFirst, isLast, isVisible, next, previous, setActiveKey]
	);

	return (
		<TabControlsContext.Provider value={contextValue}>
			<div
				role="tabpanel"
				className={applyStyle([tabContentStyle, isVisible ? null : visuallyHiddenStyle], {
					hasInsetShadow,
					horizontalInset,
					paddingBottom,
					paddingTop,
				})}
			>
				{children}
			</div>
		</TabControlsContext.Provider>
	);
};

export const TabContent = memo(PureTabContent);
