import { mapObjIndexed } from 'ramda';
import { StyleUtils } from '@creditinfo-ui/fela';
import { Spacings } from '@creditinfo-ui/styles';
import { HorizontalInset } from './types';

export const resolveHorizontalInset = (horizontalInset: HorizontalInset, utils: StyleUtils) => {
	const getSpacingValue = (spacing: 'none' | keyof Spacings, isMargin: boolean) => {
		const insetValue = horizontalInset === 'none' ? undefined : utils.spacings[spacing];

		return isMargin ? insetValue && `-${insetValue}` : insetValue;
	};

	const getResponsiveSpacingValue = (isMargin: boolean) => {
		if (typeof horizontalInset === 'string') {
			return getSpacingValue(horizontalInset, isMargin);
		}

		return mapObjIndexed(spacing => getSpacingValue(spacing, isMargin), horizontalInset);
	};

	const marginValue = getResponsiveSpacingValue(true);
	const paddingValue = getResponsiveSpacingValue(false);

	return {
		marginRight: marginValue,
		marginLeft: marginValue,
		paddingRight: paddingValue,
		paddingLeft: paddingValue,
	};
};
