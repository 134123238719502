import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const PopupTriggerIcon = forwardRef(
	(
		{
			renderIconWrapper: IconWrapper,
			iconType,
			iconSize,
			iconClassName,
			iconProps,
			wrapperProps,
			onClick,
		},
		ref
	) => {
		const isIconWrapperFragment = IconWrapper === Fragment;
		const controlProps = { onClick };

		return (
			<IconWrapper {...wrapperProps} {...(!isIconWrapperFragment ? controlProps : {})}>
				<Icon
					ref={ref}
					className={iconClassName}
					size={iconSize}
					type={iconType}
					{...iconProps}
					{...(isIconWrapperFragment ? controlProps : {})}
				/>
			</IconWrapper>
		);
	}
);

// TODO fixme proper propTypes
PopupTriggerIcon.propTypes = {
	iconClassName: PropTypes.any,
	iconProps: PropTypes.any,
	iconSize: PropTypes.any,
	iconType: PropTypes.any,
	onClick: PropTypes.any,
	renderIconWrapper: PropTypes.any,
	wrapperProps: PropTypes.any,
};

PopupTriggerIcon.displayName = 'forwardRef(PopupTriggerIcon)';

export default PopupTriggerIcon;
