import React, { Fragment, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'ramda-extension';
import { withTransition } from '@ci/react-utils';

import SlideModalFooter from '../SlideModalFooter';
import SlideModalHeader from '../SlideModalHeader';
import SlideModalContent from '../SlideModalContent';

import SlideModalBase, { ModalPropTypes } from './SlideModalBase';

// eslint-disable-next-line react/jsx-no-useless-fragment
export const SlideModalWithHeader = ({ wrapper = <Fragment />, renderFooter: Footer, ...rest }) => (
	<SlideModalBase
		renderContent={({ children, title, subtitle, details }) =>
			cloneElement(
				wrapper,
				{},
				<Fragment>
					<SlideModalHeader title={title} subtitle={subtitle} details={details} />
					<SlideModalContent>{isFunction(children) ? children(rest) : children}</SlideModalContent>
					{Footer && (
						<SlideModalFooter>
							<Footer />
						</SlideModalFooter>
					)}
				</Fragment>
			)
		}
		{...rest}
	/>
);

SlideModalWithHeader.propTypes = {
	renderFooter: PropTypes.elementType,
	transition: PropTypes.shape({
		/** Timeout */
		timeout: PropTypes.number,
		/** Class name specification */
		classNames: PropTypes.number,
	}),
	...ModalPropTypes,
};

export default withTransition(SlideModalWithHeader, { trigger: 'isVisible' });
