import { getEnvironmentVariable } from '@ci/environment';

export const configureTranslationWarnings = () => {
	if (getEnvironmentVariable('BROWSER_DISABLE_TRANSLATION_WARNINGS') === '1') {
		const consoleError = console.error.bind(console);

		console.error = (message, ...args) => {
			if (message?.code === 'MISSING_TRANSLATION') {
				return;
			}

			consoleError(message, ...args);
		};
	}
};
