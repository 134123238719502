import { useLayoutEffect, useState } from 'react';
import { isNil } from 'ramda';
import { noop } from 'ramda-extension';

const useVisibilityController = ({
	visibility,
	onChangeVisibility = noop,
	onAfterVisibilityChange = noop,
}) => {
	const [isOpen, setIsOpen] = useState(visibility);
	const close = () => {
		if (isNil(visibility)) {
			setIsOpen(false);
		}
		onChangeVisibility(false);
	};

	const open = () => {
		if (isNil(visibility)) {
			setIsOpen(true);
		}
		onChangeVisibility(true);
	};
	const toggleVisibility = isOpen ? close : open;

	useLayoutEffect(() => {
		onAfterVisibilityChange(isOpen || visibility);
	}, [isOpen, onAfterVisibilityChange, visibility]);

	return { toggleVisibility, open, close, isOpen };
};
export default useVisibilityController;
