import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import React, { Children } from 'react';

import Box from '../Box';
import './PanelGrid.scss';

/**
 * PanelGrid is used to display a grid of content inside a Panel.
 * By default a separator is drawn between each item. This can be disabled by the `noBorder` flag
 * The grid can optionally be made horizontally scrollable by using the `scrollable` flag
 */
const PanelGrid = ({ children, className, noBorder, scrollable, ...otherProps }) => {
	const childrenCount = Children.count(children);

	return (
		<Box
			className={cx(
				'panel__grid',
				{ 'panel__grid--no-border': noBorder },
				{ 'panel__grid--scroll': scrollable },
				{ 'panel__grid--odd': !scrollable && childrenCount > 3 && childrenCount % 2 },
				{ 'panel__grid--even': !scrollable && childrenCount > 3 && !(childrenCount % 2) },
				className
			)}
			{...otherProps}
		>
			{children}
		</Box>
	);
};

PanelGrid.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Additional class names */
	className: PropTypes.string,
	/** Remove borders between items */
	noBorder: PropTypes.bool,
	/** Make the grid horizontally scrollable if necessary */
	scrollable: PropTypes.bool,
};

export default PanelGrid;
