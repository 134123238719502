import React from 'react';
import { isFunction } from 'ramda-extension';
import PropTypes from 'prop-types';
import { cloneElementOrFragment, withTransition } from '@ci/react-utils';
import { pick } from 'ramda';

import Box from '../Box';

import SlideModalBase, { ModalPropTypes } from './SlideModalBase';

const pickBoxProps = pick(['className']);

const cloneWithBox = cloneElementOrFragment(Box);

/**
 * Exntesible version of slide modal.
 * Use it if you need full control over the markup within. Otherwise use e.g. SlideModalWithHeader.
 */
export const SlideModal = props => (
	<SlideModalBase
		renderContent={({ children, ...otherContentProps }) =>
			isFunction(children)
				? children({ ...props, ...otherContentProps })
				: cloneWithBox(children, pickBoxProps({ ...props, ...otherContentProps }))
		}
		{...props}
	/>
);

SlideModal.propTypes = {
	transition: PropTypes.shape({
		/** Timeout */
		timeout: PropTypes.number,
		/** Class name specification */
		classNames: PropTypes.number,
	}),
	...ModalPropTypes,
};

export default withTransition(SlideModal, { trigger: 'isVisible' });
