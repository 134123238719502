import React from 'react';
import { includes, replace } from 'ramda';
import { Text } from '@ci/atoms';
import { Message } from '@myci/intl';
import { downloadBase64File, openDataUrlInNewTab } from '@myci/utils';
import { Box, Icon, IconButton, Link } from '@myci/ui-components';
import { cx, isNilOrEmptyString, noop } from 'ramda-extension';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useAntimalwareScan } from '../useAntimalwareScan';
import { scanResults } from '../duck';
import m from '../messages';

export interface DropzoneProps {
	acceptedExtensions?: string[];
	acceptedFileSize?: string;
	fileName: string;
	isDisabled?: boolean;
	onDropAccepted: (files: any[]) => void;
	value: string;
}

const isPdfMimeType = (value?: string) => value && includes('application/pdf', value);

const getBase64FromDataUrl = replace(/data:[a-z]+\/[a-z]+;base64,/, '');

const defaultAcceptedExtensions = ['.png', '.jpeg', '.jpg', '.tiff', '.bmp', '.pdf'];
const defaultAcceptedFileSize = '10MB';

const browseLink = (
	// NOTE: The dialog opens when we click anywhere, so the link is technically pointless.
	<Link href="javascript:void(0)" onClick={noop} className="file-input__browse-link">
		<Message {...m.fileInputControlBrowse} />
	</Link>
);

export const Dropzone = ({
	acceptedExtensions = defaultAcceptedExtensions,
	acceptedFileSize = defaultAcceptedFileSize,
	fileName: fileNameProp,
	onDropAccepted,
	value,
	isDisabled,
}: DropzoneProps) => {
	const { getRootProps, open, getInputProps } = useDropzone({
		accept: acceptedExtensions,
		onDropAccepted,
		noClick: true,
		noKeyboard: true,
		disabled: isDisabled,
	});

	const intl = useIntl();
	const [isDataUrl, attachmentScanResult, isFetchingScanAttachment, handleDownloadAttachment] =
		useAntimalwareScan(value);

	const fileName = attachmentScanResult?.attachmentInfo?.fileName ?? fileNameProp;
	const isDataUrlOrNil = isDataUrl || isNilOrEmptyString(value);
	const hasScanFailed = !isFetchingScanAttachment && !attachmentScanResult;
	const isScanResultPositive =
		attachmentScanResult?.antimalwareScanDetail?.result === scanResults.threatDetected;

	if (!isDataUrlOrNil && isFetchingScanAttachment) {
		return (
			<Box className="file-input__dropzone" {...getRootProps()}>
				<Box className="file-input__dropzone-inner">
					<Message {...m.scanningForMalware} />
				</Box>
			</Box>
		);
	}

	if (!isDataUrlOrNil && (hasScanFailed || isScanResultPositive))
		return (
			<Box className="file-input__dropzone" {...getRootProps()}>
				<input {...getInputProps()} />
				<Box className="file-input__dropzone-inner">
					<Message {...(hasScanFailed ? m.scanFailed : m.malwarePresent)} />
					<Box className="file-input__actions" mt={{ xs: 2, md: 0 }}>
						<IconButton onClick={open} title={intl.formatMessage(m.fileInputControlReplace)}>
							<Icon type="rollback" size={20} />
						</IconButton>
					</Box>
				</Box>
			</Box>
		);

	return (
		<Box className="file-input__dropzone" {...getRootProps()}>
			<input {...getInputProps()} />
			{value ? (
				<Box className="file-input__dropzone-inner file-input__dropzone-inner--uploaded-file">
					<Box
						className={cx(
							'file-input__file',
							isPdfMimeType(isDataUrl ? value : attachmentScanResult?.attachmentInfo?.mediaType)
								? 'file-input__file--pdf'
								: 'file-input__file--image'
						)}
						onClick={() =>
							isDataUrl
								? openDataUrlInNewTab(value)
								: // TODO: restore 'open in new tab' functionality when @ci/api allows it
								  handleDownloadAttachment()
						}
						title={intl.formatMessage(m.fileInputControlView)}
						tabIndex={0}
					>
						<Text>{fileName}</Text>
					</Box>
					<Box className="file-input__actions" mt={{ xs: 2, md: 0 }}>
						<IconButton
							onClick={() =>
								isDataUrl
									? downloadBase64File(getBase64FromDataUrl(value), fileName)
									: handleDownloadAttachment()
							}
							title={intl.formatMessage(m.fileInputControlDownload)}
						>
							<Icon type="download" size={20} />
						</IconButton>
						<IconButton onClick={open} title={intl.formatMessage(m.fileInputControlReplace)}>
							<Icon type="rollback" size={20} />
						</IconButton>
					</Box>
				</Box>
			) : (
				<Box
					className={cx('file-input__dropzone-inner', {
						'file-input__dropzone-inner--disabled': isDisabled,
					})}
					onClick={!isDisabled ? open : noop}
					tabIndex={0}
				>
					<Icon type="folder" size={24} mb={2} className="file-input__browse-icon" />
					<Text size="caption">
						<Message {...m.fileUploadDescription} values={{ link: browseLink }} />
					</Text>
					<Text size="caption">
						<Message
							{...m.fileUploadFileTypeAndFileSizeDescription}
							values={{
								extensions: acceptedExtensions?.join(', '),
								size: acceptedFileSize,
							}}
						/>
					</Text>
				</Box>
			)}
		</Box>
	);
};
