import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import './Navigation.scss';
import PortalWithBackdrop from '../PortalWithBackdrop/PortalWithBackdrop';
import useViewport from '../../hooks/useViewport';

import Navigation from './Navigation';
import * as r from './reducer';

const stopPropagation = e => e.stopPropagation();

const NavigationWrapper = props => {
	const { breakpoint } = useViewport();
	const [
		{
			isBackdropVisible,
			isLanguageSwitcherOpen,
			isLinkOpen,
			isNavigationXSOpen,
			isUserMenuOpen,
			isWideNavigation,
			slug,
		},
		dispatch,
	] = useReducer(r.reducer, r.initialState);

	const navigation = (
		<Navigation
			breakpoint={breakpoint}
			currentSlug={slug}
			isLanguageSwitcherOpen={isLanguageSwitcherOpen}
			isLinkOpen={isLinkOpen}
			isNavigationXSOpen={isNavigationXSOpen}
			isUserMenuOpen={isUserMenuOpen}
			isWideNavigation={isWideNavigation}
			onActive={payload => dispatch(r.setSlug(payload))}
			onBackdropVisible={payload => dispatch(r.setIsBackdropVisible(payload))}
			onClick={stopPropagation}
			onLanguageSwitcherOpen={payload => dispatch(r.setIsLanguageSwitcherOpen(payload))}
			onLinkOpen={payload => dispatch(r.setIsLinkOpen(payload))}
			onNavigationXSOpen={payload => dispatch(r.setIsNavigationXSOpen(payload))}
			onUserMenuOpen={payload => dispatch(r.setIsUserMenuOpen(payload))}
			onWideNavigation={payload => dispatch(r.setIsWideNavigation(payload))}
			{...props}
		/>
	);

	if (isBackdropVisible) {
		return (
			<PortalWithBackdrop
				isVisible={isBackdropVisible}
				onBackdropClick={() => {
					dispatch(r.setIsBackdropVisible(false));
					dispatch(r.setIsLinkOpen(false));
				}}
			>
				{navigation}
			</PortalWithBackdrop>
		);
	}

	return navigation;
};

NavigationWrapper.propTypes = {
	navigationModules: PropTypes.array.isRequired,
	renderHelp: PropTypes.elementType,
	renderLogo: PropTypes.elementType,
	renderNavigationLanguageSwitcher: PropTypes.elementType,
	renderSearch: PropTypes.elementType,
	renderUserMenu: PropTypes.elementType,
};

export default NavigationWrapper;
