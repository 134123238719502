import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { ReactNode } from 'react';

const contentStyle = prepareStyle<{
	canContentOverflow: boolean;
	isBeforeFooter: boolean;
	isCentered: boolean;
}>((utils, { canContentOverflow, isBeforeFooter, isCentered }) => ({
	flexBasis: 'auto',
	flexGrow: 1,
	flexShrink: 1,

	paddingLeft: utils.spacings.md,
	paddingRight: utils.spacings.md,

	selectors: {
		[utils.breakpoints.lg]: {
			paddingLeft: utils.spacings.lg,
			paddingRight: utils.spacings.lg,
		},
	},

	extend: [
		{
			condition: isCentered,
			style: {
				textAlign: 'center',
			},
		},
		{
			condition: !isBeforeFooter,
			style: {
				paddingBottom: utils.spacings.xl,
			},
		},
		{
			condition: !canContentOverflow,
			style: {
				overflowY: 'auto',
			},
		},
	],
}));

export interface StaticModalContentProps {
	canContentOverflow?: boolean;
	children: ReactNode;
	isBeforeFooter?: boolean;
	isCentered?: boolean;
}

export const StaticModalContent = ({
	canContentOverflow = false,
	isBeforeFooter = false,
	isCentered = false,
	children,
}: StaticModalContentProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={applyStyle(contentStyle, { canContentOverflow, isBeforeFooter, isCentered })}>
			{children}
		</div>
	);
};
