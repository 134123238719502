import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as ReachRouterLink } from '@gatsbyjs/reach-router';

import Link from '../Link';

/**
 * Use for SPA navigation in client generated routes.
 */
const ClientLink = forwardRef((props, ref) => <Link ref={ref} {...props} />);

ClientLink.displayName = 'forwardRef(ClientLink)';

ClientLink.propTypes = {
	to: PropTypes.string.isRequired,
};

ClientLink.defaultProps = {
	as: ReachRouterLink,
};

export default ClientLink;
