import React, { Children, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounceImmediate, isInBrowser } from '@myci/utils';
import { toLower } from 'ramda';
import { isNotNumeric } from 'ramda-extension';

import { TranslationModeContext } from '../../contexts';

const debounce1s = debounceImmediate(1000);

const useTranslationMode = delayBetweenSwitch => {
	const [translationMode, setTranslationMode] = useState(false);
	const timer = useRef();
	const clearTimer = () => {
		clearTimeout(timer.current);
		timer.current = null;
	};

	const shortcutPressed = () => {
		if (!timer.current) {
			timer.current = setTimeout(() => {
				setTranslationMode(!translationMode);
			}, delayBetweenSwitch);
		}
	};

	const debouncedShortcutPressed = debounce1s(shortcutPressed);

	const handleKeydown = e => {
		if (e.ctrlKey && toLower(e.key) === 'i') {
			debouncedShortcutPressed();
		} else {
			clearTimer();
		}
	};

	const handleKeyup = () => {
		clearTimer();
	};

	useEffect(() => {
		if (!isInBrowser()) {
			return;
		}

		window.addEventListener('keydown', handleKeydown, false);
		window.addEventListener('keyup', handleKeyup, false);

		return () => {
			window.removeEventListener('keydown', handleKeydown, false);
			window.removeEventListener('keyup', handleKeyup, false);
		};
	});

	if (!isInBrowser() || isNotNumeric(delayBetweenSwitch)) {
		return null;
	}

	return translationMode;
};

/**
 * Switches between on and off state of translation mode.
 *
 * To toggle the state the `ctrl+i` shortcut must be pressed and held for `delayBetweenSwitch` ms.
 */
const TranslationModeProvider = ({ children, delayBetweenSwitch }) => {
	const translationMode = useTranslationMode(delayBetweenSwitch);

	return isInBrowser() ? (
		<TranslationModeContext.Provider value={translationMode}>
			{Children.only(children)}
		</TranslationModeContext.Provider>
	) : (
		children
	);
};

TranslationModeProvider.propTypes = {
	children: PropTypes.node,
	/** For how long shortcut must be pressed to toggle the translation mode (in ms). */
	delayBetweenSwitch: PropTypes.number,
};

TranslationModeProvider.defaultProps = {
	delayBetweenSwitch: 3000,
};

export default TranslationModeProvider;
