import { useCallback } from 'react';
import { makeFormatter } from 'afformative';
import { compose, join, map, intersperse, path } from 'ramda';
import { isObject, isNilOrEmptyString, defaultToEmptyArray, isArray } from 'ramda-extension';
import { ensureArray } from '@ci/utils';
import { Message } from '@ci/message';
import { useIntl } from 'react-intl';
import { simplifyResolverHook } from '@ci/react-utils';
import { identityFormatter } from '@creditinfo-ui/formatters';

export const JsonFormatter = makeFormatter(
	value => (isObject(value) ? JSON.stringify(value) : value),
	{
		displayName: 'JsonFormatter',
	}
);

export const useResolveMessageFormatter = () => {
	const intl = useIntl();

	return useCallback(
		getMessage =>
			makeFormatter(
				(value, suggestions) => {
					if (isNilOrEmptyString(value)) {
						return value;
					}

					const message = getMessage(value);

					if (suggestions.includes('primitive')) {
						return message ? intl.formatMessage(message) : value;
					}

					return <Message {...message} />;
				},
				{
					displayName: 'MessageFormatter',
				}
			),
		[intl]
	);
};

export const useMessageFormatter = simplifyResolverHook(useResolveMessageFormatter);

export const makeDynamicFormatter = getFormatterAndValue =>
	makeFormatter(
		(data, suggestions) => {
			const [Formatter, value] = getFormatterAndValue(data);

			if (suggestions.includes('primitive')) {
				return Formatter.formatAsPrimitive(value, suggestions);
			}

			return <Formatter suggestions={suggestions}>{value}</Formatter>;
		},
		{
			displayName: 'DynamicFormatter',
		}
	);

export const makeTransformFormatter = (transform, formatter = identityFormatter) =>
	formatter.wrap((delegate, value) => delegate(transform(value)), {
		displayName: 'TransformFormatter',
	});

export const makeArrayFormatter = (ItemFormatter = identityFormatter, separator = ', ') =>
	makeFormatter(
		(value, suggestions) => {
			const format = suggestions.includes('primitive')
				? compose(join(separator), map(ItemFormatter.formatAsPrimitive), defaultToEmptyArray)
				: compose(
						intersperse(separator),
						map(value => <ItemFormatter>{value}</ItemFormatter>),
						defaultToEmptyArray
				  );

			return format(value);
		},
		{
			displayName: 'ArrayFormatter',
		}
	);

export const makeDictionaryFormatter = (prop, dictionary) =>
	makeFormatter(
		value => {
			const entity = dictionary?.[value];

			return entity ? entity[prop] : '';
		},
		{
			displayName: 'DictionaryFormatter',
		}
	);

export const StringArrayFormatter = makeFormatter(
	value => (isArray(value) ? join(', ', value) : value),
	{
		displayName: 'StringArrayFormatter',
	}
);

export const makePathFormatter = propOrPath =>
	makeFormatter(path(ensureArray(propOrPath)), {
		displayName: 'PathFormatter',
	});
