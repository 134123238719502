import React, { forwardRef, memo } from 'react';
import { Text } from '@ci/atoms';
import { prepareStyle, useStyles } from '@ci/styles';
import { useVersions } from '@myci/domain-data-api';
import { Message } from '@myci/intl';
import { getCurrentInstanceEnv } from '@myci/utils';

import Grid from '../Grid';
import m from '../../messages';
import './TemplatePageFooter.scss';

const appVersionWrapperStyle = prepareStyle(utils => ({
	alignSelf: 'stretch',
	display: 'flex',
	justifyContent: 'center',
	selectors: {
		[utils.breakpoints.md]: {
			justifyContent: 'flex-end',
		},
	},
}));

interface TemplatePageFooterProps {
	hasGlobalFooter?: boolean;
	renderPageFooter?: React.ComponentType<any>;
}

const PureTemplatePageFooter = forwardRef(
	({ hasGlobalFooter, renderPageFooter: Footer }: TemplatePageFooterProps, ref) => {
		const { applyStyle } = useStyles();
		const { appVersion } = getCurrentInstanceEnv();
		const { apiVersion } = useVersions();

		return (
			<Grid
				as="footer"
				flexDirection="column"
				className="footer"
				alignItems="center"
				justifyContent="end"
				ref={ref}
			>
				{Footer && <Footer />}
				{hasGlobalFooter && (
					<div className={applyStyle(appVersionWrapperStyle)}>
						<Text size="caption" color="gray500">
							<Message {...m.appVersion} values={{ apiVersion, appVersion }} />
						</Text>
					</div>
				)}
			</Grid>
		);
	}
);

export const TemplatePageFooter = memo(PureTemplatePageFooter);
