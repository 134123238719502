import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { endsWith, o } from 'ramda';
import { createNumberMask } from 'text-mask-addons';
import { conformToMask } from 'react-text-mask';
import { defaultToEmptyString, isNilOrEmptyString, noop } from 'ramda-extension';
import { Message } from '@myci/intl';

import TextInputControl from '../TextInputControl';
import FormField from '../FormField';
import m from '../../messages';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const configureNumberMask = ({
	decimalPlaces,
	decimalSeparator,
	thousandSeparator,
	allowNegative,
	integerPlaces,
}) =>
	createNumberMask({
		prefix: '',
		decimalSymbol: decimalSeparator,
		decimalLimit: Number(decimalPlaces),
		// NOTE: `integerPlaces` shouldn't be coerced to a number because `null` means "unlimited".
		integerLimit: integerPlaces,
		allowDecimal: true,
		includeThousandsSeparator: true,
		thousandsSeparatorSymbol: thousandSeparator,
		allowNegative,
	});

const parseValue = (event, thousandSeparator, decimalSeparator) => {
	const valueWithoutThousandSeparator = event.currentTarget.value.replace(
		new RegExp(escapeRegExp(thousandSeparator), 'g'),
		''
	);

	if (isNilOrEmptyString(valueWithoutThousandSeparator)) {
		return null;
	}

	if (decimalSeparator !== '.') {
		return Number(valueWithoutThousandSeparator.replace(decimalSeparator, '.'));
	}

	return Number(valueWithoutThousandSeparator);
};

const DecimalInput = ({
	allowNegative = true,
	decimalSeparator: customDecimalSeparator,
	decimalPlaces = 3,
	hasFloatingLabel,
	integerPlaces = null,
	thousandSeparator: customThousandSeparator,
	value,
	onChange = noop,
	onBlur = noop,
	...otherProps
}) => {
	const thousandSeparator = defaultToEmptyString(customThousandSeparator);
	const decimalSeparator = customDecimalSeparator || '.';
	const [textInputValue, setTextInputValue] = useState('');
	const hasChangedRef = useRef(false);

	const numberMask = useMemo(
		() =>
			configureNumberMask({
				decimalPlaces,
				decimalSeparator,
				thousandSeparator,
				allowNegative,
				integerPlaces,
			}),
		[decimalPlaces, decimalSeparator, thousandSeparator, allowNegative, integerPlaces]
	);

	useEffect(() => {
		if (Number.isNaN(value)) {
			return;
		}

		if (!hasChangedRef.current) {
			const parsedValue = isNilOrEmptyString(value)
				? ''
				: conformToMask(String(value).replace('.', decimalSeparator), numberMask).conformedValue;

			setTextInputValue(parsedValue);
		}

		hasChangedRef.current = false;
	}, [value]);

	const boundParseValue = event => parseValue(event, thousandSeparator, decimalSeparator);

	return (
		<FormField
			control={TextInputControl}
			kind="text-input"
			hasFloatingLabel={hasFloatingLabel}
			// NOTE: Google Chrome does not respect `autoComplete="off"`.
			autoComplete="disabled"
			mask={numberMask}
			value={textInputValue}
			onChange={event => {
				hasChangedRef.current = true;
				setTextInputValue(event.currentTarget.value);

				if (endsWith(decimalSeparator, event.currentTarget.value)) {
					onChange(NaN);
				} else {
					o(onChange, boundParseValue)(event);
				}
			}}
			onBlur={o(onBlur, boundParseValue)}
			legendText={
				<Message
					{...m.maxDecimalPlaces}
					values={{
						decimalPlaces,
					}}
				/>
			}
			{...otherProps}
		/>
	);
};

DecimalInput.displayName = 'forwardRef(DecimalInput)';

DecimalInput.propTypes = {
	allowNegative: PropTypes.bool,
	decimalPlaces: PropTypes.number,
	decimalSeparator: PropTypes.string,
	hasFloatingLabel: PropTypes.bool,
	integerPlaces: PropTypes.number,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	thousandSeparator: PropTypes.string,
	value: PropTypes.number,
};

export default DecimalInput;
