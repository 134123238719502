import React from 'react';
import { useSelector } from 'react-redux';
import { FormName, getFormError } from 'redux-form';
import { Grid, NotificationBox } from '@myci/ui-components';

const FormError = () => (
	<FormName>
		{({ form }) => {
			// FIXME:
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const errors = useSelector(getFormError(form));

			return errors ? (
				<Grid py={1}>
					{errors.map(error => (
						<Grid row>
							<Grid col="auto" py={1}>
								<NotificationBox kind="warning">{error}</NotificationBox>
							</Grid>
						</Grid>
					))}
				</Grid>
			) : null;
		}}
	</FormName>
);

export default FormError;
