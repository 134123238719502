import React from 'react';

import Text from '../Text';

/**
 * Layout component - represents paragraphs of the Section.
 *
 * @see Section
 */
const SectionText = props => <Text className="section__text" mb={3} {...props} />;

export default SectionText;
