module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Credit Information Bureau for Sao Tome and Principe","short_name":"MyCI BO","start_url":"/","background_color":"#ffffff","theme_color":"#74A8E8","display":"standalone","icon":"D:\\BuildAgent01\\work\\3584ffa7ff58f781\\libs\\assets\\images\\stp_favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c76f6fe671a9cc38a25e03da9b11ee3f"},
    },{
      plugin: require('../../../../libs/gatsby-plugin-setup/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","distributionPath":"D:\\BuildAgent01\\work\\3584ffa7ff58f781\\apps\\build\\bo","environment":{"baseFilePath":"D:\\BuildAgent01\\work\\3584ffa7ff58f781\\.env.development"},"sassData":{"env-myci-instance":"stp"}},
    },{
      plugin: require('../../../../libs/gatsby-plugin-redux/gatsby-browser.js'),
      options: {"plugins":[],"applicationType":"BO"},
    },{
      plugin: require('../../../../libs/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","preloadNamespaces":["myci","myciAuth"]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
