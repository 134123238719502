import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { prepareStyle } from '@creditinfo-ui/styles';
import { TabButtonLabel } from './TabButtonLabel';
import { TabButton } from './TabButton';

interface DefaultTabButtonStyleProps {
	isActive: boolean;
}

export const defaultTabButtonStyle = prepareStyle<DefaultTabButtonStyleProps>(
	(utils, { isActive }) => {
		if (!isActive) {
			return {};
		}

		return {
			selectors: {
				'::after': {
					backgroundColor: utils.colors.primary,
					bottom: 0,
					content: '""',
					height: utils.borders.widths.md,
					insetInlineStart: 0,
					left: 0,
					position: 'absolute',
					transform: 'translateY(100%)',
					transitionDuration: utils.transitions.speeds.default,
					transitionProperty: 'transform',
					transitionTimingFunction: utils.transitions.easing,
					width: '100%',
				},
			},
		};
	}
);

export interface DefaultTabButtonProps {
	isActive: boolean;
	isDisabled: boolean;
	label: ReactNode;
	onClick: () => void;
}

export const DefaultTabButton = forwardRef(
	(
		{ isActive, isDisabled, label, onClick }: DefaultTabButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => (
		<TabButton
			ref={ref}
			isActive={isActive}
			isDisabled={isDisabled}
			isInvalid={false}
			onClick={onClick}
			customStyle={defaultTabButtonStyle}
		>
			<TabButtonLabel>{label}</TabButtonLabel>
		</TabButton>
	)
);

DefaultTabButton.displayName = 'DefaultTabButton';
