import React from 'react';
import PropTypes from 'prop-types';
import { times } from 'ramda';

import Grid from '../Grid';
import SkeletorButton from '../SkeletorButton';

const SkeletorFormFooter = ({ actionButtonCount = 1, ...rest }) => (
	<Grid row pb={4} {...rest}>
		<Grid col={12}>
			{times(
				key => (
					<SkeletorButton key={key} ml={key > 0 ? 1 : 0} />
				),
				actionButtonCount
			)}
		</Grid>
	</Grid>
);

SkeletorFormFooter.propTypes = {
	/** Count of buttons in the footer section. */
	actionButtonCount: PropTypes.number,
};

export default SkeletorFormFooter;
