import { useContext, useEffect } from 'react';

import { TranslationFetchingContext } from './contexts';
import { MessageId } from './types';

export const useTranslationPrefetching = (messageId: MessageId | null | undefined) => {
	const { getIsTranslationFetched, registerMessage } = useContext(TranslationFetchingContext);

	useEffect(() => {
		if (!messageId || getIsTranslationFetched(messageId)) {
			return;
		}

		registerMessage(messageId);
	}, [getIsTranslationFetched, messageId, registerMessage]);
};
