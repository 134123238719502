import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	IdentifierDetail,
	UserType,
	fetchIdentifierDetails,
	makeSelectIdentifierDetailsByUserType,
} from './duck';

export const useIdentifierDetails = (userType: UserType): IdentifierDetail[] => {
	const dispatch = useDispatch();
	const identifierDetails = useSelector(makeSelectIdentifierDetailsByUserType(userType));

	useEffect(() => {
		if (!identifierDetails) {
			dispatch(fetchIdentifierDetails(userType));
		}
	}, [userType]);

	return identifierDetails ?? [];
};
