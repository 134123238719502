import PropTypes from 'prop-types';
import { cx, isNilOrEmpty, isNilOrEmptyString } from 'ramda-extension';
import React from 'react';
import { Text } from '@ci/atoms';

import Box from '../Box';
import Icon from '../Icon';
import Grid from '../Grid';
import './PanelGridItem.scss';

/**
 * PanelGridItem is used to display an item inside PanelGrid.
 * Each item can have a value, description, gauge and icon.
 * Children can also be rendered after all the previously mentioned features.
 */
const PanelGridItem = ({
	children,
	className,
	description,
	gauge,
	iconProps,
	value,
	alignItems = 'center',
	flexDirection = 'column',
	withoutBorder,
	locked,
	...otherProps
}) => (
	<Grid
		className={cx('panel__grid-item', { 'panel__grid-item--no-border': withoutBorder }, className)}
		alignItems={alignItems}
		flexDirection={flexDirection}
		justifyContent="spaceAround"
		{...otherProps}
	>
		{gauge && <Box className="panel__gauge">{gauge}</Box>}
		{locked && (
			<Box className="panel__value mb-2">
				<Icon type="collaterals" size={24} />
			</Box>
		)}
		{!locked && !isNilOrEmptyString(value) && (
			<Box className="panel__value">
				{!isNilOrEmpty(iconProps) && <Text iconProps={iconProps}>{value}</Text>}
				{isNilOrEmpty(iconProps) && value}
			</Box>
		)}
		{!isNilOrEmptyString(description) && <Text className="panel__desc">{description}</Text>}
		{children}
	</Grid>
);

PanelGridItem.propTypes = {
	...Grid.propTypes,
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Additional class names */
	className: PropTypes.string,
	/** Description of the item */
	description: PropTypes.node,
	/** Gauge contained inside the item */
	gauge: PropTypes.node,
	/** Properties of an icon inside value node */
	iconProps: PropTypes.object,
	/** Value contained inside the item */
	value: PropTypes.node,
	/** Disable item border */
	withoutBorder: PropTypes.bool,
};

export default PanelGridItem;
