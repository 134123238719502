"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.wrapRootElement = void 0;

var _wrapWithProvider = _interopRequireDefault(require("./wrap-with-provider"));

var wrapRootElement = _wrapWithProvider.default;
exports.wrapRootElement = wrapRootElement;