import PropTypes from 'prop-types';
import { mergeRight } from 'ramda';
import { cx } from 'ramda-extension';
import React, { forwardRef } from 'react';

import DataList from '../DataList';
import { ItemPropType } from '../DataList/DataList';

/**
 * Preconfigured DataList to show key-value info
 */
const InfoList = forwardRef(({ className, itemProps, items, ...rest }, ref) => (
	<DataList
		ref={ref}
		className={cx('data-list--info', className)}
		descFirst
		collapseRows
		itemProps={mergeRight({ justifyContent: 'between' }, itemProps)}
		rows={[items]}
		{...rest}
	/>
));

InfoList.displayName = 'forwardRef(InfoList)';

InfoList.propTypes = {
	/** Class to be added after the info list default classes */
	className: PropTypes.string,

	itemProps: PropTypes.object,

	/** Definition of InfoList items */
	items: PropTypes.arrayOf(ItemPropType).isRequired,
};

export default InfoList;
