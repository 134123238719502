import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';
import { classNamesByBreakpoint } from '@myci/utils';

import Box from '../Box';

const alignToClassName = classNamesByBreakpoint((align, breakpoint) =>
	breakpoint === 'xs' ? `text-${align}` : `text-${breakpoint}-${align}`
);

const Text = forwardRef(
	({ align, children, wrap, transform, className, color, weight, size, ...otherProps }, ref) => (
		<Box
			ref={ref}
			{...otherProps}
			className={cx(
				className,
				{
					[`text-${wrap}`]: wrap,
					[`text-${transform}`]: transform,
					[`text-${color}`]: color,
					[`text-${size}`]: size,
					[`font-weight-${weight}`]: weight,
				},
				alignToClassName && alignToClassName(align)
			)}
		>
			{children}
		</Box>
	)
);
Text.displayName = 'forwardRef(Text)';

Text.propTypes = {
	align: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.oneOf(['left', 'center', 'right']),
	]),
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	color: PropTypes.string,
	// color: PropTypes.oneOf([
	// 	'primary',
	// 	'secondary',
	// 	'success',
	// 	'danger',
	// 	'warning',
	// 	'info',
	// 	'light',
	// 	'dark',
	// 	'muted',
	// 	'white',
	// 	'teal',
	// ]),
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	transform: PropTypes.oneOf(['lowercase', 'uppercase', 'capitalize']),
	weight: PropTypes.oneOf(['extra-bold', 'bold', 'semibold', 'normal', 'light']),
	wrap: PropTypes.oneOf(['wrap', 'nowrap', 'truncate']),
};

Text.defaultProps = {
	// NOTE: Passed through to <Box />
	as: 'p',
};

export default Text;
