import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { map } from 'ramda';

import TemplatePageContent from '../TemplatePageContent';
import Heading from '../Heading';
import TabList from '../TabList';
import Box from '../Box';

const TabPageContent = ({ children, heading, tabs }) => {
	const tabPageContentHeading = useMemo(
		() => (
			<Heading as="h1" className="h1">
				{heading}
			</Heading>
		),
		[heading]
	);

	return (
		<TemplatePageContent heading={tabPageContentHeading}>
			<TabList
				mt={3}
				labels={map(
					({ name, path }) => ({
						name,
						path,
					}),
					tabs
				)}
			/>
			<Box className="content-inner">{children}</Box>
		</TemplatePageContent>
	);
};

TabPageContent.propTypes = {
	children: PropTypes.node,
	heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
			path: PropTypes.string,
		})
	),
};

export default TabPageContent;
