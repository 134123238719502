import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { cx, noop } from 'ramda-extension';
import { IdentityFormatter } from '@ci/formatters';
import Downshift from 'downshift';

import Grid from '../Grid';
import Icon from '../Icon';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import Button from '../Button';
import Checkbox from '../Checkbox';

const MultipleCheckboxDropdown = ({
	Formatter = IdentityFormatter,
	options = [],
	isOpen = false,
	onFieldChange = noop,
	toggleVisibility = noop,
	isSelected = noop,
	label,
	dropdownClassName,
	kind,
	iconType,
	footer,
	...rest
}) => (
	<Downshift itemToString={identity} isOpen={isOpen} {...rest}>
		{({ highlightedIndex }) => (
			<div
				className={cx('dropdown', { kind: `dropdown--${kind}` }, dropdownClassName, {
					open: isOpen,
				})}
			>
				<Button tabIndex="0" className="dropdown__selected" onClick={toggleVisibility}>
					{iconType && <Icon type={iconType} />}
					{label}
				</Button>
				{isOpen && (
					<Menu>
						{options.map((item, index) => (
							<MenuItem
								className={cx('dropdown__item', {
									'dropdown__item--active': highlightedIndex === index,
								})}
								key={item}
							>
								<Checkbox
									checked={isSelected(item)}
									label={<Formatter>{item}</Formatter>}
									name={`checkbox_${item}`}
									onChange={() => onFieldChange(item)}
								/>
							</MenuItem>
						))}
						{footer && (
							<Grid justifyContent="center" py={1}>
								{footer}
							</Grid>
						)}
					</Menu>
				)}
			</div>
		)}
	</Downshift>
);

MultipleCheckboxDropdown.propTypes = {
	Formatter: PropTypes.func,
	dropdownClassName: PropTypes.string,
	footer: PropTypes.node,
	iconType: PropTypes.string,
	isOpen: PropTypes.bool,
	isSelected: PropTypes.func,
	kind: PropTypes.string,
	label: PropTypes.node,
	onFieldChange: PropTypes.func,
	options: PropTypes.array,
	toggleVisibility: PropTypes.func,
};

export default MultipleCheckboxDropdown;
