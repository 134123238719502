import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

const Br = ({ as = 'br', ...rest }) => <Box as={as} {...rest} />;

Br.propTypes = {
	as: PropTypes.element,
};

export default Br;
