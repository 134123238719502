import React from 'react';

import Grid from '../Grid';
import Skeletor from '../Skeletor';

const SkeletorHeading = props => (
	<Grid row mb={3} {...props}>
		<Grid col={{ xs: 12, lg: 8 }} mb={{ xs: 3 }}>
			<Skeletor height={20} width={200} mb={2} />
			<Skeletor height={40} />
		</Grid>
	</Grid>
);

export default SkeletorHeading;
