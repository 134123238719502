import { TransitionSpeed, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { useMemo } from 'react';

import { ClassNamesByTransitionState, UseTransitionStyles } from '../types';

const transitionExitStyle = prepareStyle(() => ({
	opacity: 0,
	pointerEvents: 'none',
	transform: 'logical translateX(100%)',
}));

const transitionEnterStyle = prepareStyle(() => ({
	opacity: 1,
	transform: 'translateX(0)',
}));

const easeInOutBack = 'cubic-bezier(0.68, -0.6, 0.32, 1.6)';

interface TransitionBaseStyleProps {
	speed: TransitionSpeed;
}

const transitionBaseStyle = prepareStyle<TransitionBaseStyleProps>(
	({ transitions }, { speed }) => ({
		transitionDuration: transitions.speeds[speed],
		transitionProperty: 'transform, opacity',
		transitionTimingFunction: easeInOutBack,
	})
);

export const useBounceSlideTransitionStyles: UseTransitionStyles = speed => {
	const { applyStyle } = useStyles();
	const classNames: ClassNamesByTransitionState = useMemo(
		() => ({
			entering: applyStyle(transitionEnterStyle),
			entered: applyStyle(transitionEnterStyle),
			exiting: applyStyle(transitionExitStyle),
			exited: applyStyle(transitionExitStyle),
		}),
		[applyStyle]
	);

	const baseClassName = applyStyle(transitionBaseStyle, { speed });

	return { classNames, baseClassName };
};
