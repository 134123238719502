import { useCallback, useEffect, useRef } from 'react';

export const useDelayedHoverEffect = (
	callback: () => void,
	timeout: number,
	isEnabled: boolean
) => {
	const timerRef = useRef<number | null>(null);

	const startTimer = useCallback(() => {
		if (isEnabled) {
			timerRef.current = window.setTimeout(() => {
				callback();
			}, timeout);
		}
	}, [callback, timeout, isEnabled]);

	const cancelTimer = useCallback(() => {
		if (timerRef.current) {
			window.clearTimeout(timerRef.current);
			timerRef.current = null;
		}
	}, []);

	useEffect(() => {
		startTimer();

		return () => cancelTimer();
	}, [startTimer, cancelTimer, isEnabled]);

	return {
		onMouseOver: cancelTimer,
		onMouseLeave: startTimer,
	};
};
