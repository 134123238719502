import React from 'react';
import PropTypes from 'prop-types';

import EditableText from '../EditableText';

const EditableHeading = props => <EditableText as="h1" {...props} />;

EditableHeading.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
};

export default EditableHeading;
