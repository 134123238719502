// based on https://stackoverflow.com/a/52092093
export const openDataUrlInNewTab = dataUrl => {
	const dataUrlParts = dataUrl.split(',');
	const mimeType = dataUrlParts[0].split(':')[1];
	const data = dataUrlParts[1];
	const byteCharacters = atob(data);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const file = new Blob([byteArray], { type: `${mimeType};base64` });
	const fileURL = URL.createObjectURL(file);
	window.open(fileURL);
};
