import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const withTransition = (Component, { trigger, defaultTransition }) => {
	const WithTransition = ({ transition, ...otherProps }) => {
		const visibilityTrigger = otherProps[trigger];
		const resolvedTransition = transition || defaultTransition;

		if (resolvedTransition) {
			return (
				<TransitionGroup>
					{visibilityTrigger && (
						// NOTE: This entire mechanism is wrong. The element to transition should not unmount
						// when `visibilityTrigger` is falsy. Instead, it should stay mounted, but be hidden.
						// However, some modals rely on their content NOT being rendered when they are not visible,
						// meaning that the proper way to handle transitions would have to be opt-in, sadly.
						<CSSTransition {...resolvedTransition} in={visibilityTrigger}>
							<Component {...otherProps} />
						</CSSTransition>
					)}
				</TransitionGroup>
			);
		}

		return visibilityTrigger && <Component {...otherProps} />;
	};

	WithTransition.propTypes = {
		transition: PropTypes.object,
	};

	return WithTransition;
};

export default withTransition;
