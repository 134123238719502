import { useCallback, useEffect, useMemo, useState } from 'react';
import { head, indexOf, last, lte, map, o } from 'ramda';
import { debounce, isInBrowser } from '@myci/utils';

import { getBreakpointByWidth } from '../utils/getBreakpointByWidth';
import { getOrderedBreakpoints } from '../utils/getOrderedBreakpoints';

const defaultOrderderedBreakpoints = getOrderedBreakpoints();

const getMaxBreakpoint = o(head, last);

const createLte = (orderedBreakpoints, currentBreakpoint) => breakpoint => {
	const breakpoints = map(head, orderedBreakpoints);

	const orderBreakpoint = indexOf(breakpoint, breakpoints);
	const orderCurrentBreakpoint = indexOf(currentBreakpoint, breakpoints);

	return lte(orderCurrentBreakpoint, orderBreakpoint);
};

/**
 * @typedef {Object} ViewportReturn
 * @property {String} breakpoint - resolved breakpoint
 */

/**
 * @arg {Array} orderedBreakpoints  - list of pairs - breakpoint-minWidth - e.g: [["xs", 0], ["sm", 10]]
 *
 * @return {ViewportReturn} Returns breakpoint according to current viewport size
 */
const useViewport = (orderedBreakpoints = defaultOrderderedBreakpoints) => {
	const getBreakpoint = useMemo(
		() => getBreakpointByWidth(orderedBreakpoints),
		[orderedBreakpoints]
	);

	const maxBreakpoint = getMaxBreakpoint(orderedBreakpoints);

	const [breakpoint, setBreakpoint] = useState(maxBreakpoint);

	const handleWindowResize = useCallback(() => {
		setBreakpoint(getBreakpoint(window.innerWidth));
	}, [getBreakpoint]);

	const debouncedHandleWindowResize = useMemo(
		() => debounce(100, handleWindowResize),
		[handleWindowResize]
	);

	useEffect(() => {
		window.addEventListener('resize', debouncedHandleWindowResize);

		return () => {
			window.removeEventListener('resize', debouncedHandleWindowResize);
		};
	}, [debouncedHandleWindowResize]);

	useEffect(() => {
		if (isInBrowser()) {
			setBreakpoint(getBreakpoint(window.innerWidth));
		}
	}, [getBreakpoint]);

	return { lte: createLte(orderedBreakpoints, breakpoint), breakpoint };
};

export default useViewport;
