import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

const AccordionRow = ({ primary, children, className }) => (
	<div
		className={cx({ 'accordion__item-row--primary': primary }, 'accordion__item-row', className)}
	>
		{children}
	</div>
);

AccordionRow.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	primary: PropTypes.bool,
};

export default AccordionRow;
