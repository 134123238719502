import { useEffect, useRef } from 'react';
import { arePassiveEventsSupported, isInBrowser } from '@ci/utils';
import { flip, forEach, reduce } from 'ramda';
import { isFunction } from 'ramda-extension';
import invariant from 'invariant';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

const forEachEvent = flip(forEach)([MOUSEDOWN, TOUCHSTART]);

const getOptions = event =>
	event !== TOUCHSTART || !arePassiveEventsSupported() ? null : { passive: true };

/**
 * Fork of https://github.com/Andarist/use-onclickoutside
 */
export const useOnClickOutside = (refs, handler) => {
	invariant(isFunction(handler), 'Handler should be a function');

	if (!isInBrowser()) {
		return;
	}

	const handlerRef = useRef(handler);

	useEffect(() => {
		handlerRef.current = handler;
	});

	useEffect(() => {
		if (!handler) {
			return;
		}

		const listener = event => {
			const isClickedOutside = reduce(
				(isClickedOutsideAllPreviousRefs, ref) =>
					isClickedOutsideAllPreviousRefs &&
					Boolean(ref.current && handlerRef.current && !ref.current.contains(event.target)),
				true,
				refs
			);

			if (isClickedOutside) {
				handlerRef.current(event);
			}
		};

		forEachEvent(event => {
			document.addEventListener(event, listener, getOptions(event));
		});

		return () => {
			forEachEvent(event => {
				document.removeEventListener(event, listener, getOptions(event));
			});
		};
	}, [handler]);
};

export default useOnClickOutside;
