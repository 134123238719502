import React from 'react';
import PropTypes from 'prop-types';
import { Match, Redirect } from '@gatsbyjs/reach-router';

const unauthorizedUrl = '/app/my-profile/settings/unauthorized';

const CheckUnauthorizedUser = ({ accountUnauthorized, children, isPasswordChangeNecessary }) => {
	if (!accountUnauthorized || isPasswordChangeNecessary) {
		return children;
	}

	return (
		<Match path={unauthorizedUrl}>
			{({ match }) => (!match ? <Redirect noThrow to={unauthorizedUrl} /> : children)}
		</Match>
	);
};
CheckUnauthorizedUser.propTypes = {
	accountUnauthorized: PropTypes.bool,
	children: PropTypes.node,
	isPasswordChangeNecessary: PropTypes.bool,
};
export default CheckUnauthorizedUser;
