import { curry, pathEq, any, path, T, replace, assocPath } from 'ramda';
import { isFunction } from 'ramda-extension';
import { ensureArray, typeEq } from '@ci/utils';
import { Action } from 'redux';

import {
	ActionTypes,
	ErrorEventAction,
	RequestAction,
	SuccessEventAction,
	ResponseEventAction,
} from './actions';

const getHasRequestType = typeEq(ActionTypes.REQUEST);
const getHasSuccessType = typeEq(ActionTypes.SUCCESS);
const getHasErrorType = typeEq(ActionTypes.ERROR);
const getHasRetryingType = typeEq(ActionTypes.RETRYING);
const getHasRemovePendingRequestType = typeEq(ActionTypes.REMOVE_PENDING_REQUEST);

const originPath = ['meta', 'origin'];
const originTypePath = [...originPath, 'type'];
const requestPath = ['meta', 'request'];

export const getOrigin = path(originPath);
export const getOriginType = path<string>(originTypePath);
export const originTypeEq = pathEq(originTypePath);
export const setOrigin = assocPath(originPath);

export const getRequest = path(requestPath);

type ApiCriterion = string | string[] | ((action: Action) => boolean);

const getIsApiActionMatched = (criterion: ApiCriterion, action: Action) => {
	if (isFunction(criterion)) {
		return criterion(action);
	}

	return any(type => originTypeEq(type, action), ensureArray(criterion));
};

export const getIsRequestAction = curry(
	(criterion, action) => getHasRequestType(action) && getIsApiActionMatched(criterion, action)
);

export const getIsSuccessResponseAction = curry(
	(criterion, action) => getHasSuccessType(action) && getIsApiActionMatched(criterion, action)
);

export const getIsErrorResponseAction = curry(
	(criterion, action) => getHasErrorType(action) && getIsApiActionMatched(criterion, action)
);

export const getIsResponseAction = curry(
	(criterion, action) =>
		(getHasSuccessType(action) || getHasErrorType(action)) &&
		getIsApiActionMatched(criterion, action)
);

export const getIsRetryingAction = curry(
	(criterion, action) => getHasRetryingType(action) && getIsApiActionMatched(criterion, action)
);

export const getIsRemovePendingRequestAction = curry(
	(criterion, action) =>
		getHasRemovePendingRequestType(action) && getIsApiActionMatched(criterion, action.payload)
);

export const getIsAnyRequestAction: (action: unknown) => action is RequestAction =
	getIsRequestAction(T) as any;

export const getIsAnySuccessResponseAction: (action: unknown) => action is SuccessEventAction =
	getIsSuccessResponseAction(T) as any;

export const getIsAnyErrorResponseAction: (action: unknown) => action is ErrorEventAction =
	getIsErrorResponseAction(T) as any;

export const getIsAnyResponseAction: (action: unknown) => action is ResponseEventAction =
	getIsResponseAction(T) as any;

export const getIsAnyRemovePendingRequestAction = getIsRemovePendingRequestAction(T);

export const replaceChunk = replace(/:chunk/g);
