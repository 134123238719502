import { alwaysEmptyArray, alwaysEmptyObject, applyCompose, isNumber } from 'ramda-extension';
import { T, always, cond, equals, ifElse, o, of, prop, without } from 'ramda';

const executeReducer = reducer => data => ({
	state: { ...data.state, ...reducer(data) },
	action: data.action,
});

export const EventTypes = { OPENING: 'OPENING', CLOSING: 'CLOSING', OPEN_ALL: 'OPEN_ALL' };

const isClosing = equals(EventTypes.CLOSING);
const isOpening = equals(EventTypes.OPENING);
const isOpenAll = equals(EventTypes.OPEN_ALL);

export const baseFunctionReducer = executeReducer(
	({ state, state: { openIndices }, action: { index, type } }) =>
		cond([
			[isClosing, always({ openIndices: without([index], openIndices) })],
			[isOpening, always({ openIndices: [...openIndices, index] })],
			[T, always(state)],
		])(type)
);

export const openAllReducer = executeReducer(({ state, action: { indicesLength, type } }) => ({
	openIndices: isOpenAll(type) ? Array.from(Array(indicesLength).keys()) : state.openIndices,
}));

export const onlySingleOpenReducer = executeReducer(({ state, action: { type, index } }) =>
	cond([
		[isClosing, alwaysEmptyObject],
		[isOpening, always({ openIndices: [index] })],
		[T, always(state)],
	])(type)
);

export const alwaysAtLeastOneOpenReducer = executeReducer(
	({ state: { openIndices }, action: { type, index } }) =>
		isClosing(type) && openIndices.length === 0 ? { openIndices: [index] } : {}
);

export const combineReducers = reducers => o(prop('state'), applyCompose(reducers));

export const getDefaultOpenIndicies = ifElse(isNumber, of, alwaysEmptyArray);
