import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Grid from '../Grid';
import Text from '../Text';
import { SLIDE_MODAL_CLASS } from '../SlideModal';

const SlideModalHeader = ({ children, className, title, subtitle, details, ...otherProps }) => (
	<Grid
		justifyContent="between"
		alignItems="end"
		flexWrap={['wrap']}
		className={cx(`${SLIDE_MODAL_CLASS}__header`, className)}
		{...otherProps}
	>
		<Text className={`${SLIDE_MODAL_CLASS}__title`}>{title}</Text>
		{subtitle && <Text className={`${SLIDE_MODAL_CLASS}__sub-title`}>{subtitle}</Text>}
		{details && (
			<Grid
				flex
				flexDirection={['column', 'row']}
				flexWrap="wrap"
				className={`${SLIDE_MODAL_CLASS}__details`}
			>
				{details}
			</Grid>
		)}
		{children}
	</Grid>
);

SlideModalHeader.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	details: PropTypes.node,
	subtitle: PropTypes.node,
	title: PropTypes.node.isRequired,
};

export default SlideModalHeader;
