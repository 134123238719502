import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { map, omit } from 'ramda';
import { cx } from 'ramda-extension';
import { responsiveStringOrNumberPropType } from '@ci/react-utils';
import { isNotNilNorEmpty } from '@myci/utils';

import { propToClassName } from '../../utils/propToClassName';

const getClassName = propToClassName(isNotNilNorEmpty);

const displayToClassName = getClassName('d');

const BoxModelProps = [
	'm',
	'p',
	'mx',
	'my',
	'mt',
	'mr',
	'mb',
	'ml',
	'px',
	'py',
	'pt',
	'pr',
	'pb',
	'pl',
	'order',
];
const omitDimensions = omit(BoxModelProps);

const Box = forwardRef(({ children, as: Comp, className, display, ...rest }, ref) => (
	<Comp
		{...omitDimensions(rest)}
		className={cx(
			...map(x => getClassName(x)(rest[x]))(BoxModelProps),
			displayToClassName(display),
			className
		)}
		ref={ref}
	>
		{children}
	</Comp>
));

Box.displayName = 'Box';

Box.propTypes = {
	/** Tag used for root component */
	as: PropTypes.any,
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	display: responsiveStringOrNumberPropType,
	m: responsiveStringOrNumberPropType,
	mb: responsiveStringOrNumberPropType,
	ml: responsiveStringOrNumberPropType,
	mr: responsiveStringOrNumberPropType,
	mt: responsiveStringOrNumberPropType,
	mx: responsiveStringOrNumberPropType,
	my: responsiveStringOrNumberPropType,
	order: responsiveStringOrNumberPropType,
	p: responsiveStringOrNumberPropType,
	pb: responsiveStringOrNumberPropType,
	pl: responsiveStringOrNumberPropType,
	pr: responsiveStringOrNumberPropType,
	pt: responsiveStringOrNumberPropType,
	px: responsiveStringOrNumberPropType,
	py: responsiveStringOrNumberPropType,
};

Box.defaultProps = {
	as: 'div',
};

export default Box;
