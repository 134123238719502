import { always, drop, equals, ifElse, prop, startsWith } from 'ramda';
import { alwaysEmptyArray, isArray, isObject } from 'ramda-extension';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FILTERING_ANY_VALUE } from '@ci/api';
import { useIntl } from 'react-intl';

import m from '../messages';

const IS_SELECT_STATIC = '__IS_SELECT_STATIC__';

const isAllStaticChecked = (currentValue, allItems) => equals(currentValue.length, allItems.length);

export const SelectStatics = {
	ANY: {
		key: 'ANY',
		message: m.selectStatics_any,
		value: FILTERING_ANY_VALUE,
		[IS_SELECT_STATIC]: true,
		default: true,
	},
	ALL: {
		key: 'ALL',
		message: m.selectStatics_all,
		[IS_SELECT_STATIC]: true,
		default: true,
	},
	NOT_SPECIFIED: {
		key: 'NOT_SPECIFIED',
		message: m.selectStatics_notSpecified,
		[IS_SELECT_STATIC]: true,
		default: true,
	},
};

export const getPlaceholderStatic = message => ({
	key: 'PLACEHOLDER',
	[IS_SELECT_STATIC]: true,
	default: true,
	hidden: true,
	message,
});

export const isSelectStatic = prop(IS_SELECT_STATIC);

export const selectStaticPropType = PropTypes.shape({
	key: PropTypes.string,
	message: PropTypes.object,
	value: PropTypes.any,
	hidden: PropTypes.bool,
	default: PropTypes.bool,
});

export const useStaticAwareFormatter = formatter => {
	const intl = useIntl();

	return useMemo(
		() =>
			formatter.wrap((delegate, value) => {
				if (prop(IS_SELECT_STATIC, value)) {
					return intl.formatMessage(value.message);
				}

				return delegate(value);
			}),
		[formatter, intl]
	);
};

// NOTE: Behavior is split into a different object as to not store functions in redux-form state.
export const SelectStaticBehaviors = {
	ANY: {
		onSelect: ifElse(isArray, alwaysEmptyArray, always(SelectStatics.ANY)),
	},
	ALL: {
		onSelect: (currentValue, allItems) =>
			isAllStaticChecked(currentValue, allItems) ? [] : [...allItems],
		isChecked: isAllStaticChecked,
	},
};

export const getSelectSortCharacter = item => (isSelectStatic(item) ? '0' : 'z');

export const selectSorter = getRenderedValue => item => {
	const renderedValue = getRenderedValue(item);

	// NOTE: _ is used to put static always as first option
	return isSelectStatic(item) ? `_${renderedValue}` : renderedValue;
};

// NOTE: This prefixing logic is used so that statics don't conflict with e.g. `ANY` ID.
const staticPrefix = 'static_';

export const getItemKey = item => (isObject(item) ? `${staticPrefix}${item.key}` : item);
export const isStaticKey = startsWith(staticPrefix);
export const getOriginalStaticKey = drop(staticPrefix.length);
